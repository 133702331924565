var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('SkeletonFooter'):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"container footer mt-5 pt-5"},[_c('div',{staticClass:"header-footer"}),_c('div',{staticClass:"row pt-3 mb-3"},[_c('div',{staticClass:"col-md-3 mt-m"},[_c('h4',{staticClass:"mb-3 mt-md-3 mt-lg-5"},[_vm._v("Info Pelanggan")]),_c('router-link',{attrs:{"to":"/"}},[_vm._v("Beranda")]),_c('br'),_c('router-link',{attrs:{"to":'/berita-detail?slug_berita=' + _vm.news[0]?.slug}},[_vm._v("Berita")]),_c('br'),_c('router-link',{attrs:{"to":"/tentang-kami"}},[_vm._v("Tentang Kami")])],1),_c('div',{staticClass:"col-md-3"},[_c('h4',{staticClass:"mb-3 mt-md-3 mt-lg-5"},[_vm._v("Sosial Media")]),_c('div',{staticClass:"sosmed mb-2"},[(_vm.setting?.instagram !== '' || _vm.setting?.instagram !== null)?_c('a',{attrs:{"href":(_vm.setting?.instagram?.substring(0, 4) === 'http' ? '' : 'https://') +
            _vm.setting?.instagram,"target":"_blank"}},[_c('svg',{staticStyle:{"transform":"","msfilter":""},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"}}),_c('circle',{attrs:{"cx":"16.806","cy":"7.207","r":"1.078"}}),_c('path',{attrs:{"d":"M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"}})])]):_vm._e(),(_vm.setting?.tiktok !== '' || _vm.setting?.tiktok !== null)?_c('a',{attrs:{"href":(_vm.setting?.tiktok?.substring(0, 4) === 'http' ? '' : 'https://') +
            _vm.setting?.tiktok,"target":"_blank"}},[_c('svg',{staticStyle:{"transform":"","msfilter":""},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"}})])]):_vm._e(),(_vm.setting?.facebook !== '' || _vm.setting?.facebook !== null)?_c('a',{attrs:{"href":(_vm.setting?.facebook?.substring(0, 4) === 'http' ? '' : 'https://') +
            _vm.setting?.facebook,"target":"_blank"}},[_c('svg',{staticStyle:{"transform":"","msfilter":""},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"}})])]):_vm._e()])]),_vm._m(0),_vm._m(1)]),_vm._m(2)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2"},[_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.yandit301.ArysMikro&pcampaignid=web_share","target":"_blank"}},[_c('figure',{staticClass:"image-download pt-md-4 pt-xl-5"},[_c('img',{attrs:{"src":require("../assets/img/installandroid.svg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('figure',{staticClass:"image-footer"},[_c('img',{attrs:{"src":require("../assets/img/bankdata.webp"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-bottom"},[_c('p',[_vm._v("@Copyright Bithouse 2023")])])
}]

export { render, staticRenderFns }