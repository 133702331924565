<template>
  <div>
    <SkeletonKategori v-if="loading" />
    <div v-if="!loading" class="container produk mt-3 mt-md-5 pl-2 over-data">
      <div class="produk-header">
        <h4><b>Kategori</b></h4>
        <!-- <router-link :to="'/kategori?page=1'">Lihat Semua <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 0.4);transform: ;msFilter:;"><path d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path></svg></router-link> -->
      </div>
      <div class="mt-3">
        <VueSlickCarousel v-bind="settings">
          <div class="px-1" v-for="(n, index) in lengthkategori" :key="n">
            <router-link
              class="link-kategori"
              :to="'/kategori?kategori=' + kategori[index]?.id"
              v-if="kategori[index]?.id"
            >
              <div class="card w-cardpb-2">
                <figure class="image-kategori d-block">
                  <img
                    :src="$imgProduct + kategori[index]?.image"
                    alt=""
                    :id="'image' + kategori[index]?.id"
                    @error="imageerror(kategori[index]?.id)"
                  />
                  <img
                    src="../../assets/img/image_icon.png"
                    class="d-none"
                    :id="'errorimage' + kategori[index]?.id"
                  />
                </figure>
                <p class="text-center text-kategori mb-3">{{ kategori[index].name }}</p>
              </div>
            </router-link>
            <router-link
              class="link-kategori mt-1"
              :to="'/kategori?kategori=' + kategori[index + lengthkategori]?.id"
              v-if="kategori[index + lengthkategori]?.id"
            >
              <div class="card w-card pb-2 mt-2">
                <figure class="image-kategori">
                  <img
                    :src="$imgProduct + kategori[index + lengthkategori]?.image"
                    :id="'image' + kategori[index + lengthkategori]?.id"
                    alt=""
                    @error="imageerror(kategori[index + lengthkategori]?.id)"
                  />
                  <img
                    src="../../assets/img/image_icon.png"
                    class="d-none"
                    :id="'errorimage' + kategori[index + lengthkategori]?.id"
                  />
                </figure>
                <p class="text-center text-kategori mb-3">
                  {{ kategori[index + lengthkategori].name }}
                </p>
              </div>
            </router-link>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from "axios";
import SkeletonKategori from "../Skeleton/Homepage/SkeletonKategori.vue";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  data() {
    return {
      datahey: [],
      seller: [],
      loading: true,
      dataharga: 0,
      lengthkategori: 0,
      settings: {
        dots: false,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: "0px",
        responsive: [
          {
            breakpoint: 2559,
            settings: {
              slidesToShow: 11,
              slidesToScroll: 1,
              dots: false,
            },
          },
          {
            breakpoint: 1640,
            settings: {
              slidesToShow: 9,
              slidesToScroll: 1,
              dots: false,
            },
          },
          {
            breakpoint: 1240,
            settings: {
              slidesToShow: 7,
              slidesToScroll: 1,
              dots: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
              dots: false,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 350,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  components: { VueSlickCarousel, SkeletonKategori },
  async mounted() {
    try {
      this.loading = true;
      if (this.kategori.length == 0) {
        await this.$store.dispatch("category");
      }
      if (this.kategori.length % 2 === 0) {
        this.lengthkategori = Math.floor(this.kategori.length / 2);
      } else {
        this.lengthkategori = Math.floor(this.kategori.length / 2) + 1;
      }
      this.loading = false;
    } catch (error) {
      this.loading = false;
      return error;
    }
  },
  computed: {
    kategori: function () {
      return this.$store.state.category;
    },
  },
  methods: {
    imageerror(id) {
      document.getElementById("image" + id).classList.add("d-none");
      document.getElementById("errorimage" + id).classList.remove("d-none");
      document.getElementById("errorimage" + id).classList.add("d-block");
    },
    
  },
};
</script>

<style scoped>
@media (max-width: 500px) {
  /* ::v-deep .slick-initialized .slick-slide {
    width: 115px !important;
  } */
  ::v-deep .slick-prev {
    left: -20px !important;
  }
  ::v-deep .slick-next {
    right: -20px !important;
  }
  .image-kategori {
    height: 70px !important;
    width: 70px !important;
  }
  .text-kategori {
    height: 40px !important;
    font-size: 0.8rem !important;
    margin-bottom: 0px !important;
  }
  .over-data {
    padding-left: 1.4rem !important;
    padding-right: 1.4rem !important;
  }
}
::v-deep .slick-prev:before,
.slick-next:before {
  color: #000 !important;
}
::v-deep .slick-next:before {
  color: #000 !important;
}
.card,
.card p,
.link-kategori {
  color: #000;
  text-decoration: none;
}
.image-kategori {
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
.image-kategori img {
  width: 100%;
  height: 100%;
}
.text-kategori {
  height: 30px;
  font-size: 0.9rem;
}
</style>
