<template>
  <div class="home">
    <Navbar/>
    <SkeletonAbout v-if="loading"/>
    <div v-if="!loading" class="p-3 p-md-5 about-data">
        <div class="header-about"></div>
        <h1 class="text-center">Tentang Kami</h1>
        <div class="text-center mt-3" v-html="setting?.about"></div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Navbar from '@/components/NavbarUno.vue'
import Footer from '@/components/FooterUno.vue'
import SkeletonAbout from '@/components/Skeleton/SkeletonAbout.vue'

export default {
  name: 'HomeView',
  data(){
    return{
        loading:true
    }
  },
  components: {
    Navbar,
    Footer,
    SkeletonAbout,
  },
  async mounted(){
        try {
            this.loading = true
        if (this.setting instanceof Promise == true || this.setting == []) {
            await this.$store.dispatch("setting");
        }
        this.loading =false
        } catch (error) {
        this.loading =false
        return error
        }
        
    },
    computed:{
        setting: function (){
            return this.$store.state.setting;
        },
    },
}
</script>
<style scoped>
.header-about{
    padding-top: 5rem;
}
.about-data{
    min-height: 70vh;
}
</style>
