<template>
  <div class="profile section">
    <Navbar />
    <div class="header-nav"></div>
    <div class="profile-container container">
      <!-- <div class="d-flex justify-content-end">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Beranda</a></li>
                <li class="breadcrumb-item active" aria-current="page">Beranda</li>
              </ol>
            </nav>
          </div> -->

      <div class="profile-data row">
        <div class="col-lg-3 d-web">
          <ul class="list-group-custom" id="profilList">
            <li @click="kliclink()">
              <router-link
                :class="[
                  {
                    'router-link-active active':
                      this.$router.currentRoute.path === '/profil/beranda',
                  },
                ]"
                to="/profil/beranda"
                @click="showContent('DashboardUser', $event)"
                class="list-group-custom-item dashboard"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-3"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="transform: ; msfilter: "
                >
                  <path
                    d="M4 13h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1zm-1 7a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v4zm10 0a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v7zm1-10h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1z"
                  ></path>
                </svg>
                Dashboard
              </router-link>
            </li>
            <li>
              <router-link
                :class="[
                  {
                    'router-link-active active':
                      this.$router.currentRoute.path === '/profil/sunting-profil',
                  },
                ]"
                to="/profil/sunting-profil"
                @click="showContent('form', $event)"
                class="list-group-custom-item form"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-3"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="transform: ; msfilter: "
                >
                  <path
                    d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"
                  ></path>
                </svg>
                Sunting Pengguna
              </router-link>
            </li>
            <li @click="kliclink()">
              <router-link
                :class="[
                  {
                    'router-link-active active':
                      this.$router.currentRoute.path === '/profil/transaksi' ||
                      this.$router.currentRoute.path === '/profil/detailtransaksi',
                  },
                ]"
                to="/profil/transaksi"
                @click="showContent('transaksi', $event)"
                class="list-group-custom-item"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-3"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="transform: ; msfilter: "
                >
                  <path
                    d="M4 7h11v2H4zm0 4h11v2H4zm0 4h7v2H4zm15.299-2.708-4.3 4.291-1.292-1.291-1.414 1.415 2.706 2.704 5.712-5.703z"
                  ></path>
                </svg>
                Daftar Transaksi
              </router-link>
            </li>
            <li>
              <router-link
                to="/profil/chat"
                @click="showContent('chat', $event)"
                class="list-group-custom-item"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-3"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="transform: ; msfilter: "
                >
                  <path
                    d="M20 2H4c-1.103 0-2 .897-2 2v18l5.333-4H20c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm0 14H6.667L4 18V4h16v12z"
                  ></path>
                  <circle cx="15" cy="10" r="2"></circle>
                  <circle cx="9" cy="10" r="2"></circle>
                </svg>
                Chat
              </router-link>
            </li>
            <li @click="kliclink()">
              <router-link
                :class="[
                  {
                    'router-link-active active':
                      this.$router.currentRoute.path === '/profil/alamat' ||
                      this.$router.currentRoute.path === '/profil/tambah-alamat' ||
                      this.$router.currentRoute.path === '/profil/edit-alamat',
                  },
                ]"
                to="/profil/alamat"
                @click="showContent('alamat', $event)"
                class="list-group-custom-item"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-3"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="transform: ; msfilter: "
                >
                  <path
                    d="m12 17 1-2V9.858c1.721-.447 3-2 3-3.858 0-2.206-1.794-4-4-4S8 3.794 8 6c0 1.858 1.279 3.411 3 3.858V15l1 2zM10 6c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2z"
                  ></path>
                  <path
                    d="m16.267 10.563-.533 1.928C18.325 13.207 20 14.584 20 16c0 1.892-3.285 4-8 4s-8-2.108-8-4c0-1.416 1.675-2.793 4.267-3.51l-.533-1.928C4.197 11.54 2 13.623 2 16c0 3.364 4.393 6 10 6s10-2.636 10-6c0-2.377-2.197-4.46-5.733-5.437z"
                  ></path>
                </svg>
                Detail Alamat
              </router-link>
            </li>
            <li @click="kliclink()">
              <a class="list-group-custom-item" @click="logout">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-3"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="transform: ; msfilter: "
                >
                  <path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
                  <path
                    d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"
                  ></path>
                </svg>
                Keluar
              </a>
            </li>
          </ul>
        </div>
        <b-modal id="modal-loading" centered hide-footer hide-header>
          <div class="text-center">
            <b-spinner variant="light"></b-spinner>
          </div>
        </b-modal>

        <div class="col-lg-9">
          <router-view></router-view>
          <!-- <DashboardUser v-if="profilContent === 'DashboardUser'"></DashboardUser>
              <FormProfile v-else-if="profilContent === 'form'"></FormProfile>
              <complain-data v-else-if="profilContent === 'komplain'"></complain-data>
              <add-complain v-else-if="profilContent === 'addkomplain'"></add-complain> -->
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import '../../assets/css/sweetalertstyle.css'
import Navbar from "@/components/NavbarUno.vue";
import Footer from "@/components/FooterUno.vue";
// import DashboardUser from '../../components/DashboardUser.vue'
// import FormProfile from '../../components/FormProfile.vue'
// import ComplainData from '../../components/ComplainUser/ComplainData.vue'
// import AddComplain from '../../components/ComplainUser/AddComplain.vue'

export default {
  name: "profile-component",
  data() {
    return {
      profilContent: "DashboardUser",
      setdata: false,
    };
  },
  components: {
    Navbar,
    Footer,
    // FormProfile,
    // ComplainData,
    // AddComplain
  },
  computed: {
    statussunting() {
      return this.$store.state.suntingdata;
    },
  },
  watch: {
    "$router.currentRoute.path": {
      handler: function () {
        // console.log('link beribah');
      },
      deep: true,
    },
  },
  methods: {
    kliclink() {
      if (this.statussunting === true) {
        this.$store.dispatch("suntingdata", false);
        this.$store.dispatch("user");
      }
    },
    showContent(param, event) {
      const item = document.querySelectorAll(".list-group-custom-item");

      item.forEach((element) => {
        element.classList.remove("active");
      });

      this.profilContent = param;
      event.target.classList.add("active");
    },
    logout() {
      swal("Apakah anda yakin untuk keluar?", "", "info", {
        buttons: ["Batal", "Ya"],
      }).then((res) => {
        if (res) {
          // this.setModal('close')
          this.$bvModal.show("modal-loading");
          axios
            .post("/customer/logout")
            .then(() => {
              // menghapus token-token yang disimpan
              this.$bvModal.hide("modal-loading");
              this.$cookies.remove("token");
              localStorage.removeItem("cart_id");
              this.$cookies.remove("id_checkout");
              this.$cookies.remove("id_transaction");
              this.auth = false;
              window.location.reload();
              this.$router.push("/");
            })
            .catch(() => {});
        }
      });
    },
  },
};
</script>
<style scoped>
::v-deep #modal-loading .modal-content {
  background-color: transparent;
  border: 0px !important;
}
::v-deep .swal-button-container > .swal-button {
  background-color: #000 !important;
}
.d-web {
  display: none;
}
.mr-3 {
  margin-right: 0.8rem;
}
.header-nav {
  height: 4rem;
}
.profile-data {
  padding-right: 0px;
  padding-left: 0px;
  margin: 0px !important;
}
.profile-content {
  padding-right: 0px;
  padding-left: 0px;
}

.profile-content-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.list-group-custom {
  padding: 1rem;
  border-radius: 0.5rem;
  list-style-type: none;
  border-radius: 10px;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}

.list-group-custom-item {
  color: #000;
  fill: #000;
  padding: 0.825rem 0.5rem;
  width: 100%;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-decoration: none;
  cursor: pointer;
}

.list-group-custom-item.active,
.list-group-custom-item:hover {
  color: #1f7acf;
  fill: #1f7acf;
}

@media (max-width: 400px) {
  .profile-container,
  .profile-container .col-lg-3,
  .profile-container .col-lg-9 {
    padding: 0px;
  }
}
@media (min-width: 319px) {
}
@media (min-width: 374px) {
}
@media (min-width: 424px) {
}
@media (min-width: 576px) {
  .profile-content {
    width: 80%;
  }

  .profile-content-container {
    flex-direction: row;
    column-gap: 1rem;
  }
}
@media (min-width: 767px) {
  .d-web {
    display: block;
  }
  .header-nav {
    height: 9rem;
  }
}
@media (min-width: 1023px) {
  .profile-data {
    padding-right: (1, 5rem, 0.75rem);
    padding-left: (1, 5rem, 0.75rem);
    margin: 0px !important;
  }
  .profile-content {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
@media (min-width: 1100px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1439px) {
}
@media (min-width: 1500px) {
}
@media (min-width: 2000px) {
}
</style>
