<template>
  <div>
    <div v-if="loading">
      <Loader></Loader>
      <SkeletonComplain></SkeletonComplain>
    </div>
    <div v-if="!loading">
      <div v-if="checkarray(complain).length > 0">
        <AddComplain @datareload="datareload" :datatransaksi="complain"></AddComplain>
      </div>
      <div v-if="cekdata">
        <div class="p-md-5">
          <img src="../../assets/img/notfounddata.webp" class="img-none" alt="" />
        </div>
        <p class="w-100 mt-3" style="text-align: center">Anda belum memilliki Komplain</p>
      </div>
      <div v-else-if="datacomplain">
        <div
          class="container-fluid"
          v-for="(complaindata, index) in datacomplain"
          :key="index"
        >
          <div v-if="complaindata.complaint">
            <h4 class="mt-3">Komplain Produk {{ index + 1 }}</h4>
            <DetailComplaint
              :complaindata="complaindata"
              :datatransaksi="datatrans(complaindata?.id)"
            ></DetailComplaint>
          </div>
        </div>
      </div>
      <div v-else-if="datacomplain.length > 0">
        <div
          class="container-fluid"
          v-for="(complaindata, index) in datacomplain"
          :key="index"
        >
          <div v-if="complaindata.complaint">
            <h4 class="mt-3">Komplain Produk {{ index + 1 }}</h4>
            <DetailComplaint
              :complaindata="complaindata"
              :datatransaksi="datatrans(complaindata?.id)"
            ></DetailComplaint>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import AddComplain from './AddComplain.vue'
import axios from "axios";
import DetailComplaint from "./DetailComplain.vue";
import AddComplain from "./AddComplain.vue";
import SkeletonComplain from "../../components/Skeleton/Transaksi/ComplainData.vue";
import Loader from "@/components/Loader/Loader.vue";
export default {
  props: ["detailtransaksi"],
  data() {
    return {
      datatransaksi: {},
      complain: [],
      datacomplain: [],
      transaksi_id: "",
      loading: true,
      cekdata: true,
    };
  },
  components: {
    AddComplain,
    DetailComplaint,
    SkeletonComplain,
    Loader,
  },
  async mounted() {
    let dataid = this.$route.query.id_transaksi;
    
    this.complain = this.detailtransaksi.complaint;
    this.datatransaksi = this.detailtransaksi?.data;

    await axios
      .get("/transaction/complaint/" + dataid)
      .then((res) => {
        this.loading = false;
        let Komplaindata = res.data.data;
        let k = 0;
        for (let i = 0; i < Komplaindata?.transaction_detail.length; i++) {
          if (Komplaindata.transaction_detail[k].complaint) {
            this.datacomplain.push(Komplaindata.transaction_detail[k]);
            this.cekdata = false;
          }
          k++;
        }
      })
      .catch(() => {
        this.loading = false;
      });
  },
  methods: {
    datatrans(id){
     var datacomplaint = this.datatransaksi.transaction_detail.filter(function(value){ 
                return value.id == id;
            });
     return datacomplaint[0]

    },
    checkarray(data){
      return Object.keys(data)
    },
    datareload(value) {
      this.loading = false;
      this.datacomplain = [];
      this.complain = value?.transaksidata?.complaint;
      let complaindetail = value.datacomplaint;
      // ini dilakukan untuk menampilkan data yang sudah di komplain tanpa harus merequest kembali namun bisa ditampilkan langsung
      if (complaindetail) {
        let k = 0;
        for (let i = 0; i < complaindetail?.transaction_detail.length; i++) {
          if (complaindetail.transaction_detail[k].complaint) {
            this.datacomplain.push(complaindetail.transaction_detail[k]);
            this.cekdata = false;
          }
          k++;
        }
      }
      this.$emit("transaksidata", value.transaksidata);
      this.datatransaksi = value.transaksidata?.data;
      // this.loading = false;
    },
  },
};
</script>
<style scoped>
.img-none {
  width: 60%;
  display: block;
  margin: 0 auto;
}
@media (max-width: 500px) {
  ::v-deep .container-fluid {
    padding: 0px !important;
  }
}
</style>
