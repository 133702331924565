<template>
  <div>
    <div class="product-data mt-3 px-md-3">
      <table class="table-product web-view">
        <tr class="header-table">
          <th class="p-3">Produk</th>
          <th>Harga</th>
          <th>Qty</th>
          <th>Subtotal</th>
        </tr>
        <tr>
          <td colspan="2" class="p-3 produk_data">
            <div class="d-flex">
              <figure class="image mx-1">
                <img :src="$imgProduct + dataimg(datatransaksi)" alt="" />
              </figure>
              <div class="mx-1 detail_product">
                <h6>{{ datatransaksi?.name_product }}</h6>
                <p v-if="datatransaksi?.variant_2">
                  {{ datatransaksi?.variant_1 }} , {{ datatransaksi?.variant_2 }}
                </p>
                <p v-else-if="!datatransaksi?.variant_2">
                  {{ datatransaksi?.variant_1 }}
                </p>
              </div>
            </div>
          </td>
          <td>
            <div v-if="parseInt(datatransaksi.discount) > 0">
                        <p  class="m-0 text-danger">
                        Rp {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(datatransaksi?.price_after_discount).toFixed()
                          )
                        }}
                        </p>
                        <div class="diskon">
                          <p>
                            {{ parseInt(datatransaksi?.discount) }}%
                          </p>
                          <small
                            ><s
                              >Rp
                              {{
                                new Intl.NumberFormat("id-ID").format(
                                  Number(parseInt(datatransaksi?.price)).toFixed()
                                )
                              }}</s
                            ></small
                          >
                        </div>
                      </div>
                      <p v-else class=" text-product text-danger">
                        
              Rp
              {{
                new Intl.NumberFormat("id-ID").format(
                  Number(datatransaksi?.price).toFixed()
                )
              }}
            </p>
          </td>
          <td>
            <p>{{ datatransaksi?.qty_available }}</p>
          </td>
          <td>
            <p class="text-danger">
              Rp
              {{
                new Intl.NumberFormat("id-ID").format(
                  Number(datatransaksi?.subtotal).toFixed()
                )
              }}
            </p>
          </td>
        </tr>
      </table>
      <table class="table-product mobile-view">
        <tr class="header-table">
          <th class="p-3">Product</th>
        </tr>
        <tr>
          <td colspan="2" class="produk_data p-3">
            <div class="d-flex">
              <figure class="image mx-1">
                <img :src="$imgProduct + dataimg(datatransaksi)" alt="" />
              </figure>
              <div class="mx-1 detail_product mobile_product_detail">
                <h6>{{ datatransaksi?.name_product }}</h6>
                <p>
                  {{ datatransaksi?.qty_available }} <small>x</small> Rp
                  {{
                    new Intl.NumberFormat("id-ID").format(
                      Number(datatransaksi?.price).toFixed()
                    )
                  }}
                </p>
                <h6>
                  Rp
                  {{
                    new Intl.NumberFormat("id-ID").format(
                      Number(datatransaksi?.subtotal).toFixed()
                    )
                  }}
                </h6>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div>
      <div class="px-3">
        <h6><b>Bintang</b></h6>
        <div>
          <svg
            v-for="(n, index) in stardata"
            :key="index"
            xmlns="http://www.w3.org/2000/svg"
            class="bintang"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style="transform: ; msfilter: "
          >
            <path
              d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
            ></path>
          </svg>
        </div>
        <h6 class="mt-3"><b>Ulasan</b></h6>
        <p class="text-ulasan mt-2" v-if="notesdata">{{ notesdata }}</p>
        <p class="text-ulasan mt-2" v-else>-</p>
        <div class="w-100 list-data-bukti mt-2 px-3" v-if="dataimage.length > 0">
          <b-modal id="modal-image-ulasan" hide-footer size="md">
            <div class="p-md-3" v-if="Object.keys(firstimagemodal).length > 0">
              <div
                class="modalfirstimage"
                v-if="
                  cekextensi(firstimagemodal[0]?.image) == 'jpg' ||
                  cekextensi(firstimagemodal[0]?.image) == 'png' ||
                  cekextensi(firstimagemodal[0]?.image) == 'jpeg' ||
                  cekextensi(firstimagemodal[0]?.image) == 'webp'
                "
              >
                <figure class="h-100">
                  <img
                    class="h-100"
                    id="gallerymodal"
                    :src="$imgProduct + firstimagemodal[0]?.image"
                    alt=""
                  />
                </figure>
              </div>
              <div
                class="modalfirstimage"
                v-else-if="
                  cekextensi(firstimagemodal[0]?.image) == 'mp4' ||
                  cekextensi(firstimagemodal[0]?.image) == 'mpeg' ||
                  cekextensi(firstimagemodal[0]?.image) == 'mov' ||
                  cekextensi(firstimagemodal[0]?.image) == '3gp' ||
                  cekextensi(firstimagemodal[0]?.image) == 'mkv' ||
                  cekextensi(firstimagemodal[0]?.image) == 'avi'
                "
              >
                <video class="video-modal" controls>
                  <source
                    :src="$imgProduct + firstimagemodal[0]?.image"
                    :type="'video/' + cekextensi(firstimagemodal[0]?.image)"
                  />
                </video>
              </div>
              <div class="w-100 d-flex bottom-image-modal">
                <div class="w-25" v-for="(dataimage, index) in modalimage" :key="index">
                  <figure
                    class="cursor-pointer"
                    v-if="
                      cekextensi(dataimage?.image) == 'jpg' ||
                      cekextensi(dataimage?.image) == 'png' ||
                      cekextensi(dataimage?.image) == 'jpeg' ||
                      cekextensi(dataimage?.image) == 'webp'
                    "
                  >
                    <img
                      v-if="dataimage != undefined"
                      :src="$imgProduct + dataimage?.image"
                      alt="Nature"
                      style="width: 100%; height: auto"
                      @click="changeImagemodal(index)"
                    />
                  </figure>
                  <figure
                    class="position-relative"
                    v-else-if="
                      cekextensi(dataimage?.image) == 'mp4' ||
                      cekextensi(dataimage?.image) == 'mpeg' ||
                      cekextensi(dataimage?.image) == 'mov' ||
                      cekextensi(dataimage?.image) == '3gp' ||
                      cekextensi(dataimage?.image) == 'mkv' ||
                      cekextensi(dataimage?.image) == 'avi'
                    "
                  >
                    <div class="card-index" @click="changeImagemodal(index)"></div>
                    <video style="width: 100%; max-height: 150px; height: auto" controls>
                      <source
                        :src="$imgProduct + dataimage?.image"
                        :type="'video/' + cekextensi(dataimage?.image)"
                      />
                    </video>
                  </figure>
                </div>
              </div>
            </div>
          </b-modal>
          <div
            class="card position-relative p-1 p-lg-2 ml-2"
            v-for="(dataimg, index) in dataimage"
            :key="index"
          >
            <div class="card-index" @click="imagemodal(dataimage, index)"></div>
            <div
              v-if="
                cekextensi(dataimg.image) == 'jpg' ||
                cekextensi(dataimg.image) == 'png' ||
                cekextensi(dataimg.image) == 'jpeg' ||
                cekextensi(dataimg.image) == 'webp'
              "
              class="figure-img"
            >
              <img :src="$imgProduct + dataimg.image" alt="" style="width: 100%" />
            </div>
            <div
              v-else-if="
                cekextensi(dataimg.image) == 'mp4' ||
                cekextensi(dataimg.image) == 'mpeg' ||
                cekextensi(dataimg.image) == 'mov' ||
                cekextensi(dataimg.image) == '3gp' ||
                cekextensi(dataimg.image) == 'mkv' ||
                cekextensi(dataimg.image) == 'avi'
              "
            >
              <video width="100" height="75" controls>
                <source
                  :src="$imgProduct + dataimg.image"
                  :type="'video/' + cekextensi(dataimg.image)"
                />
              </video>
            </div>
          </div>
        </div>
        <div class="tanggapan_admin p-3 mt-3">
          <p><b>Tanggapan Admin :</b></p>
          <p class="px-3" v-if="respon_notesdata">{{ respon_notesdata }}</p>
          <p class="px-3" v-else>-</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["datatransaksi", "stardata", "notesdata", "respon_notesdata", "dataimage"],
  data() {
    return {
      image_product: "",
      name_product: "",
      star: 4,
      notes: "",
      respon_notes: "",
      image: {},
      firstimagemodal: [],
      modalimage: [],
    };
  },
  methods: {
    
    dataimg(value){
      if(value.product_price.image != null){
        return value.product_price.image.image
      }else{
        return value.image
      }
       
    },
    imagemodal(value, index) {
      this.firstimagemodal = [];
      this.modalimage = [];
      if (value.length > 0) {
        this.$bvModal.show("modal-image-ulasan");
        for (var i = 0; i < value.length; i++) {
          if (i == index) {
            this.firstimagemodal.push(value[i]);
          } else {
            this.modalimage.push(value[i]);
          }
        }
      }
    },
    changeImagemodal(data) {
      let dataimg1 = this.firstimagemodal[0];
      let dataimg2 = this.modalimage[data];
      this.firstimagemodal.splice(0, 1, dataimg2);
      this.modalimage.splice(data, 1, dataimg1);
    },
    cekextensi(value) {
      return value.split(".").pop();
    },
    addInput() {
      this.inputs.push(this.inputs.length + 1);
    },
  },
};
</script>

<style scoped>

.diskon {
  display: flex;
}
.diskon p {
  color: #fff;
  font-size: 8px !important;
  padding: 3px;
  border-radius: 3px;
  background-color: red;
}
.diskon s {
  color: gray;
  font-size: 0.8rem !important;
  padding-left: 5px;
  text-decoration: line-through !important;
}
::v-deep .modal-header button {
  background-color: #fff;
  border: 0px !important;
}
.card-index {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0px;
  left: 0px;
  cursor: pointer;
}
.video-modal {
  height: auto;
  width: auto;
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 45vh;
}
.modalfirstimage {
  height: auto;
  max-height: 45vh;
  max-width: 100%;
  width: auto;
  display: block;
  margin: 0 auto;
}
.modalfirstimage figure img,
.modalfirstimage figure {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 45vh;
  margin: 0 auto;
}
.card-relative {
  position: relative;
}
.card-index {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0px;
  left: 0px;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.bottom-image-modal {
  overflow-x: auto;
}
.bottom-image-modal figure {
  max-height: 100px;
  overflow: hidden;
}
.product-data {
  width: 100%;
  overflow-x: auto;
}
.table-product tr th {
  min-width: 150px;
}
.border-subtotal {
  border-top: 1px solid grey;
}
.mobile-view {
  display: table;
}
.web-view {
  display: none;
}
.mobile_product_detail p {
  margin-bottom: 0.5rem;
}
.mobile_product_detail h6 {
  margin-bottom: 0px !important;
}
.produk_data {
  min-width: 250px !important;
}
.product-cart {
  overflow-x: auto;
}

.table-product {
  position: relative;
  width: 100%;
}
.table-product tr {
  background-color: transparent !important;
}
.header-table::before {
  content: "";
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  z-index: -10;
  height: 3.5rem;
  position: absolute;
}
.image {
  max-width: 50px;
  max-height: 50px;
  overflow: hidden;
  border-radius: 5px;
}
.image img {
  width: 100%;
}
.detail_product h6 {
  font-weight: 600;
}

.data-user h5 {
  margin-bottom: 5px !important;
  color: #000;
  font-weight: 600;
}
.img-profile {
  width: 100px;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.img-profile img {
  width: 100%;
}
.image_ulasan {
  overflow: hidden;
  width: 150px;
  border-radius: 10px;
  max-height: 200px;
}
.image_ulasan img {
  width: 100%;
}
input:not(:checked) ~ label:hover,
input:not(:checked) ~ label:hover ~ label {
  color: #fd4;
}

input:checked ~ label {
  color: #fd4;
}

input#rate-5:checked ~ label {
  color: #fe7;
}
.bintang {
  fill: #fd4;
  font-size: 2rem;
}
.text-ulasan {
  font-size: 0.8rem;
}

.list-data-bukti {
  display: flex;
}
.list-data-bukti i {
  text-align: center;
  font-size: 5rem;
}
.list-data-bukti p {
  margin-bottom: 0px !important;
}
.list-data-bukti .card {
  margin-left: 0.5rem !important;
}
.nilai_web {
  display: none;
}
.nilai_mobile {
  display: block;
}
.tanggapan_admin {
  background-color: #f1f1f1;
}
.figure-img {
  width: 100px;
}
@media (max-width: 500px) {
  .figure-img {
    width: 70px;
  }
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}
@media (min-width: 740px) {
  .mobile-view {
    display: none;
  }
  .web-view {
    display: table;
  }
}
@media (min-width: 767px) {
  .bintang {
    font-size: 3rem;
  }
  .text-ulasan {
    font-size: 1rem;
  }
  .nilai_web {
    display: block;
  }
  .nilai_mobile {
    display: none;
  }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>
