<template>
    <div class="container-fluid h-100">
        <div class="row h-100">
            <div class="col-md-4 col-lg-4 h-100 data-left">
                <div class="isi-data-left">
                    <img src="../../assets/img/minimarket1.webp" alt="">
                </div>
            </div>
            <div class="col-md-8 col-lg-8 data-right">
                <div class=" m-2 m-md-5">
                    <router-link to="/"
                        class="d-block text-center font-bold custom-link logo-container auth-logo-container"
                        style="font-size:24px;">
                        <img :src="require(`../../assets/logo_arys_mikro.jpeg`)" alt="" class="logo">
                    </router-link>

                    <div class="header-text text-center">
                        <h1 class="mt-3"><b>Registrasi</b></h1>
                        <p>Anda sudah memiliki akun Ary's Mikro? <router-link to="/login" class="login">Klik
                                Disini</router-link></p>
                    </div>
                    <form class="form-regis" ref="registrasi" @submit.prevent="registrasi">
                        <div class="row">
                            <div class="col-md mt-4">
                                <label for="name">Nama</label>
                                <input id="name" type="name" class="form-control mt-1 radius" v-model="name"
                                    placeholder="Nama"
                                    :class="[(v$.name.$error || name.length > 50) ? 'is-invalid' : '']">
                                <span v-if="v$.name.$error" class="text-danger font-small pesan-error">{{
                                    v$.name.$errors[0].$message }}</span>
                                <span v-if="name.length > 50" class="text-danger font-small pesan-error">Nama terlalu
                                    panjang!</span>
                            </div>
                            <div class="col-md mt-4">
                                <label for="username">Username</label>
                                <input id="username" type="text" class="form-control mt-1 radius" v-model="username"
                                    placeholder="Username" :class="[v$.username.$error ? 'is-invalid' : '']">
                                <span v-if="v$.username.$error" class="text-danger font-small pesan-error">
                                    {{ v$.username.$errors[0].$message }} </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md mt-4">
                                <label for="email">Email</label>
                                <input id="email" type="text" class="form-control mt-1 radius" v-model="email"
                                    placeholder="Email" :class="[v$.email.$error ? 'is-invalid' : '']">
                                <span v-if="v$.email.$error" class="text-danger font-small pesan-error">{{
                                    v$.email.$errors[0].$message }}</span>
                            </div>
                            <div class="col-md mt-4">
                                <label for="phone">Telepon</label>
                                <!-- <vue-tel-input style="border:0px !important;" v-model="phone"
                defaultCountry='id'
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                @validate="validatePhone"
                @country-changed="countryChanged"
                @input="validateFirstLetter"
                :class="{
                  'border-danger':
                    !phoneValid || $v.phone.$error || !$v.phone.checkData,
                }"></vue-tel-input> -->

                                <vue-tel-input v-model="phone" :autoFormat="false" class="radius"
                                    @input="validateFirstLetter"
                                    :class="[(v$.phone.$error || phone.length > 17 || !phoneValid) ? 'is-invalid' : '']"
                                    @validate="validatePhone" defaultCountry='id' @country-changed="countryChanged"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode == 43"
                                    @open="handleOpen"></vue-tel-input>
                                <!-- <vue-tel-input v-model="phone" :autoFormat="false" class="radius" @input="validateFirstLetter" :class="[(v$.phone.$error || phone.length >17 || !phoneValid) ? 'is-invalid' : '']" @validate="validatePhone" defaultCountry='id' @country-changed="countryChanged" onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode == 43"></vue-tel-input> -->
                                <!-- <input id="phone" type="text" maxlength="15" class="form-control mt-1 radius" v-model="phone" placeholder="Telepon" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                
                                > -->
                                <span v-if="v$.phone.$error" class="text-danger font-small">{{
                                    v$.phone.$errors[0].$message }}</span>
                                <span v-else-if="submitdata && !phoneValid" class="text-danger font-small">Format yang
                                    anda masukkan salah</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md mt-4">
                                <label for="birthdate">Tanggal Lahir</label>
                                <date-pick v-model="birthdate" :displayFormat="'DD/MM/YYYY'" class="w-100"
                                    :class="{ 'border-danger': v$.birthdate.$error }" id="birthdate" name="birthdate"
                                    aria-describedby="birthdate" placeholder="Tanggal Lahir"
                                    :inputAttributes="{ placeholder: 'DD/MM/YYYY' }"></date-pick>
                                <span v-if="v$.birthdate.$error" class="text-danger font-small pesan-error">{{
                                    v$.birthdate.$errors[0].$message }}</span>
                            </div>
                        </div>
                        <div class="form-group mt-4">
                            <label for="laki-laki">Jenis Kelamin</label>
                            <div class="radio-btn mt-1">
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" id="laki-laki" value="1" name="gender"
                                        class="custom-control-input" v-model="gender"
                                        :class="[v$.gender.$error ? 'is-invalid' : '']">
                                    <label class="custom-control-label" for="laki-laki"
                                        style="margin-left:5px;">Laki-Laki</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline"
                                    style="margin-left:10px;">
                                    <input type="radio" id="perempuan" value="0" name="gender"
                                        class="ml-2 custom-control-input" v-model="gender"
                                        :class="[v$.gender.$error ? 'is-invalid' : '']">
                                    <label class="custom-control-label" style="margin-left:5px;"
                                        for="perempuan">Perempuan</label>
                                </div>
                            </div>
                            <span v-if="v$.gender.$error" class="text-danger font-small pesan-error">{{
                                v$.gender.$errors[0].$message }}</span>
                        </div>
                        <!-- <div class="row">
                            <div class="col-md mt-4">
                                <label for="province_id">Provinsi</label>
                                <select id="province_id" class="form-control mt-1 radius" v-model="province_id" @change="getWilayah('district',$event)"
                                :class="[v$.province_id.$error ? 'is-invalid' : '']"
                                >
                                    <option value="" disabled>Pilih Provinsi</option>
                                    <option :value="provinsi.province_id" v-for="provinsi in provinces" :key="provinsi.province_id">{{provinsi.province_name}}</option>
                                </select>
                                <span v-if="v$.province_id.$error" class="text-danger font-small pesan-error">{{v$.province_id.$errors[0].$message}}</span>
                            </div>
                            <div class="col-md mt-4">
                                <label for="district_id">Kabupaten</label>
                                <select id="district_id" class="form-control mt-1 radius" v-model="district_id" @change="getWilayah('sub-district',$event)"
                                :class="[v$.district_id.$error ? 'is-invalid' : '']"
                                >
                                    <option value="" disabled>Pilih Kabupaten</option>
                                    <option :value="district.city_id" v-for="district in districts" :key="district.city_id">{{district.city_name}}</option>
                                </select>
                                <span v-if="v$.district_id.$error" class="text-danger font-small pesan-error">{{v$.district_id.$errors[0].$message}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md mt-4">
                                <label for="sub_district_id">Kecamatan</label>
                                <select id="sub_district_id" class="form-control mt-1 radius" v-model="sub_district_id" 
                                :class="[v$.sub_district_id.$error ? 'is-invalid' : '']"
                                >
                                    <option value="" disabled>Pilih Kecamatan</option>
                                    <option :value="sub_district.subdistrict_id" v-for="sub_district in sub_districts" :key="sub_district.sub_districtid">{{sub_district.subdistrict_name}}</option>
                                </select>
                                <span v-if="v$.sub_district_id.$error" class="text-danger font-small pesan-error">{{v$.sub_district_id.$errors[0].$message}}</span>
                            </div>
                        </div>
                        <div class="mt-3">
                            <label for="address">Alamat</label>
                            <textarea name="address" id="address" rows="3" class="form-control w-100 mt-1 border-lightdata" v-model="address"
                            :class="[(v$.address.$error || address.length >500) ? 'is-invalid' : '']"
                            ></textarea>
                            <span v-if="v$.address.$error" class="text-danger font-small pesan-error">{{v$.address.$errors[0].$message}}</span>
                            <span v-if="address.length >500" class="text-danger font-small pesan-error">Alamat terlalu panjang!</span>

                        </div> -->
                        <div class="row">
                            <div class="col-md mt-4">
                                <label for="password">Kata Sandi</label>
                                <div class="position-relative password-input">
                                    <input id="password" type="password" class="form-control mt-1 radius"
                                        autocomplete="false" placeholder="Kata Sandi" v-model="password"
                                        :class="[v$.password.$error ? 'is-invalid' : '']">
                                    <div v-if="!v$.password.$error" class="icon-container"
                                        @click="togglePass('password')">
                                        <i class='bi bi-eye'></i>
                                    </div>
                                </div>
                                <span v-if="v$.password.$error" class="text-danger font-small pesan-error">{{
                                    v$.password.$errors[0].$message }}</span>

                            </div>
                            <div class="col-md mt-4">
                                <label for="confirmpassword">Konfirmasi Kata Sandi</label>
                                <div class="position-relative password-input">
                                    <input id="confirmpassword" type="password" class="form-control mt-1 radius"
                                        autocomplete="false" placeholder="Konfirmasi Kata Sandi"
                                        v-model="password_confirmation"
                                        :class="[v$.password_confirmation.$error ? 'is-invalid' : '']">
                                    <div v-if="!v$.password_confirmation.$error" class="icon-container"
                                        @click="togglePass('confirmpassword')">
                                        <i class='bi bi-eye'></i>
                                    </div>
                                </div>
                                <span v-if="v$.password_confirmation.$error"
                                    class="text-danger font-small pesan-error">{{
                                        v$.password_confirmation.$errors[0].$message }}</span>

                            </div>
                        </div>
                        <div class="form-group mt-4">
                            <input class="form-check-input" type="checkbox" value="1" id="checkbox" v-model="setuju">
                            <label class="form-check-label label-syarat" for="checkbox" style="margin-left:5px;">
                                Saya Menyetujui <span class="fw-bold" style="color:blue" @click="modaltac">syarat dan
                                    ketentuan</span> yang berlaku
                            </label>
                        </div>
                        <b-modal id="modal-tac" hide-footer title="Syarat dan ketentuan">
                            <div v-html="setting?.tac"></div>
                        </b-modal>

                        <div v-if="error" class="alert alert-warning alert-dismissible fade show mt-4" role="alert">
                            <strong>Peringatan !</strong>
                            <span> Pastikan Anda sudah membaca dan menyetujui syarat dan ketentuan yang berlaku. </span>

                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
                                @click="errorfalse"></button>
                        </div>

                        <div v-if="errors.username != '' || errors.email != ''"
                            class="alert alert-warning alert-dismissible fade show mt-4" role="alert">
                            <strong>Peringatan ! </strong>
                            <span v-if="errors.username != '' && errors.email == ''">{{ errors.username }}</span>
                            <span v-if="errors.email != '' && errors.username == ''">Email sudah terdaftar silahkan
                                gunakan yang lain</span>
                            <span v-if="errors.username != '' && errors.email != ''">Username dan Email sudah terdaftar
                                silahkan gunakan yang lain</span>

                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
                                @click="errorilang"></button>
                        </div>

                        <div v-if="success" class="alert alert-success alert-dismissible fade show mt-4" role="alert">
                            <strong>Berhasil !</strong>
                            <span> Registrasi berhasil dilakukan, silahkan periksa email anda, untuk melakukan
                                verifikasi email. </span>
                        </div>
                        <div class="container-fluid mt-4 mb-5">
                            <div style="float: right;height:80px;">
                                <button type="submit" class="btn btn-daftar">Daftar</button>
                            </div>
                        </div>
                    </form>
                </div>
                <b-modal id="modal-loading" centered hide-footer hide-header>
                    <div class="text-center">
                        <b-spinner variant="light"></b-spinner>
                    </div>
                </b-modal>
            </div>
        </div>

        <div v-if="loadingSubmit" class="loading-submit-animation-container">
            <div class="loading-submit-animation-content">
                <div>
                    <div class="lds-facebook">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <p>Mohon tunggu, data sedang diproses</p>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-center" id="staticBackdropLabel">Syarat & Ketentuan</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div :class="{ 'loading-skeleton': loading }" v-if="loading">
                            <p>Loading..</p>
                        </div>
                        <p class="" v-html="term.tac"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <!-- <button type="button" class="btn btn-danger">Understood</button> -->
                    </div>
                </div>
            </div>
        </div>

        <Modal :show="modal.showModal" :header="modal.header" :headerBorder="modal.headerBorder" :title="modal.title"
            :titleText="modal.titleText" :footer="modal.footer" :footerBtnPosition="modal.footerBtnPosition"
            :footerBorder="modal.footerBorder" :btnCancel="modal.btnCancel" :btnSubmit="modal.btnSubmit"
            :btnSubmitText="modal.btnSubmitText" :btnCancelText="modal.btnCancelText"
            :btnSubmitWidth="modal.btnSubmitWidth" :btnCancelWidth="modal.btnCancelWidth">

            <!-- Syarat & Ketentuan -->
            <div>
                <div v-if="modalTipe === 'syarat'" class="negosisasi-container">
                    <p>
                        modal syarat
                    </p>
                </div>
            </div>
        </Modal>

    </div>
</template>

<script>
import axios from "axios";
import swal from 'sweetalert'
import useVuelidate from '@vuelidate/core'
import { required, email, helpers, minLength, sameAs } from '@vuelidate/validators'
import Modal from '../../components/Modal.vue'

import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';


export default {
    name: 'register-component',
    props: ['banners'],
    setup() {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            username: {
                required: helpers.withMessage("Username tidak boleh kosong", required),
            },
            name: {
                required: helpers.withMessage("Nama tidak boleh kosong", required),
            },
            email: {
                required: helpers.withMessage("Email tidak boleh kosong", required),
                email: helpers.withMessage("Format email salah", email)
            },
            password: {
                required: helpers.withMessage("Kata Sandi tidak boleh kosong", required),
                min: helpers.withMessage(
                    ({
                        $params
                    }) => `Kata Sandi minimal memiliki ${$params.min} karakter `,
                    minLength(8)
                )
            },
            password_confirmation: {
                required: helpers.withMessage("Konfirmasi kata sandi tidak boleh kosong", required),
                sameAs: helpers.withMessage(`Konfirmasi kata sandi tidak sama dengan kata sandi `, sameAs(this.password))
            },
            phone: {
                required: helpers.withMessage("No telepon tidak boleh kosong", required),
            },
            birthdate: {
                required: helpers.withMessage("Tanggal lahir tidak boleh kosong", required),
            },
            gender: {
                required: helpers.withMessage("Jenis kelamin tidak boleh kosong", required),
            },
            // province_id: {
            //     required: helpers.withMessage("Provinsi tidak boleh kosong", required),
            // },
            // district_id: {
            //     required: helpers.withMessage("Kabupaten tidak boleh kosong", required),
            // },
            // sub_district_id: {
            //     required: helpers.withMessage("Kecamatan tidak boleh kosong", required),
            // },
            // address: {
            //     required: helpers.withMessage("Alamat tidak boleh kosong", required),
            // },
        }
    },
    data() {
        return {
            phoneFirstTimeLoad: 0,
            phoneValid: true,
            submitdata: false,
            username: "",
            name: "",
            email: "",
            password: "",
            password_confirmation: "",
            phone: "",
            birthdate: "",
            gender: "",
            province_id: "",
            district_id: "",
            sub_district_id: "",
            address: "",
            term: [],
            loading: false,
            setuju: "",
            login_redirect_url: '',
            errors: {
                username: '',
                email: ''
            },
            provinces: "",
            districts: "",
            sub_districts: "",
            error: false,
            success: false,
            loadingSubmit: false,
            modalTipe: '',
            modal: {
                showModal: false,
                header: false,
                headerBorder: false,
                title: false,
                titleText: '',
                footer: false,
                footerBtnPosition: '',
                footerBorder: false,
                btnCancel: false,
                btnSubmit: false,
                btnSubmitText: '',
                btnCancelText: '',
                btnSubmitWidth: '',
                btnCancelWidth: '',
            },
            image: {},
        }

    },
    components: {
        Modal,
        DatePick,
    },
    created() {
        this.getWilayah('province');
    },
    methods: {
        handleOpen() {
            // Ensuring that the element is present in the DOM
            this.$nextTick(() => {
                // Get all <li>
                let listItems = document.querySelectorAll('.vti__dropdown-item');

                // Loop through each <li> element
                listItems.forEach((listItem) => {
                    let strongElement = listItem.querySelector('strong');

                    if (strongElement) {
                        let strongInnerHTML = strongElement.innerHTML;

                        // Check if "(" exists 
                        if (strongInnerHTML.includes('(')) {
                            let splitted = strongInnerHTML.split(' (');
                            splitted.pop();

                            // Modify the inner HTML of the <strong> tag
                            strongElement.innerHTML = splitted.join(' ');
                        }
                    } else {
                        console.error('No strong element');
                    }
                });
            });

        }
        ,
        countryChanged(country) {

            this.phoneFirstTimeLoad++;
            let countryCode = country.dialCode;

            if (this.phoneFirstTimeLoad > 0) {
                const myPromise = new Promise((resolve) => {
                    resolve((this.phone = ""));
                });

                myPromise.then(() => {
                    console.log(countryCode);
                    this.phone = `+${countryCode}`;
                });
            }
        },
        validatePhone(country) {

            this.phoneValid = country.valid;
        },
        validateFirstLetter() {

            if (this.phone[0] != "+") {
                this.phoneValid = false;
            }
        },
        errorfalse() {
            this.error = false
        },
        errorilang() {
            this.errors.username = ''
            this.errors.email = ''
        },
        modaltac() {
            this.$bvModal.show('modal-tac')
        },
        getWilayah(wilayah, event) {
            wilayah = 'customer/' + wilayah
            let cek_wilayah = wilayah;
            event ? wilayah += '/' + event.target.value : wilayah;
            axios.get(wilayah)
                .then(response => {
                    if (cek_wilayah == 'customer/province') {
                        this.provinces = response.data.data
                    } else if (cek_wilayah == 'customer/district') {
                        this.districts = response.data.data
                    } else if (cek_wilayah == 'customer/sub-district') {
                        this.sub_districts = response.data.data
                    }
                })
                .catch(() => {
                });
        },
        togglePass(id) {
            if (document.querySelector('#' + id).type == 'password') {
                document.querySelector('#' + id).type = 'text'
                document.querySelector('#' + id + '~.icon-container .bi').classList.remove('bi-eye')
                document.querySelector('#' + id + '~.icon-container .bi').classList.add('bi-eye-slash')
            } else {
                document.querySelector('#' + id).type = 'password'
                document.querySelector('#' + id + '~.icon-container .bi').classList.remove('bi-eye-slash')
                document.querySelector('#' + id + '~.icon-container .bi').classList.add('bi-eye')
            }
        },
        setImage() {
            if (this.banners != null) {
                for (let i = 0; i < Object.keys(this.banners).length; i++) {
                    if (this.banners[i].position === 'Registration Page') {
                        this.image = this.banners[i].image
                    }
                }
                this.loadingMainBanner = false
            }
        },
        setModal(condition, tipe) {
            this.resetDataModal()
            this.modal.showModal = condition
            condition == true ? this.modalTipe = tipe : ''
            if (tipe == 'syarat' && condition) {
                this.modal.header = true
                this.modal.headerBorder = true
                this.modal.title = true
                this.modal.titleText = 'Syarat & Ketentuan'
                this.modal.footer = true
                this.modal.footerBorder = true
                this.modal.footerBtnPosition = 'right'
                this.modal.btnSubmitText = 'Oke'
                this.modal.btnSubmitWidth = 'auto'
                this.modal.btnSubmit = true
            }
        },
        registrasi() {
            this.submitdata = true
            this.v$.$validate()
            this.login_redirect_url = window.location.origin + '/login'

            if (this.phone[0] === "0") {
                this.phoneValid = false
            }
            console.log("Phone : ", this.phone, "Valid ? ", this.phoneValid);


            let data = new FormData()
            data.append('username', this.username)
            data.append('name', this.name)
            data.append('receipt_name', this.name)
            data.append('email', this.email)
            data.append('password', this.password)
            data.append('password_confirmation', this.password_confirmation)
            data.append('phone', this.phone.toString())
            data.append('receipt_phone_number', this.phone.toString())
            data.append('birthdate', this.birthdate)
            data.append('gender', this.gender)
            // data.append('province_id', this.province_id)
            // data.append('district_id', this.district_id)
            // data.append('sub_district_id', this.sub_district_id)
            // data.append('address', this.address)
            data.append('setuju', this.setuju)
            data.append('login_redirect_url', this.login_redirect_url)
            this.resetErrorForm()

            if (this.setuju) {
                if (!this.v$.$error && this.phoneValid) {
                    this.error = false
                    this.$bvModal.show('modal-loading')
                    axios.post('customer/create', data)
                        .then(() => {
                            this.submitdata = false
                            this.$bvModal.hide('modal-loading')
                            swal('Berhasil!!!', 'Akun berhasil dibuat. Verifikasi akun telah dikirim ke WhatsApp anda!', 'success', {
                                button: "OK"
                            })
                            this.$refs.registrasi.reset();
                            this.v$.$reset();
                            this.resetForm()
                            this.$router.push('/login');

                            this.loadingSubmit = false

                        })
                        .catch(error => {
                            this.submitdata = false
                            this.$bvModal.hide('modal-loading')
                            this.loadingSubmit = false
                            let data_error = Object.entries(error.response.data.data)
                            for (let i = 0; i < data_error.length; i++) {
                                this.errors[data_error[i][0]] = data_error[i][1][0];
                            }
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        });
                }
            } else {
                this.success = false
                this.error = true;
            }

        },
        resetForm() {
            this.username = "";
            this.name = "";
            this.email = "";
            this.password = "";
            this.password_confirmation = "";
            this.phone = "";
            this.birthdate = "";
            this.gender = "";
            // this.province_id = "";
            // this.district_id =  "";
            // this.sub_district_id =  "";
            // this.address = "";
            this.setuju = "";
            this.login_redirect_url = '';
        },
        resetErrorForm() {
            this.errors.email = ''
            this.errors.username = ''
        }

    },
    async mounted() {
        try {
            this.loading = true
            if (this.setting instanceof Promise == true || this.setting == []) {
                await this.$store.dispatch("setting");
            }
            this.setImage()
        } catch (error) {
            return error
        }

    },
    computed: {
        setting: function () {
            return this.$store.state.setting;
        },
    },
    watch: {
        banners: {
            deep: true,
            handler: function () {
                this.setImage()
                //           :autoplay='{
                // "delay": 2500,
                // "disableOnInteraction": false
                // }'
            }
        },
    }
}
</script>



<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css");

::v-deep .modal-header button {
    background-color: #fff;
    border: 0px !important;
}

::v-deep .vue-tel-input input {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

::v-deep .vdpComponent input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    /* border: 0px !important; */
}

.logo {
    width: 200px;
}

::v-deep #modal-loading .modal-content {
    background-color: transparent;
    border: 0px !important;
}

.password-input .icon-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 1rem;
}

.password-input .icon-container:hover {
    cursor: pointer;
}

.password-input input[type="password"] {
    padding-right: 2.5rem;
}

.login {
    text-decoration: none;
    font-weight: bold;
    color: #1184ff;
}

.form-regis {
    margin: 0 auto;
}

.data-left {
    display: none;
    background-image: url("../../assets/img/minimarket1.webp");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
}

.data-left img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.radius {
    border-radius: 25px;
    padding-left: 15px;
}

.border-lightdata {
    border-radius: 15px;
    /* padding-left: 15px; */
}

.isi-data-left {
    height: 100vh;
}

.data-right {
    overflow: auto;
    height: 100vh;
}

.radio-btn {
    display: flex;
}

.label-syarat {
    font-size: 0.8rem;
}

.btn-daftar {
    background-color: #1F7ACF;
    color: #fff;
    padding: 5px 30px;
    border-radius: 25px;
}

.btn-daftar:hover {
    background-color: #125da3;
    color: #fff;
}

@media (min-width: 319px) {}

@media (min-width: 374px) {}

@media (min-width: 424px) {}

@media (min-width: 767px) {
    .data-left {
        display: block;
    }

    .form-regis {
        width: 100%;
    }

    .label-syarat {
        font-size: 1rem;
    }
}

@media (min-width: 1023px) {
    .form-regis {
        width: 95%;
    }

    .label-syarat {
        font-size: 1rem;
    }
}

@media (min-width: 1439px) {
    .form-regis {
        width: 75%;
    }
}

@media (min-width: 2000px) {}
</style>
