<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      supportWebp: false,
      userAgent: window.navigator.userAgent,
    };
  },

  methods: {
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    check_router(route) {
      // method ini dibuat untuk pengecekan apakah saat dia di page auth saat sudah memilikki token atau page yang berisi data user namun belum memilikki token
      const route_auth = ["/login", "/register", "/lupa-password", "/reset-password"];
      let auth = false;
      for (let i = 0; i < route_auth.length; i++) {
        if (route_auth[i] == route) {
          auth = true;
          break;
        }
      }
      return auth;
    },
    checkSupportWebp() {
      let elem = document.createElement("canvas");
      if (elem.getContext && elem.getContext("2d")) {
        this.supportWebp = elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
      } else {
        this.supportWebp = "";
      }
    },
  },
  watch: {
    $route() {
      // scroll to top
      // ini untuk perintah window saat pindah ke halaman lain agar lebih smooth
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      // pengecekan pada cookies apakah terdapat token, saat berpindah halaman
      if (this.$cookies.isKey("token")) {
        if (this.check_router(this.$router.path)) {
          this.$router.push("/");
        }

        this.showBar = true;
      } else {
        if (this.check_router(this.$router.path)) {
          this.showBar = false;
        } else {
          this.showBar = true;
        }
      }
    },
    user(value) {
      if (!(value instanceof Promise) && value !== null && this.cartsloaded == false) {
        let me = this;
        this.$store.dispatch("address").then(() => {
          me.cartsloaded = true;
        });
      }
    },
  },
  mounted() {
    // this.$store.dispatch('user').then(()=>{
    //   if(this.$store.state.address.length >0){
    //             this.loading=false
    //     }else if(this.$store.state.address.length == []){
    //             this.$store.dispatch('address')
    //     }else if(this.$store.state.address.length == 0){
    //         this.$store.dispatch('address')
    //     }else if(!this.$store.state.address){
    //             this.$store.dispatch('address')
    //     }
    // })
    this.checkSupportWebp();

    // if(this.$cookies.isKey('token')){
    //   this.$store.dispatch('user')
    // }
  },
  // mounted(){
  // this.$store.dispatch('user')
  // this.$store.dispatch('products')
  // },
  computed: {
    user: function () {
      return this.$store.state.user;
    },
    deviceIsIphoneOrIpodOrIpad() {
      return /(i[PSa-z\s]+);.*?CPU\s([OSPa-z\s]+(?:([\d_]+)|;))/g.test(this.userAgent);
    },
    browserIsSafariMobile() {
      return /version\/([\w\\.]+) .*mobile\/\w+ (safari)/i.test(this.userAgent);
    },
    browserIsSafariDesktop() {
      return /version\/([\w\\.]+) .*(mobile ?safari|safari)/i.test(this.userAgent);
    },
    browserIsSafari() {
      return this.browserIsSafariMobile || this.browserIsSafariDesktop;
    },
    browserIsOnlySafari() {
      return this.browserIsSafari && !this.browserIsSocialNetwork;
    },
    browserIsSocialNetwork() {
      return this.browserIsInstagram || this.browserIsFacebook;
    },
    browserIsSafariAndSocialNetwork() {
      return this.browserIsOnlySafari && this.browserIsSocialNetwork;
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  /* color: #42b983; */
}
.hidden {
  display: none !important;
  visibility: 0 !important;
  height: 0px !important;
  width: 0px !important;
}
</style>
