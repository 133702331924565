<template>
  <div>
    <Loader v-if="loading" />
    <SkeletonNavbar v-if="loading" />
    <div v-else-if="!loading">
      <!-- <Sidebar/> -->
      <div class="container bg-primary nav-bottom nav-mobile w-100">
        <div class="row">
          <button v-if="homebtn" class="col-3 btn-trans" @click="homepage()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              style="fill: #fff; transform: ; msfilter: "
            >
              <path
                d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
              ></path>
            </svg>
            <br />
            <small>Home</small>
          </button>
          <router-link v-else-if="!homebtn" @click="kliclink()" to="/" class="col-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              style="fill: #fff; transform: ; msfilter: "
            >
              <path
                d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
              ></path>
            </svg>
            <br />
            <small>Home</small>
          </router-link>
          <router-link
            @click="kliclink()"
            to="/profil/chat"
            v-if="this.$store.state.user.name"
            class="col-3"
            ><span class="span-chat-mobile" v-if="jmlhpesan > 0">{{ jmlhpesan }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 32 32"
            >
              <rect x="0" y="0" width="20" height="20" fill="none" stroke="none" />
              <g
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
              >
                <path
                  d="M25 5H7a4 4 0 0 0-4 4v10a4 4 0 0 0 4 4h11l6 4v-4h1a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4Z"
                />
                <path
                  d="M10 15a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm6 0a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm6 0a1 1 0 1 1-2 0a1 1 0 0 1 2 0Z"
                />
              </g>
            </svg>
            <br /><small> Chat </small>
          </router-link>
          <router-link to="/login" class="col-3" v-if="!this.$store.state.user.name">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 32 32"
            >
              <rect x="0" y="0" width="20" height="20" fill="none" stroke="none" />
              <g
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
              >
                <path
                  d="M25 5H7a4 4 0 0 0-4 4v10a4 4 0 0 0 4 4h11l6 4v-4h1a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4Z"
                />
                <path
                  d="M10 15a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm6 0a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm6 0a1 1 0 1 1-2 0a1 1 0 0 1 2 0Z"
                />
              </g>
            </svg>
            <br /><small> Chat </small>
          </router-link>
          <router-link
            @click="kliclink()"
            to="/profil/transaksi"
            class="col-3"
            v-if="this.$store.state.user.name"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              style="fill: #fff; transform: ; msfilter: "
            >
              <path
                d="M21 5c0-1.103-.897-2-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5zM5 19V5h14l.002 14H5z"
              ></path>
              <path
                d="M7 7h1.998v2H7zm4 0h6v2h-6zm-4 4h1.998v2H7zm4 0h6v2h-6zm-4 4h1.998v2H7zm4 0h6v2h-6z"
              ></path>
            </svg>
            <br /><small> Transaksi </small>
          </router-link>
          <router-link to="/login" class="col-3" v-if="!this.$store.state.user.name">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              style="fill: #fff; transform: ; msfilter: "
            >
              <path
                d="M21 5c0-1.103-.897-2-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5zM5 19V5h14l.002 14H5z"
              ></path>
              <path
                d="M7 7h1.998v2H7zm4 0h6v2h-6zm-4 4h1.998v2H7zm4 0h6v2h-6zm-4 4h1.998v2H7zm4 0h6v2h-6z"
              ></path>
            </svg>
            <br /><small> Transaksi </small>
          </router-link>
          <router-link
            to="/profil/beranda"
            class="col-3"
            v-if="this.$store.state.user.name"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              style="fill: #fff; transform: ; msfilter: "
            >
              <path
                d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"
              ></path>
            </svg>
            <br /><small> User </small>
          </router-link>
          <router-link to="/login" class="col-3" v-else-if="!this.$store.state.user.name">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              style="fill: #fff; transform: ; msfilter: "
            >
              <path
                d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"
              ></path>
            </svg>
            <br /><small> User </small>
          </router-link>
        </div>
      </div>
      <div class="navbar-header w-100">
        <nav
          class="navbar w-100 sticky-top navbar-expand-md navbar-light bg-primary"
          id="nav"
        >
          <div class="container container-nav">
            <b-button
              style="padding: 0px !important"
              class="btn-outline-secondary btn-nav btn-mobile btn nav-mobile"
              v-b-toggle.sidebar-backdrop
              ><span class="navbar-toggler-icon"></span
            ></b-button>
            <div class="p-1 rounded bg-white nav-mobile">
              <form
                action=""
                @submit.prevent="searchproduk"
                class="search-box nav-mobile"
              >
                <div class="input-group">
                  <input
                    id="searchinput"
                    type="text"
                    required
                    v-model="searchdata"
                    class="form-control"
                    placeholder="Cari Barang"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <button
                      type="submit"
                      class="input-group-text btn bg-primary rounded"
                      id="basic-addon2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        style="fill: #fff; transform: ; msfilter: "
                        width="20"
                        height="20"
                      >
                        <path
                          d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <!-- <button >a</button> -->
              </form>
            </div>

            <div class="d-flex nav-mobile">
              <router-link
                @click="kliclink()"
                v-if="this.$store.state.user.name"
                to="/cart"
                class="nav-link link-cart-mobile nav-mobile mb-2 p-1"
                @click.prevent=""
              >
                <span class="span-cart-web" v-if="carts.length > 0">{{
                  carts.length
                }}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <rect x="0" y="0" width="24" height="24" fill="none" stroke="none" />
                  <g fill="none" stroke="#fff" stroke-width="1.5">
                    <path
                      d="M3.864 16.455c-.858-3.432-1.287-5.147-.386-6.301C4.378 9 6.148 9 9.685 9h4.63c3.538 0 5.306 0 6.207 1.154c.901 1.153.472 2.87-.386 6.301c-.546 2.183-.818 3.274-1.632 3.91c-.814.635-1.939.635-4.189.635h-4.63c-2.25 0-3.375 0-4.189-.635c-.814-.636-1.087-1.727-1.632-3.91Z"
                    />
                    <path
                      d="m19.5 9.5l-.71-2.605c-.274-1.005-.411-1.507-.692-1.886A2.5 2.5 0 0 0 17 4.172C16.56 4 16.04 4 15 4M4.5 9.5l.71-2.605c.274-1.005.411-1.507.692-1.886A2.5 2.5 0 0 1 7 4.172C7.44 4 7.96 4 9 4"
                    />
                    <path d="M9 4a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Z" />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8 13v4m8-4v4m-4-4v4"
                    />
                  </g>
                </svg>
              </router-link>
              <router-link
                v-if="!this.$store.state.user.name"
                to="/login"
                class="nav-link link-cart-mobile nav-mobile mb-2"
                @click.prevent=""
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <rect x="0" y="0" width="24" height="24" fill="none" stroke="none" />
                  <g fill="none" stroke="#fff" stroke-width="1.5">
                    <path
                      d="M3.864 16.455c-.858-3.432-1.287-5.147-.386-6.301C4.378 9 6.148 9 9.685 9h4.63c3.538 0 5.306 0 6.207 1.154c.901 1.153.472 2.87-.386 6.301c-.546 2.183-.818 3.274-1.632 3.91c-.814.635-1.939.635-4.189.635h-4.63c-2.25 0-3.375 0-4.189-.635c-.814-.636-1.087-1.727-1.632-3.91Z"
                    />
                    <path
                      d="m19.5 9.5l-.71-2.605c-.274-1.005-.411-1.507-.692-1.886A2.5 2.5 0 0 0 17 4.172C16.56 4 16.04 4 15 4M4.5 9.5l.71-2.605c.274-1.005.411-1.507.692-1.886A2.5 2.5 0 0 1 7 4.172C7.44 4 7.96 4 9 4"
                    />
                    <path d="M9 4a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Z" />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8 13v4m8-4v4m-4-4v4"
                    />
                  </g>
                </svg>
              </router-link>
              <div class="nav-mobile">
                <div
                  class="nav-link link-data-name nav-user mb-2 d-flex p-1"
                  @click.prevent="userdropdown()"
                  v-if="this.$store.state.user.name"
                  id="Datauser"
                >
                  <figure class="img-nav">
                    <img :src="imageuser" alt="" />
                  </figure>
                  <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="fill: #fff; transform: ; msfilter: "
                >
                  <path
                    d="M12 2A10.13 10.13 0 0 0 2 12a10 10 0 0 0 4 7.92V20h.1a9.7 9.7 0 0 0 11.8 0h.1v-.08A10 10 0 0 0 22 12 10.13 10.13 0 0 0 12 2zM8.07 18.93A3 3 0 0 1 11 16.57h2a3 3 0 0 1 2.93 2.36 7.75 7.75 0 0 1-7.86 0zm9.54-1.29A5 5 0 0 0 13 14.57h-2a5 5 0 0 0-4.61 3.07A8 8 0 0 1 4 12a8.1 8.1 0 0 1 8-8 8.1 8.1 0 0 1 8 8 8 8 0 0 1-2.39 5.64z"
                  ></path>
                  <path
                    d="M12 6a3.91 3.91 0 0 0-4 4 3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4zm0 6a1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2 1.91 1.91 0 0 1-2 2z"
                  ></path>
                </svg> -->
                </div>
              </div>
            </div>
            <div class="card dropdown-user" id="dropdownlink">
              <ul class="list-group-custom" id="profilList">
                <li>
                  <router-link
                    to="/profil/beranda"
                    class="list-group-custom-item dashboard py-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      style="fill: #000; transform: ; msfilter: "
                    >
                      <path
                        d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"
                      ></path>
                    </svg>
                    Profile
                  </router-link>
                </li>
                <!-- <li>
                    <router-link
                    :class="[{'router-link-active active': this.$router.currentRoute.path === '/profil/sunting-profil'}]"
                      to="/profil/sunting-profil"
                      @click="showContent('form', $event)"
                      class="list-group-custom-item form"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="mr-3"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        style="transform: ; msfilter: "
                      >
                        <path
                          d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"
                        ></path>
                      </svg>
                      Sunting Pengguna
                    </router-link>
                  </li> -->
                <li>
                  <a class="list-group-custom-item py-2" @click="logout">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-3"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      style="transform: ; msfilter: "
                    >
                      <path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
                      <path
                        d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"
                      ></path>
                    </svg>
                    Keluar
                  </a>
                </li>
              </ul>
            </div>
            <div
              class="bg-dropdownuser"
              id="bgdropdown"
              @click="hideuserdropdown()"
            ></div>

            <!-- <button class="navbar-toggler" type="button" @click.prevent="" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button> -->
            <div
              class="collapse navbar-collapse"
              :class="showMenu ? 'show' : ''"
              id="navbarSupportedContent"
            >
              <div class="component-nav-left navbar-nav">
                <router-link @click="kliclink()" class="navbar-brand" to="/">
                  <figure class="img-logo border border-white">
                    <img src="../assets/logo_arys_mikro.jpeg" alt="" />
                  </figure>
                </router-link>
              </div>
              <div>
                <div class="navbar-nav">
                  <form action="" @submit.prevent="searchproduk" class="search-box">
                    <div class="input-group p-1 px-2 p-lg-2 rounded bg-white">
                      <div class="border-right">
                        <b-button v-b-toggle.sidebar-backdrop class="btn-trasnparant">
                          Semua Kategori
                        </b-button>
                      </div>
                      <input
                        id="searchinput"
                        type="text"
                        required
                        v-model="searchdata"
                        class="form-control border-0"
                        placeholder="Kamu lagi cari apa?"
                      />
                      <div class="input-group-append rounded">
                        <button
                          class="input-group-text btn bg-primary rounded"
                          id="basic-addon2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="20"
                            height="20"
                            style="fill: #fff; transform: ; msfilter: "
                          >
                            <path
                              d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <!-- <button >a</button> -->
                  </form>
                </div>
              </div>
              <div class="navbar-nav nav-right" v-if="this.$store.state.user.name">
                <router-link
                  to="/cart"
                  class="nav-link link-data-name nav-cart mb-2 text-light"
                  @click.prevent="kliclink()"
                >
                  <span class="span-cart-mobile" v-if="carts.length > 0">{{
                    carts.length
                  }}</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 24 24"
                  >
                    <rect x="0" y="0" width="26" height="26" fill="none" stroke="none" />
                    <g fill="none" stroke="#fff" stroke-width="1.5">
                      <path
                        d="M3.864 16.455c-.858-3.432-1.287-5.147-.386-6.301C4.378 9 6.148 9 9.685 9h4.63c3.538 0 5.306 0 6.207 1.154c.901 1.153.472 2.87-.386 6.301c-.546 2.183-.818 3.274-1.632 3.91c-.814.635-1.939.635-4.189.635h-4.63c-2.25 0-3.375 0-4.189-.635c-.814-.636-1.087-1.727-1.632-3.91Z"
                      />
                      <path
                        d="m19.5 9.5l-.71-2.605c-.274-1.005-.411-1.507-.692-1.886A2.5 2.5 0 0 0 17 4.172C16.56 4 16.04 4 15 4M4.5 9.5l.71-2.605c.274-1.005.411-1.507.692-1.886A2.5 2.5 0 0 1 7 4.172C7.44 4 7.96 4 9 4"
                      />
                      <path d="M9 4a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Z" />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M8 13v4m8-4v4m-4-4v4"
                      />
                    </g>
                  </svg>
                </router-link>
                <router-link
                  to="/profil/chat"
                  class="nav-link nav-cart mb-2 text-light"
                  @click.prevent="kliclink()"
                >
                  <span class="span-chat-mobile" v-if="jmlhpesan > 0">{{
                    jmlhpesan
                  }}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 32 32"
                  >
                    <rect x="0" y="0" width="26" height="26" fill="none" stroke="none" />
                    <g
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    >
                      <path
                        d="M25 5H7a4 4 0 0 0-4 4v10a4 4 0 0 0 4 4h11l6 4v-4h1a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4Z"
                      />
                      <path
                        d="M10 15a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm6 0a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm6 0a1 1 0 1 1-2 0a1 1 0 0 1 2 0Z"
                      />
                    </g>
                  </svg>
                </router-link>
                <div class="border-cart mx-2"></div>
                <router-link
                  to="/profil/beranda"
                  class="nav-link link-data-name nav-user mb-2 d-flex"
                  @click.prevent="kliclink()"
                >
                  <figure class="img-nav">
                    <img :src="imageuser" alt="" />
                  </figure>
                  <small class="pl-2 small-nav color-light">
                    {{ this.$store.state.user.username }}
                  </small>
                  <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="fill: #fff; transform: ; msfilter: "
                >
                  <path
                    d="M12 2A10.13 10.13 0 0 0 2 12a10 10 0 0 0 4 7.92V20h.1a9.7 9.7 0 0 0 11.8 0h.1v-.08A10 10 0 0 0 22 12 10.13 10.13 0 0 0 12 2zM8.07 18.93A3 3 0 0 1 11 16.57h2a3 3 0 0 1 2.93 2.36 7.75 7.75 0 0 1-7.86 0zm9.54-1.29A5 5 0 0 0 13 14.57h-2a5 5 0 0 0-4.61 3.07A8 8 0 0 1 4 12a8.1 8.1 0 0 1 8-8 8.1 8.1 0 0 1 8 8 8 8 0 0 1-2.39 5.64z"
                  ></path>
                  <path
                    d="M12 6a3.91 3.91 0 0 0-4 4 3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4zm0 6a1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2 1.91 1.91 0 0 1-2 2z"
                  ></path>
                </svg> -->
                </router-link>
              </div>
              <div class="navbar-nav py-3" v-else-if="!this.$store.state.user.name">
                <router-link to="/login" class="nav-link mx-2" @click.prevent="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 24 24"
                  >
                    <rect x="0" y="0" width="26" height="26" fill="none" stroke="none" />
                    <g fill="none" stroke="#fff" stroke-width="1.5">
                      <path
                        d="M3.864 16.455c-.858-3.432-1.287-5.147-.386-6.301C4.378 9 6.148 9 9.685 9h4.63c3.538 0 5.306 0 6.207 1.154c.901 1.153.472 2.87-.386 6.301c-.546 2.183-.818 3.274-1.632 3.91c-.814.635-1.939.635-4.189.635h-4.63c-2.25 0-3.375 0-4.189-.635c-.814-.636-1.087-1.727-1.632-3.91Z"
                      />
                      <path
                        d="m19.5 9.5l-.71-2.605c-.274-1.005-.411-1.507-.692-1.886A2.5 2.5 0 0 0 17 4.172C16.56 4 16.04 4 15 4M4.5 9.5l.71-2.605c.274-1.005.411-1.507.692-1.886A2.5 2.5 0 0 1 7 4.172C7.44 4 7.96 4 9 4"
                      />
                      <path d="M9 4a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Z" />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M8 13v4m8-4v4m-4-4v4"
                      />
                    </g>
                  </svg>
                </router-link>
                <div class="border-cart mx-2"></div>
                <router-link
                  to="/login"
                  class="nav-link btn btn-outline-primary color-light"
                  @click.prevent=""
                >
                  Masuk
                </router-link>
                <router-link
                  to="/register"
                  class="nav-link btn btn-outline-primary btn-daftar"
                  @click.prevent=""
                >
                  Daftar
                </router-link>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <b-sidebar
        class="datasidebar"
        id="sidebar-backdrop"
        ref="sidebar-backdrop"
        title=""
        backdrop
        shadow
      >
        <router-link class="navbar-brand p-3 logo" to="/">
          <figure class="img-logo-mobile pt-2">
            <img src="../assets/logo_arys_mikro.jpeg" alt="" />
          </figure>
        </router-link>
        <hr class="mt-2 mb-2" />
        <div class="px-3 py-2">
          <h3><b>Kategori</b></h3>
          <!-- <div class="accordion" role="tablist">
            <b-card no-body class="mb-1 no-border">
              <b-card-header header-tag="header" class="w-100 p-0 no-border" role="tab">
                <b-button block v-b-toggle.accordion-1 class="w-100 nav-link text-left bg-transparant">Accordion 1</b-button>
              </b-card-header>
              <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>adadad</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1 no-border">
              <b-card-header header-tag="header" class="w-100 p-0 no-border" role="tab">
                <b-button block v-b-toggle.accordion-2 class="w-100 nav-link text-left bg-transparant">Accordion 2</b-button>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>adadadgdgdg</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1 no-border">
              <b-card-header header-tag="header" class="w-100 p-0 no-border" role="tab">
                <b-button block v-b-toggle.accordion-3 class="w-100 nav-link text-left bg-transparant">Accordion 3</b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>hhfhfhfh</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div> -->
          <ul class="ul-sidebar">
            <template v-for="(datakategori, index) in kategori">
              <router-link
                v-if="datakategori?.subcategory?.length <= 0"
                :to="'/kategori?kategori=' + datakategori.id + '&stocked=1'"
                class="nav-link border-list py-1"
                @click.prevent=""
                :key="index"
              >
                <li
                  class="nav-item pb-2 pt-3 px-2 position-relative"
                  style="z-index: 2000"
                  :class="{ 'text-primary': route.query.kategori == datakategori?.id }"
                >
                  {{ datakategori.name }}
                </li>
              </router-link>
              <li
                class="p-1 border-list"
                :key="index"
                v-if="datakategori?.subcategory?.length > 0"
                :id="'tool-' + datakategori?.id"
                @click="dataklick(datakategori?.id)"
              >
                <div
                  :id="'dropdownid' + datakategori?.id"
                  class="dropdown-data d-none bg-white p-2"
                >
                  <router-link
                    class="link_kategori w-100 d-block"
                    :to="
                      '/kategori?kategori=' +
                      datakategori.id +
                      '&sub_kategori=' +
                      subkategory.id +
                      '&stocked=1'
                    "
                    v-for="(subkategory, dataindex) in datakategori?.subcategory"
                    :key="dataindex"
                    ><span
                      class="px-1"
                      :class="{
                        'text-primary': route.query.sub_kategori == subkategory.id,
                      }"
                      >{{ subkategory?.name }}</span
                    >
                    <hr
                      style="margin: 5px 0px !important"
                      class="w-100"
                      v-if="datakategori?.subcategory?.length > 1"
                  /></router-link>
                </div>
                <b-card no-body class="mb-1 no-border bg-transparant z-index-height">
                  <b-card-header
                    header-tag="header"
                    class="w-100 p-header-card no-border bg-transparant"
                    role="tab"
                  >
                    <b-button
                      block
                      v-b-toggle="'accordion-' + datakategori?.id"
                      class="w-100 nav-link text-left bg-transparant px-2 position-relative"
                      style="z-index: 2000"
                      :class="{
                        'text-primary': route.query.kategori == datakategori?.id,
                      }"
                      >{{ datakategori.name }}
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
                        >
                          <path
                            d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"
                          ></path>
                        </svg>
                      </span>
                    </b-button>
                  </b-card-header>
                </b-card>
                <b-tooltip
                  :target="'tool-' + datakategori?.id"
                  tabindex="0"
                  role="button"
                  triggers="focus"
                  placement="left"
                  boundary="scrollParent"
                >
                  <!-- <b-collapse :id="'accordion-'+datakategori?.id" accordion="my-accordion" class="tab_link" role="tabpanel">
                                  <b-card-body class="data-grid"> -->
                  <!-- <router-link
                    class="link_kategori w-100 d-block"
                    :to="
                      '/kategori?kategori=' +
                      datakategori.id +
                      '&sub_kategori=' +
                      subkategory.id +
                      '&stocked=1'
                    "
                    v-for="(subkategory, dataindex) in datakategori?.subcategory"
                    :key="dataindex"
                    ><span class="px-1" :class="{'text-primary':route.query.sub_kategori == subkategory.id}">{{ subkategory?.name }}</span>
                    <hr
                      style="margin: 5px 0px !important"
                      class="w-100"
                      v-if="datakategori?.subcategory?.length > 1"
                  /></router-link> -->
                  <!-- <b-card-text v></b-card-text> -->
                  <!-- </b-card-body>
                                </b-collapse> -->
                </b-tooltip>
                <!-- <b-tooltip :target="'tool-'+datakategori?.id" placement="topleft" class="web-tool"> -->
                <!-- <b-collapse :id="'accordion-'+datakategori?.id" accordion="my-accordion" class="tab_link" role="tabpanel">
                                  <b-card-body class="data-grid"> -->
                <!-- <router-link class="link_kategori" :to="'/kategori?kategori='+datakategori.id+'&sub_kategori='+subkategory.id+'&stocked=1'" v-for="(subkategory, dataindex) in datakategori?.subcategory" :key="dataindex"><span class="px-1">{{subkategory?.name}}</span> <hr style="margin:5px 0px !important" class="w-100" v-if="datakategori?.subcategory?.length>1"></router-link> -->
                <!-- <b-card-text v></b-card-text> -->
                <!-- </b-card-body>
                                </b-collapse> -->
                <!-- </b-tooltip> -->
              </li>
            </template>
          </ul>
        </div>
        <div class="bg-dropdown" @click="deletedropdown()"></div>
        <div class="bg-right" @click="closesidebar()"></div>
      </b-sidebar>
    </div>

    <div class="floating-wa">
      <a
        href="https://wa.me/6287751009312?text="
        target="_blank"
        rel="noopener noreferrer"
      >
        <figure>
          <img src="../assets/img/whatsapp.webp" alt="" />
        </figure>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import Img_Default from "../assets/img/img-default.png";
import SkeletonNavbar from "../components/Skeleton/SkeletonNavbar.vue";
import Loader from "../components/Loader/Loader.vue";
export default {
  data() {
    return {
      check: false,
      isMobile: false,
      homebtn: false,
      searchdata: "",
      news: [],
      showMenu: false,
      loading: true,
      isCatActive: false,
      route: null,
      iddata: null,
    };
  },
  setup() {},
  methods: {
    closesidebar() {
      document.querySelector(".close").click();
      this.deletedropdown();
    },
    deletedropdown() {
      if (this.iddata != null) {
        document.getElementById("dropdownid" + this.iddata).classList.remove("d-block");
        document.getElementById("dropdownid" + this.iddata).classList.add("d-none");
        this.iddata = null;
      }
    },
    dataklick(data) {
      if (this.iddata != null) {
        document.getElementById("dropdownid" + this.iddata).classList.remove("d-block");
        document.getElementById("dropdownid" + this.iddata).classList.add("d-none");
      }
      this.iddata = data;
      let id = document.getElementById("tool-" + this.iddata);
      if (id) {
        // Position
        var rect = id.getBoundingClientRect();
        var checkbottom = window.innerHeight - rect.bottom;
        // const bottomPositionInVh = rect.bottom / window.innerHeight * 100
        document.getElementById("dropdownid" + this.iddata).classList.remove("d-none");
        document.getElementById("dropdownid" + this.iddata).classList.add("d-block");
        // ini untuk pengecekan apakah dropdown ditampilkan scroll ke atas atau kebawah, di cek dengan cara mengecek tinggi posisi list yang di klik dengan bottom device
        let iddata = document.getElementById("dropdownid" + this.iddata);
        var rectcontent = iddata.getBoundingClientRect();

        if (rectcontent.height < checkbottom) {
          document.getElementById("dropdownid" + this.iddata).style.top = rect.top + "px";
        } else if (rectcontent.height < rect.top) {
          document.getElementById("dropdownid" + this.iddata).style.bottom =
            checkbottom + "px";
        } else {
          document.getElementById("dropdownid" + this.iddata).style.top = rect.top + "px";
          document.getElementById("dropdownid" + this.iddata).style.height =
            rect.bottom + "px";
          document.getElementById("dropdownid" + this.iddata).style.overflowY = "scroll";
        }

        // Height
        //var height = id.clientHeight;
        // console.log("Height:", height);

        // Content
        //var content = id.innerHTML;
        // console.log("Content:", content);
      } else {
        console.error("Element not found!");
      }
    },
    onScroll(e) {
      this.windowTop = e.target.documentElement.scrollTop;
      if (this.windowTop > 50) {
        document.getElementById("nav").classList.add("scroll-nav");
      } else if (this.windowTop < 50) {
        document.getElementById("nav").classList.remove("scroll-nav");
      }
    },
    chatrealtime() {
      this.$store.dispatch("jmlhpesan");
      // let me = this;
      // setInterval(this.updateChat, 5000)

      // let urlParams = new URLSearchParams(window.location.search);

      // let i = 0
      // TimerHandler();
      // function TimerHandler() {
      //   setTimeout(function () {
      // me.$store.dispatch("jmlhpesan").then(() => {
      //       TimerHandler();
      //     });
      //   }, 10000);
      // }
    },
    checkScreenWidth() {
      this.isMobile = window.innerWidth < 768;
    },
    homepage() {
      window.scrollTo(0, 0);
    },
    kliclink() {
      this.$el.scrollIntoView({ behavior: "smooth" });
      if (this.statussunting === true) {
        this.$store.dispatch("suntingdata", false);
        this.$store.dispatch("user");
      }
    },
    userdropdown() {
      document.getElementById("dropdownlink").style.height = "100px";
      document.getElementById("dropdownlink").classList.add("p-2");
      document.getElementById("bgdropdown").classList.add("d-block");
    },
    hideuserdropdown() {
      document.getElementById("dropdownlink").style.height = "0px";
      document.getElementById("dropdownlink").classList.remove("p-2");
      document.getElementById("bgdropdown").classList.remove("d-block");
    },
    searchproduk() {
      this.$router.push("/kategori?search=" + this.searchdata + "&stocked=1");
    },
    logout() {
      swal("Apakah anda yakin untuk keluar?", "", "info", {
        buttons: ["Batal", "Ya"],
      }).then((res) => {
        if (res) {
          // this.setModal('close')
          this.$bvModal.show("modal-loading");
          axios
            .post("/customer/logout")
            .then(() => {
              this.$bvModal.hide("modal-loading");
              this.$cookies.remove("token");
              localStorage.removeItem("cart_id");
              this.$cookies.remove("id_checkout");
              this.$cookies.remove("id_transaction");
              this.auth = false;
              window.location.reload();
              this.$router.push("/");
            })
            .catch(() => {});
        }
      });
    },
  },
  components: {
    SkeletonNavbar,
    Loader,
  },
  async mounted() {
    try {
      this.route = this.$route;
      this.loading = true;
      if (this.user instanceof Promise == true) {
        await this.$store.dispatch("user");
        this.$store.dispatch("address");
        this.$store.dispatch("carts");
      }
      if (this.user instanceof Promise == true) {
        this.check = false;
      } else if (this.user == undefined) {
        this.check = false;
      } else {
        this.check = true;
      }
      if (this.kategori.length == 0) {
        await this.$store.dispatch("category");
      }
      if (this.carts == [] || this.$store.state.carts[0] === undefined) {
        await this.$store.dispatch("carts");
      }
      this.chatrealtime();
      // if (this.berita instanceof Promise == true || this.berita == []) {
      //       await this.$store.dispatch("berita");
      //       this.news = this.berita
      //   }
      window.addEventListener("resize", this.checkScreenWidth);
      this.checkScreenWidth();
      this.userdata = this.user;
      if (this.userdata.image) {
        this.imageuser = this.$imgUrl + this.userdata.image;
      } else {
        this.imageuser = Img_Default;
      }
      this.news = this.berita;
      this.loading = false;
      if (window.location.pathname === "/") {
        this.homebtn = true;
      } else {
        this.homebtn = false;
      }
    } catch (error) {
      this.loading = false;
      return error;
    }
    window.addEventListener("scroll", this.onScroll);
  },

  computed: {
    berita: function () {
      return this.$store.state.berita;
    },
    carts: function () {
      return this.$store.state.carts;
    },
    statussunting() {
      return this.$store.state.suntingdata;
    },
    jmlhpesan: function () {
      return this.$store.state.jmlhpesan;
    },
    kategori: function () {
      return this.$store.state.category;
    },
    categorydata() {
      return this.kategori.map((datakategori) => ({
        datasub: datakategori.subcategory,
      }));
    },
    user: function () {
      return this.$store.state.user;
    },
    getMainNavClasses() {
      return {
        "scroll-up": this.scrollingUp,
        "scroll-down": this.scrollingDown,
      };
    },
  },

  watch: {
    async $route() {
      this.route = this.$route;
      if (window.location.pathname === "/") {
        this.homebtn = true;
      } else {
        this.homebtn = false;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.checkScreenWidth);
  },
};
</script>
<style scoped>
.p-header-card {
  padding: 0.85rem 0px 0px 0px;
}
.btn-trans {
  background-color: transparent !important;
  border: 0px !important;
  border-radius: 0px !important;
}
::v-deep .mobile-tool {
  display: block;
}
::v-deep .close {
  z-index: 30 !important;
}
::v-deep .logo {
  z-index: 30 !important;
}
::v-deep .web-tool {
  display: none;
}
::v-deep .ul-sidebar {
  list-style-type: none !important;
}
::v-deep .tooltip .tooltip-inner {
  background-color: #fff !important;
  color: #000 !important;
  position: relative;
  width: 250px;
  left: -30px;
  /* max-width: 400px; */
}
.z-index-height {
  z-index: 30 !important;
}
.bg-dropdown {
  height: 100vh;
  width: 100vh;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 20;
}
.bg-right {
  height: 100vh;
  width: 100vh;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 200px;
  right: 0px;
  z-index: 20;
}
.dropdown-data {
  position: absolute;
  border-radius: 5px;
  background-color: #fff;
  left: 200px;
  min-width: 150px;
  max-width: 200px;
  z-index: 10000;
}
.floating-wa {
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 70px;
  right: 10px;
  z-index: 195;
}
.floating-wa figure {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.floating-wa figure img {
  width: 100%;
}
::v-deep .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}
::v-deep .collapsed > .when-open,
::v-deep .not-collapsed > .when-closed {
  display: none;
}
::v-deep .b-sidebar > .b-sidebar-header {
  height: 100px !important;
}
::v-deep .data-grid {
  display: grid !important;
}
.bg-dropdownuser {
  position: absolute;
  width: 100%;
  height: 100vh;
  bottom: 0px;
  top: 0px;
  left: 0px;
  display: none;
  z-index: 205;
}
.dropdown-user {
  width: 125px;
  height: 0px;
  border-radius: 5px !important;
  position: absolute;
  top: 50px;
  right: 15px;
  z-index: 210;
  overflow: hidden;
  transition: height 0.5s;
  border: 0px !important;
}

.list-group-custom {
  padding: 0px !important;
  border-radius: 0.5rem;
  list-style-type: none;
  border-radius: 10px;
}

.list-group-custom-item {
  color: #000;
  fill: #000;
  padding: 7px 0px !important;
  font-weight: 400 !important;
  width: 100%;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-decoration: none;
  cursor: pointer;
}
.kategori-header {
  font-size: 1rem;
}
.border-list {
  border-radius: 5px;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
}
.border-list:hover {
  background-color: rgba(47, 49, 146, 0.1);
}
.text-left {
  text-align: left !important;
  display: flex;
  justify-content: space-between;
}
.p-0 {
  padding: 0px !important;
}
.tab_link {
  background-color: transparent;
}
::v-deep .tab_link .card-body {
  background-color: transparent !important;
}
.link_kategori {
  line-height: 2;
  text-decoration: none !important;
  color: #000;
}
.link_kategori:hover {
  background-color: rgba(47, 49, 146, 0.1);
}
.no-border {
  border: 0px !important;
}
.bg-transparant {
  background-color: transparent !important;
  color: #000;
  border: 0px !important;
}
::v-deep .b-sidebar-header button {
  background-color: transparent !important;
  border: 0px !important;
}
::v-deep .datalink {
  color: #000 !important;
  text-decoration: none !important;
}
::v-deep .collapse a:active {
  background-color: transparent !important;
}
::v-deep .navbar-header {
  z-index: 200;
  background-color: #fff;
  position: fixed;
}
::v-deep .navbar {
  padding: 10px 0px;
}
::v-deep .navbar-nav {
  display: none;
}

::v-deep .navbar .nav-link {
  margin-bottom: 0px !important;
}
.img-nav {
  width: 25px;
  height: 25px;
  overflow: hidden;
  display: block;
  border: 2px solid rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  margin: 0px !important;
}
.img-nav img {
  width: 100%;
}
.small-nav {
  line-height: 2.5;
  font-weight: 600;
  padding-left: 5px;
  color: rgba(0, 0, 0, 0.6);
}
.span-cart-mobile {
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 0px 4px;
  font-size: 10px;
  border-radius: 15px;
  text-align: center;
}
.span-chat-mobile {
  position: absolute;
  background-color: red;
  color: #fff;
  padding: 0px 4px;
  font-size: 10px;
  border-radius: 15px;
  text-align: center;
}
.span-cart-web {
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 0px 4px;
  font-size: 10px;
  border-radius: 15px;
  text-align: center;
}
.navbar-nav input {
  width: 300px;
}
.d-show {
  display: block !important;
}
.d-none {
  display: none;
}

.scroll-nav {
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}
.color-light {
  color: #fff !important;
}
.color-light:hover {
  color: #fff !important;
}
.btn-daftar {
  margin-left: 0.5rem;
}
.btn-daftar:hover {
  color: #fff !important;
}
.btn-daftar:active {
  color: #fff !important;
}
.navbar {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
}
.nav-bottom {
  z-index: 200;
  position: fixed;
  bottom: 0;
  padding: 5px 0px;
  box-shadow: -5px -5px 20px rgba(1, 1, 1, 0.05);
}
::v-deep .nav-bottom .row {
  padding: 0px !important;
  margin: 0px !important;
}
::v-deep .col-3 {
  text-align: center;
}
::v-deep .nav-bottom .col-3,
.nav-bottom .col-3 small {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
}
.collapse-kategori {
  border: 0px !important;
  background-color: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}
::v-deep .collapse-kategori .card-body {
  display: flex;
  overflow-x: auto;
}
.navbar .nav-mobile {
  display: block;
}
.navbar .nav-mobile input {
  max-width: 140px;
  border: 0px !important;
}
.navbar .nav-mobile input:hover,
.navbar .nav-mobile input:focus,
.navbar .nav-mobile input:focus-visible {
  border: 0px !important;
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0.25) !important;
  outline: -webkit-focus-ring-color auto 0px !important;
}
.link-cart-mobile {
  padding-top: 5px;
}
.btn-mobile {
  border: 0px !important;
}
.navbar .btn-nav {
  background-color: transparent !important;
}
.navbar .navbar-brand {
  display: flex;
  align-items: center;
}
.navbar .navbar-brand .mobile-nav {
  display: none;
}

.navbar .navbar-collapse {
  justify-content: space-between;
}
.navbar .nav-link {
  font-weight: 400;
  color: #000;
  font-size: 0.8rem;
  text-transform: capitalize;
}
/* .navbar .nav-link:hover {
  color: #000 !important;
} */
/* .navbar .nav-link:active {
  color: #000 !important;
} */
.navbar .justify-content-right {
  justify-content: right !important;
}
.img-logo {
  max-width: 150px;
  margin: 0 auto;
}
.img-logo-mobile img {
  height: 60px !important;
}
.img-logo img {
  width: 100%;
}
.border-cart {
  border-right: solid 2px #fff !important;
}
.datasidebar .navbar-brand {
  position: absolute;
  top: -5px;
}
.datasidebar .navbar-brand img {
  height: 60px;
}
.datalink {
  color: #000 !important;
  text-decoration: none !important;
}

.datalink:hover {
  background-color: transparent !important;
}
.datalink:active {
  background-color: transparent !important;
}

.middle {
  position: absolute;
  border-radius: 50px;
  padding-right: 15px;
  border: 1px solid #000;
}
.middcliked {
  padding-right: 30px !important;
}
.input_search {
  width: 0.6rem;
  height: 0.1rem;
  background: none;
  outline: none;
  border: 0px;
  transition-property: width, height;
  transition-duration: 0.8s;
  transition-timing-function: ease-in-out;
  color: #000;
  font-size: 1rem;
}
.border-right {
  border-right: 3px solid #2f3192a5;
}
.input-group input,
.input-group input:focus,
.input-group input:focus-visible {
  border-right: 0px !important;
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0.25) !important;
  outline: -webkit-focus-ring-color auto 0px !important;
}
.input-group button,
.input-group .input-group-append span {
  border-left: 0px !important;
  cursor: pointer;
}
.incliked {
  height: 32px;
  border-radius: 40px;
  padding: 0 1rem;
  transition-property: width, height;
  transition-duration: 0.8s;
  transition-timing-function: ease-in-out;
}
.btndata svg {
  width: 25px;
  height: 25px;
}

@media (max-width: 500px) {
  ::v-deep .b-sidebar {
    width: 200px !important
    ;
  }
  .border-list {
    font-size: 0.85rem !important;
  }
  ::v-deep .ul-sidebar {
    list-style-type: none !important;
    padding-left: 0px !important;
  }
  ::v-deep .tooltip {
    left: 200px !important;
  }
  ::v-deep .tooltip .tooltip-inner {
    width: 150px !important;
  }
}
@media (min-width: 374px) {
  .navbar .nav-mobile input {
    max-width: 205px;
  }
}

@media (min-width: 424px) {
  .navbar .nav-mobile input {
    width: 240px;
    max-width: 250px;
  }
}
@media (max-width: 767.98px) {
  .navbar.navbar-brand {
    margin: 0;
  }
  /* ::v-deep .tooltip{ */
  /* transform: translate3d(30px, 190px, 0px) !important; */
  /* } */
}
@media (min-width: 767px) {
  .nav-link {
    padding: 0.38rem 1rem;
  }
  .floating-wa {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 15px;
    right: 20px;
  }
  .floating-wa figure {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .floating-wa figure img {
    width: 100%;
  }
  ::v-deep .ul-sidebar {
    list-style-type: none !important;
    padding-left: 10px !important;
  }
  .small-nav {
    max-width: 40px;
    overflow-x: hidden;
  }
  ::v-deep .mobile-tool {
    display: none;
  }
  ::v-deep .web-tool {
    display: block;
  }
  ::v-deep .tooltip .tooltip-inner {
    background-color: #fff !important;
    position: relative;
    width: 300px;
    /* max-width: 400px; */
    left: 14.5rem;
  }
  .navbar .nav-mobile {
    display: none;
  }
  ::v-deep .navbar-nav {
    display: flex;
  }
  .nav-bottom {
    display: none;
  }
  .component-nav-left {
    width: 100px;
    display: flex;
  }
  ::v-deep .btn-kategori-nav {
    background-color: transparent !important;
    border: 0px !important;
    color: rgba(0, 0, 0, 0.5) !important;
    padding-top: 10px;
  }
  ::v-deep .btn-kategori-nav:hover {
    background-color: transparent !important;
    border: 0px !important;
    color: rgba(0, 0, 0, 0.5) !important;
    padding-top: 10px;
  }
  .btn-kategori-nav small {
    color: rgba(0, 0, 0, 0.5);
  }
  .navbar {
    padding: 10px;
  }
  .incliked {
    width: 10rem;
  }
  .navbar-nav input {
    width: 175px;
  }
  .img-logo {
    max-width: 100px;
  }
}
@media (max-width: 1023px) {
  .nav-right {
    margin-bottom: 10px;
  }
}
@media (min-width: 1023px) {
  .small-nav {
    max-width: 50px;
  }

  .link-data-name {
    padding: 0.5rem 0.5rem 0.1rem 0.5rem !important;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .nav-cart {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .nav-cart:hover {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .nav-user {
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .nav-user:hover {
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .img-logo {
    max-width: 150px;
  }
  .btn-kategori-nav {
    padding-left: 20px;
  }
  .navbar-nav input {
    width: 325px;
  }
}
@media (min-width: 1023px) {
  .navbar {
    padding: 10px;
  }
  .component-nav-left {
    width: 150px;
  }
}
@media (min-width: 1200px) {
  .small-nav {
    max-width: 85px;
  }
  .container-nav {
    width: 1200px !important ;
    margin-left: auto;
    margin-right: auto;
  }
  .nav-link {
    padding: 0.38rem 1rem;
  }
  .link-data-name {
    padding: 0.5rem 0.5rem 0.1rem 0.5rem !important;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .nav-cart {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .nav-cart:hover {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .nav-user {
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .nav-user:hover {
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .incliked {
    width: 360px;
  }
  .navbar-collapse {
    max-width: 1140px;
    margin: auto;
  }
  .btn-kategori-nav {
    padding-left: 30px;
  }
  .navbar-nav input {
    width: 425px;
  }
}
@media (min-width: 1439px) {
  .navbar-collapse {
    max-width: 1320px;
    margin: auto;
  }
  .navbar-nav input {
    width: 525px;
  }
}
</style>
