<template>
  <div class="p-md-3">
    <Loader v-if="loading"></Loader>
    <SkeletonUlasan v-if="loading"></SkeletonUlasan>
    <div class="" v-if="!loading">
      <div v-if="checkarray(review).length > 0">
        <AddUlasan @datareload="datareload" :datatransaksi="review"></AddUlasan>
      </div>
      <div v-else-if="review.length <= 0"></div>
      <div v-if="cekdata">
        <div class="p-md-5">
          <img src="../../assets/img/notfounddata.webp" class="img-none" alt="" />
        </div>
        <p class="w-100 mt-3" style="text-align: center">Anda belum memilliki Ulasan</p>
      </div>
      <div v-else-if="datareview">
        <div v-for="(ulasandata, index) in datareview" :key="index">
          <div v-if="ulasandata.review">
            <h5 class="mt-3">Produk {{ index + 1 }}</h5>
            <DetailUlasan
              :datatransaksi="datatrans(ulasandata?.id)"
              :stardata="ulasandata?.review?.star"
              :notesdata="ulasandata?.review?.notes"
              :dataimage="ulasandata?.review?.image"
              :respon_notesdata="ulasandata?.review?.respon_notes"
            >
            </DetailUlasan>
          </div>
        </div>
      </div>
      <!-- <div v-else-if="datareview.length > 0">
        <div v-for="(ulasandata, index) in datareview" :key="index">
          <div v-if="ulasandata.review">
            <h5 class="mt-3">Produk {{ index + 1 }}</h5>
            <DetailUlasan
              :datatransaksi="datatransaksi?.transaction_detail[index]"
              :stardata="ulasandata?.review?.star"
              :notesdata="ulasandata?.review?.notes"
              :dataimage="ulasandata?.review?.image"
              :respon_notesdata="ulasandata?.review?.respon_notes"
            >
            </DetailUlasan>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import SkeletonUlasan from "../../components/Skeleton/Transaksi/UlasanData.vue";
import Loader from "@/components/Loader/Loader.vue";
import axios from "axios";
import AddUlasan from "./AddUlasan.vue";
import DetailUlasan from "./DetailUlasan.vue";
export default {
  props: ["detailtransaksi"],
  data() {
    return {
      datatransaksi: {},
      review: [],
      loading: true,
      cekdata: true,
      datareview: [],
      lengthreview: [],
    };
  },
  components: {
    DetailUlasan,
    AddUlasan,
    SkeletonUlasan,
    Loader,
  },
  async mounted() {
    let dataid = this.$route.query.id_transaksi;
    this.review = this.detailtransaksi.reviews;
    this.datatransaksi = this.detailtransaksi?.data;
    await axios
      .get("/review/" + dataid)
      .then((res) => {
        let datareviews = res.data.data;
        let k = 0;
        for (let i = 0; i < datareviews?.transaction_detail.length; i++) {
          if (datareviews.transaction_detail[k]?.review) {
            this.datareview.push(datareviews.transaction_detail[k]);
            this.cekdata = false;
          }
          k++;
        }
        this.loading = false;
        // this.$emit('datareload', datasupport)
        // this.setModal(false, this.modalTipe)
        // this.loading=false
      })
      .catch(() => {
        // this.loading=false
      });
  },
  methods: {
    datatrans(id){
      var datareview = this.datatransaksi.transaction_detail.filter(function(value){ 
                return value.id == id;
            });
     return datareview[0]

    },
    checkarray(data){
      return Object.keys(data)
    },
    datareload(value) {
      this.review = value.datatransaksi?.reviews;
      this.datareview = [];
      let reviewdata = value.datareview;
      let k = 0;
      // ini dilakukan untuk menampilkan data yang sudah di review tanpa harus merequest kembali namun bisa ditampilkan langsung
      for (let i = 0; i < reviewdata?.transaction_detail.length; i++) {
        if (reviewdata.transaction_detail[k]?.review) {
          this.datareview.push(reviewdata.transaction_detail[k]);
          this.cekdata = false;
        }
        k++;
      }
      this.$emit("transaksidata", value.datatransaksi);

      this.datatransaksi = value.datatransaksi?.data;
    },
  },
};
</script>
<style scoped>
.img-none {
  width: 60%;
  display: block;
  margin: 0 auto;
}
@media (max-width: 500px) {
  ::v-deep .container-fluid {
    padding: 0px !important;
  }
}
</style>
