<template>
  <div>
    <SkeletonBerita v-if="loading" />
    <div class="container mt-3 mt-md-5 over-data" v-if="!loading">
      <div class="header-berita">
        <h4>Berita</h4>
        <p>Info terbaru dari perusahaan kami</p>
      </div>
      <VueSlickCarousel v-bind="settings" ref="carousel" v-if="databerita.length">
        <div class="card w-card" v-for="(berita, index) in databerita" :key="index">
          <figure style="height: 200px; overflow: hidden; position: relative">
            <img
              style="width: 100%"
              class="card-img-top"
              :src="$imgProduct + '/storage/' + berita.image"
              alt="Card image cap"
            />
          </figure>
          <div class="card-body text-truncate">
            <!-- <small class="time">{{dateTime(berita.updated_at)}}</small> -->
            <h2 class="card-title mt-2">{{ berita.title }}</h2>
            <!-- <div class=""> -->
            <h5 class="text text-card mt-3" v-html="berita.content"></h5>
            <!-- </div> -->
            <router-link
              :to="'/berita-detail?slug_berita=' + berita.slug"
              class="btn-card mt-3"
              variant="light"
              >Selengkapnya</router-link
            >
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>
<script>
//   import axios from "axios";
import SkeletonBerita from "../Skeleton/Homepage/SkeleteonBerita.vue";
import moment from "moment";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  data() {
    return {
      news: [],
      loading: true,
      settings: {
        dots: true,
        infinite: false,
        centerPadding: "20px",
        focusOnSelect: false,
        slidesToShow: 3,
        speed: 500,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  components: { VueSlickCarousel, SkeletonBerita },
  async mounted() {
    try {
      this.loading = true;
      if (this.berita instanceof Promise == true || this.berita == []) {
        await this.$store.dispatch("berita");
        this.news = this.berita;
      }
      this.news = this.berita;
      this.loading = false;
    } catch (error) {
      return error;
    }
  },
  computed: {
    berita: function () {
      return this.$store.state.berita;
    },
    databerita() {
      return this.news.map((item) => {
        var data = {
          ...item,
          content: item.content.replace(
            /(<[\w\d ="'/]+>|<\/[\w\d ="']+>|<a.*?<\/a>)+/g,
            ""
          ),
        };
        return data;
      });
    },
  },
  methods: {
    dateTime(value) {
      return moment(value).format("DD MMMM YYYY");
    },
  },
};
</script>
<style scoped>
.over-data {
  overflow: hidden;
}
::v-deep .w-card {
  max-width: 20rem;
}
::v-deep .w-card p {
  font-size: 9pt;
  line-height: 2;
  color: rgba(0, 0, 0, 0.6);
}
.header-berita {
  text-align: left;
}
.header-berita h4 {
  font-weight: 600;
}
.header-berita p {
  color: rgba(0, 0, 0, 0.6);
}
.flashdata {
  overflow: hidden;
}
.header_flash_sale {
  display: flex;
}
.header_flash_sale h4 {
  margin-top: 15px;
  margin-right: 15px;
  font-weight: 600;
}
.waktu_flash h6 {
  padding: 10px;
  background-color: red;
  border-radius: 10px;
  font-weight: 600;
  color: #fff;
  margin: 5px 10px;
}
.waktu_flash small {
  font-weight: 400;
  font-size: 0.8rem;
}

.card figure {
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.time {
  display: block;
  width: 100%;
  text-align: right;
  color: rgba(0, 0, 0, 0.4);
}
.card-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal !important;
  text-align: left;
  line-height: 1.5;
}
.text-card {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal !important;
}
@media (max-width: 500px) {
  .card-title {
    font-size: 12px;
  }
  .text-card {
    font-size: 9px;
    height: 150px !important;
    max-height: 70px !important;
  }
  ::v-deep .slick-initialized .slick-slide {
    width: 150px !important;
  }
  .card figure {
    width: 100%;
    height: 100px !important;
    overflow: hidden;
    margin: 0px !important;
  }
  .w-card .card-body {
    padding: 0.5rem !important;
  }
  .w-card .card-body .time {
    font-size: 0.5rem;
  }
  .w-card .card-body h5 {
    min-height: 35px !important;
    font-size: 11px !important;
  }
  .w-card .card-body .harga,
  .w-card p {
    font-size: 0.6rem !important;
  }
  .btn-card {
    font-size: 0.8rem;
  }
}
.card-text {
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 9pt;
  font-weight: 400;
  height: 120px;
}
.text-card {
  line-height: 2;
}
.btn-card {
  display: block !important;
  margin: 0 auto !important;
  text-align: center;
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.6) !important;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff);
}

::v-deep .navbar {
  box-shadow: 0px 0px 0px !important;
}
::v-deep .d-flex {
  display: flex !important;
}
::v-deep .slick-track .slick-slide {
  padding-left: 5px;
  padding-right: 5px;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .card-title {
    font-size: 15pt;
    font-weight: 600;
    height: 150px;
    max-height: 65px;
  }
  .text-card {
    height: 220px;
    max-height: 95px;
    font-size: 1rem;
  }
  .btn-card {
    display: block !important;
    margin: 0 auto !important;
    text-align: center;
    text-decoration: none !important;
    color: #000 !important;
  }
  ::v-deep .w-card p {
    font-size: 0.8rem;
    line-height: 1.5;
  }
}

@media (min-width: 1023px) {
  .card-title {
    height: 70px;
    max-height: 55px;
  }
  .text-card {
    height: 180px;
    max-height: 100px;
  }
}

@media (min-width: 1439px) {
  .card-title {
    height: 75px;
    max-height: 60px;
  }
  .text-card {
    height: 150px;
    max-height: 105px;
  }
  ::v-deep .w-card {
    max-width: 90%;
  }
  ::v-deep .slick-track .slick-slide {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 2000px) {
}
</style>
