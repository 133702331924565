import Vue from 'vue'
import VueCookies from 'vue-cookies';
// import store from '../store/store'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutUs from '../views/AboutusView.vue'
import KategoriProduk from '../components/Kategori/KategoriView.vue'
import DetailProduk from '../views/DetailProduk.vue'
import DetailBerita from '../views/DetailBerita.vue'
import DataCart from '../views/CartView.vue'
import DataCartCoba from '../components/Cart/cartView.vue'
import DataCheckout from '../views/CheckoutView.vue'
import DataInvoice from '../views/Transaksi/DetailInvoice.vue'
import Profile from '../views/Profile/ProfileView.vue'
import DashboardUser from '../views/Dashboard/DashboardView.vue'
import EditProfile from '../views/SuntingProfile/SuntingProfile.vue'
import Datatransaksi from '../views/Transaksi/DataTransaksi.vue'
import ProfileChat from '../views/Dashboard/ChatView.vue'
import TableAddress from '../views/Dashboard/AlamatView.vue'
import FormUserAddress from '../components/Alamat/AddAlamat.vue'
import EditUserAddress from '../components/Alamat/EditAlamat.vue'
import DetailPesananData from '../views/Transaksi/DetailTransaksi.vue'
import Register from '../views/Auth/RegisterView.vue'
import Login from '../views/Auth/LoginView.vue'
import Konfirmasi from '../views/KonfirmasiView.vue'
import Forgotpass from '../views/Auth/ForgetPassword.vue'
import ResetPassword from '../views/Auth/ResetPassword.vue'
import notFound from '../components/404_not_found.vue'
import PerView from '../components/PercobaanToltip.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/per',
    name: 'per',
    component: PerView
  },
  {
    path: '/tentang-kami',
    name: 'Tentang-kami',
    component: AboutUs
  },
  {
    path: '/konfirmasi',
    name: 'Konfirmasi',
    component: Konfirmasi
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '*',
    name: '',
    component: notFound
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
      path: '/lupa-password',
      name: 'lupa-password',
      component: Forgotpass,
  },
  {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
  },
  {
    path: '/kategori',
    props: route => ({ query: route.query.id_kategori },{ query: route.query.search }),
    component: KategoriProduk
  },
  {
    path: '/cart',
    name: 'DataCart',
    component: DataCart,
    meta: {
        requiresAuth: true
    }
  },
  {
    path: '/cartcoba',
    name: 'DataCartCoba',
    component: DataCartCoba,
    meta: {
        requiresAuth: true
    }
  },
  {
    path: '/checkout',
    name: 'DataCheckout',
    component: DataCheckout,
    meta: {
        requiresAuth: true
    }
  },
  {
    path: '/invoice',
    name: 'DataInvoice',
    component: DataInvoice,
    meta: {
        requiresAuth: true
    }
  },
  {
    path: '/produk-detail',
    props: route => ({ query: route.query.id_produk }),
    component: DetailProduk
  },
  {
    path: '/berita-detail',
    props: route => ({ query: route.query.slug_berita }),
    component: DetailBerita
  },
  {
    path: '/profil',
    name: 'Profile',
    component: Profile,
    redirect: VueCookies.isKey('token') ? '/profil/dashboard' : '/login',
    // props: (route) => ({ param: route.params || 'dashboard' }) 
    children: [
        {
            path: '/profil/beranda',
            component: DashboardUser,
            name: 'Beranda Pengguna',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/profil/sunting-profil',
            component: EditProfile,
            name: 'Sunting Pengguna',
            meta: {
                requiresAuth: true
            }
        },
        {
          path: '/profil/transaksi',
          name: 'Transaksi',
          component: Datatransaksi,
          meta: {
              requiresAuth: true
          }
      },
      {
          path: '/profil/chat',
          component: ProfileChat,
          name: 'Chat',
          meta: {
              requiresAuth: true
          }
      },
      {
          path: '/profil/alamat',
          name: 'Alamat',
          component: TableAddress,
          meta: {
              requiresAuth: true
          }
      },
      
      {
          path: '/profil/tambah-alamat',
          component: FormUserAddress,
          name: 'Tambah Alamat',
          meta: {
              requiresAuth: true
          }
      },
      {
          path: '/profil/edit-alamat',
          component: EditUserAddress,
          name: 'Edit Alamat',
          props: route => ({ query: route.query.id_alamat }),
          meta: {
              requiresAuth: true
          }
      },
      {
        path: '/profil/detailtransaksi',
        name: 'Detail Transaksi',
        component: DetailPesananData,
        props: route => ({ query: route.query.id_transaksi }),
        meta: {
            requiresAuth: true
        }
    },
      ]
    },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition
    } else {
        return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    var token = VueCookies.get('token')
    if (!token) {
      next({ path: '/login' });
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
