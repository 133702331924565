<template>
  <div class="home">
    <Navbar/>
    <Produk/>
    <RecomendProduk/>
    <Footer/>
  </div>
</template>

<script>
import Navbar from '@/components/NavbarUno.vue'
import Footer from '@/components/FooterUno.vue'
import Produk from '@/components/Produk/ProdukDetail.vue'
import RecomendProduk from '@/components/Produk/RekomendasiProduk.vue'

export default {
  name: 'HomeView',
  components: {
    Navbar,
    Footer,
    Produk,
    RecomendProduk,
  }
}
</script>
