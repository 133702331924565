<template>
  <div>
    <Loader v-if="loading" />
    <SkeletonSunting v-if="loading" />
    <div class="card-dashboard" v-if="!loading">
      <div class="px-3 pt-3 header-sunting mb-3">
        <h4 class="px-2 pt-2">Sunting Profile</h4>
        <b-button v-b-modal.modalpass variant="outline-warning" class="ubahpassword">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 20 20"
          >
            <path
              fill="currentColor"
              d="M15 6a1 1 0 1 1-2 0a1 1 0 0 1 2 0Zm-2.5-4C9.424 2 7 4.424 7 7.5c0 .397.04.796.122 1.175c.058.27-.008.504-.142.638l-4.54 4.54A1.5 1.5 0 0 0 2 14.915V16.5A1.5 1.5 0 0 0 3.5 18h2A1.5 1.5 0 0 0 7 16.5V16h1a1 1 0 0 0 1-1v-1h1a1 1 0 0 0 1-1v-.18c.493.134 1.007.18 1.5.18c3.076 0 5.5-2.424 5.5-5.5S15.576 2 12.5 2ZM8 7.5C8 4.976 9.976 3 12.5 3S17 4.976 17 7.5S15.024 12 12.5 12c-.66 0-1.273-.095-1.776-.347A.5.5 0 0 0 10 12.1v.9H9a1 1 0 0 0-1 1v1H7a1 1 0 0 0-1 1v.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.586a.5.5 0 0 1 .146-.353l4.541-4.541c.432-.432.522-1.044.412-1.556A4.619 4.619 0 0 1 8 7.5Z"
            />
          </svg>
          Ubah Kata Sandi
        </b-button>
        <b-modal id="modalpass" title="UbahPassword" hide-footer>
          <template #modal-header="{ hide }">
            <h5>Ubah Kata Sandi</h5>
            <!-- Emulate built in modal header close button action -->
            <span class="btn-modal-close" size="sm" @click="hide()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style="transform: ; msfilter: "
              >
                <path
                  d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
                ></path>
              </svg>
            </span>
          </template>
          <form action="" @submit.prevent="ubahpassword">
            <div class="modal-body">
              <div class="form-group col-12 mb-3">
                <label class="form-label">Kata Sandi baru</label>
                <div
                  class="input-group mb-3"
                  :class="{ 'border-danger': v$.datapassword.password.$error }"
                >
                  <input
                    type="password"
                    v-model="datapassword.password"
                    id="password"
                    class="form-control field-password input-grouppass"
                    placeholder="Kata Sandi Baru"
                    aria-label="Kata Sandi"
                  />
                  <div v-if="!v$.datapassword.password.$error" class="input-group-append">
                    <svg
                      @click="togglePass"
                      xmlns="http://www.w3.org/2000/svg"
                      class="eyeshow d-show"
                      width="1em"
                      height="1em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 36 36"
                    >
                      <path
                        fill="currentColor"
                        d="M33.62 17.53c-3.37-6.23-9.28-10-15.82-10S5.34 11.3 2 17.53l-.28.47l.26.48c3.37 6.23 9.28 10 15.82 10s12.46-3.72 15.82-10l.26-.48Zm-15.82 8.9C12.17 26.43 7 23.29 4 18c3-5.29 8.17-8.43 13.8-8.43S28.54 12.72 31.59 18c-3.05 5.29-8.17 8.43-13.79 8.43Z"
                        class="clr-i-outline clr-i-outline-path-1"
                      />
                      <path
                        fill="currentColor"
                        d="M18.09 11.17A6.86 6.86 0 1 0 25 18a6.86 6.86 0 0 0-6.91-6.83Zm0 11.72A4.86 4.86 0 1 1 23 18a4.87 4.87 0 0 1-4.91 4.89Z"
                        class="clr-i-outline clr-i-outline-path-2"
                      />
                      <path fill="none" d="M0 0h36v36H0z" />
                    </svg>
                    <svg
                      @click="togglePass"
                      xmlns="http://www.w3.org/2000/svg"
                      class="eyeslas d-hide"
                      width="1em"
                      height="1em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 36 36"
                    >
                      <path
                        fill="currentColor"
                        d="M25.19 20.4a6.78 6.78 0 0 0 .43-2.4a6.86 6.86 0 0 0-6.86-6.86a6.79 6.79 0 0 0-2.37.43L18 13.23a4.78 4.78 0 0 1 .74-.06A4.87 4.87 0 0 1 23.62 18a4.79 4.79 0 0 1-.06.74Z"
                        class="clr-i-outline clr-i-outline-path-1"
                      />
                      <path
                        fill="currentColor"
                        d="M34.29 17.53c-3.37-6.23-9.28-10-15.82-10a16.82 16.82 0 0 0-5.24.85L14.84 10a14.78 14.78 0 0 1 3.63-.47c5.63 0 10.75 3.14 13.8 8.43a17.75 17.75 0 0 1-4.37 5.1l1.42 1.42a19.93 19.93 0 0 0 5-6l.26-.48Z"
                        class="clr-i-outline clr-i-outline-path-2"
                      />
                      <path
                        fill="currentColor"
                        d="m4.87 5.78l4.46 4.46a19.52 19.52 0 0 0-6.69 7.29l-.26.47l.26.48c3.37 6.23 9.28 10 15.82 10a16.93 16.93 0 0 0 7.37-1.69l5 5l1.75-1.5l-26-26Zm9.75 9.75l6.65 6.65a4.81 4.81 0 0 1-2.5.72A4.87 4.87 0 0 1 13.9 18a4.81 4.81 0 0 1 .72-2.47Zm-1.45-1.45a6.85 6.85 0 0 0 9.55 9.55l1.6 1.6a14.91 14.91 0 0 1-5.86 1.2c-5.63 0-10.75-3.14-13.8-8.43a17.29 17.29 0 0 1 6.12-6.3Z"
                        class="clr-i-outline clr-i-outline-path-3"
                      />
                      <path fill="none" d="M0 0h36v36H0z" />
                    </svg>
                  </div>
                </div>

                <span
                  v-if="v$.datapassword.password.$error"
                  class="text-danger font-small pesan-error"
                  >{{ v$.datapassword.password.$errors[0].$message }}</span
                >
              </div>
              <div class="form-group col-12 mb-3">
                <label class="form-label">Konfirmasi Kata Sandi Baru</label>
                <div
                  class="input-group mb-3"
                  :class="{ 'border-danger': v$.datapassword.confirmpassword.$error }"
                >
                  <input
                    type="password"
                    v-model="datapassword.confirmpassword"
                    id="confirmpassword"
                    class="form-control field-confirmpassword input-grouppass"
                    placeholder="Konfirmasi Kata Sandi Baru"
                    aria-label="Kata Sandi"
                  />
                  <div
                    v-if="!v$.datapassword.confirmpassword.$error"
                    class="input-group-append"
                  >
                    <svg
                      @click="toggleconfirmPass"
                      xmlns="http://www.w3.org/2000/svg"
                      class="eyeshowkonfir d-show"
                      width="1em"
                      height="1em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 36 36"
                    >
                      <path
                        fill="currentColor"
                        d="M33.62 17.53c-3.37-6.23-9.28-10-15.82-10S5.34 11.3 2 17.53l-.28.47l.26.48c3.37 6.23 9.28 10 15.82 10s12.46-3.72 15.82-10l.26-.48Zm-15.82 8.9C12.17 26.43 7 23.29 4 18c3-5.29 8.17-8.43 13.8-8.43S28.54 12.72 31.59 18c-3.05 5.29-8.17 8.43-13.79 8.43Z"
                        class="clr-i-outline clr-i-outline-path-1"
                      />
                      <path
                        fill="currentColor"
                        d="M18.09 11.17A6.86 6.86 0 1 0 25 18a6.86 6.86 0 0 0-6.91-6.83Zm0 11.72A4.86 4.86 0 1 1 23 18a4.87 4.87 0 0 1-4.91 4.89Z"
                        class="clr-i-outline clr-i-outline-path-2"
                      />
                      <path fill="none" d="M0 0h36v36H0z" />
                    </svg>
                    <svg
                      @click="toggleconfirmPass"
                      xmlns="http://www.w3.org/2000/svg"
                      class="eyeslaskonfir d-hide"
                      width="1em"
                      height="1em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 36 36"
                    >
                      <path
                        fill="currentColor"
                        d="M25.19 20.4a6.78 6.78 0 0 0 .43-2.4a6.86 6.86 0 0 0-6.86-6.86a6.79 6.79 0 0 0-2.37.43L18 13.23a4.78 4.78 0 0 1 .74-.06A4.87 4.87 0 0 1 23.62 18a4.79 4.79 0 0 1-.06.74Z"
                        class="clr-i-outline clr-i-outline-path-1"
                      />
                      <path
                        fill="currentColor"
                        d="M34.29 17.53c-3.37-6.23-9.28-10-15.82-10a16.82 16.82 0 0 0-5.24.85L14.84 10a14.78 14.78 0 0 1 3.63-.47c5.63 0 10.75 3.14 13.8 8.43a17.75 17.75 0 0 1-4.37 5.1l1.42 1.42a19.93 19.93 0 0 0 5-6l.26-.48Z"
                        class="clr-i-outline clr-i-outline-path-2"
                      />
                      <path
                        fill="currentColor"
                        d="m4.87 5.78l4.46 4.46a19.52 19.52 0 0 0-6.69 7.29l-.26.47l.26.48c3.37 6.23 9.28 10 15.82 10a16.93 16.93 0 0 0 7.37-1.69l5 5l1.75-1.5l-26-26Zm9.75 9.75l6.65 6.65a4.81 4.81 0 0 1-2.5.72A4.87 4.87 0 0 1 13.9 18a4.81 4.81 0 0 1 .72-2.47Zm-1.45-1.45a6.85 6.85 0 0 0 9.55 9.55l1.6 1.6a14.91 14.91 0 0 1-5.86 1.2c-5.63 0-10.75-3.14-13.8-8.43a17.29 17.29 0 0 1 6.12-6.3Z"
                        class="clr-i-outline clr-i-outline-path-3"
                      />
                      <path fill="none" d="M0 0h36v36H0z" />
                    </svg>
                    <!-- <i  @click="toggleconfirmPass" class='btn-eye eye-icon-konfir input-group-text bx bx-hide'></i> -->
                  </div>
                </div>

                <span
                  v-if="v$.datapassword.confirmpassword.$error"
                  class="text-danger font-small pesan-error"
                  >{{ v$.datapassword.confirmpassword.$errors[0].$message }}</span
                >
              </div>
            </div>
            <div class="modal-footer">
              <span @click="closemodalpass()" type="button" class="btn btn-secondary">
                Batal</span
              >
              <button type="submit" class="btn btn-primary">Simpan</button>
            </div>
          </form>
        </b-modal>
      </div>
      <form @submit.prevent="editprofile()" ref="editprofile">
        <div class="container-fluid row card-data-user">
          <div class="col-lg-4 px-4 align-middle">
            <!-- <h3 class="p-3">Beranda</h3> -->
            <figure class="img-profile" id="image_foto">
              <img :src="imageuser" alt="" />
            </figure>
            <input
              type="file"
              @input="handleUpload($event)"
              style="display: none"
              accept=".jpg,.jpeg,.png,.webp"
              id="inputGroupFile02"
            />
            <label
              class="w-100 btn btn-outline-dark mt-3"
              @click="$refs.image.click(), resetimage()"
              for="inputGroupFile02"
              >Pilih Foto</label
            >
            <small class="mt-5 bottom-img"
              >Besar file: maksimum 2.000.000 bytes (2Megabytes). Ekstensi file yang
              diperbolehkan: .JPG .JPEG .PNG</small
            >
          </div>
          <div class="col-lg-8 card-user">
            <div class="mb-2">
              <label for="name" class="form-label">Name</label>
              <input
                @change="databerubah"
                type="text"
                v-model="userdata.name"
                class="form-control"
                :class="{ 'border-danger': v$.userdata.name.$error }"
                id="name"
                name="name"
                aria-describedby="name"
                placeholder="Name"
              />
              <span
                v-if="v$.userdata.name.$error"
                class="text-danger font-small pesan-error"
                >{{ v$.userdata.name.$errors[0].$message }}</span
              >
            </div>
            <div class="mb-2">
              <label for="username" class="form-label">Username</label>
              <input
                @change="databerubah"
                type="text"
                v-model="userdata.username"
                class="form-control"
                :class="{ 'border-danger': v$.userdata.username.$error }"
                id="username"
                name="username"
                aria-describedby="username"
                placeholder="Username"
              />
              <span
                v-if="v$.userdata.username.$error"
                class="text-danger font-small pesan-error"
              >
                {{ v$.userdata.username.$errors[0].$message }}
              </span>
            </div>
            <div class="mb-2">
              <label for="birthdate" class="form-label">Tanggal Lahir</label>
              <date-pick
                @input="databerubah"
                v-model="userdata.birthdate"
                :displayFormat="'DD/MM/YYYY'"
                class="w-100"
                :class="{ 'border-danger': v$.userdata.birthdate.$error }"
                id="birthdate"
                name="birthdate"
                aria-describedby="birthdate"
                placeholder="birthdate"
              ></date-pick>
              <span
                v-if="v$.userdata.birthdate.$error"
                class="text-danger font-small pesan-error"
                >{{ v$.userdata.birthdate.$errors[0].$message }}</span
              >
            </div>
            <b-form-group label="Jenis Kelamin" class="mb-2">
              <b-form-group id="radio-slots" name="radio-options-slots" class="d-flex">
                <input
                  type="radio"
                  @change="databerubah"
                  v-model="userdata.gender"
                  :value="true"
                  :checked="userdata?.gender === true"
                /><span class="mx-2">Laki-Laki</span>
                <input
                  type="radio"
                  @change="databerubah"
                  class="ml-3"
                  v-model="userdata.gender"
                  :value="false"
                  :checked="userdata?.gender === false"
                />
                <span class="mx-2">Perempuan</span>
              </b-form-group>
            </b-form-group>
            <div class="mb-2">
              <label for="email" class="form-label">Email</label>
              <input
                @change="databerubah"
                type="text"
                v-model="userdata.email"
                class="form-control"
                :class="{ 'border-danger': v$.userdata.email.$error }"
                id="email"
                name="email"
                aria-describedby="email"
                placeholder="Email"
              />
              <span
                v-if="v$.userdata.email.$error"
                class="text-danger font-small pesan-error"
                >{{ v$.userdata.email.$errors[0].$message }}</span
              >
            </div>
            <div class="mb-2">
              <label for="phone" class="form-label">No Telepon</label>
              <vue-tel-input
                v-model="userdata.phone"
                :autoFormat="false"
                class="radius"
                @input="validateFirstLetter"
                :class="[v$.userdata.phone.$error || !phoneValid ? 'is-invalid' : '']"
                @validate="validatePhone"
                defaultCountry="id"
                @country-changed="countryChanged"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode == 43"
              ></vue-tel-input>
              <!-- <input
                        @change="databerubah"
                            type="text"
                            v-model="userdata.phone"
                            class="form-control"
                            :class="{ 'border-danger': v$.userdata.phone.$error }"
                            id="phone"
                            name="phone"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            aria-describedby="phone"
                            placeholder="No Telephone"
                        /> -->
              <span v-if="v$.userdata.phone.$error" class="text-danger font-small">{{
                v$.userdata.phone.$errors[0].$message
              }}</span>
            </div>

            <button class="btn btn-primary float-right mt-3 mb-4" type="submit">
              Simpan
            </button>
          </div>
        </div>
      </form>
      <b-modal ref="modalcropper" title="Cropped Foto">
        <template #modal-header="{ close }">
          <h5>Cropped Foto</h5>
          <!-- Emulate built in modal header close button action -->
          <span class="btn-modal-close" size="sm" @click="close(), modalclose()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="transform: ; msfilter: "
            >
              <path
                d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
              ></path>
            </svg>
          </span>
        </template>
        <cropper
          style="max-height: 400px !important"
          :class="{
            'image-exist': img,
            'border-danger': image_validation_message,
          }"
          class="cropper rounded image-cropper-style"
          :src="img"
          :autoZoom="false"
          :stencil-props="{
            aspectRatio: 1 / 1,
          }"
          ref="cropper"
        />
        <template #modal-footer="{ cancel }">
          <b-button size="md" variant="secondary" @click="cancel(), modalclose()">
            Batal
          </b-button>
          <b-button size="md" variant="primary" @click="getCropped()"> OK </b-button>
        </template>
      </b-modal>
      <b-modal id="modal-loading" centered hide-footer hide-header>
        <div class="text-center">
          <b-spinner variant="light"></b-spinner>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Img_Default from "../../assets/img/img-default.png";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import swal from "sweetalert";
import '../../assets/css/sweetalertstyle.css'
import useVuelidate from "@vuelidate/core";
import { required, email, helpers, minLength, sameAs } from "@vuelidate/validators";
import SkeletonSunting from "../../components/Skeleton/Dashboard/SkeletonSunting.vue";
import Loader from "@/components/Loader/Loader.vue";
import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";
// import { required,email,minLength,helpers,sameAs } from '@vuelidate/validators'
// import { required } from "vuelidate/lib/validators"
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      phoneFirstTimeLoad: 0,
      phoneValid: true,
      codecountry: "",
      loading: true,
      address: "-",
      imageuser: "",
      imagename: "",
      image_validation_message: "",
      img: "",
      url: "",
      userdata: {
        id: "",
        name: "",
        username: "",
        image: "",
        email: "",
        phone: "",
        birthdate: "",
      },
      datapassword: {
        password: "",
        confirmpassword: "",
      },
    };
  },
  validations() {
    return {
      datapassword: {
        password: {
          required: helpers.withMessage("Kata Sandi wajib diisi", required),
          minLength: helpers.withMessage(
            ({ $params }) => `Kata Sandi harus minimal diisi ${$params.min} Karakter`,
            minLength(8)
          ),
        },
        confirmpassword: {
          required: helpers.withMessage(
            "Konfirmasi Kata Sandi Kata Sandi wajib diisi",
            required
          ),
          minLength: helpers.withMessage(
            ({ $params }) =>
              `konfirmasi Kata Sandi harus minimal diisi ${$params.min} karakter`,
            minLength(8)
          ),
          sameAs: helpers.withMessage(
            `Konfirmasi Kata Sandi baru tidak sama dengan Kata Sandi baru
                     `,
            sameAs(this.datapassword.password)
          ),
        },
      },
      userdata: {
        username: {
          required: helpers.withMessage("Username tidak boleh kosong", required),
        },
        name: {
          required: helpers.withMessage("Nama tidak boleh kosong", required),
        },
        email: {
          required: helpers.withMessage("Email tidak boleh kosong", required),
          email: helpers.withMessage("Format email salah", email),
        },
        phone: {
          required: helpers.withMessage("No telepon tidak boleh kosong", required),
        },
        birthdate: {
          required: helpers.withMessage("Tanggal lahir tidak boleh kosong", required),
        },
      },
    };
  },
  // validations:{
  // datapassword:{
  //     password: {
  //         required: helpers.withMessage('Kata Sandi wajib diisi', required),
  //         minLength : helpers.withMessage(
  //                     ({
  //                         $params,
  //                     }) => `Kata Sandi harus minimal diisi ${$params.min} Karakter`,
  //                     minLength(8)
  //                     ),
  //     },
  //     confirmpassword: {
  //         required: helpers.withMessage('Konfirmasi Kata Sandi Kata Sandi wajib diisi', required),
  //         minLength : helpers.withMessage(
  //                     ({
  //                         $params,
  //                     }) => `konfirmasi Kata Sandi harus minimal diisi ${$params.min} karakter`,
  //                     minLength(8)
  //                     ),
  //         sameAs: helpers.withMessage(`Konfirmasi Kata Sandi baru tidak sama dengan Kata Sandi baru
  //          `,sameAs(this.datapassword.password))
  //     }
  // },
  //         userdata: {
  //             name: {
  //                 required
  //             },
  //             email: {
  //                 required
  //             },
  //             phone: {
  //                 required
  //             },
  //             birthdate: {
  //                 required
  //             },
  //         }
  // },
  components: {
    Cropper,
    DatePick,
    SkeletonSunting,
    Loader,
  },
  async mounted() {
    this.loading = true;
    try {
      if (this.user instanceof Promise == true) {
        await this.$store.dispatch("user");
        await this.$store.dispatch("address");
      }
      this.userdata = this.$store.state.user;
      this.userdata.phone = this.$store.state.user.phone;
      this.phoneFirstTimeLoad = 0;
      this.phoneValid = true;
      //   this.userdata = this.$store.state.user;
      if (this.userdata.image) {
        this.imageuser = this.$imgUrl + this.userdata.image;
      } else {
        this.imageuser = Img_Default;
      }
      setTimeout(() => {
        this.loading = false;
      }, 2000);
      this.phoneValid = true;
    } catch (error) {
      this.loading = false;
      return error;
    }
  },
  computed: {
    // loading:function(){
    //     return this.$store.state.user instanceof Promise
    // },
    user: function () {
      return this.$store.state.user;
    },
    dataname() {
      return this.userdata.name;
    },
  },
  watch: {
    user: {
      handler: function () {
        this.$store.dispatch("suntingdata", true);
      },
      deep: true,
    },
    "userdata.phone": function () {
      if (this.userdata.phone == "" || this.userdata.phone == null) {
        this.userdata.phone = this.codecountry;
      }
    },
    // dataname(){
    //     console.log(this.dataname);
    // }
  },
  methods: {
    countryChanged(country) {
      this.phoneFirstTimeLoad++;
      let countryCode = country.dialCode;
      // countrycode ini didapat dari hasil return yang diberikan oleh vue-tel-input dan berisi nomor yang telah diinput dan kode negara yang telah kita pilih 
      this.codecountry = `+${countryCode}`;
      if (this.phoneFirstTimeLoad > 1) {
        const myPromise = new Promise((resolve) => {
          resolve((this.userdata.phone = ""));
        });

        myPromise.then(() => {
          this.userdata.phone = `+${countryCode}`;
        });
      }
    },
    validatePhone(country) {
      this.phoneValid = country.valid;
    },
    validateFirstLetter() {
      if (this.userdata?.phone[0] != "+") {
        this.phoneValid = false;
      }
    },
    errorfalse() {
      this.error = false;
    },
    databerubah() {
      this.$emit("setuser", true);
    },
    modalclose() {
      this.imagename = "";
      this.img = "";
      this.url = "";
      document.getElementById("inputGroupFile02").value = "";
    },
    togglePass() {
      // ini hanya mengubah icon pada password jika dipilih untuk dilihat atau tidak
      const input = document.querySelector(".field-password");
      const eyeshow = document.querySelector(".eyeshow");
      const eyehide = document.querySelector(".eyeslas");
      if (input.type === "password") {
        eyeshow.classList.remove("d-show");
        eyehide.classList.remove("d-hide");
        eyehide.classList.add("d-show");
        eyeshow.classList.add("d-hide");
        input.type = "text";
      } else {
        eyehide.classList.remove("d-show");
        eyeshow.classList.remove("d-hide");
        eyeshow.classList.add("d-show");
        eyehide.classList.add("d-hide");
        input.type = "password";
      }
    },
    toggleconfirmPass() {
      // ini hanya mengubah icon pada konfirmasi password jika dipilih untuk dilihat atau tidak
      const input = document.querySelector(".field-confirmpassword");
      const eyeshow = document.querySelector(".eyeshowkonfir");
      const eyehide = document.querySelector(".eyeslaskonfir");
      if (input.type === "password") {
        eyeshow.classList.remove("d-show");
        eyehide.classList.remove("d-hide");
        eyehide.classList.add("d-show");
        eyeshow.classList.add("d-hide");
        input.type = "text";
      } else {
        eyehide.classList.remove("d-show");
        eyeshow.classList.remove("d-hide");
        eyeshow.classList.add("d-show");
        eyehide.classList.add("d-hide");
        input.type = "password";
      }
    },
    closemodalpass() {
      this.$bvModal.hide("modalpass");
    },
    URLFile(dataurl, filename) {
      // ini adalah pengecekan untuk nama dari file yang diinput, hal ini perlu disesuaikan agar ada saat proses mengirim ke api tidak terdapat kesalahan, terrutama jika nama dari file berisi karaktr , . + - ' / dll
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    handleUpload(event) {
      if (event.target.value == "") {
        this.preview_image = this.previous_image;
      }
      // ini untuk emngambil file yang telah diinput melalui input type file
      let file = event.target.files[0];
      
      if (!file) {
        this.image_validation_message = null;
        this.img = null;
      } else {
        if (
          file["type"] === "image/jpeg" ||
          file["type"] === "image/jpg" ||
          file["type"] === "image/png"
        ) {
          // setelah data image memilikki extensi yang benar, maka akan dilakukan pemanggilan modal yang berisi cropper image dan data image yang diterima akan dikirim ke bariable model dalam croper tersbebut
          this.$refs["modalcropper"].show();
          this.image_validation_message = null;
          this.img = URL.createObjectURL(event.target.files[0]);
        } else {
          document.getElementById("inputGroupFile02").value = "";
          swal("Ooops", "format gambar yang anda unggah salah", "error", {
            button: "OK",
          });
          this.resetimage();
          this.img = null;
        }
      }
    },
    getCropped() {
      // memasukkan hasil cropper kedalam variabel object yang bernama canvas 
      const { canvas } = this.$refs.cropper.getResult();
      // data image yang telah didapat namanya akan diganti dengan berisi nama + waktu yang sekarang supaya tidak memilikki kesamaan dengan data yang lain didalam database
      const dataimageurl = this.URLFile(canvas.toDataURL(), `${Date.now()}.jpg`);
      if (dataimageurl.size > 2000000) {
        swal("Ooops", "ukuran gambar lebih dari 2mb", "error", {
          button: "OK",
        });
        this.$refs["modalcropper"].hide();
        document.getElementById("inputGroupFile02").value = "";
      } else if (dataimageurl.size < 2000000) {
        // jika data kurang dari 20mb baru bisa dikirim ke database
        this.imagename = dataimageurl;
        this.url = URL.createObjectURL(dataimageurl);
        this.imageuser = this.url;
        this.$refs["modalcropper"].hide();
      }
    },

    editprofile() {
      this.v$.userdata.$validate();
      if (!this.v$.userdata.$error && this.phoneValid) {
        let data = new FormData();
        data.append("_method", "PUT");
        data.append("name", this.userdata.name);
        data.append("username", this.userdata.username);
        data.append("birthdate", this.userdata.birthdate);
        if (this.userdata.gender == true) {
          data.append("gender", 1);
        } else if (this.userdata.gender == false) {
          data.append("gender", 0);
        }
        if (this.imagename != "") {
          data.append("image", this.imagename);
        }
        data.append("email", this.userdata.email);
        data.append("phone", this.userdata.phone);
        this.loadingSubmit = true;
        this.$bvModal.show("modal-loading");
        axios
          .post(`/customer/data-customer/update/` + this.userdata.id, data)
          .then((response) => {
            this.$store.dispatch("suntingdata", false);
            this.$store.dispatch("user");
            this.loadingSubmit = false;
            if (response.data.status === "Success") {
              this.$bvModal.hide("modal-loading");
              swal("Berhasil!!!", "Data diri anda berhasil diperbaharui", "success", {
                button: "OK",
              });
            } else {
              this.$bvModal.hide("modal-loading");
              // validation.value = response.data.response
            }
          })
          .catch((e) => {
            this.$bvModal.hide("modal-loading");
            this.loadingSubmit = false;
            this.$store.dispatch("suntingdata", false);
            if (e.response.data.data) {
              swal("Ooops", e.response.data.data.email[0], "error", {
                button: "OK",
              });
              //   this.dataemailsalah = e.response.data.data.email[0]
            } else {
              swal("Ooops", e.response.data.message, "error", {
                button: "OK",
              });
            }
          });
      }
    },
    ubahpassword() {
      this.v$.datapassword.$validate();
      if (!this.v$.datapassword.$error) {
        let data = new FormData();
        data.append("_method", "PUT");
        data.append("name", this.userdata.name);
        data.append("username", this.userdata.username);
        data.append("birthdate", this.userdata.birthdate);
        if (this.userdata.gender == true) {
          data.append("gender", 1);
        } else if (this.userdata.gender == false) {
          data.append("gender", 0);
        }
        data.append("password", this.datapassword.password);
        data.append("password_confirmation", this.datapassword.confirmpassword);
        data.append("email", this.userdata.email);
        data.append("phone", this.userdata.phone);
        this.loadingSubmit = true;
        this.$bvModal.show("modal-loading");
        axios
          .post(`/customer/data-customer/update/` + this.userdata.id, data)
          .then((response) => {
            this.$bvModal.hide("modal-loading");
            this.$store.dispatch("user");
            this.loadingSubmit = false;
            if (response.data.status === "Success") {
              swal("Berhasil!!!", "Password anda berhasil diperbaharui", "success", {
                button: "OK",
              });
              this.$bvModal.hide("modalpass");
            } else {
              // validation.value = response.data.response
            }
          })
          .catch((e) => {
            this.$bvModal.hide("modal-loading");
            this.loadingSubmit = false;
            if (e.response.data.data) {
              swal("Ooops", e.response.data.data.email[0], "error", {
                button: "OK",
              });
              //   this.dataemailsalah = e.response.data.data.email[0]
            } else {
              swal("Ooops", e.response.data.message, "error", {
                button: "OK",
              });
            }
          });
      }
    },
  },
};
</script>
<style scoped>
::v-deep #modal-loading .modal-content {
  background-color: transparent;
  border: 0px !important;
}
::v-deep .vdpComponent input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  /* border: 0px !important; */
}
.input-grouppass {
  border-right: 0px !important;
}
.input-group-append {
  padding: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.d-show {
  display: block;
  cursor: pointer;
}
.d-hide {
  display: none;
}
.ml-3 {
  margin-left: 1rem;
}
.float-right {
  float: right;
}
.ubahpassword svg {
  width: 24px;
  height: 24px;
}
.card-dashboard {
  border-radius: 10px;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}
.header-sunting {
  display: block;
}
.header-sunting h4 {
  font-weight: 600;
}
.card-data-user {
  margin: 0 !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.nav-desk {
  display: none;
}
.navmobile {
  display: block;
}
.card-user {
  margin-top: 3rem;
}
.data-user p {
  margin-bottom: 5px !important;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
}
.data-user h5 {
  font-weight: 600;
  display: block;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 32px;
}
.labeldata {
  min-width: 150px;
}
.isidatauser {
  padding-left: 10px !important;
  font-weight: 400;
}
.img-profile {
  width: 100%;
  max-height: 240px;
  overflow: hidden;
  align-items: center;
  margin: 0 auto;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.img-profile img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.card-user h6 {
  font-weight: 600;
}
.table {
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
}
.header-tabel-profile {
  width: 120px;
}
.bottom-img {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.7rem;
  line-height: 1;
}
.btn-modal-close {
  cursor: pointer;
}
.btn-modal-close svg {
  fill: rgba(0, 0, 0, 0.5);
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .card-data-user {
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
  }
  .data-user h5 {
    display: flex;
  }
  .header-sunting {
    display: flex;
    justify-content: space-between;
  }
  .img-profile {
    max-width: 240px;
  }
}

@media (min-width: 1023px) {
  .card-user {
    margin-top: 0rem;
  }
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>
