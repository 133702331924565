<template>
 <div>
   <div v-if="endDate > now && user.name != undefined">
    <div class="container h-flashsale pt-3">
      <div class="header_flash_sale">
        <h4>{{ dataflash[0]?.name }} :</h4>
        <div class="waktu_flash">
          <h6>{{ hour }}</h6>
          <small>Jam</small>
        </div>
        <div class="waktu_flash">
          <h6>{{ min }}</h6>
          <small>Menit</small>
        </div>
        <div class="waktu_flash">
          <h6>{{ sec }}</h6>
          <small>Detik</small>
        </div>
      </div>
    </div>
    <div class="container flashdata w-100 mt-3" v-if="flashsaleproduct.length > 0">
      <div class="image-left">
        <figure v-if="flashsaleproduct.length > 0">
          <img src="../../assets/img/flashimage.webp" alt="" />
        </figure>
      </div>
      <div class="mt-3 list-swipper pb-3">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide
            v-for="(produkflash, index) in flashsaleproduct"
            :key="index"
          >
            <div class="card w-card">
              <router-link
                :to="'/produk-detail?id_produk=' + produkflash?.id"
                class="link-product"
            :class="checkstock(produkflash?.price) <= 0 ? 'link-product-disabled' : ''"
              ></router-link>
              <div class="disabled-product"
           v-if="checkstock(produkflash?.price) <= 0"
           ></div>
              <div class="diskon-atas">
                <p>{{ Number(produkflash?.discount) }}%</p>
              </div>
              <figure style="height: 150px; overflow: hidden; position: relative">
                <img
                  style="width: 100%"
                  class="card-img-top"
                  :src="$imgProduct + produkflash?.image"
                  alt="Card image cap"
                />
              </figure>
              <div class="card-body">
                <h5 class="card-title">{{ produkflash?.name }}</h5>
                <!-- <div class="star">
                        <svg v-for="(n,index) in parseInt(produkflash?.star_review)" :key="index" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"></path></svg>
                    </div> -->
                <div class="diskon">
                  <p class="harga mt-2 mb-0">
                    Rp
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(produkflash?.flashsale_price).toFixed()
                      ) ?? 0
                    }}
                  </p>
                  <small
                    ><s
                      >Rp
                      {{
                        new Intl.NumberFormat("id-ID").format(
                          Number(produkflash?.price).toFixed()
                        ) ?? 0
                      }}</s
                    ></small
                  >
                </div>
                <div class="diskon-mobile">
                  <p class="harga mt-2 mb-0">
                    Rp
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(produkflash?.flashsale_price).toFixed()
                      ) ?? 0
                    }}
                  </p>
                  <small
                    ><s
                      >Rp
                      {{
                        new Intl.NumberFormat("id-ID").format(
                          Number(produkflash?.price).toFixed()
                        ) ?? 0
                      }}</s
                    ></small
                  >
                </div>
                <div class="star">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    style="transform: ; msfilter: "
                  >
                    <path
                      d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                    ></path>
                  </svg>
                  <small class="pt-3"
                    >{{ datastar(produkflash?.star_review) }} | Terjual
                    {{ datasold(produkflash?.total_sold) }}</small
                  >
                </div>
                <div class="modal-btn">
                  <button
                    @click="datarekomendasi(produkflash?.id)"
                    class="btn btn-primary mt-2 w-100 d-flex justify-content-center"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <rect
                        x="0"
                        y="0"
                        width="26"
                        height="26"
                        fill="none"
                        stroke="none"
                      />
                      <g fill="none" stroke="#fff" stroke-width="1.5">
                        <path
                          d="M3.864 16.455c-.858-3.432-1.287-5.147-.386-6.301C4.378 9 6.148 9 9.685 9h4.63c3.538 0 5.306 0 6.207 1.154c.901 1.153.472 2.87-.386 6.301c-.546 2.183-.818 3.274-1.632 3.91c-.814.635-1.939.635-4.189.635h-4.63c-2.25 0-3.375 0-4.189-.635c-.814-.636-1.087-1.727-1.632-3.91Z"
                        />
                        <path
                          d="m19.5 9.5l-.71-2.605c-.274-1.005-.411-1.507-.692-1.886A2.5 2.5 0 0 0 17 4.172C16.56 4 16.04 4 15 4M4.5 9.5l.71-2.605c.274-1.005.411-1.507.692-1.886A2.5 2.5 0 0 1 7 4.172C7.44 4 7.96 4 9 4"
                        />
                        <path d="M9 4a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Z" />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M8 13v4m8-4v4m-4-4v4"
                        />
                      </g>
                    </svg>
                    <small class="pl-2 d-flex pt-md-1"
                      ><span class="d-none d-md-block mx-1">Tambah </span>
                      Keranjang</small
                    >
                  </button>
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <b-modal id="modal-produk3" size="lg" title="Produk" hide-footer>
      <Modalproduk :id="productId" />
    </b-modal>
  </div>
 </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import Modalproduk from "../../components/ModalProduct.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  data() {
    return {
      dataflash: [],
      productflash: [],
      now: null,
      productId: null,
      endDate: null,
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 10,
        loop: false,
        breakpoints: {
          320: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2.5,
          },
          1024: {
            slidesPerView: 4,
          },
          1443: {
            slidesPerView: 5,
          },
        },
      },
    };
  },
  components: { Swiper, SwiperSlide, Modalproduk },
  async mounted() {
    await axios.get(`/flashsale?status=true&with_product=1`).then((response) => {
      this.dataflash = response.data.data;
      this.productflash = this.dataflash[0];
      // console.log(this.productflash);
      var time_now = moment().unix();
      this.now = time_now * 1000;
      // console.log(this.now);
      var hms = this.productflash?.time_end;
      var time_end = moment(this.productflash?.date + "T" + hms).unix();

      this.endDate = time_end * 1000;
      // console.log(this.endDate);
    });
  },
  computed: {
    hour() {
      let h = Math.trunc((this.endDate - this.now) / 1000 / 3600);
      return h > 9 ? h : "0" + h;
    },
    min() {
      let m = Math.trunc((this.endDate - this.now) / 1000 / 60) % 60;
      return m > 9 ? m : "0" + m;
    },
    sec() {
      let s = Math.trunc((this.endDate - this.now) / 1000) % 60;
      return s > 9 ? s : "0" + s;
    },
    user: function () {
      return this.$store.state.user;
    },
    flashsaleproduct(){
      console.log(this.productflash);
      return [...this.productflash?.product_list].sort((a, b) => {
        const sumStockA = a.ammount_max - a.total_sold;
        const sumStockB = b.ammount_max - b.total_sold

        if (sumStockA <= 0 && sumStockB > 0) {
          return 1; // Move product A to the end
        } else if (sumStockA > 0 && sumStockB <= 0) {
          return -1; // Move product B to the end
        }

        return 0; // Maintain the order for other cases
      });
    }
  },
  methods: {
    checkstock(data){
      return data.ammount_max - data.total_sold
    },
    datarekomendasi(iddata) {
      this.productId = iddata;
      this.$bvModal.show("modal-produk3");
    },
    dataimg(value) {
      var dataimg = value.filter((item) => {
        if (item.default_image) {
          return item;
        }
      });
      if (dataimg[0]?.webp != null) {
        return dataimg[0]?.webp;
      } else {
        return dataimg[0]?.image;
      }
    },
    datastar(value) {
      var data = parseFloat(value);
      return data.toFixed(1);
    },
    datasold(data) {
      var sold = "";
      var check = "";
      if (data > 1000000000) {
        sold = data.toString();
        check = sold.slice(0, -9) + "M";
      } else if (data > 1000000) {
        sold = data.toString();
        check = sold.slice(0, -6) + "jt";
      } else if (data > 1000) {
        sold = data.toString();
        check = sold.slice(0, -3) + "rb";
      } else {
        check = data.toString();
      }
      return check;
    },
    dataset(value) {
      let discountflashsale = 0;
      let priceflashsale = 0;
      let pricenormal = 0;
      // console.log(value);

      value.forEach((product) => {
        if (product.flashsale && product.flashsale.length > 0) {
          let discount = 0
          let discountedPrice = 0
          product.flashsale.forEach((flashsale)=>{
            
            if(flashsale.flashsale != null){
          discount = parseFloat(flashsale.discount);
          discountedPrice = parseFloat(flashsale.flashsale_price);
            }
          })
          const normalPrice = parseFloat(product.price);

          if (discount > discountflashsale) {
            discountflashsale = discount;
            priceflashsale = discountedPrice;
            pricenormal = normalPrice;
          }
        }
      });
      var Datareturn = {
        priceflashsale,
        discountflashsale,
        pricenormal
      };
      if (Datareturn.priceflashsale > 0 && Datareturn.discountflashsale > 0) {
        return Datareturn;
      }
    },
    dataprice(price) {
      var pricedata = parseInt(price[0].price);
      price.forEach((element) => {
        if (parseInt(element.price) < pricedata) {
          pricedata = parseInt(element.price);
        }
      });
      return pricedata;
    },
  },
  watch: {
    endDate: {
      immediate: true,
      handler(newVal) {
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
          var time_now = moment().unix();
          this.now = time_now * 1000;
          if (this.negative) return;
          if (this.now > newVal) {
            this.now = newVal;
            this.$emit("endTime");
            clearInterval(this.timer);
          }
        }, 1000);
      },
    },
  },
};
</script>
<style scoped>
.modal-btn button svg {
  width: 15px;
  height: 15px;
}
.modal-btn button small {
  font-size: 0.6rem;
}
.image-left {
  position: absolute;
}
.flashdata {
  height: 280px;

}
.link-product-disabled{
  z-index: 35 !important;
}
.disabled-product{
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 33;
  width: 100%;
  height: 100%;
  left: 0 !important;
}
.image-left figure {
  max-width: 120px;
  height: 280px;
  overflow: hidden;
  border-radius: 10px;
}
.image-left figure img {
  height: 100%;
  border-radius: 15px;
}
.list-swipper {
  position: relative;
  top: 0px;
  margin-left: 6rem;
}
::v-deep .modal-header .close {
  background-color: transparent !important;
  border: 0px !important;
}
.modal-btn button {
  position: relative;
  z-index: 20;
}
.link-product {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0 !important;
}
::v-deep .w-card {
  max-width: 20rem;
  text-decoration: none !important;
}
::v-deep .w-card p {
  font-size: 9pt;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none !important;
}
::v-deep .card-title {
  color: #000 !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal !important;
}
.diskon-mobile {
  display: none;
}
@media (max-width: 325px) {
  ::v-deep .swiper-slide {
    width: 110px !important;
  }
  .card .star small {
    font-size: 0.55rem !important;
  }
}
@media (max-width: 500px) {
  ::v-deep .slick-initialized .slick-slide {
    width: 125px !important;
  }
  .card figure {
    width: 100%;
    height: 100px !important;
    overflow: hidden;
    margin: 0px !important;
  }
  .w-card .card-body {
    padding: 0.5rem !important;
  }
  .w-card .card-body h5 {
    min-height: 25px !important;
    font-size: 10px !important;
  }
  .w-card .card-body .harga,
  .w-card p {
    font-size: 0.7rem !important;
  }
  .w-card .card-body .harga {
    margin-bottom: 0px !important;
    margin-top: 1.2rem !important;
    line-height: 0.3;
  }
  .diskon {
    display: none !important;
  }
  .diskon-mobile {
    display: block;
  }
  .diskon-atas {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 10;
  }
  .diskon-atas p {
    color: #fff;
    padding: 3px !important;
    border-radius: 5px;
    background-color: red;
    font-size: 0.4rem !important;
  }
  .diskon-mobile p {
    color: red !important;
  }
  .diskon-mobile s {
    color: gray;
    text-decoration: line-through;
    font-size: 0.8rem;
  }
  .card .star small {
    font-size: 0.7rem;
  }
  .card .star svg {
    fill: gold;
    font-size: 10px;
    width: 15px !important;
    height: 15px !important;
    vertical-align: sub !important;
  }
}
.flashdata {
  overflow: hidden;
}
.header_flash_sale {
  display: flex;
}
.header_flash_sale h4 {
  margin-top: 15px;
  margin-right: 15px;
  font-weight: 600;
}
.waktu_flash h6 {
  padding: 10px;
  background-color: red;
  border-radius: 10px;
  font-weight: 600;
  color: #fff;
  margin: 5px 10px;
}
.waktu_flash small {
  font-weight: 400;
  font-size: 0.8rem;
  width: 100%;
  text-align: center;
  display: block;
}

.card figure {
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.card-title {
  text-align: left;
  text-decoration: none;
}
.card .star {
  text-align: left;
}
.card .star svg {
  fill: gold;
  font-size: 10px;
  width: 20px;
  height: 20px;
  vertical-align: sub !important;
}
.card .star small {
  padding-left: 5px;
  color: #aeaeae;
}
.harga {
  font-size: 1.1rem !important;
  text-align: left;
  font-weight: 550;
  margin-bottom: 0px !important;
  text-decoration: none;
}
.diskon-atas {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10;
}
.diskon-atas p {
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  background-color: red;
}

.diskon p {
  color: red !important;
}

.diskon s {
  color: gray;
  text-decoration: line-through;
  font-size: 0.8rem;
}
::v-deep .navbar {
  box-shadow: 0px 0px 0px !important;
}
::v-deep .d-flex {
  display: flex !important;
}
::v-deep .slick-track .slick-slide {
  padding-left: 5px;
  padding-right: 5px;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  ::v-deep .modal-dialog {
    max-width: 95% !important;
  }
  .modal-btn button svg {
    width: 26px;
    height: 26px;
  }
  .modal-btn button small {
    font-size: 0.875em;
  }
  .flashdata {
    height: 475px;

}
  .image-left figure {
    max-width: 250px;
    height: 475px;
    overflow: hidden;
    border-radius: 15px;
  }
  .list-swipper {
    position: relative;
    top: 20px;
    margin-left: 10rem;
  }
  .card-title {
    font-size: 12pt;
    font-weight: 600;
    height: 45px;
  }
  .card-text {
    color: rgba(0, 0, 0, 0.6);
    font-size: 9pt;
    font-weight: 400;
    height: 120px;
  }
  .text-card {
    height: 120px;
  }
  .btn-card {
    display: block !important;
    margin: 0 auto !important;
    text-align: center;
    text-decoration: none !important;
    color: rgba(0, 0, 0, 0.6) !important;
  }
  ::v-deep .w-card p {
    font-size: 7pt;
  }
}

@media (min-width: 1023px) {
  .card-title {
    height: 40px;
  }
  .text-card {
    height: 100px;
  }
}

@media (min-width: 1439px) {
  ::v-deep .w-card {
    width: 100%;
  }
  ::v-deep .slick-track .slick-slide {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 2000px) {
}
</style>
