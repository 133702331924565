<template>
    <div>
      <Loader v-if="loading"/>
      <SkeletonAddress v-if="loading"/>
      <div class="card-dashboard pb-3 p-md-3" v-if="!loading">
        <div class="px-3 pt-3 header-alamat">
          <div class="d-flex justify-content-between">
            <h4>Alamat</h4>
            <router-link to="/profil/tambah-alamat" class="color-primary d-mobile link-none">Tambah Alamat</router-link>
          </div>
          <div class="d-flex justify-content-between mt-3 mt-md-1">
            <router-link to="/profil/tambah-alamat" class="btn btn-primary d-web">Tambah Alamat</router-link>
            <div class="input-group border-solid searchinput mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text bg-transparant" id="basic-addon1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path></svg>
              </span>
            </div>
            <input v-model="search" type="search" class="form-control bg-transparant" placeholder="Cari alamat" aria-label="alamat" aria-describedby="basic-addon1">
          </div>
          </div>
        </div>
        <div class="container-fluid">
          <div v-if="filteredData?.length > 0">
            <div class="card card-address p-3 mt-3" v-for="(dataaddres,index) in addresssearch" :key="index">
              <h4>{{dataaddres?.receipt_name}}</h4>
              <small class="mt-1">{{dataaddres?.receipt_phone_number}}</small>
              <p class="mt-1"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M12 14c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"></path><path d="M11.42 21.814a.998.998 0 0 0 1.16 0C12.884 21.599 20.029 16.44 20 10c0-4.411-3.589-8-8-8S4 5.589 4 9.995c-.029 6.445 7.116 11.604 7.42 11.819zM12 4c3.309 0 6 2.691 6 6.005.021 4.438-4.388 8.423-6 9.73-1.611-1.308-6.021-5.294-6-9.735 0-3.309 2.691-6 6-6z"></path></svg>{{dataaddres?.address}}</p>
              <p>{{dataaddres?.sub_district?.subdistrict_name}}, {{dataaddres?.district?.city_name}}, {{dataaddres?.province?.province_name}}, {{dataaddres?.district?.postal_code}}</p>

              <small class="mt-3 bottom-card"><router-link :to="'/profil/edit-alamat?id_alamat='+dataaddres.id" class="color-primary">Ubah alamat</router-link> | <span @click="deteledata(dataaddres?.id)">Hapus</span></small>
            </div>
            <div class="display-flex w-100 mt-5 mb-3 mb-lg-5 button-pagination">
                    <button
                      class="btn btn-primary-outline border-radius p-1 px-2 rounded-0 btn-arrow"
                      @click="btnpagefilter(-1)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        style=" transform: ; msfilter: "
                      >
                        <path
                          d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"
                        ></path>
                      </svg>
                    </button>
                    <button
                      v-if="curentpage + 1 == initPaginationfilter && curentpage - 1 > 0"
                      class="btn btn-primary-outline btn-current p-1 px-2 rounded-0"
                      @click="btnpagefilter(-2)"
                    >
                      {{ curentpage - 1 }}
                    </button>
                    <button
                      v-if="curentpage > 0"
                      class="btn btn-primary-outline btn-current p-1 px-2 rounded-0"
                      @click="btnpagefilter(-1)"
                    >
                      {{ curentpage }}
                    </button>
                    <button class="btn btn-primary border-radius p-1 px-3 btn-current rounded-0">
                      {{ curentpage + 1 }}
                    </button>
                    <button
                      v-if="
                        initPaginationfilter > 1 && curentpage + 1 < initPaginationfilter
                      "
                      class="btn btn-primary-outline btn-current p-1 px-2 rounded-0"
                      @click="btnpagefilter(+1)"
                    >
                      {{ curentpage + 2 }}
                    </button>
                    <button
                      class="btn btn-primary-outline border-radius p-1 px-2 rounded-0 btn-arrow"
                      @click="btnpagefilter(+1)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        style=" transform: ; msfilter: "
                      >
                        <path
                          d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"
                        ></path>
                      </svg>
                    </button>
                  </div>
          </div>
          <div class="py-4" v-else-if="filteredData?.length <= 0 && search != ''">
            <p class="px-4 w-100 text-center">Maaf data yang anda cari tidak ada</p>
          </div>
          <div class="py-4" v-else>
            <p class="px-4 w-100 text-center">Maaf saat ini belum terdapat data alamat</p>
          </div>
        </div>
          <!-- <b-container fluid>
              <div class="header-table">
                  <div class="perpage pt-3 d-flex">
                      <p class="px-1">Show</p>
                      <b-form-select
                      class="mx-1 select-tabel"
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                  ></b-form-select>
                  <p class="px-1">Entries</p>
                  </div>
                  <div class="search_tabel">
                      <b-input-group class="mt-3">
                          <template #prepend>
                          <b-input-group-text><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path></svg></b-input-group-text>
                          </template>
                          <b-form-input id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"></b-form-input>
                      </b-input-group>
                  </div>
              </div>

              <div class="p-md-3">
                  <b-table
                  :items="address"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  stacked="md"
                  show-empty
                  small
                  @filtered="onFiltered"
                  >
                  <template #cell(index)="data">
                      {{ (data.index + 1)+(perPage*(currentPage-1)) }}
                  </template>
                  <template #cell(receipt_name)="data">
                      <div class="w-name">
                        {{ (data.item.receipt_name) }}
                      </div>
                  </template>
                  <template #cell(address)="data">
                      {{ (data.item.address) }}
                  </template>
                  <template #cell(Aksi)="data">
                      <div class="w-aksi">
                        <b-button @click="detailaddress(data.item)" v-b-modal.modal-detail class="btn btn-primary mx-2">Detail</b-button>
                        <router-link :to="'/profil/edit-alamat?id_alamat='+data.item.id" class="btn btn-warning mx-2">Edit</router-link>
                        <button @click="deteledata(data.item.id)" class="btn btn-danger mx-2">Delete</button>
                      </div>
                  </template>
                  </b-table>
              </div>
              <b-col sm="7" md="6" class="my-1 pb-4">
                  <b-pagination
                  style="width:200px;"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                  ></b-pagination>
              </b-col>

          </b-container> -->
          <b-modal id="modal-detail" hide-footer>
            <template #modal-title>
              Detail Alamat
            </template>
            <div class="d-block container-fluid detail-modal">
              <div class="row">
                <div class="col-12 col-md-6">
                  <h5>Nama</h5>
                  <p>{{datadetail?.receipt_name}}</p>
                </div>
                <div class="col-12 col-md-6">
                  <h5>Telepon</h5>
                  <p>{{datadetail?.receipt_phone_number}}</p>
                </div>
                <div class="col-12 col-md-6">
                  <h5>Provinsi</h5>
                  <p>{{datadetail?.province?.province_name}}</p>
                </div>
                <div class="col-12 col-md-6">
                  <h5>Kabupaten</h5>
                  <p>{{datadetail?.district?.city_name}}</p>
                </div>
                <div class="col-12 col-md-6">
                  <h5>Kecamatan</h5>
                  <p>{{datadetail?.sub_district?.subdistrict_name}}</p>
                </div>
                <div class="col-12">
                  <h5>Alamat</h5>
                  <p>{{datadetail?.address}}</p>
                </div>
              </div>
            </div>
            <b-button class="mt-3 w-100" block @click="hidemodal()">Close Me</b-button>
          </b-modal>
          <b-modal id="modal-loading" centered hide-footer hide-header>
                <div class="text-center">
                <b-spinner variant="light"></b-spinner>
                </div>
            </b-modal>
      </div>
    </div>
</template>
<script>
import swal from 'sweetalert'
import axios from 'axios'
import SkeletonAddress from '../../components/Skeleton/Dashboard/SkeletonAddress.vue'
import Loader from '@/components/Loader/Loader.vue'
  export default {
    data() {
      return {
        search:'',
        jmlh_value: 4,
        curentpage: 0,
        fields: [
            {key:'index',label:'No'},
          { key: 'receipt_name', label: 'Nama'},
          { key: 'address', label: 'Alamat' },
          { key: 'Aksi', label: 'Aksi' },
          
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        loading: true,
        sortDirection: 'asc',
        filter: null,
        datadetail:{},
        filterOn: [],
      }
    },
    components:{
      SkeletonAddress,
      Loader
    },
    async mounted(){
        try {
          this.loading=true
            if(this.user instanceof Promise == true){
                 await this.$store.dispatch('user')
                  this.$store.dispatch('address')
                 
            }
            if(this.address == [] || this.$store.state.address[0] === undefined){
                 await this.$store.dispatch('address')
                 
            }
          // Set the initial number of items
          this.totalRows = this.address.length
          this.loading=false
        } catch (error) {
          this.loading=false
          return error
        }
        
        
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
      address: function() {
          return this.$store.state.address;
      },
      addresssearch:function(){
        // ini untuk filter setelah user search data alamat
        return this.address.filter(x => x?.receipt_name.toLowerCase().includes(this.search.toLowerCase()) || x?.address.toLowerCase().includes(this.search.toLowerCase()))
      },

      initPagination() {
      return Math.ceil(this.addresssearch?.length / this.jmlh_value);
    },
    filteredData: function () {
      // ini untuk mendapatkan button yang digunakan untuk pindah halaman, dan button tersebut ditampilan yang sekarang, sebelum dan sesudahnya
      let pageStart = this.curentpage * this.jmlh_value;
      let pageEnd = this.jmlh_value * (this.curentpage * 1 + 1);

      return this.addresssearch.filter((item, index) => {
        return index >= pageStart && index < pageEnd;
      });
    },
    },
    methods: {
      detailaddress(item){
        this.datadetail =item
      },
      deteledata(id){
      swal("Apakah yakin untuk menghapus alamat ini?", '', "info", {
          buttons: ["Batal", "Hapus"]
      }).then((res) => {
          if(res) {
            this.$bvModal.show('modal-loading')
            axios.delete('customer/address/delete/'+id)
            .then(() => {
                this.loading=true
                this.$bvModal.hide('modal-loading')
                swal('Berhasil!!!', 'Data alamat berhasil dihapus', 'success', {
                          button: "OK"
                      })
                this.$store.dispatch('address').then(()=>{
                this.loading=false
                this.curentpage=0
                })
            })
            .catch(() => {
                this.$bvModal.hide('modal-loading')
            }); 
          } 
      });
    },
      hidemodal(){
        this.$bvModal.hide('modal-detail')
      },
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
    }
  }
</script>
<style scoped>
.border-solid{
  border: 1px solid #cdcdcd;
  border-radius: 5px;
}
.border-radius{
  border-radius: 5px !important;
}
.d-web{
  display: none;
}
.d-mobile{
  display: block;
}
.bg-transparant{
  border: 0px !important;
  background-color: transparent !important;
}
.searchinput{
  width: 260px;
  margin-bottom: 0px !important;
}
.card-address::before{
  content: "";
    position: absolute;
    display: block;
    top: 12px;
    left: 0px;
    width: 6px;
    height: 34px;
    background-color: var(--GN500,#2F3192);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.card-address h4{
  margin-bottom: 0px !important;
  font-weight: 700;
}
.card-address small{
  color: #acacac;
  line-height: 1;
  font-weight: 400;
}
.card-address p{
  margin-bottom: 0px !important;
  line-height: 1.5 !important;
}
.bottom-card a,.bottom-card{
  text-decoration: none !important;
  cursor: pointer;
}
.link-none{
  text-decoration: none !important;
}
::v-deep #modal-loading .modal-content{
  background-color: transparent;
  border: 0px !important;
}
::v-deep .modal-header button{
  background-color: #fff;
  border: 0px !important;
}
::v-deep .b-table{
    position: relative;
    width: 100%;
}
.detail-modal p{
  padding-left: 5px;
  color: rgba(0, 0, 0, 0.6);
}
.table-product tr{
    background-color: transparent !important;
}
::v-deep .b-table thead{
  padding-left: 5px;
  padding-right: 5px;
  height: 3.5rem;
}
::v-deep .b-table thead th{
  vertical-align: middle;
}
::v-deep .b-table thead::before{
    content: '';
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    width: 104%;
    left: -2%;
    z-index: -10;
    height: 3.5rem;
    position: absolute;

}
::v-deep .b-table tbody tr td{
  padding-top: 15px;
  overflow-x: auto;
}
::v-deep .b-table tbody tr td .w-aksi{
  min-width: 300px;
  overflow-x: auto;
}
::v-deep .b-table tbody tr td .w-name{
  min-width: 150px;
}
.card-dashboard{
    border-radius: 10px;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}
.header-alamat h4{
  font-weight: 600;
}
.select-tabel{
    height: 1.8rem;
}
::v-deep .search_tabel .input-group-prepend{
    background-color: transparent;
}
::v-deep .search_tabel .input-group-prepend .input-group-text{
    background-color: transparent;
    border-right: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
::v-deep .search_tabel input{
    border-left: 0px !important;
}
@media (max-width: 500px) {
  .card-address p{
    font-size: 0.8rem !important;
    }
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .header-table{
    display: flex;
    justify-content: space-between;
}
.d-web{
  display: block;
}
.d-mobile{
  display: none;
}
}

@media (min-width: 1023px) {
  }

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>