<template>
    <div>
        <Loader v-if="loading"/>
        <SkeletonBerita v-if="loading"/>
        <div class="detail_berita" v-if="!loading">
            <div class="header-berita w-100"></div>
            <div class="databerita pt-5">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="box container-fluid">
                                <div class="row">
                                    <div class="col-md-8 col-12 col-xl-9">
                                        <p class="time">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history" viewBox="0 0 16 16">
                                            <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
                                            <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
                                            <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                                            </svg>
                                            &nbsp;
                                            {{dateTime(databerita.updated_at)}}</p>
                                        <h1 class="mt-3 berita-header">{{databerita.title}}</h1>
                                        <figure class="image">
                                            <img :src="$imgProduct+'/storage/'+databerita.image" :alt="'mo and haku blog '+databerita.title" :title="'mo and haku blog '+databerita.title">
                                        </figure>
                                        <client-only>
                                            <p class="descberita" v-html="databerita.content"></p>
                                        </client-only>
                                    </div>
                                    <div class="col-md-4 col-xl-3 sidebarberita py-md-4">
                                        <h6>Berita Lainnya</h6>
                                        <div v-if="berita.length == 0">
                                            <p style="text-align:center">Maaf Belum Terdapat rekomendasi Berita</p>
                                        </div>
                                        <ul class="mt-3">
                                            <li class="py-md-3" v-for="(isiberita,index) in berita" :key="index">
                                                <router-link :to="'/berita-detail?slug_berita='+isiberita.slug" class="list-sidebar">
                                                    <figure>
                                                        <img :src="$imgUrl+isiberita.image">
                                                    </figure>
                                                    <div class="text">
                                                        <p>
                                                            {{dateTime(isiberita.updated_at)}}
                                                        </p>
                                                        <h6>{{isiberita.title}}</h6>
                                                    </div>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
  import axios from "axios";
  import SkeletonBerita from '../Skeleton/Berita/SkeletonBerita.vue'
  import Loader from '@/components/Loader/Loader.vue'
export default {
    data() {
    return {
        check:true,
        loading:true,
        databerita:[],
        berita:[],
        recomendberita:[],
    }
    },
    async mounted(){
        this.check =true
        this.loading =true
        let slug_berita = this.$route.query.slug_berita
        await axios.get(`/blog/show/`+slug_berita).then((response)=>{
            this.databerita =response.data.data.data
         })
        await axios.get(`/blog`).then((response)=>{
            this.loading =false     
            const berita= response.data.data.blogs
            this.recomendside(berita)  

        })
        this.check =false
    },
    components:{
        SkeletonBerita,
        Loader
    },
    watch: {
       async $route(to) {
            // perubahan data setelah memilih berita lainnya
            if(to.path == '/berita-detail'){
                this.check =true
                let slug_berita = to.query.slug_berita
                await axios.get(`/blog/show/`+slug_berita).then((response)=>{
                    this.databerita =response.data.data.data
                })
                await axios.get(`/blog`).then((response)=>{
                    const berita= response.data.data.blogs
                    this.recomendside(berita)  

                })
                this.check =false

            }
        },
    },
    computed:{
    },
	methods:{
		dateTime(value) {
			return moment(value).format("DD MMMM YYYY h:mm:ss a");
		},
        recomendside(data){
            this.berita = []
            let slug_berita = this.$route.query.slug_berita
            data.forEach((vdata) => {
                if(vdata.slug != slug_berita && this.berita.length <= 5){
                    this.berita.push(vdata) 
                }
            });

        },
	}
}
</script>

<style scoped>

.header-berita{
    height: 14rem;
}
.image{
    margin: 0 auto 40px;
    overflow: hidden;
    border-radius: 10px;
    width: 100%;
    max-height: 300px;
}
.image	img{
    margin: 0 auto;
    height: 100%;
    z-index: 2;
    left: 0;
    border-radius: 10px;
    width:100%;
}
.berita-header{
    font-size: 1.5rem !important;
}
::v-deep .descberita p {
    text-align: justify !important;
}
.databerita{
    margin: -300px 0 0;
    overflow-x: hidden;
    }
.databerita .box{
    background: white;
    padding: 40px 60px;
    margin: 100px 0 40px;
    /* box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); */
    position: relative;
    z-index: 4;
}
.databerita .sidebarberita{
    border-top: 3px solid #000;
    padding-left: 10px;
    padding-right: 0px;
}
.databerita ul{
    list-style-type: none;
    padding-left: 0px !important;
}
.databerita ul li{
    border-top: 1px solid rgba(0, 0, 0, 0.4);
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}
.databerita ul li a{
    text-decoration: none;
}
.databerita ul li .list-sidebar{
    display: flex;
}
.databerita ul li figure{
    max-height: 70px;
    max-width: 70px;
    overflow: hidden;
}
.databerita ul li img{
    height: 100%;
}
.databerita ul li .text{
    padding-left: 5px;
}
.databerita ul li p{
    margin-bottom: 0px !important;
    font-size: 7pt;
    color: rgba(0, 0, 0, 0.6);
}
.databerita ul li h6{
    color: #000;
    font-size: 8pt;
    font-weight: 600;
}
.databerita .title{
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
    margin: 0 0 20px 0;
    text-align: center;
}
.databerita .time{
    margin-bottom: 0px !important;
    color: rgba(0, 0, 0, 0.6);
}
.databerita p{
    font-size: 9pt;
    line-height: 2;
}

@media (max-width: 767.98px){
.databerita .box{
    padding: 10px 20px;
}
.databerita .box .title{
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
    margin: 0 0 20px 0;
    text-align: center;
}
.databerita .box .time{
    margin-bottom: 0px !important;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 0.5rem !important;
}
.databerita .box h1{
    font-size: 9pt;
    font-weight: 600;
}
.databerita .box p{
    font-size: 7pt;
    line-height: 1.5;
}

}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
.card-title{
    font-size:12pt;
    font-weight: 600;
    height: 75px;
}
.card-text{
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    font-size: 9pt;
    font-weight: 400;
    height: 120px;
}
.text-card{
    height: 120px;
}
.btn-card{
    display: block !important;
    margin: 0 auto !important;
    text-align: center;
    text-decoration: none !important;
    color: rgba(0, 0, 0, 0.6) !important;

}
.detail_berita .image{
    position: relative;
    display: block;
    margin: 0 auto 40px;
    overflow: hidden;
    border-radius: 10px;
    width: 100%;
    max-height: 400px;
}
.detail_berita .image img{
    display: block;
    margin: 0 auto;
    height: 100%;
    position: relative;
    z-index: 2;
    left: 0;
    border-radius: 10px;
}
.pr-md-1{
    padding-right: 1rem !important;
}
}

@media (min-width: 1023px) {
    .card-title{
        height: 40px;
    }
	.text-card{
        height: 100px;
    }
    .pr-lg-4{
        padding-right: 2.25rem !important;
    } 
}

@media (min-width: 1439px) {
    ::v-deep .slick-track .slick-slide{
        padding-left: 0px;
        padding-right: 0px;
    } 
    .pr-xl-5{
        padding-right: 3rem !important;
    }
}

@media (min-width: 2000px) {
}
</style>