<template>
  <section>
    <div class="header-slider"></div>
    <SkeletonSlider v-if="loading" />
    <div v-else-if="bannerdata.length > 0 && !loading" id="banner" class="Slider">
      <div class="h-100">
        <b-carousel
          class="h-100 corausel-content"
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          style="text-shadow: 1px 1px 2px #333"
          label-next=""
          label-prev=""
        >
          <!-- Text slides with image -->

          <!-- Slides with image only -->
          <!-- <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=58"></b-carousel-slide> -->

          <!-- Slides with img slot -->
          <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
          <b-carousel-slide v-for="(image, index) in bannerdata" :key="index">
            <template #img v-if="image.position == 'Slider'">
              <a
                :href="
                  (image.url.substring(0, 4) === 'http' ? '' : 'https://') + image.url
                "
                v-if="image.url !== '' || image.url !== null"
                target="_blank"
                class="datalink"
              >
                <img
                  class="d-block img-fluid w-100"
                  width="1024"
                  height="480"
                  :src="$imgUrl + image.image"
                  alt="image slot"
                />
              </a>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </div>
  </section>
</template>
<script>
//   import axios from "axios";
import SkeletonSlider from "../Skeleton/Homepage/SkeletonSlider.vue";
export default {
  data() {
    return {
      slide: 0,
      sliding: null,
      slider: [],
      bannerdata: [],
      databanner: [],
      loading: true,
    };
  },
  async mounted() {
    try {
      this.loading = true;
      if (this.banners == null) {
        await this.$store.dispatch("banners");
      }
      this.setbanner();
      this.loading = false;
    } catch (error) {
      this.loading = false;
      return error;
    }
  },
  watch: {
    banners() {
      this.loading = true;
      this.setbanner();
    },
  },
  computed: {
    banners: function () {
      return this.$store.state.banners;
    },
  },
  components: {
    SkeletonSlider,
  },
  methods: {
    redirectlink(value) {
      window.location.href = value;
    },
    setbanner() {
      this.loading = true;
      var bannerdata = Object.entries(this.banners).map((data) => {
        return data[1];
      });
      var slider = bannerdata.filter((databanner) => {
        if (databanner.position == "Slider") {
          return databanner;
        }
      });

      this.loading = false;
      this.bannerdata = slider;
    },
  },
};
</script>
<style scoped>
.Slider {
  width: 100%;
  height: 13vh;
  margin-top: 2rem;
}
::v-deep .carousel-control-next-icon {
  background-color: rgb(145, 145, 145);
  box-shadow: 5px 5px 30px 20px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  background-size: 50% 50% !important;
}
::v-deep .carousel-control-prev-icon {
  background-color: rgb(145, 145, 145);
  box-shadow: 5px 5px 30px 20px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}
::v-deep .carousel-control-prev-icon,
.carousel-control-next-icon {
  background-size: 50% 50% !important;
}
.header-slider {
  padding-top: 3rem;
}
.datalink {
  cursor: pointer;
}

.corausel-content {
  max-height: 35vh;
  overflow: hidden;
  border-radius: 10px;
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
  .Slider {
    width: 100%;
    height: 95px;
  }
}

@media (min-width: 424px) {
  .Slider {
    width: 100%;
    height: 17vh;
  }
}

@media (min-width: 767px) {
  .header-slider {
    padding-top: 4rem;
  }
  .corausel-content {
    max-height: 250px;
    overflow: hidden;
    border-radius: 10px;
  }
  .Slider {
    width: 100%;
    height: 235px;
    overflow-y: hidden;
    padding: 2rem;
    max-height: 330px;
  }
  .card-title {
    font-size: 12pt;
    font-weight: 600;
    height: 75px;
  }
  .card-text {
    color: rgba(0, 0, 0, 0.6);
    font-size: 9pt;
    font-weight: 400;
    height: 120px;
  }
  .text-card {
    height: 120px;
  }
  .btn-card {
    display: block !important;
    margin: 0 auto !important;
    text-align: center;
    text-decoration: none !important;
    color: rgba(0, 0, 0, 0.6) !important;
  }
  ::v-deep .w-card p {
    font-size: 7pt;
    line-height: 1.5;
  }
}

@media (min-width: 1023px) {
  .Slider {
    width: 100%;
    height: 50vh;
    overflow-y: hidden;
    padding: 2rem;
  }
  .header-slider {
    padding-top: 5rem;
  }
  .card-title {
    height: 40px;
  }
  .text-card {
    height: 100px;
  }
}
@media (min-width: 1200px) {
  .Slider {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    height: 55vh;
  }
}

@media (min-width: 1439px) {
  .Slider {
    width: 1320px;
    margin-left: auto;
    margin-right: auto;
    height: 57vh;
    padding: 1rem;
  }
}

@media (min-width: 2000px) {
}
</style>
