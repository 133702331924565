<template>
    <div>
        <Navbar/>
        <Transaksi/>
        <Footer/>
    </div>
</template>

<script>
import Navbar from '@/components/NavbarUno.vue'
import Footer from '@/components/FooterUno.vue'
import Transaksi from '@/components/Checkout/DataTransaksi.vue'

export default {
  name: 'HomeView',
  components: {
    Navbar,
    Footer,
    Transaksi,
  }
}
</script>