<template>
    <div>
        <b-container fluid class="loading-skeleton">
            <!-- User Interface controls -->
            <div class="header-table">
                <div class="search_tabel">
                    <b-input-group class="mt-3">
                        <b-form-input id="filter-input"
                    type="search"
                    placeholder="Type to Search"></b-form-input>
                    </b-input-group>
                </div>
                <div class="search_tabel">
                    <b-input-group class="mt-3">
                        <b-form-input id="filter-input"
                    type="search"
                    placeholder="Type to Search"></b-form-input>
                    </b-input-group>
                </div>
            </div>

            

            

            

            <div class="p-3">
                <!-- Main table element -->
                <div class="d-flex btn-flex">
                  <b-skeleton class="mx-1" type="button"></b-skeleton>
                  <b-skeleton class="mx-1" type="button"></b-skeleton>
                  <b-skeleton class="mx-1" type="button"></b-skeleton>
                  <b-skeleton class="mx-1" type="button"></b-skeleton>
                  <b-skeleton class="mx-1" type="button"></b-skeleton>
                  <b-skeleton class="mx-1" type="button"></b-skeleton>
                </div>
                <div class="p-1 data-ongkir" style="overflow-x:scroll;">
                    <h5 class="mt-3">Pengiriman Partial</h5>
                    <!-- <div v-for="(partialongkir,dataindex) in produk.shipping" :key="dataindex"> -->
                        <table class="mt-2 data-tabel w-100" >
                            <thead>
                                <tr style="border:0px !important;">
                                    <th style="min-width:80px;"><p>jumlah</p></th>
                                    <th style="min-width:130px;"><p>Harga Ongkir</p></th>
                                    <th style="min-width:130px;"><p>Harga Ongkir</p></th>
                                    <th style="min-width:130px;"><p>Harga Ongkir</p></th>
                                    <!-- <th style="min-width:180px;" colspan="2">Nego Harga Ongkir</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="border:0px !important;" >
                                    <td> <p>Unit</p></td>
                                    <td><p>Rp.</p> </td>
                                    <td><p>Rp.</p> </td>
                                    <td><p>Rp.</p> </td>
                                    <!-- <td>
                                        <input class="form-control" type="text" v-model="negoongkir" name="nego-harga">
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                        <div class="w-100 mt-3" style="display: flow-root;">
                            <a class="btn btn-danger float-right w-100">Ajukan Negosiasi Harga Ongkir</a>
                        </div>
                    <!-- </div> -->
                </div>
                <div class="p-1 data-ongkir" style="overflow-x:scroll;">
                    <h5 class="mt-3">Pengiriman Partial</h5>
                    <!-- <div v-for="(partialongkir,dataindex) in produk.shipping" :key="dataindex"> -->
                        <table class="mt-2 data-tabel w-100" >
                            <thead>
                                <tr style="border:0px !important;">
                                    <th style="min-width:80px;"><p>jumlah</p></th>
                                    <th style="min-width:130px;"><p>Harga Ongkir</p></th>
                                    <th style="min-width:130px;"><p>Harga Ongkir</p></th>
                                    <th style="min-width:130px;"><p>Harga Ongkir</p></th>
                                    <!-- <th style="min-width:180px;" colspan="2">Nego Harga Ongkir</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="border:0px !important;" >
                                    <td> <p>Unit</p></td>
                                    <td><p>Rp.</p> </td>
                                    <td><p>Rp.</p> </td>
                                    <td><p>Rp.</p> </td>
                                    <!-- <td>
                                        <input class="form-control" type="text" v-model="negoongkir" name="nego-harga">
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                        <div class="w-100 mt-3" style="display: flow-root;">
                            <a class="btn btn-danger float-right w-100">Ajukan Negosiasi Harga Ongkir</a>
                        </div>
                    <!-- </div> -->
                </div>

            </div>
            <b-col sm="7" md="3" class="my-1 pb-4">
               <div class="search_tabel">
                    <b-input-group class="mt-3">
                        <b-form-input id="filter-input"
                    type="search"
                    placeholder="Type to Search"></b-form-input>
                    </b-input-group>
                </div>
            </b-col>

        </b-container>
    </div>
</template>
<script>
  import axios from "axios";
import moment from 'moment'
  export default {
    data() {
      return {
        items: [],
        fields: [
        {key:'index',label:'No'},
          { key: 'invoice', label: 'ID Pembelian'},
          { key: 'updated_at', label: 'Tanggal' },
          { key: 'status', label: 'Status' },
          { key: 'Aksi', label: 'Aksi' },
          
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    mounted() {
      axios.get('/transaction/list-transaction').then((response)=>{
         let datatransaksi= response.data.data
         this.items = datatransaksi.reverse()
      })
      // Set the initial number of items
      this.totalRows = this.items.length
    },
    methods: {
      toLowerCase(value){
        return value.toLowerCase()
      },
      dateTime(value) {
          return moment(value).format("DD MMMM YYYY");
      },
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
    }
  }
</script>
<style scoped>
.btn-flex{
  flex-wrap: wrap;
  justify-content: center;
}
.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton v-select,
.loading-skeleton label,
.loading-skeleton .image-user,
.loading-skeleton .datatable,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder, 
.loading-skeleton v-select::placeholder, 
.loading-skeleton .btn::placeholder,
.loading-skeleton .datatable::placeholder,
.loading-skeleton .image-user::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}


@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.loading-skeleton .datatable {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.loading-skeleton h1{
    height: 2rem;
}
.loading-skeleton h2{
    height: 1.5rem;
}
.loading-skeleton h3{
    height: 1.25rem;
}
.loading-skeleton h4{
    height: 1rem;
}
.loading-skeleton h5{
    height: 0.7rem;
}
.select-tabel{
    height: 1.8rem;
}
::v-deep .search_tabel .input-group-prepend{
    background-color: transparent;
}
::v-deep .search_tabel .input-group-prepend .input-group-text{
    background-color: transparent;
    border-right: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
::v-deep .search_tabel input{
    border-left: 0px !important;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .header-table{
    display: flex;
    justify-content: space-between;
}
}

@media (min-width: 1023px) {
  }

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>