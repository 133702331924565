<template>
  <div class="loading-skeleton">
    <div class="container  pt-4">
      <div class="row">
        <div class="col-lg-9">
          <div class="container-fluid">
            <div class="float-right loading-skeleton">
              <p class="tinggi-p" style="width: 280px">
                Tanggal 02 January 2022
              </p>
            </div>
          </div>
          <div class="container-fluid">
            <div class="card-group">
              <div class="col-12 w-100 card p-2 p-md-4 mx-1">
                <h5 class="mb-3 mb-md-4">Informasi User</h5>
                <p>Nama Instansi: <b></b></p>
                <p>No Telepon: <b></b></p>
                <p>Email:</p>
                <p>Alamat:</p>
                <p>NIP:</p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, Lorem Ipsum is
                  simply dummy text of the printing and typesetting industry.
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s,
                </p>
              </div>
            </div>
          </div>
          <div class="container-fluid pb-5 pb-md-1 mt-3">
            <div class="background-white p-md-3">
              <div></div>
              <div class="mt-3 p-3 data-ongkir" style="overflow-x: scroll">
                <h5 class="mt-3">Pengiriman Partial</h5>
                <!-- <div v-for="(partialongkir,dataindex) in produk.shipping" :key="dataindex"> -->
                <table class="mt-2 data-tabel w-100">
                  <thead>
                    <tr style="border: 0px !important">
                      <th style="min-width: 80px"><p>jumlah</p></th>
                      <th style="min-width: 130px"><p>Harga Ongkir</p></th>
                      <th style="min-width: 130px"><p>Harga Ongkir</p></th>
                      <th style="min-width: 130px"><p>Harga Ongkir</p></th>
                      <!-- <th style="min-width:180px;" colspan="2">Nego Harga Ongkir</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr style="border: 0px !important">
                      <td><p>Unit</p></td>
                      <td><p>Rp.</p></td>
                      <td><p>Rp.</p></td>
                      <td><p>Rp.</p></td>
                      <!-- <td>
                                        <input class="form-control" type="text" v-model="negoongkir" name="nego-harga">
                                    </td> -->
                    </tr>
                  </tbody>
                </table>
                <div class="w-100 mt-3" style="display: flow-root">
                  <a class="btn btn-danger float-right w-100"
                    >Ajukan Negosiasi Harga Ongkir</a
                  >
                </div>
                <!-- </div> -->
              </div>
              <div class="mt-3 p-3 data-ongkir" style="overflow-x: scroll">
                <h5 class="mt-3">Pengiriman Partial</h5>
                <!-- <div v-for="(partialongkir,dataindex) in produk.shipping" :key="dataindex"> -->
                <table class="mt-2 data-tabel w-100">
                  <thead>
                    <tr style="border: 0px !important">
                      <th style="min-width: 80px"><p>jumlah</p></th>
                      <th style="min-width: 130px"><p>Harga Ongkir</p></th>
                      <th style="min-width: 130px"><p>Harga Ongkir</p></th>
                      <th style="min-width: 130px"><p>Harga Ongkir</p></th>
                      <!-- <th style="min-width:180px;" colspan="2">Nego Harga Ongkir</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr style="border: 0px !important">
                      <td><p>Unit</p></td>
                      <td><p>Rp.</p></td>
                      <td><p>Rp.</p></td>
                      <td><p>Rp.</p></td>
                      <!-- <td>
                                        <input class="form-control" type="text" v-model="negoongkir" name="nego-harga">
                                    </td> -->
                    </tr>
                  </tbody>
                </table>
                <div class="w-100 mt-3" style="display: flow-root">
                  <a class="btn btn-danger float-right w-100"
                    >Ajukan Negosiasi Harga Ongkir</a
                  >
                </div>
                <!-- </div> -->
              </div>
            </div>
            
          </div>
        </div>
        <div class="col-lg-3">
            <div class="card w-100 p-3">
                <h5>pembayaran</h5>
                <b-skeleton type="button" class="w-100"></b-skeleton>
            </div>
            <div class="card w-100 p-3">
                <h3 class="mt-4" style="width: 250px">Ringkasan Belanja</h3>
                <div class="cover-ringakasan">
                <div class="mt-3">
                    <div class="ringkasan w-100">
                    <p>Total Nego Harga</p>
                    <p><b>Rp. </b></p>
                    </div>
                    <div class="ringkasan w-100">
                    <p>Total PPN</p>
                    <p><b>Rp. 30.000</b></p>
                    </div>
                    <div class="total-ringkasan w-100">
                    <h3>Total Nego Tagihan</h3>
                    <h3>Rp.</h3>
                    </div>
                </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DetailPesanan",
};
</script>
<style scoped>
.data-tabel,
tr,
td,
th {
  border: 0px !important;
}
.data-ongkir {
  box-shadow: 10px 10px 19px #f5f5f5, -10px -10px 19px #efefef;
  border-radius: 15px;
}
.transaksi-footer-bottom {
  display: block;
  position: initial;
  bottom: 1.5rem;
  right: 2rem;
}
.img-profile {
  max-height: 150px !important;
  max-width: 150px !important;
}
.img-profile img {
  width: 100%;
}
.td-nama-produk {
  max-width: 200px !important;
}
.nama-produk {
  display: flex;
  margin: 0px;
  margin-right: 0px !important;
}
.discount-harga {
  text-align: right;
  margin-bottom: 5px;
  /* max-width: 40px; */
}
.discount-harga span {
  background-color: #dc3545;
  color: #ffffff;
  border-radius: 5px;
  /* max-width: 40px; */
}
.ringkasan {
  display: flex;
  justify-content: space-between;
}
.total-ringkasan {
  border-top: solid 1px grey;
  display: flex;
  justify-content: space-between;
}
.form-group label {
  font-weight: 600;
  font-size: 1.3rem;
}
.header-form {
  border-bottom: solid 1px grey;
}
.tinggi-p {
  height: 30px;
}
.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton label,
.loading-skeleton .image-user,
.loading-skeleton .datatable,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton .datatable::placeholder,
.loading-skeleton .image-user::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.loading-skeleton .datatable {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
.cover-ringakasan {
  text-align: right;
  display: flex;
  justify-content: end;
}
.data-ringakasan {
  width: 100%;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}
@media (min-width: 520px) {
  .data-ringakasan {
    width: 70%;
  }
}

@media (min-width: 767px) {
  .transaksi-footer-bottom {
    position: absolute;
  }
  .data-ringakasan {
    width: 50%;
  }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>
