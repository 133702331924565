<template>
  <div>
    <div class="mx-md-1 datacard">
      <router-link
        :to="'/produk-detail?id_produk=' + id"
        class="link-product"
        :class="checkstock(price) <= 0 ? 'link-product-disabled' : ''"
      ></router-link>
      <div class="disabled-product" v-if="checkstock(price) <= 0"></div>
      <div class="card w-card">
        <div class="diskon-atas" v-if="dataflashsale(price)">
          <p>{{ dataflashsale(price).discountflashsale }}%</p>
        </div>
        <div class="diskon-atas" v-if="datapromo(price)">
          <p>{{ datapromo(price).discountpromo }}%</p>
        </div>
        <figure style="height: 200px; overflow: hidden; position: relative">
          <img
            style="width: 100%"
            class="card-img-top"
            :src="$imgProduct + dataimg(img)"
            alt="Card image cap"
          />
        </figure>
        <div class="card-body">
          <h5 class="card-title">{{ title }}</h5>
          <div v-if="dataflashsale(price)">
            <div class="diskon">
              <p class="harga mt-2 pb-0">
                Rp
                {{
                  new Intl.NumberFormat("id-ID").format(
                    Number(dataflashsale(price).priceflashsale).toFixed()
                  )
                }}
              </p>
              <small
                ><s
                  >Rp
                  {{
                    new Intl.NumberFormat("id-ID").format(
                      Number(dataflashsale(price).pricenormal).toFixed()
                    )
                  }}</s
                ></small
              >
            </div>
            <div class="diskon-mobile">
              <p class="harga mt-2 pb-0">
                Rp
                {{
                  new Intl.NumberFormat("id-ID").format(
                    Number(dataflashsale(price).priceflashsale).toFixed()
                  )
                }}
              </p>
              <small
                ><s
                  >Rp
                  {{
                    new Intl.NumberFormat("id-ID").format(
                      Number(dataflashsale(price).pricenormal).toFixed()
                    )
                  }}</s
                ></small
              >
            </div>
          </div>
          <div v-else-if="datapromo(price)">
            <div class="diskon">
              <p class="harga mt-2 pb-0">
                Rp
                {{
                  new Intl.NumberFormat("id-ID").format(
                    Number(datapromo(price).pricepromo).toFixed()
                  )
                }}
              </p>
              <small
                ><s
                  >Rp
                  {{
                    new Intl.NumberFormat("id-ID").format(
                      Number(datapromo(price).pricenormal).toFixed()
                    )
                  }}</s
                ></small
              >
            </div>
            <div class="diskon-mobile">
              <p class="harga mt-2 pb-0">
                Rp
                {{
                  new Intl.NumberFormat("id-ID").format(
                    Number(datapromo(price).pricepromo).toFixed()
                  )
                }}
              </p>
              <small
                ><s
                  >Rp
                  {{
                    new Intl.NumberFormat("id-ID").format(
                      Number(datapromo(price).pricenormal).toFixed()
                    )
                  }}</s
                ></small
              >
            </div>
          </div>
          <div v-else>
            <div class="diskon harganormal pt-3">
              <p class="harga mt-2">
                Rp
                {{
                  new Intl.NumberFormat("id-ID").format(
                    Number(dataprice(price)).toFixed()
                  )
                }}
              </p>
            </div>
            <div class="diskon-mobile harganormal">
              <p class="harga mt-2">
                Rp
                {{
                  new Intl.NumberFormat("id-ID").format(
                    Number(dataprice(price)).toFixed()
                  )
                }}
              </p>
            </div>
          </div>
          <div class="star">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              style="transform: ; msfilter: "
            >
              <path
                d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
              ></path>
            </svg>
            <small class="pt-3"
              >{{ datastar(rating) }} | Terjual {{ datasold(total_sold) }}</small
            >
          </div>
          <div class="modal-btn">
            <b-button
              class="btn btn-primary mt-2 w-100 d-flex justify-content-center"
              @click="modalproduct(id)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <rect x="0" y="0" width="26" height="26" fill="none" stroke="none" />
                <g fill="none" stroke="#fff" stroke-width="1.5">
                  <path
                    d="M3.864 16.455c-.858-3.432-1.287-5.147-.386-6.301C4.378 9 6.148 9 9.685 9h4.63c3.538 0 5.306 0 6.207 1.154c.901 1.153.472 2.87-.386 6.301c-.546 2.183-.818 3.274-1.632 3.91c-.814.635-1.939.635-4.189.635h-4.63c-2.25 0-3.375 0-4.189-.635c-.814-.636-1.087-1.727-1.632-3.91Z"
                  />
                  <path
                    d="m19.5 9.5l-.71-2.605c-.274-1.005-.411-1.507-.692-1.886A2.5 2.5 0 0 0 17 4.172C16.56 4 16.04 4 15 4M4.5 9.5l.71-2.605c.274-1.005.411-1.507.692-1.886A2.5 2.5 0 0 1 7 4.172C7.44 4 7.96 4 9 4"
                  />
                  <path d="M9 4a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Z" />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8 13v4m8-4v4m-4-4v4"
                  />
                </g>
              </svg>
              <small class="pl-2 d-flex pt-md-1"
                ><span class="d-none d-md-block mx-1">Tambah </span> Keranjang</small
              >
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <b-modal :id="'modal-produk' + id" size="lg" title="Produk" hide-footer>
      <Modalproduk :id="productId" />
    </b-modal>
  </div>
</template>

<script>
import Modalproduk from "../../components/ModalProduct.vue";
export default {
  props: [
    "id",
    "img",
    "title",
    "desc",
    "price",
    "rating",
    "status",
    "total_sold",
    "supportWebp",
  ],
  name: "product-list-component",
  data() {
    return {
      baseUrl: process.env.VUE_APP_NAME_AXIOS,
      src: "",
      productId: null,
    };
  },
  components: { Modalproduk },
  methods: {
    checkstock(data) {
      return data.reduce((total, price) => total + price.stock, 0);
    },
    modalproduct(data) {
      this.productId = data;
      const timeoutId = setTimeout(() => {
        if (this.productId) {
          this.$bvModal.show("modal-produk" + data);
          clearTimeout(timeoutId);
        }
        2000;
      });
    },

    dataimg(value) {
      var dataimg = value.filter((item) => {
        if (item.default_image) {
          return item;
        }
      });
      if (dataimg[0]?.webp != null) {
        return dataimg[0]?.webp;
      } else {
        return dataimg[0]?.image;
      }
    },
    datastar(value) {
      var data = parseFloat(value);
      return data.toFixed(1);
    },
    datasold(data) {
      var sold = "";
      var check = "";
      if (data > 1000000000) {
        sold = data.toString();
        check = sold.slice(0, -9) + "M";
      } else if (data > 1000000) {
        sold = data.toString();
        check = sold.slice(0, -6) + "jt";
      } else if (data > 1000) {
        sold = data.toString();
        check = sold.slice(0, -3) + "rb";
      } else {
        check = data.toString();
      }
      return check;
    },
    getSrc() {
      if (this.img != undefined) {
        if (!Array.isArray(this.img)) {
          return this.img;
        } else {
          if (this.img.length > 0) {
            let data = this.img.filter(function (e) {
              return e.default_image === true;
            });
            if (data.length > 0) {
              if (this.supportWebp && data[0].webp != null) {
                return data[0].webp;
              } else {
                return data[0]?.image;
              }
            } else {
              if (this.supportWebp && data[0].webp != null) {
                return this.img[0]?.webp;
              } else {
                return this.img[0]?.image;
              }
            }
          }
        }
      } else {
        return "";
      }
    },
    dataflashsale(data) {
      let discountflashsale = 0;
      let priceflashsale = 0;
      let pricenormal = 0;

      data.forEach((product) => {
        if (product.flashsale && product.flashsale.length > 0) {
          product.flashsale.forEach((dataflash) => {
            if (dataflash.flashsale != null && this.$store.state.user.name != undefined) {
              const discount = parseFloat(dataflash.discount);
              const discountedPrice = parseFloat(dataflash.flashsale_price);
              const normalPrice = parseFloat(product.price);
              if (discount > discountflashsale) {
                discountflashsale = discount;
                priceflashsale = discountedPrice;
                pricenormal = normalPrice;
              }
            }
          });
        }
      });
      var Datareturn = {
        priceflashsale,
        discountflashsale,
        pricenormal,
      };
      if (Datareturn.priceflashsale > 0 && Datareturn.discountflashsale > 0) {
        return Datareturn;
      }
    },
    datapromo(data) {
      let discountpromo = 0;
      let pricepromo = 0;
      let pricenormal = 0;

      data.forEach((product) => {
        if (product.promo && product.promo.length > 0) {
          product.promo.forEach((datapromo) => {
            if (datapromo.promo != null) {
              const discount = parseFloat(datapromo.discount);
              const discountedPrice = parseFloat(datapromo.price_promo);
              const normalPrice = parseFloat(product.price);
              if (discount > discountpromo) {
                discountpromo = discount;
                pricepromo = discountedPrice;
                pricenormal = normalPrice;
              }
            }
          });
        }
      });
      var Datareturn = {
        pricepromo,
        discountpromo,
        pricenormal,
      };
      if (Datareturn.pricepromo > 0 && Datareturn.discountpromo > 0) {
        return Datareturn;
      }
    },
    dataprice(price) {
      var pricedata = parseInt(price[0].price);
      price.forEach((element) => {
        if (parseInt(element.price) < pricedata) {
          pricedata = parseInt(element.price);
        }
      });
      return pricedata;
    },
  },
  created() {
    this.getSrc();
  },
};
</script>
<style scoped>
::v-deep .modal-header .close {
  background-color: transparent !important;
  border: 0px !important;
}
.modal-btn button svg {
  width: 15px;
  height: 15px;
}
.modal-btn button small {
  font-size: 0.6rem;
}
.modal-btn button {
  position: relative;
  z-index: 20;
}
.datacard {
  position: relative;
}
.link-product-disabled {
  z-index: 35 !important;
}
.disabled-product {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 33;
  width: 100%;
  height: 100%;
  left: 0 !important;
}
.link-product {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0 !important;
}
.harganormal p {
  line-height: 2.4 !important;
}
.diskon-mobile {
  display: none;
}
.data_produk {
  padding: 5px;
  text-decoration: none;
}
.data_produk h5,
.data_produk p,
.data_produk s,
.data_produk small {
  text-decoration: none;
}
.data_produk h5 {
  color: #000;
}
.card figure {
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.card-title {
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal !important;
}

.card .star {
  text-align: left;
}
.card .star svg {
  fill: gold;
  font-size: 10px;
  width: 20px;
  height: 20px;
  vertical-align: sub !important;
}
.card .star small {
  padding-left: 5px;
  color: #aeaeae;
}
.harga {
  font-size: 1.1rem !important;
  text-align: left;
  font-weight: 550;
  margin-bottom: 5px !important;
  color: #000;
}
.diskon-atas {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10;
}
.diskon-atas p {
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  background-color: red;
}
.diskon p {
  color: red !important;
}
.diskon s {
  color: gray;
  text-decoration: line-through;
  font-size: 0.8rem;
}

@media only screen and (max-device-width: 325px) {
  .w-card .card-body .harga,
  .w-card p {
    font-size: 0.7rem !important;
  }
  .diskon-atas p {
    font-size: 0.4rem !important;
  }
}
@media only screen and (min-device-width: 421px) and (max-device-width: 500px) and (orientation: portrait) {
  .w-card .card-body .harga,
  .w-card p {
    font-size: 0.7rem !important;
  }

  .diskon-atas p {
    font-size: 0.4rem !important;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 420px) and (orientation: portrait) {
  .w-card .card-body h5 {
    min-height: 35px !important;
    font-size: 15px !important;
  }
  .diskon-mobile s {
    color: gray;
    text-decoration: line-through;
    font-size: 0.8rem !important;
  }
  .w-card .card-body .harga,
  .w-card p {
    font-size: 0.9rem !important;
  }
  .modal-btn button small {
    font-size: 0.8rem !important;
  }
  .card .star small {
    font-size: 0.8rem !important;
  }

  .diskon-atas p {
    font-size: 0.6rem !important;
  }
}
@media (max-width: 500px) {
  ::v-deep .slick-initialized .slick-slide {
    width: 125px !important;
  }

  .card figure {
    width: 100%;
    height: 100px !important;
    overflow: hidden;
    margin: 0px !important;
  }
  .w-card .card-body {
    padding: 0.5rem !important;
  }
  .w-card .card-body h5 {
    min-height: 35px !important;
    font-size: 11px;
  }
  .w-card .card-body .harga {
    margin-bottom: 0px !important;
    margin-top: 1.2rem !important;
    line-height: 0.3;
  }
  .produk-header h4 {
    font-weight: 600;
    font-size: 1rem !important;
  }
  .produk-header a {
    font-size: 0.8rem !important;
  }
  .card .star small {
    font-size: 0.6rem;
  }
  .card .star svg {
    fill: gold;
    font-size: 10px;
    width: 14px !important;
    height: 14px !important;
    vertical-align: sub !important;
  }
  .diskon {
    display: none !important;
  }
  .diskon-mobile {
    display: block;
  }
  .diskon-atas {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 10;
  }
  .diskon-atas p {
    color: #fff;
    padding: 3px !important;
    border-radius: 5px;
    background-color: red;
  }
  .diskon-mobile p {
    color: red !important;
  }
  .diskon-mobile s {
    color: gray;
    text-decoration: line-through;
    font-size: 0.6rem;
  }
}

@media (min-width: 319px) {
  ::v-deep .w-card {
    max-width: 9rem;
  }
}

@media (min-width: 374px) {
  ::v-deep .w-card {
    max-width: 11.5rem;
  }
}

@media (min-width: 424px) {
  ::v-deep .w-card {
    max-width: 8rem;
  }
}

@media (min-width: 767px) {
  ::v-deep .modal-dialog {
    max-width: 95% !important;
  }
  ::v-deep .w-card {
    max-width: 250px;
    min-width: 190px;
    width: 195px !important;
  }
  .harganormal p {
    line-height: 1.6 !important;
  }
  .modal-btn button svg {
    width: 26px;
    height: 26px;
  }
  .modal-btn button small {
    font-size: 0.875em;
  }
  .card-title {
    font-size: 12pt;
    font-weight: 600;
    height: 45px;
  }
  .card-text {
    color: rgba(0, 0, 0, 0.6);
    font-size: 9pt;
    font-weight: 400;
    height: 120px;
  }
  .text-card {
    height: 120px;
  }
  .btn-card {
    display: block !important;
    margin: 0 auto !important;
    text-align: center;
    text-decoration: none !important;
    color: rgba(0, 0, 0, 0.6) !important;
  }
  ::v-deep .w-card p {
    font-size: 7pt;
  }
}

@media (min-width: 1023px) {
  .card-title {
    height: 40px;
  }
  .text-card {
    height: 100px;
  }
  ::v-deep .w-card {
    max-width: 250px;
    min-width: 200px;
    width: 200px !important;
  }
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>
