<template>
    <div>
        <div class="container-custom">
            <div class="data-left">
                <img src="../../assets/img/minimarket2.webp" alt="">
            </div>
            <div class="data-right">
                <div class="isi-data-right">
                    <router-link to="/" class="d-block text-center font-bold custom-link logo-container auth-logo-container" style="font-size:24px;">
                        <img src="../../assets/logo_arys_mikro.jpeg" alt="" class="logo">
                    </router-link>

                    <h1 class="mt-3">Lupa Kata Sandi?</h1>
                    <small>Silahkan masukkan nomor whatsapp Anda yang sudah terdaftar di Ary's Mikro.</small>
                    <form @submit.prevent="lupaPassword" class="mt-xl-5">
                        <div class="form-group mt-4">
                            <div class="col-md mt-4">
                                <label for="phone">Nomor WhatsApp</label>
                
                            <vue-tel-input v-model="phone" :autoFormat="false" class="radius" @input="validateFirstLetter" :class="[(v$.phone.$error || phone.length >17 || !phoneValid) ? 'is-invalid' : '']" @validate="validatePhone" defaultCountry='id' @country-changed="countryChanged" onkeypress="return event.charCode >= 48 && event.charCode <= 57 || event.charCode == 43"></vue-tel-input>
                                <span v-if="v$.phone.$error" class="text-danger font-small">{{v$.phone.$errors[0].$message}}</span>
                                <span v-else-if="submitdata && !phoneValid" class="text-danger font-small">Format yang anda masukkan salah</span>
                            </div>

                        </div>
                        

                        <div v-if="errorMessage != ''" class="alert alert-warning alert-dismissible fade show mt-4"  role="alert">
                            <strong>Peringatan ! </strong> 
                            <span>{{errorMessage}}</span>
                        </div>
                        <div class="form-group mt-4 btnlink">
                            <button type="submit" class="btn btn-blue mt-md-3 btn-radius">Reset Kata Sandi</button>
                            <router-link to="/login" class="btn btn-outline-blue btn-radius mx-3 mt-md-3">Kembali Ke Menu Login</router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div v-if="loadingSubmit" class="loading-submit-animation-container" >
            <div class="loading-submit-animation-content">
                <div>
                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                    <p>Mohon tunggu, data sedang diproses</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert'
import useVuelidate from '@vuelidate/core'
import { required, helpers   } from '@vuelidate/validators'

export default {
  name: 'forgot-password-component',
  props:['banners'],
    setup () {
        return { v$: useVuelidate() }
    },
    components: {
    },
    validations() {
        return {
            
            phone: {
                required: helpers.withMessage("No telepon tidak boleh kosong", required),
            },
        }
    },
  data(){
      return{
        phoneFirstTimeLoad: 0,
        phoneValid: true,
        dialcode: "",
        'phone' : '',
        'errorMessage':'',
        loadingSubmit: false,
        image: {},
        baseUrl: process.env.VUE_APP_NAME_AXIOS
      }
  },
  mounted(){
    this.setImage()
  },
  methods: {
    
        countryChanged(country) {
          
          this.phoneFirstTimeLoad++;
          let countryCode = country.dialCode;
            this.dialcode = `+${countryCode}`
          if (this.phoneFirstTimeLoad > 0) {
            const myPromise = new Promise((resolve) => {
              resolve((this.phone = ""));
            });

            myPromise.then(() => {
              this.phone = `+${countryCode}`;
            });
          }
        },
        validatePhone(country) {
           
          this.phoneValid = country.valid;
        },
        validateFirstLetter() {
          
          if (this.phone[0] != "+") {
            this.phoneValid = false;
          }
        },
        errorfalse(){
            this.error = false
        },
    lupaPassword(){
        this.v$.$validate()
        if(this.dialcode == this.phone){
            swal("Ooops", "Mohon masukkan nomor whatsapp terlebih dahulu", "error", {
            button: "OK",
            });
        }else if(this.dialcode != this.phone){
            let data = new FormData()
        let url_reset_pass = window.location.origin + '/reset-password' 
        data.append('phone', this.phone.toString())
        this.errorMessage = ''

        if ( !this.v$.$error && this.phoneValid) {
            this.loadingSubmit =  true
            axios.post('customer/forget-password?redirect_url='+url_reset_pass, data)
            .then(() => {
               swal('Berhasil!!!', 'Permintaan Ganti password sudah dilakukan, silahkan periksa whatsapp anda, untuk melakukan verifikasi whatsapp.', 'success', {
                    button: "OK"
                })
                this.v$.$reset();
                this.resetForm()
                this.loadingSubmit =  false
            })
            .catch((error) => {
                // let val = Object.values(error.response.data)
                this.errorMessage = error.response.data.message
                // let key = Object.keys(error.response.data)
                // for(let j = 0; j < key.length; j++){
                //     if(key[j] == 'message'){
                //         if(val[j] != ''){
                //              = val[j] 
                //         }
                //     }
                // }
                this.loadingSubmit =  false
                // let data_error = Object.entries(error.response.data.data)
                // for(let i = 0; i < data_error.length; i++){
                //     this.errors[data_error[i][0]] = data_error[i][1][0];
                // }
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }); 
        }
        }
    },
    resetForm(){
        this.phone = "";
    },
    setImage(){
        if(this.banners != null){
            for(let i = 0; i < Object.keys(this.banners).length; i++){
                if(this.banners[i].position === 'Forget Password Page'){
                    this.image = this.banners[i].image
                }
            }
            this.loadingMainBanner = false
        }
    },
  },
  watch:{
        banners:{
            deep: true,
            handler: function(){
                this.setImage()
                //           :autoplay='{
                // "delay": 2500,
                // "disableOnInteraction": false
                // }'
            }
        },
    }
}
</script>

<style scoped>

.btn-blue{
    background-color: #1F7ACF;
    color:#fff;
    padding: 5px 30px;
    border-radius: 25px;
}
.btn-blue:hover{
    background-color: #125da3;
    color:#fff;
}
.btn-outline-blue{
    border:1px solid #1F7ACF;
    color:#1F7ACF;
    padding: 5px 30px;
    border-radius: 25px;
}
.btn-outline-blue:hover{
    background-color: #1F7ACF;
    color:#fff;
}

.logo{
    width: 200px;
}
.container-custom{
    display: flex;
    flex-direction: column;
}
.data-left{
    height: 100vh;
    position: relative;
    display: none;
}
.data-left img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.data-left::after{
    content:'';
    width: 100%;
    min-height: 100vh;
    height: 100%;
    /* background-image: url("../../assets/img/abby-anaday-Z3fXPuxa15k-unsplash.webp"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    position: fixed;
    top:0;
    left: 0;
}
.data-right{
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:70px 20px;
    position: relative;
    background: white;
}

.data-left,
.data-right{
    width: 100%;
}

.isi-data-right small{
    color: var(--color-light);
}
.radius{
    border-radius: 25px;
    padding: 0.7rem 1.5rem
}
.btn-radius{
    border-radius: 25px;
    /* padding-left: 15px; */
    padding: 0.7rem 1.5rem
}
.btnlink{
    display: flex;
}
.btn-data-login{
    padding: 0.7rem 1.5rem;
    text-decoration:none;
}
.btn-data-login:hover{
    color: black;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .container-custom{
        flex-direction: row;
    }
    .data-left,
    .data-right{
        width: 50%;
    }

    .data-left{
        display: block;
    }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>