<template>
  <div>
    <div class="container" v-if="loading">
      <div class="header-checkout"></div>
      <Loader />
      <SkeletonCheckout />
    </div>
    <div class="header-checkout" v-if="!loading"></div>

    <div class="container" v-if="!loading">
      <h6>Pembayaran</h6>
      <div class="card w-100 py-4 mt-3 px-3 mt-md-4 pb-3 mb-3">
        <h4><b>Metode Penerimaan</b></h4>
        <select
          class="form-control"
          id="selectekspedisi"
          v-model="delivery_type"
          @change="checkdelivery"
        >
          <option value="" disabled selected>Penerimaan</option>
          <option value="self_pickup" selected="self_pickup">Pengambilan Sendiri</option>
          <option selected="home_delivery" value="home_delivery">
            Dikirim Oleh Kurir
          </option>
          <!-- <option selected="payment_gateway" disabled value="payment_gateway">Payment Gateway</option> -->
        </select>
        <small class="text-danger pl-1"
          >Note: harus memilih Metode penerimaan terlebih dahulu</small
        >
      </div>
      
      <div class="row">
        <div class="col-lg-8 col-xl-9">
          <div class="row">
            <div v-if="delivery_type == 'home_delivery'" class="col-md-6 mb-3-mobile">
              <div class="card p-2">
                <div class="card-header">
                  <h6 class="pt-1">Alamat Pengiriman</h6>
                </div>
                
                <div v-if="Object.keys(dataaddress).length <= 0">
                  <div class="btn-middle w-100 text-center">
                    <router-link to="/profil/tambah-alamat?back=checkout"
                    class="btn btn-outline-dark"
                    @click="alamatbaru()"
                  >
                    Tambahkan Alamat
      </router-link>
                  </div>
                </div>
                <div
                  v-else-if="dataaddress != undefined"
                  class="card-content pt-2 pb-1 px-3 mb-3"
                >
                  <h5>{{ dataaddress?.receipt_name }}</h5>
                  <h6>{{ dataaddress?.receipt_phone_number }}</h6>
                  <p>{{ dataaddress?.address }}</p>
                  <!-- <p class="m-0">{{dataaddress?.address}} , <br> {{dataaddress?.sub_district?.subdistrict_name}} ,{{dataaddress?.district?.city_name}} ,{{dataaddress?.province?.province_name}} ,{{dataaddress?.district?.postal_code}} </p> -->
                </div>
                <div
                  v-else-if="dataaddress != null"
                  class="card-content pt-2 pb-1 px-3 mb-3"
                >
                  <h5>{{ dataaddress?.receipt_name }}</h5>
                  <h6>{{ dataaddress?.receipt_phone_number }}</h6>
                  <p>{{ dataaddress?.address }}</p>
                  <!-- <p class="m-0">{{dataaddress?.address}} , <br> {{dataaddress?.sub_district?.subdistrict_name}} ,{{dataaddress?.district?.city_name}} ,{{dataaddress?.province?.province_name}} ,{{dataaddress?.district?.postal_code}} </p> -->
                </div>
                <div
                  v-else-if="address?.length > 0"
                  class="card-content pt-2 pb-1 px-3 mb-3"
                >
                  <h5>{{ address[0]?.receipt_name }}</h5>
                  <h6>{{ address[0]?.receipt_phone_number }}</h6>
                  <p>{{ address[0]?.address }}</p>
                  <!-- <p class="m-0">{{address[0]?.address}} , <br> {{address[0]?.sub_district?.subdistrict_name}} ,{{address[0]?.district?.city_name}} ,{{address[0]?.province?.province_name}} ,{{address[0]?.district?.postal_code}} </p> -->
                </div>
                <div v-else class="card-content pt-2 pb-2 px-3 py-lg-5 mb-3">
                  <h6 class="w-100 text-center">Saat ini Alamat belum dipilih</h6>
                  <small class="text-center w-100 d-block">Pilih alamat terlebih dahulu</small>
                </div>
                <div class="card-footer">
                  <b-button
                    v-if="address?.length > 0"
                    v-b-modal.ModalAlamat
                    class="btn btn-outline-dark"
                  >
                    Pilih Alamat Lain
                  </b-button>
                </div>
              </div>
            </div>
            <div class="col-md-6" v-if="delivery_type == 'home_delivery'">
              <div class="card p-2">
                <div class="card-header">
                  <h6>Cabang Pengantar</h6>
                </div>
                <div
                  v-if="branch?.length > 0 && databranch.length == 0"
                  class="card-content pt-2 pb-2 px-3 mb-3"
                >
                  <b-button v-b-modal.ModalCabang class="btn btn-middle btn-outline-dark">
                    Pilih Cabang
                  </b-button>
                </div>
                <div
                  v-else-if="Object.keys(branch)?.length > 0 && databranch.length == 0"
                  class="card-content pt-2 pb-2 px-3 mb-3"
                >
                  <b-button v-b-modal.ModalCabang class="btn btn-middle btn-outline-dark">
                    Pilih Cabang
                  </b-button>
                </div>
                <div
                  v-else-if="databranch.length != 0 && branch?.length > 0"
                  class="card-content pt-2 pb-2 px-3 mb-3"
                >
                  <h5>{{ databranch?.name }}</h5>
                  <h6>
                    Jam Buka : {{ datastring(databranch?.open) }} -
                    {{ datastring(databranch?.close) }}
                  </h6>
                  <p>{{ databranch?.address }}</p>
                </div>
                <div
                  v-else-if="databranch.length != 0 && Object.keys(branch)?.length > 0"
                  class="card-content pt-2 pb-2 px-3 mb-3"
                >
                  <h5>{{ databranch?.name }}</h5>
                  <h6>
                    Jam Buka : {{ datastring(databranch?.open) }} -
                    {{ datastring(databranch?.close) }}
                  </h6>
                  <p>{{ databranch?.address }}</p>
                </div>
                <div v-else class="card-content pt-2 pb-2 px-3 mb-3">
                  <p class="text-center mt-3">
                    Maaf belum terdapat data cabang untuk saat ini
                  </p>
                </div>
                <div class="card-footer">
                  <b-button
                    v-if="branch?.length > 0 && databranch.length != 0"
                    v-b-modal.ModalCabang
                    class="btn btn-outline-dark"
                  >
                    Pilih Cabang Lain
                  </b-button>
                  <b-button
                    v-else-if="Object.keys(branch)?.length > 0 && databranch.length != 0"
                    v-b-modal.ModalCabang
                    class="btn btn-outline-dark"
                  >
                    Pilih Cabang Lain
                  </b-button>
                </div>
                <b-modal id="ModalCabang" title="Cabang" hide-footer>
                  <div
                    :class="databranch?.id == branchdata?.id ? 'card-active' : ''"
                    class="card card-container p-3 mt-3"
                    v-for="(branchdata, index) in branch"
                    :key="'modal' + index"
                    @click="pickaddress(branchdata)"
                  >
                    <h5>{{ branchdata?.name }}</h5>
                    <h6>
                      Jam Buka : {{ branchdata?.open.substring(0, 5) }} -
                      {{ branchdata?.close.substring(0, 5) }}
                    </h6>
                    <p>{{ branchdata?.address }}</p>
                  </div>
                </b-modal>
              </div>
            </div>
            <div class="col-12" v-else>
              <div class="card card-checkout p-2">
                <div class="disabeld-card" v-if="delivery_type == ''"></div>
                <div class="card-header">
                  <h6>Cabang Pengambilan</h6>
                </div>
                <div
                  v-if="branch?.length > 0 && databranch.length == 0"
                  class="card-content pt-2 pb-2 px-3 mb-3"
                >
                  <b-button v-b-modal.ModalCabang class="btn btn-middle btn-outline-dark">
                    Pilih Cabang
                  </b-button>
                </div>
                <div
                  v-else-if="Object.keys(branch)?.length > 0 && databranch.length == 0"
                  class="card-content pt-2 pb-2 px-3 mb-3"
                >
                  <b-button v-b-modal.ModalCabang class="btn btn-middle btn-outline-dark">
                    Pilih Cabang
                  </b-button>
                </div>
                <div
                  v-else-if="databranch.length != 0 && branch?.length > 0"
                  class="card-content pt-2 pb-2 px-3 mb-3"
                >
                  <h5>{{ databranch?.name }}</h5>
                  <h6>
                    Jam Buka : {{ datastring(databranch?.open) }} -
                    {{ datastring(databranch?.close) }}
                  </h6>
                  <p>{{ databranch?.address }}</p>
                </div>
                <div
                  v-else-if="databranch.length != 0 && Object.keys(branch)?.length > 0"
                  class="card-content pt-2 pb-2 px-3 mb-3"
                >
                  <h5>{{ databranch?.name }}</h5>
                  <h6>
                    Jam Buka : {{ datastring(databranch?.open) }} -
                    {{ datastring(databranch?.close) }}
                  </h6>
                  <p>{{ databranch?.address }}</p>
                </div>
                <div v-else class="card-content pt-2 pb-2 px-3 mb-3">
                  <p class="text-center mt-3">
                    Maaf belum terdapat data cabang untuk saat ini
                  </p>
                </div>
                <div class="card-footer">
                  <div class="d-block">
                    <b-button
                    v-if="branch?.length > 0 && databranch.length != 0"
                    v-b-modal.ModalCabang
                    class="btn btn-outline-dark"
                  >
                    Pilih Cabang Lain
                  </b-button>
                  <b-button
                    v-else-if="Object.keys(branch)?.length > 0 && databranch.length != 0"
                    v-b-modal.ModalCabang
                    class="btn btn-outline-dark"
                  >
                    Pilih Cabang Lain
                  </b-button>
                  <div>
                    <small class="text-danger" v-if="delivery_type == 'self_pickup'">Note: Untuk Request waktu pengambilan, bisa hubungi admin</small>
                  </div>
                  </div>
                </div>
                <b-modal id="ModalCabang" title="Cabang" hide-footer>
                  <div
                    :class="databranch?.id == branchdata?.id ? 'card-active' : ''"
                    class="card card-container p-3 mt-3"
                    v-for="(branchdata, index) in branch"
                    :key="'modal' + index"
                    @click="pickaddress(branchdata)"
                  >
                    <h5>{{ branchdata?.name }}</h5>
                    <h6>
                      Jam Buka : {{ branchdata?.open.substring(0, 5) }} -
                      {{ branchdata?.close.substring(0, 5) }}
                    </h6>
                    <p>{{ branchdata?.address }}</p>
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
          <div class="product-data mt-3 mt-md-5" v-if="!loading">
            <div class="datatable">
              <table class="table-product web-view">
                <template v-for="(product, index) in dataproduct">
                  <tr class="header-table" :key="'headerproduct' + index">
                    <th class="p-3">Produk</th>
                    <th>Varian</th>
                    <th>Qty</th>
                    <th>Harga</th>
                  </tr>
                  <tr :key="'product' + index">
                    <td colspan="2" class="p-3 td-produk">
                      <div class="d-flex">
                        <figure class="image mx-1">
                          <img
                            :src="$imgProduct + dataimg(product.product)"
                            alt=""
                          />
                        </figure>
                        <div class="mx-1 detail_product">
                          <h6>{{ product.product.product.name }}</h6>
                        </div>
                      </div>
                    </td>
                    <td class="td-nonproduk">
                      <p v-if="product.product?.second_variant_option?.option">
                        {{ product.product?.first_variant_option?.option }} ,
                        {{ product.product?.second_variant_option?.option }}
                      </p>
                      <p v-else-if="!product.product?.second_variant_option?.option">
                        {{ product.product?.first_variant_option?.option }}
                      </p>
                    </td>
                    <td class="td-nonproduk">
                      <p v-if="product.qty <= product.product.stock">{{ product.qty }} {{ product?.product?.product?.unit?.name }}</p>
                      <p v-else><span class="text-danger">{{ product.qty }} {{ product?.product?.product?.unit?.name }}</span>
                      <br><small>Tersedia {{ product.product.stock }} {{ product?.product?.product?.unit?.name }}</small></p>
                    </td>
                    <td class="harga_data td-nonproduk">
                      <div v-if="datawholesale(product?.product, product?.qty)">
                        <h6 class="text-danger">
                          Rp
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(
                                parseInt(datawholesale(product?.product, product?.qty))
                              ).toFixed()
                            )
                          }}
                        </h6>
                      </div>
                      <div
                        v-else-if="
                          dataflashsale(product?.product).length > 0 &&
                          product?.qty <= dataflashsale(product?.product)[0]?.ammount_max
                        "
                      >
                        <h6 class="text-danger">
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(
                                parseInt(
                                  dataflashsale(product?.product)[0]?.flashsale_price
                                )
                              ).toFixed()
                            )
                          }}
                        </h6>
                        <div class="diskon">
                          <p>
                            {{ parseInt(dataflashsale(product?.product)[0]?.discount) }}%
                          </p>
                          <small
                            ><s
                              >Rp
                              {{
                                new Intl.NumberFormat("id-ID").format(
                                  Number(parseInt(product?.product?.price)).toFixed()
                                )
                              }}</s
                            ></small
                          >
                        </div>
                      </div>
                      <div v-else-if="datapromo(product?.product).length > 0">
                        <h6 class="text-danger">
                          Rp
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(
                                parseInt(datapromo(product?.product)[0]?.price_promo)
                              ).toFixed()
                            )
                          }}
                        </h6>
                        <div class="diskon">
                          <p>{{ parseInt(datapromo(product?.product)[0]?.discount) }}%</p>
                          <small
                            ><s
                              >Rp
                              {{
                                new Intl.NumberFormat("id-ID").format(
                                  Number(parseInt(product?.product?.price)).toFixed()
                                )
                              }}</s
                            ></small
                          >
                        </div>
                      </div>
                      <h6 class="text-danger" v-else>
                        Rp
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(parseInt(product?.product?.price)).toFixed()
                          )
                        }}
                      </h6>
                    </td>
                  </tr>
                  <tr :key="'total' + index" class="border-subtotal">
                    <td colspan="3">
                      <div
                        v-if="product?.note == 'null'"
                        class="bottom-product catatan-bottom"
                      >
                        <b-button
                          class="catatan"
                          v-b-modal.modal-catatan
                          @click="
                            modaldata(product.note, index)
                          "
                        >
                          Tulis Catatan
                        </b-button>
                      </div>
                      <div v-else-if="product?.note">
                        <span class="bottom-left d-flex" v-if="product?.note.length > 20">
                          <p class="bottom-product catatan-bottom">
                            {{ product?.note.substring(0, 20) + "..." }}
                            <b
                              ><span class="readmore" @click.prevent="modalnote(product,index)"
                                >Selengkapnya</span
                              ></b
                            >
                            <b-button
                              class="catatan"
                              v-b-modal.modal-catatan
                              @click="modaldata(product.note, index)"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                style="transform: ; msfilter: "
                              >
                                <path
                                  d="M4 21a1 1 0 0 0 .24 0l4-1a1 1 0 0 0 .47-.26L21 7.41a2 2 0 0 0 0-2.82L19.42 3a2 2 0 0 0-2.83 0L4.3 15.29a1.06 1.06 0 0 0-.27.47l-1 4A1 1 0 0 0 3.76 21 1 1 0 0 0 4 21zM18 4.41 19.59 6 18 7.59 16.42 6zM5.91 16.51 15 7.41 16.59 9l-9.1 9.1-2.11.52z"
                                ></path>
                              </svg>
                            </b-button>
                          </p>
                        </span>
                        <span
                          class="bottom-left d-flex bottom-product catatan-bottom"
                          v-else-if="product?.note.length < 20"
                        >
                          <p>
                            {{ product?.note }}
                            <b-button
                              class="catatan px-1 py-1"
                              v-b-modal.modal-catatan
                              @click="modaldata(product.note, index)"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                style="transform: ; msfilter: "
                              >
                                <path
                                  d="M4 21a1 1 0 0 0 .24 0l4-1a1 1 0 0 0 .47-.26L21 7.41a2 2 0 0 0 0-2.82L19.42 3a2 2 0 0 0-2.83 0L4.3 15.29a1.06 1.06 0 0 0-.27.47l-1 4A1 1 0 0 0 3.76 21 1 1 0 0 0 4 21zM18 4.41 19.59 6 18 7.59 16.42 6zM5.91 16.51 15 7.41 16.59 9l-9.1 9.1-2.11.52z"
                                ></path>
                              </svg>
                            </b-button>
                          </p>
                        </span>
                      </div>
                      <div v-if="!product?.note" class="bottom-product catatan-bottom">
                        <b-button
                          class="catatan"
                          v-b-modal.modal-catatan
                          @click="modaldata(product.note, index)"
                        >
                          Tulis Catatan
                        </b-button>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 subtotal px-3 py-2 mb-3 mb-lg-4">
                        <h6>Subtotal</h6>
                      </div>
                    </td>
                    <td>
                      <div class="w-100 subtotal mb-3 mb-lg-4">
                        <h6
                          class="text-danger"
                          v-if="datawholesale(product?.product, product?.qty)"
                        >
                          Rp
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(
                                parseInt(datawholesale(product?.product, product?.qty)) *
                                  product.qty
                              ).toFixed()
                            )
                          }}
                        </h6>
                        <h6
                          class="text-danger"
                          v-else-if="
                            dataflashsale(product?.product).length > 0 &&
                            product?.qty <=
                              dataflashsale(product?.product)[0]?.ammount_max
                          "
                        >
                          <span>
                            Rp
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(
                                parseInt(dataflashsale(product?.product)[0]?.flashsale_price) * product.qty
                              ).toFixed()
                            )
                          }}
                          </span>
                        </h6>
                        <h6
                          class="text-danger"
                          v-else-if="datapromo(product?.product).length > 0"
                        >
                          <span>
                            Rp
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(
                                parseInt(datapromo(product?.product)[0]?.price_promo) * product.qty
                              ).toFixed()
                            )
                          }}
                          </span>
                        </h6>
                        <h6 class="text-danger" v-else>
                          <span>
                            Rp
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(
                                parseInt(product.product.price) * product.qty
                              ).toFixed()
                            )
                          }}
                          </span>
                        </h6>
                      </div>
                    </td>
                  </tr>
                </template>
              </table>
              <table class="table-product mobile-view">
                <template v-for="(product, index) in dataproduct">
                  <tr class="header-table" :key="'headerproduct' + index">
                    <th class="p-3">Produk</th>
                  </tr>
                  <tr :key="'product' + index">
                    <td colspan="2" class="p-3 td-produk">
                      <div class="d-flex">
                        <figure class="image mx-1">
                          <img
                            :src="$imgProduct + dataimg(product.product)"
                            alt=""
                          />
                        </figure>
                        <div class="mx-1 detail_product mobile_product_detail">
                          <h6>{{ product.product.product.name }}</h6>
                          <div v-if="datawholesale(product?.product, product?.qty)">
                            <h6>
                              {{ product.qty }}
                              {{ product?.product?.product?.unit?.name }}
                              <small>X</small> <span class="text-danger">
                                Rp
                              {{
                                new Intl.NumberFormat("id-ID").format(
                                  Number(
                                    parseInt(
                                      datawholesale(product?.product, product?.qty)
                                    )
                                  ).toFixed()
                                )
                              }}
                              </span>
                            </h6>
                          </div>
                          <div
                            v-else-if="
                              dataflashsale(product?.product).length > 0 &&
                              product?.qty <=
                                dataflashsale(product?.product)[0]?.ammount_max
                            "
                          >
                            <div>
                              <h6>
                                {{ product.qty }}
                                {{ product?.product?.product?.unit?.name }}
                                <small>X</small> <span class="text-danger">
                                  Rp
                                {{
                                  new Intl.NumberFormat("id-ID").format(
                                    Number(
                                      parseInt(
                                        dataflashsale(product?.product)[0]
                                          ?.flashsale_price
                                      )
                                    ).toFixed()
                                  )
                                }}
                                </span>
                              </h6>
                              <div>
                                <badge class="bg-danger text-white badge-diskon">{{ parseInt(dataflashsale(product?.product)[0]?.discount) }}%</badge>
                              <small class="text-secondary"
                                ><s
                                  >Rp
                                  {{
                                    new Intl.NumberFormat("id-ID").format(
                                      Number(parseInt(product?.product?.price)).toFixed()
                                    )
                                  }}</s
                                ></small
                              >

                              </div>
                            </div>
                          </div>
                          <div v-else-if="datapromo(product?.product).length > 0">
                            <div>
                              <h6>
                                {{ product.qty }}
                                {{ product?.product?.product?.unit?.name }}
                                <small>X</small> <span class="text-danger">
                                  Rp
                                {{
                                  new Intl.NumberFormat("id-ID").format(
                                    Number(
                                      parseInt(
                                        datapromo(product?.product)[0]?.price_promo
                                      )
                                    ).toFixed()
                                  )
                                }}
                                </span>
                              </h6>
                              <div>
                                <badge class="bg-danger text-white badge-diskon">{{ parseInt(datapromo(product?.product)[0]?.discount) }}%</badge>
                              <small class="text-secondary"
                                ><s 
                                  >Rp
                                  {{
                                    new Intl.NumberFormat("id-ID").format(
                                      Number(parseInt(product?.product?.price)).toFixed()
                                    )
                                  }}</s
                                ></small
                              >

                              </div>
                            </div>
                          </div>
                          <h6 v-else>
                            {{ product.qty }} {{ product?.product?.product?.unit?.name }}
                            <small>X</small> <span class="text-danger">
                              Rp
                            {{
                              new Intl.NumberFormat("id-ID").format(
                                Number(parseInt(product?.product?.price)).toFixed()
                              )
                            }}
                            </span>
                          </h6>
                          <!-- <h6 v-if="datawholesale(product?.product, product?.qty)">
                            Rp
                            {{
                              new Intl.NumberFormat("id-ID").format(
                                Number(
                                  parseInt(
                                    datawholesale(product?.product, product?.qty)
                                  ) * product.qty
                                ).toFixed()
                              )
                            }}
                          </h6>
                          <h6
                            v-else-if="
                              dataflashsale(product?.product).length > 0 &&
                              product?.qty <=
                                dataflashsale(product?.product)[0]?.ammount_max
                            "
                          >
                            Rp
                            {{
                              new Intl.NumberFormat("id-ID").format(
                                Number(
                                  parseInt(
                                    dataflashsale(product?.product)[0]?.flashsale_price
                                  ) * product.qty
                                ).toFixed()
                              )
                            }}
                          </h6>
                          <h6 v-else-if="datapromo(product?.product).length > 0">
                            Rp
                            {{
                              new Intl.NumberFormat("id-ID").format(
                                Number(
                                  parseInt(datapromo(product?.product)[0]?.price_promo) *
                                    product.qty
                                ).toFixed()
                              )
                            }}
                          </h6>
                          <h6 v-else>
                            Rp
                            {{
                              new Intl.NumberFormat("id-ID").format(
                                Number(
                                  parseInt(product.product.price) * product.qty
                                ).toFixed()
                              )
                            }}
                          </h6> -->
                        </div>
                      </div>
                      <div>
                        <small><b>Variant: </b><span v-if="product.product?.second_variant_option?.option">{{ product.product?.first_variant_option?.option }} ,
              {{ product.product?.second_variant_option?.option }}</span><span v-else-if="!product.product?.second_variant_option?.option">{{ product.product?.first_variant_option?.option }}</span></small>
                      </div>
                    </td>
                  </tr>
                  <tr :key="'total' + index" class="border-subtotal">
                    <td colspan="2">
                      <div
                        v-if="product?.note == 'null'"
                        class="bottom-product catatan-bottom"
                      >
                        <b-button
                          class="catatan"
                          v-b-modal.modal-catatan
                          @click="
                            modaldata(product.note, index)
                          "
                        >
                          Tulis Catatan
                        </b-button>
                      </div>
                      <div v-else-if="product?.note">
                        <span class="bottom-left d-flex" v-if="product?.note.length > 20">
                          <p class="bottom-product catatan-bottom">
                            {{ product?.note.substring(0, 20) + "..." }}
                            <b
                              ><span class="readmore" @click.prevent="modalnote(product,index)"
                                >Selengkapnya</span
                              ></b
                            >
                            <b-button
                              class="catatan"
                              v-b-modal.modal-catatan
                              @click="modaldata(product.note, index)"
                            >
                              ubah
                            </b-button>
                          </p>
                        </span>
                        <span
                          class="bottom-left d-flex bottom-product catatan-bottom"
                          v-else-if="product?.note.length < 20"
                        >
                          <p>
                            {{ product?.note }}
                            <b-button
                              class="catatan px-1 py-1"
                              v-b-modal.modal-catatan
                              @click="modaldata(product.note, index)"
                            >
                              ubah
                            </b-button>
                          </p>
                        </span>
                      </div>
                      <div v-if="!product?.note" class="bottom-product catatan-bottom">
                        <b-button
                          class="catatan"
                          v-b-modal.modal-catatan
                          @click="modaldata(product.note, index)"
                        >
                          Tulis Catatan
                        </b-button>
                      </div>
                    </td>
                  </tr>
                </template>
              </table>
              <b-modal id="modal-catatan" title="Catatan Produk" hide-footer>
                <form @submit.prevent="updatenote(dataIndex, true)">
                  <div class="form-group">
                    <label for="catatan">Catatan</label>
                    <textarea
                      class="form-control"
                      v-model="catatan"
                      id="catatan"
                      rows="3"
                    ></textarea>
                  </div>
                  <div class="d-flex mt-3 float-end">
                    <span class="btn btn-secondary" @click="cancelmodal()"> Batal </span>
                    <button class="btn btn-primary mx-2">Simpan</button>
                  </div>
                </form>
              </b-modal>
            </div>
            <div
              class="ringkasan card-pembayaran card-checkout py-4 mt-3 mx-3 mt-md-4 pb-3"
            >
              <div class="disabeld-card" v-if="delivery_type == ''"></div>
              <h5>Metode Pembayaran</h5>
              <select class="form-control" id="selectekspedisi" v-model="pembayaran">
                <option value="" disabled selected>Pembayaran</option>
                <option selected="cod" value="cod">COD</option>
                <option selected="pembayaran_digital" value="payment_gateway">
                  Pembayaran Digital
                </option>
                <!-- <option selected="payment_gateway" disabled value="payment_gateway">Payment Gateway</option> -->
              </select>
            </div>
            <div class="ringkasan mt-3 mt-md-4 card-checkout">
              <div class="disabeld-card" v-if="delivery_type == ''"></div>
              <h5 class="px-3">Ringkasan Belanja</h5>
              <div class="m-md-3">
                <div class="card-ringkasan">
                  <table class="table-ringkasan-belanja border-0">
                    <tr>
                      <th class="border-0 p-1">Total Harga {{ total_qty }} Barang</th>
                      <td class="border-0 p-1 text-danger">
                        Rp.
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(total_price).toFixed()
                          )
                        }}
                      </td>
                    </tr>
                    <!-- <tr>
                                            <th class="border-0 p-1">Total PPN (11%)</th>
                                            <td class="border-0 p-1">Rp. {{new Intl.NumberFormat('id-ID').format(Number(total_price*(11/100)).toFixed())}}</td>
                                        </tr> -->
                    <!-- <tr>
                                            <th class="border-0 p-1">Biaya Admin</th>
                                            <td class="border-0 p-1 text-danger">Rp. 1000</td>
                                        </tr> -->
                    <tr>
                      <th class="p-left-1">
                        <small class="text-danger">Harga sudah termasuk pajak*</small>
                      </th>
                    </tr>
                    <tr v-if="delivery_type == 'home_delivery'">
                      <th class="p-left-1 px-2">Ongkir</th>
                      <td class="text-danger">
                        Rp
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(harga_ongkir).toFixed()
                          )
                        }}
                      </td>
                    </tr>
                    <tr class="total-ringkasan-belanja border-top">
                      <th class="border-0 p-1">Total Tagihan</th>
                      <th
                        class="border-0 p-1 text-danger"
                        v-if="harga_ongkir <= 0 && delivery_type == 'home_delivery'"
                      >
                        <span class="text-danger"
                          >Rp.
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(total_price).toFixed()
                            )
                          }}</span
                        >
                      </th>
                      <th
                        class="border-0 p-1 text-danger"
                        v-else-if="harga_ongkir > 0 && delivery_type == 'home_delivery'"
                      >
                        <span class="text-danger"
                          >Rp.
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(total_price + harga_ongkir).toFixed()
                            )
                          }}</span
                        >
                      </th>
                      <th
                        class="border-0 p-1 text-danger"
                        v-else-if="delivery_type != 'home_delivery'"
                      >
                        <span class="text-danger"
                          >Rp.
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(total_price).toFixed()
                            )
                          }}</span
                        >
                      </th>
                      <th class="border-0 p-1 text-danger" v-else>
                        <span class="text-danger"> Rp. 0</span>
                      </th>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="w-100 container ringkasan mt-3 mt-lg-4">
              <div class="float-right">
                <button
                  :disabled="loading ? true : false"
                  class="btn btn-primary"
                  id="btn_checkout"
                  @click.prevent="Checkoudata()"
                >
                  Buat Pesanan
                </button>
              </div>
            </div>
            <b-modal id="modal-loading" centered hide-footer hide-header>
              <div class="text-center">
                <b-spinner variant="light"></b-spinner>
              </div>
            </b-modal>
          </div>
        </div>
        <div class="col-lg-4 col-xl-3">
          <div class="card-ringkasan-kanan card-checkout">
            <div class="disabeld-card" v-if="delivery_type == ''"></div>
            <!-- <h5><b>Metode Penerimaan</b></h5> -->
            <!-- <select
              class="form-control mb-3"
              id="selectekspedisi"
              v-model="delivery_type"
            >
              <option value="" disabled selected>Penerimaan</option>
              <option value="self_pickup" selected="self_pickup">
                Pengambilan Sendiri
              </option>
              <option selected="home_delivery" value="home_delivery">
                Dikirim Oleh Kurir
              </option>
            </select> -->
            <h5><b>Metode Pembayaran</b></h5>
            <select class="form-control" id="selectekspedisi" v-model="pembayaran">
              <option value="" disabled selected>Pembayaran</option>
              <option selected="cod" value="cod">COD</option>
              <option selected="pembayaran_digital" value="payment_gateway">
                Pembayaran Digital
              </option>
              <!-- <option selected="payment_gateway" disabled value="payment_gateway">Payment Gateway</option> -->
            </select>
          </div>
          <div class="card-ringkasan-kanan card-checkout mt-3">
            <div class="disabeld-card" v-if="delivery_type == ''"></div>
            <h5><b>Ringkasan Belanja</b></h5>
            <table class="w-100 border-0">
              <tr>
                <td class="border-0 p-1">Total Harga {{ total_qty }} Barang</td>
                <td class="border-0 p-1 text-danger">
                  Rp.
                  {{
                    new Intl.NumberFormat("id-ID").format(Number(total_price).toFixed())
                  }}
                </td>
              </tr>
              <!-- <tr>
                                <td class="border-0 p-1">Total PPN (11%)</td>
                                <td class="border-0 p-1">Rp. {{new Intl.NumberFormat('id-ID').format(Number(total_price*(11/100)).toFixed())}}</td>
                            </tr> -->
              <!-- <tr>
                                <td class="border-0 p-1">Biaya admin</td>
                                <td class="border-0 p-1 text-danger">Rp. 1000</td>
                            </tr> -->
              <tr v-if="delivery_type == 'home_delivery'">
                <td class="border-0">Ongkir</td>
                <td class="border-0 text-danger">
                  Rp
                  {{
                    new Intl.NumberFormat("id-ID").format(Number(harga_ongkir).toFixed())
                  }}
                </td>
              </tr>
              <tr class="total-ringkasan-belanja-kanan border-top">
                <td class="border-0" colspan="2">
                  <small class="text-danger">Harga sudah termasuk pajak*</small>
                  <p class="mt-3 grand-total"><b>Total Tagihan</b></p>
                </td>
              </tr>
              <tr>
                <th class="border-0 px-4" colspan="2" v-if="total_price > 0">
                  <h5 class="text-danger" v-if="harga_ongkir > 0 && delivery_type == 'home_delivery'">
                    <b
                      >Rp.
                      {{
                        new Intl.NumberFormat("id-ID").format(
                          Number(total_price + harga_ongkir).toFixed()
                        )
                      }}</b
                    >
                  </h5>
                  <h5 class="text-danger" v-else-if="delivery_type != 'home_delivery'">
                    <b
                      >Rp.
                      {{
                        new Intl.NumberFormat("id-ID").format(
                          Number(total_price).toFixed()
                        )
                      }}</b
                    >
                  </h5>
                  <h5 class="text-danger" v-else>
                    <b
                      >Rp.
                      {{
                        new Intl.NumberFormat("id-ID").format(
                          Number(total_price).toFixed()
                        )
                      }}</b
                    >
                  </h5>
                </th>
                <th class="border-0 px-4" colspan="2" v-else>
                  <h5 class="text-danger"><b>Rp. 0</b></h5>
                </th>
              </tr>
            </table>
            <div class="w-100 ringkasan-kanan mt-2">
              <button
                :disabled="loading ? true : false"
                class="btn btn-primary w-100"
                id="btn_checkout"
                @click.prevent="Checkoudata()"
              >
                Buat Pesanan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="ModalAlamat" title="Alamat" hide-footer>
      <div
        :class="dataaddress?.id == addressdata?.id ? 'card-active' : ''"
        class="card card-container p-3 mt-3"
        v-for="(addressdata, index) in address"
        :key="index"
        @click="pickalamat(addressdata)"
      >
        <h5>{{ addressdata?.receipt_name }}</h5>
        <h6>{{ addressdata?.receipt_phone_number }}</h6>
        <p>
          {{ addressdata?.address }} , <br />
          {{ addressdata?.sub_district?.subdistrict_name }} ,{{
            addressdata?.district?.city_name
          }}
          <br />
          ,{{ addressdata?.province?.province_name }} ,{{
            addressdata?.district?.postal_code
          }}
        </p>
      </div>
      <router-link to="/profil/tambah-alamat?back=checkout"
        class="card p-5 card-container newalamat border-dashed card-add mt-2"
        @click="alamatbaru()"
      >
        <h5 class="text-center w-100">+ Alamat</h5>
      </router-link>
    </b-modal>
    <b-modal id="modal-read" hide-footer scrollable title="Detail Catatan">
      {{ modal_note }}
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
import '../../assets/css/sweetalertstyle.css'
import useVuelidate from "@vuelidate/core";
import { required, helpers, numeric } from "@vuelidate/validators";
import SkeletonCheckout from "../Skeleton/Cart/SkeletonCheckout.vue";
import Loader from "@/components/Loader/Loader.vue";

export default {
  data() {
    return {
      dataIndex:'',
      datacart: [],
      branch: [],
      pembayaran: "",
      harga_ongkir: 0,
      massage_ongkir: "",
      delivery_type: "",
      catatan: "",
      databranch: [],
      modal_note: "",
      loading: true,
      notFound: true,
      dataproduct: [],
      provinces: [],
      districts: [],
      sub_districts: [],
      ekspedisidata: [],
      listExpedisi: [],
      listExpedisiService: [],
      detailEkspedisi: [],
      dataaddress: {},
      dataCarts: [],
      dataShippingCost: [],
      total_price: 0,
      total_qty: 0,
      loadingCart: true,
      formPartial: [],
      productDetail: [],
      listCheckOutDetail: [],
      ringkasan: {
        total_price: 0,
        total_qty: 0,
        shipping_cost: 0,
        total_ppn: 0,
        grand_total: 0,
      },
      courier: "",
      service: "",
      customer_id: this.$store.state.user?.id,
      address_id: "",
      biayaOngkir: 0,
      loadingLayanan: false,
      messageErrorPengajuan: "",
      loadingPengajuan: false,
      messageErrorModal: "",
      errorPartialId: [],
      data_partial: "",
      estimate: "",
      checked: false,
      dataqty: [],
      markers: [],
    };
  },

  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      receipt_name: {
        required: helpers.withMessage("Nama penerima tidak boleh kosong", required),
      },
      addressdata: {
        required: helpers.withMessage("Alamat tidak boleh kosong", required),
      },
      province_id: {
        required: helpers.withMessage("Silahkan pilih provinsi", required),
      },
      district_id: {
        required: helpers.withMessage("Silahkan pilih kabupaten", required),
      },
      sub_district_id: {
        required: helpers.withMessage("Silahkan pilih kecamatan", required),
      },
      receipt_phone_number: {
        required: helpers.withMessage("No telepon tidak boleh kosong", required),
        numeric: helpers.withMessage("Pastikan data yang diinput berupa angka", numeric),
      },
    };
  },
  created() {
    // this.getExpedisi()
  },
  async mounted() {
    try {
      this.loading = true;
      if (this.user instanceof Promise == true) {
        await this.$store.dispatch("user");
      }
      const datacart = JSON.parse(localStorage.getItem("cart_id"));
      this.datacart = datacart;
      axios.get("/transaction/cart/list-cart?" + this.cartiddata).then((res) => {
        let dataset = res.data.data;
        if(dataset.length <= 0){
          this.$router.push("/cart");
        }
        this.dataproduct = dataset;
        this.getTotalPrice(dataset);
        this.loading = false;
      });
      if (this.address == [] || this.$store.state.address[0] === undefined) {
        await this.$store.dispatch("address");
      }
      axios.get("/branch").then((response) => {
        this.branch = response.data.data;
      });
      this.address.forEach((data)=>{
        if(data.lat != null){
          this.dataaddress = data
        return false
        }
      })
      // this.dataaddress = this.address[0];
    } catch {
      this.loading = false;
    }
  },
  components: {
    SkeletonCheckout,
    Loader,
  },
  watch: {
    dataaddress() {
      this.checkdelivery();
    },
    databranch() {
      this.checkdelivery();
    },
  },
  computed: {
    dataurl() {
      return this.datacart.map((x) => `cart[]=` + x).join("&");
    },
    cartiddata() {
      return this.datacart.map((x) => `cart_id[]=` + x).join("&");
    },
    user: function () {
      return this.$store.state.user;
    },
    address: function () {
      return this.$store.state.address;
    },
  },
  methods: {
    setmap(data){
      this.markers =data
    },
    checkdelivery() {
      if (
        this.delivery_type === "home_delivery"
      ) {
        if (Object.keys(this.databranch).length > 0) {
          if (this.dataaddress?.lat != null && this.dataaddress?.long != null) {
            this.massage_ongkir = "";
            let form = new FormData();
            form.append("branch_id", this.databranch?.id);
            form.append("target_lat", this.dataaddress?.lat);
            form.append("target_long", this.dataaddress?.long);
            axios.post(`/transaction/delivery-price`, form).then((res) => {
              this.harga_ongkir = res?.data?.data?.delivery_fee;
            });
          } else {
            this.massage_ongkir = "Alamat yang anda pilih belum menentukan titik di peta";
          }
        } else {
          this.massage_ongkir = "Silahkan memilih cabang pengantar terlebih dahulu";
        }
      }
    },
    dataimg(value) {
      var valueimage = value.product.image
      let dataimg = valueimage.filter((el)=>{
        if(el.product_price_id == value.id){
          return el
        }
      })
      if(dataimg.length > 0){
        return dataimg[0]?.image
      }else{
         var img_default =  value?.product?.image.filter((item)=>{
            if(item.default_image){
                return item
            }
        })
        return img_default[0]?.image
        
      }
    
    },
    modalnote(value,index) {
        this.dataIndex = index;
      if(value.note != null && value.note != 'null'){
        this.modal_note = value.note;
      }else{
        this.modal_note = ''
      }
      this.$bvModal.show("modal-read");
      // this.idmodal = id
    },
    datastring(value) {
      var stringdata = value?.substring(0, 5);
      return stringdata;
    },
    dataflashsale(data) {
      var dataflashsale = data?.flashsale?.filter((element) => {
        let checkflashsale = element?.flashsale != null;
        if (checkflashsale) {
          return element?.flashsale_price;
        }
      });
      return dataflashsale;
    },
    datapromo(data) {
      var datapromo = data?.promo?.filter((element) => {
        let checkpromo = element?.promo != null;
        if (checkpromo) {
          return element?.price_promo;
        }
      });
      return datapromo;
    },
    datawholesale(data, jmlh) {
      var wholesale = null;
      data.wholesale?.forEach((element) => {
        if (jmlh >= element?.ammount_start) {
          wholesale = element?.wholesales_price;
        }
      });
      return wholesale;
    },
    alamatbaru() {
      this.$bvModal.hide("ModalAlamat");
    },
    pickaddress(data) {
      this.databranch = data;
      this.checkdelivery();
      this.courier = "";
      this.service = "";
      this.biayaOngkir = 0;
      this.$bvModal.hide("ModalCabang");
    },
    pickalamat(data) {
      this.dataaddress = data;
      this.$bvModal.hide("ModalAlamat");
    },
    getTotalPrice(dataproduct) {
      var data_total = 0;
      dataproduct.forEach((data) => {
        if (this.datawholesale(data?.product, data?.qty)) {
          data_total =
            data_total +
            Number(data?.qty) * Number(this.datawholesale(data?.product, data?.qty));
        } else if (
          this.dataflashsale(data?.product).length > 0 &&
          data?.qty <= this.dataflashsale(data?.product)[0]?.ammount_max
        ) {
          data_total =
            data_total +
            Number(data?.qty) *
              Number(this.dataflashsale(data?.product)[0]?.flashsale_price);
        } else if (this.datapromo(data?.product).length > 0) {
          data_total =
            data_total +
            Number(data?.qty) *
              Number(this.datapromo(data?.product)[0]?.price_promo);
          
        } else {
          data_total = data_total + Number(data?.qty) * Number(data?.product?.price);
        }
      });
      this.total_price = data_total;
      dataproduct.forEach((data) => {
        this.total_qty = this.total_qty + data.qty;
      });
    },
    modaldata: function (data, index) {
        this.dataIndex = index;
      if(data != null && data != 'null'){
      this.datamodal = data;
      this.catatan = data
      }else{
        this.catatan = ''
        this.datamodal = ''
      }
      
    },
    cancelmodal() {
      this.$bvModal.hide("modal-catatan");
    },
    async updatenote(index, isnote) {
      let cart = this.dataproduct[index];
      let data = new FormData();
      if (isnote) {
        if (this.catatan != "") {
          data.append("note", this.catatan);
        } else if(this.catatan == null) {
          data.append("note", " ");
        } else {
          data.append("note", " ");
        }
      }

      data.append("_method", "PUT");
      this.loading = true;
      try {
      await axios.post("/transaction/cart/update-note/" + cart.id, data).then((res)=>{
          this.dataproduct[index].note = res.data.data.note;
          if (isnote) {
          this.loading = false;
          swal("Berhasil!!!", "Catatan telah diperbaharui", "success", {
            button: "OK",
          });
        }
        }).finally(()=>{
          this.loading = false
        })
        
        
          this.loading = false;
        this.$bvModal.hide("modal-catatan");
        // this.$store.dispatch("carts");
      } catch {
        return;
      }
      // alert(err.response.data.messag
    },
    resetForm() {
      this.receipt_name = "";
      this.receipt_phone_number = "";
      this.addressdata = "";
      this.name = "";
      this.province_id = "";
      this.district_id = "";
      this.sub_district_id = "";
    },

    getDetailExpedisi() {
      if (this.dataaddress != undefined) {
        this.service = "";
        this.listExpedisiService = [];
        this.loadingLayanan = true;
        let data = new FormData();
        data.append("customer_address_id", this.dataaddress.id);
        data.append("courier", this.courier);
        axios
          .post("/transaction/cart/get-shipping-cost?" + this.cartiddata, data)
          .then((res) => {
            this.loadingLayanan = false;
            this.listExpedisiService = res.data.data[0]?.[0].costs;
            this.detailEkspedisi = res.data.data;
            this.estimate = res.data.data[0]?.[0].costs[0]?.cost[0].etd;
          })
          .catch(() => {
            this.loading = false;
            this.notFound = true;
          });
      } else {
        this.courier = "";
        swal("Ooops", "Mohon menambahkan alamat terlebih dahulu", "error", {
          button: "OK",
        });
      }
    },
    getOngkirPrice() {
      let layanan = this.service;
      this.loadingLayanan = true;
      let totalOngkir = 0;
      let dataShippingCost = [];
      for (let i = 0; i < Object.keys(this.listExpedisiService).length; i++) {
        if (this.listExpedisiService[i].service == layanan) {
          totalOngkir += Number(this.listExpedisiService[i].cost[0].value);

          dataShippingCost.push({
            shipping_cost: Number(this.listExpedisiService[i].cost[0].value),
            etd: this.listExpedisiService[i].cost[0].etd,
          });
        }
      }
      this.dataShippingCost = dataShippingCost;
      //   this.storeOngkir(dataShippingCost)
      this.biayaOngkir = totalOngkir;
      setTimeout(() => {
        this.loadingLayanan = false;
      }, 2000);
    },

    Checkoudata() {
      if (this.delivery_type != "") {
        if (this.delivery_type == "home_delivery" && this.address?.length > 0) {
          if (this.massage_ongkir == "") {
            this.prosesCheckout();
          } else {
            swal("Ooops", this.massage_ongkir, "error", {
              button: "OK",
            });
          }
        } else if (this.delivery_type == "home_delivery" && this.address?.length <= 0) {
          swal("Ooops", "Mohon memasukkan alamat terlebih dahulu", "error", {
            button: "OK",
          });
        } else if (this.delivery_type == "self_pickup") {
          this.prosesCheckout();
        }
      } else {
        swal("Ooops", "Mohon memilih metode penerimaan terlebih dahulu", "error", {
          button: "OK",
        });
      }
    },
    prosesCheckout() {
      if (this.pembayaran == "cod") {
        if (this.databranch?.id != null && this.databranch?.id != undefined) {
          let data = new FormData();

          this.datacart.forEach((dataid) => {
            data.append("cart_id[]", dataid);
          });
          data.append("branch_id", this.databranch?.id);
          data.append("payment_type", this.pembayaran);
          data.append("delivery_type", this.delivery_type);
          if (this.delivery_type == "home_delivery") {
            data.append("customer_address_id", this.dataaddress?.id);
          }
          this.loading = true;

          axios
            .post("/transaction/create", data)
            .then((res) => {
              this.loading = false;
              this.ringkasan = res.data.data;
              this.$cookies.set("id_checkout", this.ringkasan.id);
              this.$store.dispatch("carts");

              swal(
                "Berhasil!!!",
                "Selamat pesanan anda telah dibuat",
                "success",
                {
                  button: "OK",
                }
              ).then(() => {
                this.$router.push("/invoice");
              });
              // this.getDetailCheckout(this.cartiddata)
            })
            .catch((err) => {
              this.loading = false;
              if(err.response.data.message == 'error'){
                swal("Ooops", "Produk yang anda pilih saat ini stocknya sedang kosong", "error", {
                button: "OK",
              });
              }else{
                swal("Ooops", err.response.data.message, "error", {
                button: "OK",
              });
              }
              
            });
        } else {
          swal("Ooops", "Mohon memilih cabang terlebih dahulu", "error", {
            button: "OK",
          });
        }
      } else if (this.pembayaran == "payment_gateway") {
        if (this.databranch?.id != null && this.databranch?.id != undefined) {
          let data = new FormData();
          this.datacart.forEach((dataid) => {
            data.append("cart_id[]", dataid);
          });
          data.append("branch_id", this.databranch?.id);
          data.append("payment_type", this.pembayaran);
          data.append("delivery_type", this.delivery_type);
          if (this.delivery_type == "home_delivery") {
            data.append("customer_address_id", this.dataaddress?.id);
          }
          this.loading = true;

          axios
            .post("/transaction/create", data)
            .then((res) => {
              this.ringkasan = res.data.data;
              this.$cookies.set("id_checkout", this.ringkasan.id);
              this.$store.dispatch("carts");

              let dataform = new FormData();
              dataform.append("transaction_id", res.data?.data?.id);
              dataform.append("payment_success_url", window.location.origin + "/invoice");
              dataform.append("payment_failed_url", window.location.origin);

              axios.post("/transaction/payment", dataform).then((respon) => {
                window.location.href = respon?.data?.data?.url_payment;
              }).catch((err) => {
              this.loading = false;
              swal("Ooops", err.response.data.message, "error", {
                button: "OK",
              });
            });
            })
            .catch((err) => {
              this.loading = false;
              swal("Ooops", err.response.data.message, "error", {
                button: "OK",
              });
            });
        } else {
          swal("Ooops", "Mohon memilih cabang terlebih dahulu", "error", {
            button: "OK",
          });
        }
      } else {
        swal("Ooops", "Mohon memilih pembayaran terlebih dahulu", "error", {
          button: "OK",
        });
      }
    },
  },
};
</script>
<style scoped>
::v-deep #modal-loading .modal-content {
  background-color: transparent;
  border: 0px !important;
}
.newalamat{
  text-decoration: none !important;
}
.badge-diskon{
  font-size: 0.6rem !important;
  padding: 3px;
  border-radius: 3px;
}
.card-checkout {
  position: relative !important;
}
.disabeld-card {
  position: absolute;
  z-index: 150;
  background-color: rgba(195, 195, 195, 0.4);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  border-radius: 5px;
}
.p-left-1 {
  padding-left: 0.5rem;
}
.card-add {
  cursor: pointer !important;
}
.border-dashed {
  border: 1px dashed #acacac;
}
.border-dashed:hover {
  background-color: #e9e9e9;
  border: 1px solid #acacac;
}
.mobile-view button {
  font-size: 0.8rem !important;
}
.bottom-product .catatan {
  background-color: #fff;
  border: 0px !important;
  fill: #1f7acf;
  color: #1f7acf;
}
.bottom-product .catatan:hover {
  background-color: #fff;
  border: 0px !important;
  fill: #1f7acf;
  color: #1f7acf;
}
.mobile-view {
  display: table;
}
.web-view {
  display: none;
}
.btn-middle {
  display: block;
  margin: 2rem auto;
}
.grand-total {
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 0.667);
}
.btn-alamat {
  display: block;
  margin: 0 auto;
}
::v-deep .modal-header button {
  background-color: #fff !important;
  border: 0px !important;
}

.harga_data h6 {
  font-weight: 600;
}
.diskon {
  display: flex;
}
.diskon p {
  color: #fff;
  font-size: 8px !important;
  padding: 3px;
  border-radius: 3px;
  background-color: red;
}
.diskon s {
  color: gray;
  font-size: 0.8rem !important;
  padding-left: 5px;
  text-decoration: line-through !important;
}
.float-right button {
  justify-content: end;
  display: flex;
  float: right;
}
.btn-outline-dark {
  background-color: #fff;
}
.btn-outline-dark:hover {
  background-color: #fff;
  color: #000 !important;
}
.card-active {
  border: 2px solid red;
}
.datatable {
  width: 100%;
  overflow-x: auto;
}
.table-product {
  position: relative;
  width: 100%;
}
.table-product tr {
  background-color: transparent !important;
}
.header-table::before {
  content: "";
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  z-index: -10;
  height: 3.5rem;
  position: absolute;
}
.header-checkout {
  height: 9rem;
}
.card-header {
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  background-color: transparent !important;
}
.card-header h6 {
  font-weight: 600;
}
.card-content h5 {
  font-weight: 600;
}
.card-content h6 {
  font-weight: 550;
}
.card-content p {
  color: rgba(0, 0, 0, 0.4);
}
.card-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  background-color: transparent !important;
}
.product-data {
  overflow-x: auto;
}
.headcart {
  text-align: left;
  font-weight: 600;
  color: #000;
}
.product-cart {
  overflow-x: auto;
}
.image {
  max-width: 50px;
  max-height: 50px;
  overflow: hidden;
  border-radius: 5px;
}
.image img {
  width: 100%;
}
.mobile_product_detail p {
  margin-bottom: 0.5rem;
}
.mobile_product_detail h6 {
  margin-bottom: 0px !important;
}
.detail_product h6 {
  font-weight: 600;
}
.border-subtotal {
  border-top: 1px solid #000;
  position: relative;
}
/* .border-subtotal td::after{
    top: 0px;
    left: 0px;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-top: 0.2px solid rgba(0, 0, 0, 0.3);
} */
.subtotal {
  justify-content: space-between;
  display: flex;
}
.subtotal h6 {
  font-weight: 600;
}
.ringkasan h5 {
  font-weight: 600;
}
.ringkasan-kanan {
  display: none;
}
.card-pembayaran {
  border-radius: 10px;
  padding: 1rem;
  display: block;
  margin: 0 auto;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}
.card-ringkasan {
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
  text-align: right;
  display: flex;
  justify-content: end;
}

.table-ringkasan-belanja {
  width: 100%;
}

.table-ringkasan-belanja th {
  text-align: left;
}

.total-ringkasan-belanja th {
  border-top: 1px solid var(--primary-color);
  font-size: 1.25rem;
}

.total-ringkasan-belanja th:nth-child(2) {
  text-align: right;
}
.card-ringkasan-kanan {
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
  display: none;
}
.card-ringkasan-kanan table td {
  font-size: 14px;
}

.table-ringkasan-belanja-kanan {
  width: 100%;
}

.table-ringkasan-belanja-kanan th {
  text-align: left;
}

.total-ringkasan-belanja-kanan th {
  border-top: 1px solid var(--primary-color);
  font-size: 1.25rem;
}

.total-ringkasan-belanja-kanan th:nth-child(2) {
  text-align: right;
}
@media (max-width: 500px) {
  h6,
  p {
    font-size: 0.85rem !important;
  }
  h5 {
    font-size: 1rem !important;
  }
  .mb-3-mobile {
    margin-bottom: 1rem !important;
  }
  .card-header button {
    font-size: 0.85rem !important;
  }
  .td-produk {
    min-width: 250px;
    max-width: 350px;
  }
  .td-nonproduk {
    min-width: 150px;
    max-width: 225px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .table-ringkasan-belanja th,
  td {
    font-size: 0.85rem;
  }
  #btn_checkout {
    font-size: 0.85rem;
  }
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}
@media (min-width: 576px) {
  .table-ringkasan-belanja {
    width: 70%;
  }
}

@media (min-width: 767px) {
  .mobile-view {
    display: none;
  }
  .web-view {
    display: table;
  }
  .card-header {
    display: flex;
  }
  .header-product {
    display: block;
  }
  .table-ringkasan-belanja {
    width: 50%;
  }
  .card-ringkasan {
    padding: 1.75rem;
  }
}

@media (min-width: 1023px) {
  .card-ringkasan-kanan {
    display: block;
  }
  .ringkasan {
    display: none;
  }
  .ringkasan-kanan {
    display: block;
  }
  .card-ringkasan {
    display: none;
  }
}

@media (min-width: 1439px) {
  .header-price {
    text-align: right !important;
  }
  .header-quantity {
    text-align: right !important;
  }
}

@media (min-width: 2000px) {
}
</style>
