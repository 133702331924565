<template>
  <div>
    <SkeletonDetail v-if="loading" />
    <div class="container" v-if="!loading">
      <div class="row">
        <div class="col-md-6">
          <div class="dataimg">
            <div class="leftdata">
              <figure v-for="(dataimage, index) in dataimage" :key="index">
                <img
                  v-if="dataimage != undefined"
                  :src="$imgProduct + dataimage?.image"
                  alt="Nature"
                  style="width: 100%"
                  @click="changeImage(index)"
                />
              </figure>
            </div>
            <div class="rightdata">
              <figure>
                <img id="galleryd" :src="$imgProduct + fistimage[0]?.image" alt="" />
              </figure>
              <div class="w-100 d-flex bottom-image">
                <figure v-for="(dataimage, index) in dataimage" :key="index">
                  <img
                    v-if="dataimage != undefined"
                    :src="$imgProduct + dataimage?.image"
                    alt="Nature"
                    style="width: 100%"
                    @click="changeImage(index)"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 detailproduk">
          <h4>{{ dataproduct.name }}</h4>
          <div class="star">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              style="transform: ; msfilter: "
            >
              <path
                d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
              ></path>
            </svg>
            <small
              >{{ datastar(dataproduct?.star_review) }} | Terjual
              {{ datasold(dataproduct?.total_sold) }}</small
            >
          </div>
          <div v-if="priceproduct?.length > 0">
            <div v-if="hargadata" class="harga-Produk mt-3">
              <h6>
                Rp
                {{ new Intl.NumberFormat("id-ID").format(Number(hargadata).toFixed()) }}
              </h6>
            </div>
            <div v-else-if="!hargadata">
              <div
                class="mt-3 harga-Produk"
                v-if="
                this.$store.state.user.name != undefined &&
                  qty <= checkflashsale &&
                  qty <= price_flashsale[0]?.ammount_max
                "
              >
                <h5>
                  <b>
                    Rp
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(parseInt(price_flashsale[0]?.flashsale_price)).toFixed()
                      )
                    }}
                  </b>
                </h5>
                <div class="diskon">
                  <p>{{ parseInt(price_flashsale[0]?.discount) }}%</p>
                  <small
                    ><s
                      >Rp
                      {{
                        new Intl.NumberFormat("id-ID").format(
                          Number(parseInt(priceproduct[0]?.price)).toFixed()
                        )
                      }}</s
                    ></small
                  >
                </div>
              </div>
              <div class="mt-3 harga-Produk" v-else-if="price_promo?.length > 0">
                <h5>
                  <b>
                    Rp
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(parseInt(price_promo[0]?.price_promo)).toFixed()
                      )
                    }}
                  </b>
                </h5>
                <div class="diskon">
                  <p>{{ parseInt(price_promo[0]?.discount) }}%</p>
                  <small
                    ><s
                      >Rp
                      {{
                        new Intl.NumberFormat("id-ID").format(
                          Number(parseInt(priceproduct[0]?.price)).toFixed()
                        )
                      }}</s
                    ></small
                  >
                </div>
              </div>
              <div class="d-flex mt-3 harga-Produk" v-else>
                <h6 v-if="priceproduct[0].price != null">
                  Rp
                  {{
                    new Intl.NumberFormat("id-ID").format(
                      Number(parseInt(priceproduct[0]?.price)).toFixed()
                    )
                  }}
                </h6>
                <h6 v-else>Rp 0</h6>
              </div>
            </div>
          </div>
          <div v-else-if="priceproduct?.length == 0">
            <div
              class="d-flex mt-3 harga-Produk"
              v-if="this.$store.state.user.name != undefined && dataproduct?.price[0]?.flashsale[0]?.flashsale"
            >
              <h6>
                Rp
                {{
                  new Intl.NumberFormat("id-ID").format(
                    Number(
                      parseInt(dataproduct?.price[0]?.flashsale[0]?.flashsale_price)
                    ).toFixed()
                  )
                }}
              </h6>
              <s
                >Rp
                {{
                  new Intl.NumberFormat("id-ID").format(
                    Number(parseInt(dataproduct?.price[0]?.price)).toFixed()
                  )
                }}</s
              >
            </div>
            <div
              class="d-flex mt-3 harga-Produk"
              v-else-if="dataproduct?.price[0]?.promo[0]?.promo"
            >
              <h6>
                Rp
                {{
                  new Intl.NumberFormat("id-ID").format(
                    Number(
                      parseInt(dataproduct?.price[0]?.promo[0]?.price_promo)
                    ).toFixed()
                  )
                }}
              </h6>
              <s
                >Rp
                {{
                  new Intl.NumberFormat("id-ID").format(
                    Number(parseInt(dataproduct?.price[0]?.price)).toFixed()
                  )
                }}</s
              >
            </div>
            <div class="d-flex mt-3 harga-Produk" v-else>
              <h6>
                Rp
                {{
                  new Intl.NumberFormat("id-ID").format(
                    Number(parseInt(dataproduct?.price[0]?.price)).toFixed()
                  )
                }}
              </h6>
            </div>
          </div>
          <hr class="w-100 mt-3" v-if="Object.keys(variant).length > 0" />
          <div v-for="(datavariant, key, dataindex) in variant" :key="key">
            <p class="mt-2 sizetext">{{ key }} :</p>
            <div class="sizeproduk">
              <div v-for="(item, index) in datavariant" :key="index">
                <label
                  :for="'size' + item.id"
                  :class="{ activeSize: picked[dataindex] === item.id }"
                  >{{ item.option }}</label
                >
                <input
                  type="radio"
                  :name="'variant' + item.id"
                  :value="item.id"
                  @change="changepick(item.option)"
                  v-model="picked[dataindex]"
                  :id="'size' + item.id"
                />
              </div>
            </div>
          </div>
          <hr class="w-100 mb-4" v-if="Object.keys(variant).length > 0" />

          <div class="Promo mt-2" v-if="priceproduct?.length > 0">
            <div class="dalam_promo" v-if="priceproduct[0]?.wholesale.length > 0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
              >
                <path
                  d="M22 8a.76.76 0 0 0 0-.21v-.08a.77.77 0 0 0-.07-.16.35.35 0 0 0-.05-.08l-.1-.13-.08-.06-.12-.09-9-5a1 1 0 0 0-1 0l-9 5-.09.07-.11.08a.41.41 0 0 0-.07.11.39.39 0 0 0-.08.1.59.59 0 0 0-.06.14.3.3 0 0 0 0 .1A.76.76 0 0 0 2 8v8a1 1 0 0 0 .52.87l9 5a.75.75 0 0 0 .13.06h.1a1.06 1.06 0 0 0 .5 0h.1l.14-.06 9-5A1 1 0 0 0 22 16V8zm-10 3.87L5.06 8l2.76-1.52 6.83 3.9zm0-7.72L18.94 8 16.7 9.25 9.87 5.34zM4 9.7l7 3.92v5.68l-7-3.89zm9 9.6v-5.68l3-1.68V15l2-1v-3.18l2-1.11v5.7z"
                ></path>
              </svg>
              <p>Produk ini memiliki harga grosir*</p>
              <small
                >cek tautan ini untuk melihat detail dari harga grosir
                <span
                  class="span-grosir"
                  @click.prevent="modalgrosir(priceproduct[0]?.wholesale)"
                  >Klik Disini</span
                ></small
              >
            </div>
            <b-modal id="modal-grosir" hide-footer size="sm" title="Detail Harga Grosir">
              <table class="w-100">
                <tr>
                  <th><small class="color-grey">Kuantitas</small></th>
                  <th><small class="color-grey">Harga Satuan</small></th>
                  <th><small class="color-grey">Hemat</small></th>
                </tr>
                <tr v-for="(grosir, index) in datagrosir" :key="index">
                  <td class="pt-2 pb-2">≥ {{ grosir?.ammount_start }}</td>
                  <td class="pt-2 pb-2 color-orange">
                    Rp
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(parseInt(grosir?.wholesales_price)).toFixed()
                      )
                    }}
                  </td>
                  <td class="pt-2 pb-2">
                    Rp
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(
                          parseInt(priceproduct[0]?.price) - grosir?.wholesales_price
                        ).toFixed()
                      )
                    }}
                  </td>
                </tr>
              </table>
            </b-modal>

            <!-- <div class="dalam_promo mt-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 32 32"
              >
                <path
                  fill="currentColor"
                  d="m16 30l-6.176-3.293A10.982 10.982 0 0 1 4 17V4a2.002 2.002 0 0 1 2-2h20a2.002 2.002 0 0 1 2 2v13a10.982 10.982 0 0 1-5.824 9.707ZM6 4v13a8.985 8.985 0 0 0 4.766 7.942L16 27.733l5.234-2.79A8.985 8.985 0 0 0 26 17V4Z"
                />
                <path
                  fill="currentColor"
                  d="M16 25.277V6h8v10.805a7 7 0 0 1-3.7 6.173Z"
                />
              </svg>
              <p>Proteksi Kerusakan Produk</p>
              <small>Jaminan produk diterima dalam keadaan baik</small>
            </div> -->
          </div>
          <p class="sizetext mt-3">Total Pembelian</p>
          <form @submit.prevent="addCart" class="mt-3">
            <div class="stock mt-2">
              <div class="form-stock">
                <span class="btn btn-primary-outline-disabled" v-if="qty <= 0">-</span>
                <span
                  class="btn btn-primary-outline"
                  v-else-if="qty > 0"
                  @click="minvalue"
                  >-</span
                >
                <input
                  type="text"
                  class="mx-2 px-2 input-produk"
                  id="stock_input"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  v-model="qty"
                />
                <span class="btn btn-primary-outline" @click="addvalue">+</span>
              </div>
            </div>

            <div v-if="priceproduct?.length > 0">
              <p class="m-2">
                Stock: <span>{{ priceproduct[0]?.stock }}</span>
              </p>
            </div>
            <div class="mt-2">
              <small class="text-danger" v-if="price_flashsale?.length > 0 && this.$store.state.user.name != undefined"
                >Harga dapat berubah karena telah melebihi batas maksimum
                pembelian.</small
              >
            </div>
            <div class="w-100 d-flex btn-cart mt-4" v-if="user?.id">
              <div class="w-50">
                <span
                  v-if="submit"
                  @click="addkeranjang"
                  class="btn btn-outline-primary w-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    style="fill: #fff; transform: ; msfilter: "
                  >
                    <path
                      d="M5 22h14c1.103 0 2-.897 2-2V9a1 1 0 0 0-1-1h-3V7c0-2.757-2.243-5-5-5S7 4.243 7 7v1H4a1 1 0 0 0-1 1v11c0 1.103.897 2 2 2zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v1H9V7zm-4 3h2v2h2v-2h6v2h2v-2h2l.002 10H5V10z"
                    ></path>
                  </svg>
                  Keranjang
                </span>
                <span v-else-if="!submit" class="btn btn-outline-primary w-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    style="fill: #fff; transform: ; msfilter: "
                  >
                    <path
                      d="M5 22h14c1.103 0 2-.897 2-2V9a1 1 0 0 0-1-1h-3V7c0-2.757-2.243-5-5-5S7 4.243 7 7v1H4a1 1 0 0 0-1 1v11c0 1.103.897 2 2 2zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v1H9V7zm-4 3h2v2h2v-2h6v2h2v-2h2l.002 10H5V10z"
                    ></path>
                  </svg>
                  Keranjang
                </span>
              </div>
              <div class="w-50">
                <button
                  id="btnbuy"
                  :disabled="loading"
                  class="w-100 btn btn-primary-outline"
                >
                  Beli Sekarang
                </button>
              </div>
            </div>
            <div class="w-100 d-flex btn-cart mt-4" v-else>
              <div class="w-50">
                <router-link to="/login" class="btn btn-outline-primary w-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    style="transform: ; msfilter: "
                  >
                    <path
                      d="M5 22h14c1.103 0 2-.897 2-2V9a1 1 0 0 0-1-1h-3V7c0-2.757-2.243-5-5-5S7 4.243 7 7v1H4a1 1 0 0 0-1 1v11c0 1.103.897 2 2 2zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v1H9V7zm-4 3h2v2h2v-2h6v2h2v-2h2l.002 10H5V10z"
                    ></path>
                  </svg>
                  Keranjang
                </router-link>
              </div>
              <div class="w-50">
                <router-link to="/login" class="w-100 btn btn-primary"
                  >Beli Sekarang</router-link
                >
              </div>
            </div>
          </form>
        </div>
      </div>
      <hr class="w-100" />
      <div class="container mt-3 deskripsi-produk">
        <h6>Deskripsi</h6>
        <p v-html="dataproduct.descriptions"></p>
      </div>
      <b-modal id="modal-loading" centered hide-footer hide-header>
        <div class="text-center">
          <b-spinner variant="light"></b-spinner>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import Img_Default from "../assets/img/img-default.png";
import axios from "axios";
import SkeletonDetail from "./Skeleton/Produk/SkeletonProdukModal.vue";
import swal from "sweetalert";
import '../assets/css/sweetalertstyle.css'
export default {
  data() {
    return {
      submit: true,
      gallery: "",
      cart_id: "",
      hargadata: "",
      picked: [],
      datacart: [],
      variant1: "",
      variant2: "",
      imageutama: "",
      imagechange: "",
      product_id: "",
      selectstar: "",
      qty: 0,
      jmlh_value: 4,
      curentpage: 0,
      dataproduct: {},
      dataimage: [],
      pickvariant: [],
      checkflashsale: 0,
      datagrosir: [],
      // datareview: [],
      fistimage: {},
      variant: [],
      review: [],
      img_null: "",
      loading: true,
      filter: false,
    };
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  async mounted() {
    try {
      this.loading = true;

      if (this.user instanceof Promise == true) {
        await this.$store.dispatch("user");
        this.$store.dispatch("carts");
      }
      if (this.address == [] || this.$store.state.address[0] === undefined) {
        await this.$store.dispatch("carts");
      }
      // axios.post(`/product/view/` + this.id).then(() => {})
      axios.get(`/product/` + this.id).then((response) => {
        const data = response.data.data.product;
        this.dataproduct = data;
        this.product_id = data.id;
        this.variant = response.data.data.variant;
        data.image.filter((item, index) => {
          // ini untuk menentukan apakah dalam produk memilikki default image atau tidak, jika ya maka akan ditampilkan di paling besar 
          if (item.default_image == true) {
            this.fistimage = data.image.splice(index, 1);
          }
        });
        for (var i = 0; i < data?.image.length; i++) {
          if (!data.image.default_image) {
            this.dataimage.push(data.image[i]);
          }
        }
        this.img_null = Img_Default;

        this.loading = false;
      });
    } catch {
      return;
    }
  },
  components: {
    SkeletonDetail,
  },
  computed: {
    carts: function () {
      return this.$store.state.carts;
    },
    user: function () {
      return this.$store.state.user;
    },
    priceproduct() {
      return this.dataproduct?.price?.filter((item) => {
        let jmlhvariant = Object.keys(this.variant).length;
        if (jmlhvariant == 2) {
          return (
            item.variant_option_1_id == this.picked[0] &&
            item.variant_option_2_id == this.picked[1]
          );
        } else if (jmlhvariant == 1) {
          return item.variant_option_1_id == this.picked[0];
        } else {
          return item;
        }
      });
    },
    price_flashsale() {
      return this.priceproduct[0]?.flashsale.filter((item) => item?.flashsale);
    },
    price_promo() {
      return this.priceproduct[0]?.promo.filter((x) => x?.promo);
    },
    wholesale() {
      return this.priceproduct[0]?.wholesale;
    },
    initPaginationfilter() {
      return Math.ceil(this.getdataFilter?.length / this.jmlh_value);
    },
    filteredDatafilter: function () {
      // ini untuk menentukan nomor dari button page sebelum dan sesudah 
      let pageStart = this.curentpage * this.jmlh_value;
      let pageEnd = this.jmlh_value * (this.curentpage * 1 + 1);

      return this.getdataFilter.filter((item, index) => {
        return index >= pageStart && index < pageEnd;
      });
    },
  },
  watch: {
    picked() {
      let jmlhvariant = Object.keys(this.variant).length;
      if(this.$store.state.user.name != undefined){
        if (jmlhvariant == 2) {
        axios
          .get(
            `transaction/check-flashsale?product_name=` +
              this.dataproduct?.name +
              `&flashsale=` +
              this.price_flashsale[0].flashsale.name +
              `&flashsale_date=` +
              this.price_flashsale[0].flashsale.date +
              `&variant_1=` +
              this.pickvariant[0] +
              `&variant_2=` +
              this.pickvariant[1]
          )
          .then((res) => {
            this.checkflashsale = res?.data?.data?.ammount_left;
          })
          .catch((error) => {
            this.checkflashsale = error?.response?.data?.data?.ammount_left;
          });
      } else if (jmlhvariant == 1) {
        axios
          .get(
            `transaction/check-flashsale?product_name=` +
              this.dataproduct?.name +
              `&flashsale=` +
              this.price_flashsale[0].flashsale.name +
              `&flashsale_date=` +
              this.price_flashsale[0].flashsale.date +
              `&variant_1=` +
              this.pickvariant[0]
          )
          .then((res) => {
            this.checkflashsale = res?.data?.data?.ammount_left;
          })
          .catch((error) => {
            this.checkflashsale = error?.response?.data?.data?.ammount_left;
          });
      }
      }
      
    },
    priceproduct() {
      // ini menentukan gambar paling utama yang akan ditampilkan, hal tersebut dipilih sesuai dengan variant yang dipilih oleh user
      if (this.priceproduct.length > 0) {
        if (this.fistimage[0]?.product_price_id !== this.priceproduct[0]?.id) {
          let imagebaru = (this.dataimage ?? []).filter(
            (x) => x?.product_price_id == this.priceproduct[0]?.id
          );
          let imagesetnew = (this.dataimage ?? []).filter(
            (x) => x?.product_price_id != this.priceproduct[0]?.id && x != undefined
          );
          imagesetnew.push(this.fistimage[0]);
          if (imagebaru.length > 0) {
            let dataimg2 = imagebaru[0];
            this.fistimage.splice(0, 1, dataimg2);
            this.dataimage = imagesetnew;
          }
        }
      }
    },
    qty() {
      if (this.priceproduct.length <= 0) {
        this.qty = 0;
        swal("Ooops", "Tolong pilih Variant terlebih dahulu", "error", {
          button: "OK",
        });
      }
      if (this.wholesale?.length > 0) {
        this.hargadata = "";
        this.wholesale.forEach((element) => {
          if (this.qty >= element?.ammount_start) {
            this.hargadata = element?.wholesales_price;
          }
        });
      } else {
        this.hargadata = "";
      }
      if (this.qty > this.priceproduct[0]?.stock) {
        this.qty = this.priceproduct[0]?.stock;
      } else if (this.qty <= 0) {
        this.qty = 0;
      } else {
        var dataqty = parseInt(this.qty);
        this.qty = dataqty;
      }
    },
  },
  methods: {
    datastar(value) {
      var data = parseFloat(value);
      return data.toFixed(1);
    },
    datasold(data) {
      var sold = "";
      var check = "";
      // mengubah data yang sebelumnya angka menjadi tulisan untuk menghemat tempat pada data terjual 
      if (data > 1000000000) {
        sold = data.toString();
        check = sold.slice(0, -9) + "M";
      } else if (data > 1000000) {
        sold = data.toString();
        check = sold.slice(0, -6) + "jt";
      } else if (data > 1000) {
        sold = data.toString();
        check = sold.slice(0, -3) + "rb";
      } else {
        check = data.toString();
      }
      return check;
    },
    modalgrosir(data) {
      this.datagrosir = data;
      this.$bvModal.show("modal-grosir");
    },
    changeImage(data) {
      let dataimg1 = this.fistimage[0];
      let dataimg2 = this.dataimage[data];
      this.fistimage.splice(0, 1, dataimg2);
      this.dataimage.splice(data, 1, dataimg1);
    },
    changepick(datapick) {
      this.pickvariant.push(datapick);
      this.qty = 0;
    },
    addvalue() {
      var dataqty = parseInt(this.qty);
      if (this.priceproduct?.length > 0) {
        if (dataqty < this.priceproduct[0].stock) {
          this.qty = dataqty + 1;
        }
      } else {
        swal("Ooops", "Tolong pilih Variant terlebih dahulu", "error", {
          button: "OK",
        });
      }
    },
    minvalue() {
      var dataqty = parseInt(this.qty);
      if (dataqty > 0) {
        this.qty = dataqty - 1;
      } else {
        swal("Ooops", "Tolong pilih Variant terlebih dahulu", "error", {
          button: "OK",
        });
      }
    },
    addCart() {
      // document.getElementById('btnbuy').disabled = true
      this.submit = false;
      let jmlhvariant = Object.keys(this.variant).length;
      if (jmlhvariant == 2) {
        if (this.picked[0] != undefined && this.picked[1] != undefined) {
          if (this.qty > 0 && this.priceproduct[0].stock > 0) {
            let productID = this.product_id;
            let qtyProductCart = [];
            if (Object.keys(this.carts).length > 0) {
              // ini untuk melakukan pengecekan apakah produk yang dipilih sudah terdapat didalam data cart
              qtyProductCart = this.carts.filter(function (data) {
                return Number(data.product_id) == Number(productID);
              });
              if (qtyProductCart.length > 0) {
                if (
                  // pengecekan apakah jika qty di cart ditambah qty  yang ditambahkan akan melebihi stock atau tidak 
                  Number(qtyProductCart[0].qty) + Number(this.qty) >
                  Number(this.productDetail.stock)
                ) {
                  this.qty = "";

                  // document.getElementById('btnbuy').disabled = false
                  this.submit = true;
                  swal(
                    "Ooops",
                    "Anda telah memasukan (" +
                      qtyProductCart[0].qty +
                      ") barang ke dalam keranjang, anda tidak bisa menambah jumlah barang di keranjang karena telah melebihi batas pembelian",
                    "error",
                    {
                      button: "OK",
                    }
                  );
                } else {
                  this.prosesAddCart();
                }
              } else {
                this.prosesAddCart();
              }
            } else {
              this.prosesAddCart();
            }
          } else if (this.qty >= 0 && this.priceproduct[0]?.stock <= 0) {
            swal("Ooops", "Stok pada variant yang anda pilih kosong", "error", {
              button: "OK",
            });
            // document.getElementById('btnbuy').disabled = false
            this.submit = true;
          } else {
            swal("Ooops", "Tolong masukkan jumlah barang yang ingin anda beli", "error", {
              button: "OK",
            });
            // document.getElementById('btnbuy').disabled = false
            this.submit = true;
          }
        } else {
          swal("Ooops", "Tolong pilih varian terlebih dahulu", "error", {
            button: "OK",
          });
          // document.getElementById('btnbuy').disabled = false
          this.submit = true;
        }
      } else if (jmlhvariant == 1) {
        if (this.picked[0] != undefined) {
          if (this.qty > 0 && this.priceproduct[0].stock > 0) {
            let productID = this.product_id;
            let qtyProductCart = [];
            if (Object.keys(this.carts).length > 0) {
              // ini untuk melakukan pengecekan apakah produk yang dipilih sudah terdapat didalam data cart
              qtyProductCart = this.carts.filter(function (data) {
                return Number(data.product_id) == Number(productID);
              });
              if (qtyProductCart.length > 0) {
                if (
                  // pengecekan apakah jika qty di cart ditambah qty  yang ditambahkan akan melebihi stock atau tidak 
                  Number(qtyProductCart[0].qty) + Number(this.qty) >
                  Number(this.productDetail.stock)
                ) {
                  this.qty = "";
                  // document.getElementById('btnbuy').disabled = false
                  this.submit = true;
                  swal(
                    "Ooops",
                    "Anda telah memasukan (" +
                      qtyProductCart[0].qty +
                      ") barang ke dalam keranjang, anda tidak bisa menambah jumlah barang di keranjang karena telah melebihi batas pembelian",
                    "error",
                    {
                      button: "OK",
                    }
                  );
                } else {
                  this.prosesAddCart();
                }
              } else {
                this.prosesAddCart();
              }
            } else {
              this.prosesAddCart();
            }
          } else if (this.qty >= 0 && this.priceproduct[0]?.stock <= 0) {
            swal("Ooops", "Stok pada variant yang anda pilih kosong", "error", {
              button: "OK",
            });
            // document.getElementById('btnbuy').disabled = false
            this.submit = true;
          } else {
            swal("Ooops", "Tolong masukkan jumlah barang yang ingin anda beli", "error", {
              button: "OK",
            });
            // document.getElementById('btnbuy').disabled = false
            this.submit = true;
          }
        } else {
          swal("Ooops", "Tolong pilih varian terlebih dahulu", "error", {
            button: "OK",
          });
          // document.getElementById('btnbuy').disabled = false
          this.submit = true;
        }
      } else if (jmlhvariant == 0) {
        if (this.qty > 0 && this.priceproduct[0].stock > 0) {
          let productID = this.product_id;
          let qtyProductCart = [];
          if (Object.keys(this.carts).length > 0) {
            // ini untuk melakukan pengecekan apakah produk yang dipilih sudah terdapat didalam data cart
            qtyProductCart = this.carts.filter(function (data) {
              return Number(data.product_id) == Number(productID);
            });
            if (qtyProductCart.length > 0) {
              if (
                // pengecekan apakah jika qty di cart ditambah qty  yang ditambahkan akan melebihi stock atau tidak 
                Number(qtyProductCart[0].qty) + Number(this.qty) >
                Number(this.productDetail.stock)
              ) {
                this.qty = "";

                // document.getElementById('btnbuy').disabled = false
                this.submit = true;
                swal(
                  "Ooops",
                  "Anda telah memasukan (" +
                    qtyProductCart[0].qty +
                    ") barang ke dalam keranjang, anda tidak bisa menambah jumlah barang di keranjang karena telah melebihi batas pembelian",
                  "error",
                  {
                    button: "OK",
                  }
                );
              } else {
                this.prosesAddCart();
              }
            } else {
              this.prosesAddCart();
            }
          } else {
            this.prosesAddCart();
          }
        } else if (this.qty >= 0 && this.priceproduct[0]?.stock <= 0) {
          swal("Ooops", "Stok pada variant yang anda pilih kosong", "error", {
            button: "OK",
          });
          // document.getElementById('btnbuy').disabled = false
          this.submit = true;
        } else {
          swal("Ooops", "Tolong masukkan jumlah barang yang ingin anda beli", "error", {
            button: "OK",
          });
          // document.getElementById('btnbuy').disabled = false
          this.submit = true;
        }
      }
    },
    addkeranjang() {
      this.submit = false;
      let jmlhvariant = Object.keys(this.variant).length;
      if (jmlhvariant == 2) {
        if (this.picked[0] != undefined && this.picked[1] != undefined) {
          if (this.qty > 0 && this.priceproduct[0].stock > 0) {
            let productID = this.product_id;
            let qtyProductCart = [];
            if (Object.keys(this.carts).length > 0) {
              // ini untuk melakukan pengecekan apakah produk yang dipilih sudah terdapat didalam data cart
              qtyProductCart = this.carts.filter(function (data) {
                return Number(data.product_id) == Number(productID);
              });
              if (qtyProductCart.length > 0) {
                if (
                  // pengecekan apakah jika qty di cart ditambah qty  yang ditambahkan akan melebihi stock atau tidak 
                  Number(qtyProductCart[0].qty) + Number(this.qty) >
                  Number(this.productDetail.stock)
                ) {
                  this.qty = "";
                  this.submit = true;
                  swal(
                    "Ooops",
                    "Anda telah memasukan (" +
                      qtyProductCart[0].qty +
                      ") barang ke dalam keranjang, anda tidak bisa menambah jumlah barang di keranjang karena telah melebihi batas pembelian",
                    "error",
                    {
                      button: "OK",
                    }
                  );
                } else {
                  this.prosesAddKeranjang();
                }
              } else {
                this.prosesAddKeranjang();
              }
            } else {
              this.prosesAddKeranjang();
            }
          } else if (this.qty >= 0 && this.priceproduct[0]?.stock <= 0) {
            swal("Ooops", "Stok pada variant yang anda pilih kosong", "error", {
              button: "OK",
            });
            // document.getElementById('btnbuy').disabled = false
            this.submit = true;
          } else {
            swal("Ooops", "Tolong masukkan jumlah barang yang ingin anda beli", "error", {
              button: "OK",
            });
            this.submit = true;
          }
        } else {
          swal("Ooops", "Tolong pilih varian terlebih dahulu", "error", {
            button: "OK",
          });
          this.submit = true;
        }
      } else if (jmlhvariant == 1) {
        if (this.picked[0] != undefined) {
          if (this.qty > 0 && this.priceproduct[0].stock > 0) {
            let productID = this.product_id;
            let qtyProductCart = [];
            if (Object.keys(this.carts).length > 0) {
              // ini untuk melakukan pengecekan apakah produk yang dipilih sudah terdapat didalam data cart
              qtyProductCart = this.carts.filter(function (data) {
                return Number(data.product_id) == Number(productID);
              });
              if (qtyProductCart.length > 0) {
                if (
                  // pengecekan apakah jika qty di cart ditambah qty  yang ditambahkan akan melebihi stock atau tidak 
                  Number(qtyProductCart[0].qty) + Number(this.qty) >
                  Number(this.productDetail.stock)
                ) {
                  this.qty = "";
                  this.submit = true;
                  swal(
                    "Ooops",
                    "Anda telah memasukan (" +
                      qtyProductCart[0].qty +
                      ") barang ke dalam keranjang, anda tidak bisa menambah jumlah barang di keranjang karena telah melebihi batas pembelian",
                    "error",
                    {
                      button: "OK",
                    }
                  );
                } else {
                  this.prosesAddKeranjang();
                }
              } else {
                this.prosesAddKeranjang();
              }
            } else {
              this.prosesAddKeranjang();
            }
          } else if (this.qty >= 0 && this.priceproduct[0]?.stock <= 0) {
            swal("Ooops", "Stok pada variant yang anda pilih kosong", "error", {
              button: "OK",
            });
            // document.getElementById('btnbuy').disabled = false
            this.submit = true;
          } else {
            swal("Ooops", "Tolong masukkan jumlah barang yang ingin anda beli", "error", {
              button: "OK",
            });
            this.submit = true;
          }
        } else {
          swal("Ooops", "Tolong pilih varian terlebih dahulu", "error", {
            button: "OK",
          });
          this.submit = true;
        }
      } else if (jmlhvariant == 0) {
        if (this.qty > 0 && this.priceproduct[0].stock > 0) {
          let productID = this.product_id;
          let qtyProductCart = [];
          if (Object.keys(this.carts).length > 0) {
            // ini untuk melakukan pengecekan apakah produk yang dipilih sudah terdapat didalam data cart
            qtyProductCart = this.carts.filter(function (data) {
              return Number(data.product_id) == Number(productID);
            });
            if (qtyProductCart.length > 0) {
              if (
                // pengecekan apakah jika qty di cart ditambah qty  yang ditambahkan akan melebihi stock atau tidak 
                Number(qtyProductCart[0].qty) + Number(this.qty) >
                Number(this.productDetail.stock)
              ) {
                this.qty = "";
                this.submit = true;
                swal(
                  "Ooops",
                  "Anda telah memasukan (" +
                    qtyProductCart[0].qty +
                    ") barang ke dalam keranjang, anda tidak bisa menambah jumlah barang di keranjang karena telah melebihi batas pembelian",
                  "error",
                  {
                    button: "OK",
                  }
                );
              } else {
                this.prosesAddKeranjang();
              }
            } else {
              this.prosesAddKeranjang();
            }
          } else {
            this.prosesAddKeranjang();
          }
        } else if (this.qty >= 0 && this.priceproduct[0]?.stock <= 0) {
          swal("Ooops", "Stok pada variant yang anda pilih kosong", "error", {
            button: "OK",
          });
          // document.getElementById('btnbuy').disabled = false
          this.submit = true;
        } else {
          swal("Ooops", "Tolong masukkan jumlah barang yang ingin anda beli", "error", {
            button: "OK",
          });
          this.submit = true;
        }
      }
    },
    prosesAddCart() {
      // this.$bvModal.show('modal-loading')
      this.loading = true;
      // ini untuk melakukan pengecekan apakah produk yang dipilih sudah terdapat didalam data cart
      let cartCheck = this.carts.filter((datacart) => {
        return datacart.product_price_id == this.priceproduct[0].id;
      });
      if (cartCheck[0]?.qty + this?.qty > cartCheck[0]?.product?.stock) {
        // this.$bvModal.hide('modal-loading')
        this.loading = false;
        swal(
          "Ooops",
          "Anda menambahkan barang melebihi stok, barang anda di keranjang berjumlah " +
            cartCheck[0]?.qty +
            " dan stok pada barang ini " +
            cartCheck[0]?.product?.stock,
          "error",
          {
            button: "OK",
          }
        ).then(() => {
          this.qty = 0;
          this.loading = false;
          // document.getElementById('btnbuy').disabled = false
          this.submit = true;
          // this.$bvModal.hide('modal-loading')
        });
      } else {
        let data = new FormData();
        data.append("customer_id", this.user?.id);
        data.append("product_id", this.product_id);
        data.append("product_price_id", this.priceproduct[0].id);
        data.append("qty", this.qty);

        axios
          .post("/transaction/cart", data)
          .then((res) => {
            localStorage.setItem("id_pembelian", JSON.stringify(res.data.data.id));
            this.datacart = res.data.data;
            let jmlhvariant = Object.keys(this.variant).length;
            if(jmlhvariant == 2){
              var dataproduct1  = this.carts.filter((item) => {
                return ( 
                  this.picked.some((variant) => variant == item.product.variant_option_1_id) &&
                  this.picked.some((variant) => variant == item.product.variant_option_2_id)
                )
              });
              if(dataproduct1.length > 0){
                this.loading = false;
                this.submit = true;
                this.$store.dispatch("carts");
                this.qty = 0;
                
                this.$router.push("/cart?cart_select="+this.datacart.id);
              }else{
                var cartid1 = [this.datacart.id];
            this.$store
              .dispatch("set_Cart", {
                cart_id: cartid1,
              })
              .then(() => {});
              this.$cookies.set("cart_id", cartid1);
              this.loading = false;
              this.submit = true;
              this.$store.dispatch("carts");
              this.qty = 0;
              this.$router.push("/checkout");
                }
              }else if(jmlhvariant == 1){
                var dataproduct2 = this.carts.filter((item) => {
                  return ( 
                  this.picked.some((variant) => variant == item.product.variant_option_1_id)
                ) 
                  
                });
                if(dataproduct2.length > 0){
                this.loading = false;
                this.submit = true;
                this.$store.dispatch("carts");
                this.qty = 0;
                
                this.$router.push("/cart?cart_select="+this.datacart.id);
              }else{
                var cartid2 = [this.datacart.id];
            this.$store
              .dispatch("set_Cart", {
                cart_id: cartid2,
              })
              .then(() => {});
              this.$cookies.set("cart_id", cartid2);
              this.loading = false;
              this.submit = true;
              this.$store.dispatch("carts");
              this.qty = 0;
              this.$router.push("/checkout");
                }
              }
          })
          .catch((err) => {
            this.qty = 0;
            this.loading = false;
            // document.getElementById('btnbuy').disabled = false
            this.submit = true;
            // this.$bvModal.hide('modal-loading')
            if(err.response.data.message == 'error'){
                swal("Ooops", "Produk yang anda pilih saat ini stocknya sedang kosong", "error", {
                button: "OK",
              });
              }else{
                swal("Ooops", err.response.data.message, "error", {
                button: "OK",
              });
              }
          });
      }
    },
    prosesAddKeranjang() {
      this.$bvModal.show("modal-loading");
      this.loadingSubmit = true;
      // ini untuk melakukan pengecekan apakah produk yang dipilih sudah terdapat didalam data cart
      let cartCheck = this.carts.filter((datacart) => {
        return datacart.product_price_id == this.priceproduct[0].id;
      });
      if (cartCheck[0]?.qty + this.qty > cartCheck[0]?.product?.stock) {
        this.$bvModal.hide("modal-loading");
        this.loadingSubmit = false;
        swal(
          "Ooops",
          "Anda menambahkan barang melebihi stok, barang anda di keranjang berjumlah " +
            cartCheck[0].qty +
            " dan stok pada barang ini " +
            cartCheck[0].product.stock,
          "error",
          {
            button: "OK",
          }
        ).then(() => {
          this.qty = 0;
          this.loadingSubmit = false;
          this.submit = true;
          this.$bvModal.hide("modal-loading");
        });
      } else {
        let data = new FormData();
        data.append("customer_id", this.user?.id);
        data.append("product_id", this.product_id);
        data.append("product_price_id", this.priceproduct[0].id);
        data.append("qty", this.qty);

        axios
          .post("/transaction/cart", data)
          .then(() => {
            this.$bvModal.hide("modal-loading");
            this.loadingSubmit = false;
            this.submit = true;
            this.$store.dispatch("carts");
            this.qty = 0;

            //show modal sukses
            swal("Berhasil!!!", "Produk berhasil di tambahkan ke keranjang", "success", {
              button: "OK",
            });
          })
          .catch((err) => {
            this.qty = 0;
            this.loadingSubmit = false;
            this.submit = true;
            this.$bvModal.hide("modal-loading");
            if(err.response.data.message == 'error'){
                swal("Ooops", "Produk yang anda pilih saat ini stocknya sedang kosong", "error", {
                button: "OK",
              });
              }else{
                swal("Ooops", err.response.data.message, "error", {
                button: "OK",
              });
              }
            // if (this.user?.id) {
            //   this.setModal(true,'modalErrorCheckout')
            // } else{
            //   this.$router.push('/login')
            // }
          });
      }
    },
  },
};
</script>
<style scoped>
::v-deep #modal-loading .modal-content {
  background-color: transparent;
  border: 0px !important;
}
::v-deep .modal-header button {
  background-color: #fff;
  border: 0px !important;
}
.form-stock input::-webkit-outer-spin-button,
.form-stock input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-stock input[type="number"] {
  -moz-appearance: textfield;
}
.color-grey {
  color: rgba(0, 0, 0, 0.4);
}
.color-orange {
  color: #ee4d2d;
}
.span-grosir {
  cursor: pointer;
  color: #0d6efd;
}
.list-data-bukti {
  display: flex;
}
.list-data-bukti i {
  text-align: center;
  font-size: 5rem;
}
.list-data-bukti p {
  margin-bottom: 0px !important;
}
.list-data-bukti .card {
  margin-left: 0.5rem !important;
}
.header-produk {
  height: 9rem;
}
.dataimg {
  width: 100%;
}
.leftdata {
  display: none;
  float: left;
  width: 100px;
  height: 100%;
  scrollbar-width: thin;
  overflow-y: auto;
}
.leftdata::-webkit-scrollbar {
  width: 1px; /* Change the scrollbar width for the .child class */
}
.leftdata::-webkit-scrollbar-thumb {
  width: 1px; /* Change the thumb width */
  background-color: #cecece;
}
.leftdata figure {
  height: auto;
  width: auto;
  max-height: 100px;
  max-width: 100px;
  margin-top: 2.5px;
  margin-bottom: 2.5px;
  border-radius: 5px;
  overflow: hidden;
}
.leftdata figure:hover {
  cursor: pointer;
}
.leftdata figure img {
  width: 100%;
}
.rightdata {
  padding: 5px;
  height: 100%;
  overflow: hidden;
}
.rightdata figure img {
  cursor: pointer;
}
.rightdata figure {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.rightdata figure img {
  width: 100%;
  border-radius: 5px;
}
.rightdata .bottom-image {
  overflow-x: auto;
}
.detailproduk {
  text-align: left;
}
.detailproduk h4 {
  font-weight: 600;
}
.star {
  display: flex;
}
.star svg {
  fill: gold;
  font-size: 10px;
  width: 20px;
  height: 20px;
  vertical-align: sub !important;
}
.star small {
  padding-left: 5px;
  color: #aeaeae;
}
.star_big {
  display: flex;
}
.star_big svg {
  fill: gold;
  font-size: 10px;
  width: 20px;
  height: 20px;
  vertical-align: sub !important;
}
.star_big small {
  padding-left: 5px;
  color: #aeaeae;
}
.harga-Produk h6 {
  font-weight: 550;
  font-size: 1.2rem;
}
.harga-Produk s {
  color: rgba(0, 0, 0, 0.4);
  padding-left: 15px;
  font-size: 0.8rem;
  line-height: 2;
  margin-bottom: 0.5rem;
}
.diskon {
  display: flex;
}
.diskon p {
  color: #fff;
  padding: 3px;
  border-radius: 3px;
  background-color: red;
  font-size: 10px;
}
.diskon s {
  color: gray;
  font-size: 15px;
  line-height: 1;
  padding-left: 10px;
  text-decoration: line-through !important;
}
.sizetext {
  margin-bottom: 0.5rem;
  font-weight: 600;
}
.sizeproduk {
  display: flex;
  flex-wrap: wrap;
}
.sizeproduk label {
  border: 1px solid #2f3192;
  padding: 10px 10px;
  margin: 5px;
  text-align: center;
}
.sizeproduk label:hover {
  cursor: pointer;
  background-color: #2f3192 !important;
  color: #fff !important;
}
.sizeproduk input {
  display: none;
}
.activeSize {
  background-color: #2f3192 !important;
  color: #fff !important;
}
.dalam_promo {
  position: relative;
}
.dalam_promo svg {
  fill: #2f3192;
  width: 28px;
  height: 28px;
  position: absolute;
  left: 0px;
  top: 0px;
}
.dalam_promo p {
  padding-left: 35px;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0px;
}
.dalam_promo small {
  padding-left: 35px;
  color: rgba(0, 0, 0, 0.6);
}
.stock {
  display: flex;
}
.form-stock {
  display: flex;
}
.form-stock button {
  margin-left: 5px;
  margin-right: 5px;
  max-height: 38px;
}
.form-stock input {
  border-radius: 5px;
}
.btn-cart .w-50 {
  padding-left: 5px;
  padding-right: 5px;
}
.btn-cart svg {
  fill: #fff;
  width: 1.2rem;
  height: 1.2rem;
  vertical-align: text-bottom;
}
::v-deep .btn-cart .btn-outline-primary:hover > svg {
  fill: #fff !important;
}
.deskripsi-produk {
  text-align: left;
}
.deskripsi-produk h6 {
  font-weight: 600;
}
.deskripsi-produk p {
  color: rgba(0, 0, 0, 0.4);
  text-align: justify;
}
.ulasan_pembeli {
  text-align: left;
}
.ulasan_pembeli h6 {
  font-weight: 600;
}
.card-review {
  max-width: 180px;
  text-align: center;
  border: 1px solid #0d6efd;
  border-radius: 10px;
  padding: 5px;
}
.card-review h4 {
  padding-left: 2rem;
  font-size: 4rem;
  font-weight: 550;
  text-align: center;
  position: relative;
}
.card-review .star_big svg {
  position: absolute;
  top: 25px;
  left: 5px;
}
.card-review h4 span {
  font-size: 1.5rem;
  color: grey;
  padding-top: 2.5rem;
}
.card-review h6 {
  font-size: 0.8rem;
  font-weight: 550;
}
.card-review p {
  color: rgba(0, 0, 0, 0.4);
}
.review_star {
  display: flex;
  overflow-x: auto;
}
.review_star button {
  margin-left: 5px;
  margin-right: 5px;
}
.review_star svg {
  vertical-align: text-bottom;
}
.review_user .star small {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.6rem;
  padding-top: 5px;
}
.user figure {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.user figure img {
  width: 100%;
}
.review p {
  text-align: justify;
  line-height: 1.5;
}
.review_admin {
  background-color: #f1f1f1;
}
.review_admin p {
  text-align: justify;
  line-height: 1.5;
}

@media (max-width: 800px) {
  .input-produk {
    max-width: 100px;
  }
}
@media (max-width: 500px) {
  .rightdata .bottom-image figure {
    min-width: 75px;
    min-height: 75px;
    max-width: 100px;
    max-height: 100px;
    width: auto !important;
    height: auto !important;
    margin-left: 5px;
    margin-right: 5px;
  }
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .dataimg {
    height: 60vh;
  }
  .leftdata {
    display: block;
  }
  .rightdata .bottom-image {
    display: none !important;
  }
  .rightdata {
    margin-left: 120px;
  }
  .card-review h4 {
    font-size: 2rem;
    padding-top: 20px;
    font-weight: 550;
    position: relative;
  }
  .card-review h4 span {
    padding-top: 0.5rem;
  }
}

@media (min-width: 1023px) {
  .card-review h4 span {
    padding-top: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .card-review h4 span {
    padding-top: 0.5rem;
  }
}

@media (min-width: 1439px) {
  .card-review h4 {
    font-size: 4rem;
    padding-top: 0px;
    font-weight: 550;
    position: relative;
  }
  .card-review h4 span {
    padding-top: 2.5rem;
  }
}

@media (min-width: 2000px) {
}
</style>
