<template>
  <div class="confirmation-order section section-modif">
    <div class="confirmation-order-container container">
      <Loader v-if="loading" />
      <SkeletonInvoice v-if="loading" />
      <div v-if="!loading">
        <div class="confirmation-order-top">
          <i class="bx bxs-basket text-red" style="font-size: 115px"></i>
          <h3 class="text-center"><b>Terima Kasih</b></h3>
          <!-- <p>Pengajuan pembelian anda dengan no <b>{{dataTransactions.invoice}}</b> telah kami terima.<br> Kami akan mengirimkan detail pengajuan & informasi pembelian selanjutnya melalui email <b>{{user?.email}}</b>. Atau anda bisa melihat informasi tersebut pada halaman dashboard profil anda.</p> -->
          <p class="text-center">
            Terima Kasih Pengajuan pembelian anda dengan no
            <b>{{ datatransaction.invoice }}</b> telah kami terima. Lihat detail pesanan
            untuk informasi lebih lanjut.
          </p>
          <h5 class="text-center"><b>Total Pembayaran Anda :</b></h5>
          <h1 class="text-center text-danger">
            Rp
            {{
              new Intl.NumberFormat("id-ID").format(
                Number(datatransaction.grand_total).toFixed()
              )
            }}
          </h1>
          <!-- <button
            v-if="datatransaction?.status == 'pending_payment'"
            class="btn btn-primary btn-pembayaran mt-3 mt-md-4"
            @click.prevent="Checkoudata"
            id="lanjutkan_transaksi"
          >
            Lanjutkan Pembayaran
          </button> -->
          <router-link
            :to="'/profil/detailtransaksi?id_transaksi=' + datatransaction.id"
            class="btn btn-primary btn-pembayaran btn-width-link mt-3 mt-md-4"
          >
            Lihat Detail
          </router-link>
          <div class="confirmation-order-info-container container-fluid mt-3 mt-lg-4">
            <div class="row">
              <div class="col-lg-6 p-1 p-md-3">
                <div class="card p-md-2">
                  <div class="card-header">
                    <h6 class="pt-3">Informasi User</h6>
                  </div>
                  <div class="card-content p-2 pb-2 px-md-3 mb-3">
                    <table class="table-no-border informationTable">
                      <tr>
                        <td><b>Nama</b></td>
                        <td>: {{ user?.name }}</td>
                      </tr>
                      <tr>
                        <td><b>Email</b></td>
                        <td>: {{ user?.email }}</td>
                      </tr>
                      <tr>
                        <td><b>No Telepon</b></td>
                        <td>: {{ user?.phone }}</td>
                      </tr>
                      <tr v-if="datatransaction?.delivery != null">
                        <td><b>Alamat</b></td>
                        <!-- <td>: {{dataaddress?.address}}</td> -->
                        <td>
                          : <b>{{ datatransaction?.delivery?.recipient_name }}</b
                          ><br />
                          {{ datatransaction?.delivery?.address }},
                          {{ datatransaction?.delivery?.sub_district }},
                          {{ datatransaction?.delivery?.district }},
                          {{ datatransaction?.delivery?.province }}
                          {{ datatransaction?.delivery?.postal_code }}
                        </td>
                      </tr>
                      <tr v-else-if="datatransaction?.customer_address != null">
                        <td><b>Alamat</b></td>
                        <!-- <td>: {{dataaddress?.address}}</td> -->
                        <td>
                          : {{ dataaddress?.address }},
                          {{ dataaddress?.sub_district?.subdistrict_name }},
                          {{ dataaddress?.district?.city_name }},
                          {{ dataaddress?.province?.province_name }}
                          {{ dataaddress?.district?.postal_code }}
                        </td>
                      </tr>
                      <tr v-else-if="address.length > 0">
                        <td><b>Alamat</b></td>
                        <!-- <td>: {{dataaddress?.address}}</td> -->
                        <td>
                          : {{ address[0]?.address }},
                          {{ address[0]?.sub_district?.subdistrict_name }},
                          {{ address[0]?.district?.city_name }},
                          {{ address[0]?.province?.province_name }}
                          {{ address[0]?.district?.postal_code }}
                        </td>
                      </tr>
                      <tr v-else>
                        <td><b>Alamat</b></td>
                        <!-- <td>: {{dataaddress?.address}}</td> -->
                        <td>: -</td>
                      </tr>
                    </table>
                  </div>
                  <div class="card-footer p-3"></div>
                </div>
              </div>
              <div class="col-lg-6 p-1 p-md-3">
                <div class="card p-2">
                  <div class="card-header">
                    <h6 class="pt-3">Informasi Cabang</h6>
                  </div>
                  <div class="card-content p-2 pb-2 px-md-3 mb-3">
                    <table class="table-no-border informationTable">
                      <tr>
                        <td><b>Cabang</b></td>
                        <td>: {{ databranch?.name }}</td>
                      </tr>
                      <tr>
                        <td><b>Tipe Pembayaran</b></td>
                        <td v-if="datatransaction?.payment_type == 'payment_gateway'">
                          : Pembayaran Digital
                        </td>
                        <td v-if="datatransaction?.payment_type == 'cod'">: COD</td>
                      </tr>
                      <tr>
                        <td><b>Jam Buka</b></td>
                        <td>
                          : {{ datastring(databranch?.open) }} -
                          {{ datastring(databranch?.close) }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Alamat Cabang</b></td>
                        <td>: {{ databranch?.address }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="card-footer p-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="confirmation-order-bottom mt-3">
          <div class="keranjang-content-container">
            <div class="bg-white product-data p-3 mb-4">
              <table class="table-product web-view">
                <template
                  v-for="(dataproduct, index) in datatransaction?.transaction_detail"
                >
                  <tr class="header-table" :key="index">
                    <th class="p-3">Produk</th>
                    <th>Harga</th>
                    <th>Qty</th>
                    <th>Subtotal</th>
                  </tr>
                  <tr :key="index">
                    <td colspan="2" class="p-3 td-produk">
                      <div class="d-flex">
                        <figure class="image mx-1">
                          <img :src="$imgProduct + dataimg(dataproduct)" alt="" />
                        </figure>
                        <div class="mx-1 detail_product">
                          <h6>{{ dataproduct.name_product }}</h6>
                          <p>{{ dataproduct.variant_1 }} , {{ dataproduct.variant_2 }}</p>
                        </div>
                      </div>
                    </td>
                    <td class="td-nonproduk">
                      <div v-if="Number(dataproduct.price_after_discount) > 1">
                        <h6 class="text-danger">
                          Rp
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(dataproduct.price_after_discount).toFixed()
                            )
                          }}
                        </h6>
                        <div class="diskon">
                          <p>{{ parseInt(dataproduct.discount) }}%</p>
                          <small
                            ><s
                              >Rp
                              {{
                                new Intl.NumberFormat("id-ID").format(
                                  Number(dataproduct.price).toFixed()
                                )
                              }}</s
                            ></small
                          >
                        </div>
                      </div>
                      <div v-else>
                        <p class="text-danger">
                          Rp
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(dataproduct.price).toFixed()
                            )
                          }}
                        </p>
                      </div>
                    </td>
                    <td class="td-nonproduk">
                      <p>{{ dataproduct.qty }} {{ dataproduct.unit }}</p>
                    </td>
                    <td class="td-nonproduk">
                      <p class="text-danger">
                        Rp
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(dataproduct.subtotal).toFixed()
                          )
                        }}
                      </p>
                    </td>
                  </tr>
                  <tr :key="'footer' + index">
                    <td colspan="4">
                      <div v-if="dataproduct?.notes">
                        <span
                          class="bottom-left d-flex"
                          v-if="
                            dataproduct?.notes.length > 50 && dataproduct?.notes != 'null'
                          "
                        >
                          <p>
                            <b>Catatan :</b>
                            {{ dataproduct?.notes.substring(0, 50) + "..." }}
                            <span
                              class="readlink"
                              @click.prevent="modalnote(dataproduct?.notes)"
                              >Selengkapnya</span
                            >
                          </p>
                        </span>
                        <span
                          class="bottom-left d-flex"
                          v-else-if="
                            dataproduct?.notes.length < 50 && dataproduct?.notes != 'null'
                          "
                        >
                          <p><b>Catatan :</b> {{ dataproduct?.notes }}</p>
                        </span>
                      </div>
                      <div v-if="!dataproduct?.notes || dataproduct?.notes == 'null'">
                        <p><b>Catatan :</b></p>
                      </div>
                    </td>
                  </tr>
                </template>
              </table>
              <table class="table-product mobile-view">
                <template
                  v-for="(dataproduct, index) in datatransaction?.transaction_detail"
                >
                  <tr class="header-table" :key="'headerproduct' + index">
                    <th class="p-3">Produk</th>
                  </tr>
                  <tr :key="'product' + index">
                    <td colspan="2" class="p-3 td-produk">
                      <div class="d-flex">
                        <figure class="image mx-1">
                          <img :src="$imgProduct + dataimg(dataproduct)" alt="" />
                        </figure>
                        <div class="mx-1 detail_product mobile_product_detail">
                          <h6>{{ dataproduct.name_product }}</h6>
                          <div v-if="Number(dataproduct.price_after_discount) > 1">
                            <div class="diskon">
                              <h6 class="text-danger">
                                {{ dataproduct.qty }} {{ dataproduct.unit }}
                                <small>x</small> Rp
                                {{
                                  new Intl.NumberFormat("id-ID").format(
                                    Number(dataproduct.price_after_discount).toFixed()
                                  )
                                }}
                              </h6>
                            </div>
                            <div class="diskon">
                              <small class="badge bg-danger py-1">
                                {{ Number(dataproduct.discount) }}%
                              </small>
                              <small
                                ><s
                                  >Rp
                                  {{
                                    new Intl.NumberFormat("id-ID").format(
                                      Number(dataproduct.price).toFixed()
                                    )
                                  }}</s
                                ></small
                              >
                            </div>
                          </div>
                          <div v-else>
                            <p>
                              {{ dataproduct.qty }} {{ dataproduct.unit }}
                              <small>x</small> Rp
                              {{
                                new Intl.NumberFormat("id-ID").format(
                                  Number(dataproduct.price).toFixed()
                                )
                              }}
                            </p>
                          </div>

                          <!-- <h6 class="text-danger">
                            Rp
                            {{
                              new Intl.NumberFormat("id-ID").format(
                                Number(dataproduct.subtotal).toFixed()
                              )
                            }}
                          </h6> -->
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr :key="'variant' + index">
                    <td>
                      <small
                        ><b>Variant :</b> {{ dataproduct?.variant_1 }} ,
                        {{ dataproduct?.variant_2 }}</small
                      >
                    </td>
                  </tr>
                  <tr :key="'total' + index" class="border-subtotal">
                    <td colspan="2">
                      <div v-if="dataproduct?.notes">
                        <span
                          class="bottom-left d-flex"
                          v-if="
                            dataproduct?.notes.length > 50 && dataproduct?.notes != 'null'
                          "
                        >
                          <p>
                            <b>Catatan :</b>
                            {{ dataproduct?.notes.substring(0, 50) + "..." }}
                            <span
                              class="readlink"
                              @click.prevent="modalnote(dataproduct?.notes)"
                              >Selengkapnya</span
                            >
                          </p>
                        </span>
                        <span
                          class="bottom-left d-flex"
                          v-else-if="
                            dataproduct?.notes.length < 50 && dataproduct?.notes != 'null'
                          "
                        >
                          <p><b>Catatan :</b> {{ dataproduct?.notes }}</p>
                        </span>
                      </div>
                      <div v-if="!dataproduct?.notes || dataproduct?.notes == 'null'">
                        <p><b>Catatan :</b></p>
                      </div>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
            <div class="ringkasan mt-3 mt-md-4">
              <h5>Ringkasan Belanja</h5>
              <div class="m-md-3">
                <div class="card-ringkasan">
                  <table class="table-ringkasan-belanja border-0">
                    <tr>
                      <th class="border-0 p-1">
                        Total Harga {{ datatransaction.total_qty }} Barang
                      </th>
                      <td class="border-0 p-1 text-danger">
                        Rp.
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(datatransaction.total_price).toFixed()
                          )
                        }}
                      </td>
                    </tr>
                    <tr v-if="datatransaction.delivery_type == 'home_delivery'">
                      <th class="border-0 p-1">Biaya Ongkir</th>
                      <td class="border-0 p-1 text-danger">
                        Rp.
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(datatransaction.shipping_cost).toFixed()
                          )
                        }}
                      </td>
                    </tr>
                    <!-- <tr>
                      <th class="border-0 p-1">Total PPN (11%)</th>
                      <td class="border-0 p-1">Rp. {{ new Intl.NumberFormat('id-ID').format(Number(datatransaction.total_ppn).toFixed())}}</td>
                    </tr> -->
                    <!-- <tr>
                      <th class="border-0 p-1">Biaya Admin</th>
                      <td class="border-0 p-1 text-danger">Rp. 1000</td>
                    </tr> -->
                    <tr class="total-ringkasan-belanja border-top">
                      <th class="border-0 p-1">Total Tagihan</th>
                      <th class="border-0 p-1 text-danger">
                        Rp.
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(datatransaction.grand_total).toFixed()
                          )
                        }}
                      </th>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-loading" centered hide-footer hide-header>
      <div class="text-center">
        <b-spinner variant="light"></b-spinner>
      </div>
    </b-modal>
    <b-modal id="modal-catatan-invoice" hide-footer scrollable title="Detail Catatan">
      {{ modal_note }}
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
import "../../assets/css/sweetalertstyle.css";
import SkeletonInvoice from "../Skeleton/Cart/SkeletonInvoice.vue";
import Loader from "@/components/Loader/Loader.vue";
export default {
  data() {
    return {
      datacart: [],
      loading: true,
      databranch: [],
      datatransaction: [],
      dataaddress: {},
      modal_note: "",
    };
  },
  components: {
    SkeletonInvoice,
    Loader,
  },
  async mounted() {
    try {
      this.loading = true;
      if (this.user instanceof Promise == true) {
        await this.$store.dispatch("user");
        this.$store.dispatch("carts");
        this.$store.dispatch("address").then(() => {
          this.dataaddress = this.$store.state.address[0];
        });
      }
      if (this.carts == [] || this.$store.state.carts[0] === undefined) {
        await this.$store.dispatch("carts");
      }
      if (this.$store.state.carts[0] === undefined) {
        await this.$store.dispatch("address").then(() => {
          this.dataaddress = this.address[0];
        });
      }
      this.dataaddress = this.$store.state.address[0];
      let dataid = this.$cookies.get("id_checkout");
      axios.get("/transaction/" + dataid).then((res) => {
        this.databranch = res.data.data.data.branch;
        this.datatransaction = res.data.data.data;
        this.loading = false;
      });
    } catch {
      this.loading = false;
    }
  },
  computed: {
    user: function () {
      return this.$store.state.user;
    },
    carts: function () {
      return this.$store.state.carts;
    },
    address: function () {
      return this.$store.state.address;
    },
  },
  methods: {
    dataimg(value) {
      if (value.product_price.image != null) {
        return value.product_price.image.image;
      } else {
        return value.image;
      }
    },
    modalnote(value) {
      this.modal_note = value;
      this.$bvModal.show("modal-catatan-invoice");
      // this.idmodal = id
    },
    datastring(value) {
      var stringdata = value?.substring(0, 5);
      return stringdata;
    },
    Checkoudata() {
      document.getElementById("lanjutkan_transaksi").disabled = true;
      let dataid = this.$cookies.get("id_checkout");
      let data = new FormData();
      data.append("transaction_id", dataid);
      data.append(
        "payment_success_url",
        window.location.origin + "/profil/detailtransaksi?id_transaksi="
      );
      data.append("payment_failed_url", window.location.origin + "/profil/cart");
      this.$bvModal.show("modal-loading");
      axios
        .post("/transaction/payment", data)
        .then((res) => {
          document.getElementById("lanjutkan_transaksi").disabled = false;
          this.$bvModal.hide("modal-loading");
          swal(
            "Berhasil!!!",
            "Barang telah dicheckout, silahkan untuk melakukan pembayaran",
            "success",
            {
              button: "OK",
            }
          );
          let datapayment = res.data.data;
          window.location.href = datapayment.url_payment;
          this.$store.dispatch("carts");

          // this.getDetailCheckout(this.cartiddata)
        })
        .catch(() => {
          this.$bvModal.hide("modal-loading");
        });
    },
  },
};
</script>
<style scoped>
::v-deep .modal-header button {
  background-color: #fff !important;
  border: 0px !important;
}
::v-deep #modal-loading .modal-content {
  background-color: transparent;
  border: 0px !important;
}
.btn-width-link {
  max-width: 125px;
}
.data-button {
  margin: 0 auto !important;
}
.border-subtotal {
  border-top: 1px solid grey;
}
.mobile-view {
  display: table;
}
.web-view {
  display: none;
}
.mobile_product_detail p {
  margin-bottom: 0.5rem;
}
.mobile_product_detail h6 {
  margin-bottom: 0px !important;
}
.diskon {
  display: flex;
}
.diskon p {
  color: #fff;
  font-size: 8px;
  padding: 3px;
  border-radius: 3px;
  background-color: red;
}
.diskon s {
  color: gray;
  font-size: 0.8rem;
  padding-left: 5px;
  text-decoration: line-through !important;
}
.btn-pembayaran {
  display: block;
  margin: 0 auto;
}
.btn-pembayaran-link {
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  display: table;
}
.btn-pembayaran-link a {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.card-header {
  background-color: #fff !important;
}
.card-footer {
  background-color: #fff !important;
}
.informationTable td {
  padding: 2px;
}
.confirmation-order-top {
  padding: 3rem;
}
table.checkout tr th.nama {
  width: 40%;
}
table.checkout tr th.jumlah {
  width: 10%;
}
table.checkout tr th.total {
  width: 30%;
}

.product-data {
  overflow-x: auto;
}
.headcart {
  text-align: left;
  font-weight: 600;
  color: #000;
}

.product-cart {
  overflow-x: auto;
}

.table-product {
  position: relative;
  width: 100%;
}
.table-product tr {
  background-color: transparent !important;
}
.header-table::before {
  content: "";
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  height: 3.5rem;
  position: absolute;
}
.image {
  max-width: 50px;
  max-height: 50px;
  overflow: hidden;
  border-radius: 5px;
}
.image img {
  width: 100%;
}
.detail_product h6 {
  font-weight: 600;
}
.transaksi-footer-bottom {
  display: block;
  position: initial;
  bottom: 1.5rem;
  right: 2rem;
}

.ringkasan h5 {
  font-weight: 600;
}
.card-ringkasan {
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
  text-align: right;
  display: flex;
  justify-content: end;
}

.table-ringkasan-belanja {
  width: 100%;
}

.table-ringkasan-belanja th {
  text-align: left;
}

.total-ringkasan-belanja th {
  border-top: 1px solid var(--primary-color);
  font-size: 1.25rem;
}

.total-ringkasan-belanja th:nth-child(2) {
  text-align: right;
}

@media (max-width: 500px) {
  .confirmation-order-top {
    padding: 6rem 0px 0px 0px !important;
  }
  p,
  h6 {
    font-size: 0.85rem !important;
  }
  h5 {
    font-size: 0.9rem !important;
  }
  .td-produk {
    min-width: 250px;
    max-width: 350px;
  }
  .td-nonproduk {
    min-width: 150px;
    max-width: 225px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .table-ringkasan-belanja th,
  td {
    font-size: 0.85rem;
  }
  .confirmation-order-info-container td {
    font-size: 0.85rem !important;
  }
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}
@media (min-width: 576px) {
  .table-ringkasan-belanja {
    width: 70%;
  }
}

@media (min-width: 767px) {
  .header-product {
    display: block;
  }
  .mobile-view {
    display: none;
  }
  .web-view {
    display: table;
  }
  .td-produk {
    min-width: 250px;
    max-width: 350px;
  }
  .td-nonproduk {
    min-width: 125px;
    max-width: 175px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .table-ringkasan-belanja {
    width: 50% !important;
  }
  .confirmation-order-top {
    padding: 7rem 2rem 2rem 2rem !important;
  }
  .card-ringkasan {
    padding: 1.75rem;
  }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
  .header-price {
    text-align: right !important;
  }
  .header-quantity {
    text-align: right !important;
  }
}

@media (min-width: 2000px) {
}
</style>
