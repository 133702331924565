<template>
    <div class="card-dashboard p-2">
        <div class="px-3 pt-3 header-dashboard">
            <h4 class="px-2 pt-2">Detail Transaksi</h4>
        </div>
        <div class="mt-3 p-0">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link" 
                @click.prevent="tabclick('detail_pesanan')" 
                :class="{
                    'active': dataid=='detail_pesanan',
                }"
                id="detail_pesanan" 
                data-toggle="tab"
                role="tab" 
                aria-controls="detail_pesanan" 
                aria-selected="true">Detail Pesanan</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" 
                @click.prevent="tabclick('Timeline')" 
                :class="{
                    'active': dataid=='Timeline',
                }" 
                id="Timeline" 
                data-toggle="tab"
                role="tab" 
                aria-controls="Timeline" 
                aria-selected="false">Log Transaksi</a>
            </li>
            <li class="nav-item" v-if="detailtransaksi?.data?.status == 'received' || detailtransaksi?.data?.status == 'done_refund' && !checksdata">
                <a class="nav-link" 
                @click.prevent="tabclick('komplain')" 
                :class="{
                    'active': dataid=='komplain',
                }" 
                id="komplain" 
                data-toggle="tab"
                role="tab" 
                aria-controls="komplain" 
                aria-selected="false">Komplain</a>
            </li>
            <li class="nav-item" v-if="detailtransaksi?.data?.status == 'received' || detailtransaksi?.data?.status == 'done_refund' && !checksdata">
                <a class="nav-link" 
                @click.prevent="tabclick('ulasan')" 
                :class="{
                    'active': dataid=='ulasan',
                }" 
                id="ulasan" 
                data-toggle="tab"
                role="tab" 
                aria-controls="ulasan" 
                aria-selected="false">Ulasan</a>
            </li>
            </ul>
            <div class="tab-content container-fluid">
            
            </div>
        </div>
        <div>
        <div class="w-100 background-white pb-md-1">
            <detail-pesanan @transaksidata=transaksidata :checksdata=checksdata class="pb-5" v-if="dataid == 'detail_pesanan'"></detail-pesanan>
            <timeline class="pb-5" v-else-if="dataid == 'Timeline'" :datatimeline=datatimeline></timeline>
            <data-ulasan @transaksidata=transaksidata :detailtransaksi=detailtransaksi class="pb-5" v-else-if="dataid == 'ulasan'"></data-ulasan>
            <data-complain @transaksidata=transaksidata :detailtransaksi=detailtransaksi class="pb-5" v-else-if="dataid == 'komplain'"></data-complain>
        </div>
    </div>
    </div>
</template>
<script>
import DetailPesanan from '../../components/Transaksi/DetailPesanan.vue'
import Timeline from '../../components/Transaksi/LogTransaksi.vue'
import DataUlasan from '../../components/Transaksi/UlasanData.vue'
import DataComplain from '../../components/Transaksi/ComplainData.vue'
import axios from "axios";
export default {
    data() {
        return {
        dataid:'detail_pesanan',
        transaksi_id: '',
        datanego: '',
        status: '',
        datatimeline:[],
        detailtransaksi:{
            data:{
                status:'pending'
            }
        }
        };
    },
    components: {
        DetailPesanan,
        Timeline,
        DataUlasan,
        DataComplain
  },
  mounted() {
    let dataid = this.$route.query.id_transaksi;
    axios
      .get("/transaction/log/" + dataid)
      .then((res) => {
        this.loading = false;
        // console.log(res.data.data.log);
        // console.log(res.data.data.log_status);
        var log = res.data.data.log
        var logstatus = res.data.data.log_status
        logstatus.forEach(element => {
          log.push(element)
        });
        // pengecekan data apakah data terakhir di cek dan yang baru memilikki waktu yang berbeda, dan yang terbaru akan dipindahkan ke bawah
        var datalog =log.slice().sort((a, b) => {
        // Convert the date strings to JavaScript Date objects for proper comparison
        const dateA = new Date(a.updated_at);
        const dateB = new Date(b.updated_at);

        // Compare the date objects
        return dateA - dateB;
      });
        this.datatimeline = datalog;
      })
      .catch(() => {
        this.loading = false;
      });
  },
  computed:{
    checksdata(){
        // pengecekan log data yang sebelumnya cancel dan menjadi done refund, ini dibuat karena done refund dapat dilakukan dari beberapa kondisi. dan dengan log sebelumnya itu memilikki case tampilan yang berbeda, ini dilakukan disini karena data log digunakan untuk case pada detail pesanan, dan juga harus ditampilkan di log status
        return this.datatimeline.some((obj)=>obj.new_value == 'done_refund' && obj.old_value == 'cancel')
    }
  },
  methods:{
    transaksidata(value){
        this.detailtransaksi=value
    },
      tabclick(data){
          this.dataid = data
      },
  }
}
</script>
<style scoped>
.nav-item{
    cursor: pointer !important;
}
.card-dashboard{
    border-radius: 10px;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}
.header-dashboard h4{
    font-weight: 600;
}

.nav-link{
    color: rgba(0, 0, 0, 0.6);
}
.active{
    color:#1F7ACF !important;
}
.nav-tabs{
    position: relative !important;
}
.bg-red{
    background-color: #1F7ACF !important;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .card-data-user{
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
}
}

@media (min-width: 1023px) {
    .card-user{
    margin-top: 0rem;
    }
    }

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>