<template>
  <div class="card-dashboard p-3 p-lg-5">
    <div class="header-alamat">
      <h4><b>Tambah Alamat</b></h4>
    </div>
    <form class="mt-3" @submit.prevent="simpanAlamat()">
      <div class="form-profile-info row">
        <input type="hidden" name="" v-model="customer_id" />
        <div class="form-group col-sm-12 col-md-6 mb-3">
          <label class="form-label"
            >Nama Penerima <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control input w-100"
            id="receipt_name"
            v-model="receipt_name"
            :class="[v$.receipt_name.$error ? 'is-invalid' : '']"
          />
          <span v-if="v$.receipt_name.$error" class="text-danger font-small">
            {{ v$.receipt_name.$errors[0].$message }}</span
          >
        </div>
        <div class="form-group col-sm-12 col-md-6 mb-3">
          <label class="form-label">No Telepon <span class="text-danger">*</span></label>
          <input
            type="text"
            id="receipt_phone_number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            class="form-control input w-100"
            v-model="receipt_phone_number"
            :class="[v$.receipt_phone_number.$error ? 'is-invalid' : '']"
          />
          <span v-if="v$.receipt_phone_number.$error" class="text-danger font-small">
            {{ v$.receipt_phone_number.$errors[0].$message }}</span
          >
        </div>
        <div class="form-group col-sm-12 col-md-6 mb-3">
          <label for="province_id">Provinsi <span class="text-danger">*</span></label>
          <select
            id="province_id"
            class="form-control mt-1 input"
            v-model="province_id"
            @change="getWilayah('district', $event.target.value)"
            :class="[v$.province_id.$error ? 'is-invalid' : '']"
          >
            <option value="" disabled>Pilih Provinsi</option>
            <option
              :value="provinsi.province_id"
              v-for="provinsi in provinces"
              :key="provinsi.province_id"
            >
              {{ provinsi.province_name }}
            </option>
          </select>
          <span v-if="v$.province_id.$error" class="text-danger font-small">
            {{ v$.province_id.$errors[0].$message }}</span
          >
        </div>
        <div class="form-group col-sm-12 col-md-6 mb-3">
          <label for="district_id">Kabupaten <span class="text-danger">*</span></label>
          <select
            id="district_id"
            class="form-control mt-1 input"
            v-model="district_id"
            @change="getWilayah('sub-district', $event.target.value)"
            :class="[v$.district_id.$error ? 'is-invalid' : '']"
          >
            <option value="" disabled>Pilih Kabupaten</option>
            <option
              :value="district.city_id"
              v-for="district in districts"
              :key="district.city_id"
            >
              {{ district.city_name }}
            </option>
          </select>
          <span v-if="v$.district_id.$error" class="text-danger font-small">
            {{ v$.district_id.$errors[0].$message }}</span
          >
        </div>

        <div class="form-group col-sm-12 col-md-6 mb-3">
          <label for="sub_district_id"
            >Kecamatan <span class="text-danger">*</span></label
          >
          <select
            id="sub_district_id"
            class="form-control mt-1 input"
            v-model="sub_district_id"
            :class="[v$.sub_district_id.$error ? 'is-invalid' : '']"
          >
            <option value="" disabled>Pilih Kecamatan</option>
            <option
              :value="sub_district.subdistrict_id"
              v-for="sub_district in sub_districts"
              :key="sub_district.sub_districtid"
            >
              {{ sub_district.subdistrict_name }}
            </option>
          </select>
          <span v-if="v$.sub_district_id.$error" class="text-danger font-small">
            {{ v$.sub_district_id.$errors[0].$message }}</span
          >
        </div>
        <div class="form-group col-12 mb-3">
          <label class="form-label">Alamat <span class="text-danger">*</span></label>
          <textarea
            class="form-control input w-100"
            id="address"
            v-model="address"
            :class="[v$.address.$error ? 'is-invalid' : '']"
          ></textarea>
          <span v-if="v$.address.$error" class="text-danger font-small">
            {{ v$.address.$errors[0].$message }}</span
          >
        </div>
        <div class="datamaps">
          <pickmap @setmap="setmap" :mapdata="markers"></pickmap>
          <small class="px-2 px-md-3">Tambahkan pin sesuai dengan alamat rumah anda. <span class="text-danger">*</span> </small>
        </div>
        <div class="col-12 text-right">
          <router-link :to="backurl" class="btn btn-outline-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style="transform: ; msfilter: "
            >
              <path
                d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"
              ></path>
            </svg>
            Kembali
          </router-link>
          <button type="submit" class="btn btn-primary" style="margin-left: 5px">
            <i class="bx bxs-save"></i> Simpan
          </button>
        </div>
      </div>
    </form>
    <b-modal id="modal-loading" centered hide-footer hide-header>
      <div class="text-center">
        <b-spinner variant="light"></b-spinner>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import '../../assets/css/sweetalertstyle.css'
import useVuelidate from "@vuelidate/core";
import pickmap from "./PickMap.vue";
import { required, helpers, numeric } from "@vuelidate/validators";

export default {
  name: "FormUserAddress",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      customer_id: this.$store.state.user?.id,
      receipt_name: "",
      address: "",
      province_id: "",
      district_id: "",
      sub_district_id: "",
      receipt_phone_number: "",
      provinces: "",
      districts: "",
      sub_districts: "",
      address_id: "",
      backurl:'',
      loadingSubmit: false,
      toastShowed: "hide-before",
      markers: [],
    };
  },
  validations() {
    return {
      receipt_name: {
        required: helpers.withMessage("Nama penerima tidak boleh kosong", required),
      },
      address: {
        required: helpers.withMessage("Alamat tidak boleh kosong", required),
      },
      province_id: {
        required: helpers.withMessage("Silahkan pilih provinsi", required),
      },
      district_id: {
        required: helpers.withMessage("Silahkan pilih kabupaten", required),
      },
      sub_district_id: {
        required: helpers.withMessage("Silahkan pilih kecamatan", required),
      },
      receipt_phone_number: {
        required: helpers.withMessage("No telepon tidak boleh kosong", required),
        numeric: helpers.withMessage("Pastikan data yang diinput berupa angka", numeric),
      },
    };
  },
  components:{
    pickmap
  },
  created() {},
  methods: {
    setmap(data){
      this.markers =data
    },
    getWilayah(wilayah, value) {
      wilayah = "customer/" + wilayah;
      let cek_wilayah = wilayah;
      value ? (wilayah += "/" + value) : wilayah;
      if (cek_wilayah == "customer/district") {
        this.districts = [];
        this.sub_districts = [];
        document.getElementById("district_id").selectedIndex = 0;
        document.getElementById("sub_district_id").selectedIndex = 0;
        this.district_id = "";
        this.sub_district_id = "";
      } else if (cek_wilayah == "customer/sub-district") {
        this.sub_district_id = "";
        this.sub_districts = [];
        document.getElementById("sub_district_id").selectedIndex = 0;
      }
      // pengecekan api daerah yang perlu dicari
      axios
        .get(wilayah)
        .then((response) => {
          if (cek_wilayah == "customer/province") {
            this.provinces = response.data.data;
          } else if (cek_wilayah == "customer/district") {
            this.districts = response.data.data;
          } else if (cek_wilayah == "customer/sub-district") {
            this.sub_districts = response.data.data;
          }
        })
        .catch(() => {});
    },
    kembali() {
      this.$emit("kembali");
    },
    async simpanAlamat() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        if (this.markers[0]?.latLng?.lat && this.markers[0]?.latLng?.lng) {
          let form = new FormData();
          form.append("customer_id", this.customer_id);
          form.append("receipt_name", this.receipt_name);
          form.append("address", this.address);
          form.append("province_id", this.province_id);
          form.append("district_id", this.district_id);
          form.append("sub_district_id", this.sub_district_id);
          form.append("receipt_phone_number", this.receipt_phone_number);
          form.append("lat", this.markers[0]?.latLng?.lat);
          form.append("long", this.markers[0]?.latLng?.lng);
          this.loadingSubmit = true;
          this.$bvModal.show("modal-loading");
          axios
            .post(`/customer/address/create`, form)
            .then(() => {
              this.loadingSubmit = false;
              this.$store.dispatch("address").then(() => {
                this.$bvModal.hide("modal-loading");
                this.$router.push(this.backurl);
                this.loadingSubmit = false;
                swal("Berhasil!!!", "Data alamat berhasil ditambahkan", "success", {
                  button: "OK",
                });
              });
            })
            .catch((e) => {
              this.$bvModal.hide("modal-loading");
              this.loadingSubmit = false;
              this.massageadmin = e.response.data.message;
              swal("Ooops", e.response.data.message, "error", {
                button: "OK",
              });
            });
        } else {
          swal("Ooops", "Tolong tentukan alamat dalam peta terlebih dahulu", "error", {
            button: "OK",
          });
        }
      }
    },
    resetForm() {
      this.receipt_name = "";
      this.receipt_phone_number = "";
      this.address = "";
      this.name = "";
      this.province_id = "";
      this.district_id = "";
      this.sub_district_id = "";
    },

    setToast(param = null) {
      if (param == null) {
        this.toastShowed = "show";
        setTimeout(() => (this.toastShowed = "hide-after"), 5000);
      } else {
        this.toastShowed = param;
      }
    },
  },
  mounted() {
    this.getWilayah("province");
    let url =this.$route.query.back
    if(url != undefined){
      this.backurl = '/'+url
    }else{
      this.backurl = '/profil/alamat'
    }
  },
  computed: {},
  watch: {},
};
</script>

<style scoped>
::v-deep #modal-loading .modal-content {
  background-color: transparent;
  border: 0px !important;
}
.maps{
  position: relative;
  z-index: 100;
}
.text-right {
  text-align: right;
  width: 100%;
}
::v-deep .btn-outline-primary svg {
  fill: #0b5ed7;
}
::v-deep .btn-outline-primary:hover > svg {
  fill: #fff;
}
</style>
