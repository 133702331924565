<template>
  <div class="background-white p-3">
    <div class="form-profile-body">
      <div>
        <b-button v-b-modal.modal-complain variant="primary">Tambah Komplain</b-button>
      </div>
    </div>
    <b-modal id="modal-complain" size="lg" hide-footer title="Tambah Complain">
      <form @submit.prevent="addComplain">
        <div class="form-profile-body">
          <div>
            <label for="" class="mt-3">Produk</label>
            <div class="container-fluid px-lg-3 px-1" style="margin: 0px">
              <div v-if="datatransaksi.length <= 0" class="row">
                <p style="width: 100%; text-align: center">
                  Mohon Maaf tidak terdapat produk yang dapat di ulas pada ID pesanan
                  diatas
                </p>
              </div>
              <div v-else-if="trasaksidata" style="padding: 0px" class="row">
                <div
                  v-for="(produk, index) in trasaksidata"
                  :key="index"
                  class="col-md-6 p-lg-3 mt-2"
                >
                  <div v-if="produk?.qty_available > 0"
                    class="card data-tabel w-100"
                    :class="{ console_log: produk.id == detail_id }"
                    @click="setId(produk.id)"
                  >
                    <div class="d-flex">
                      <figure
                        class="img-profile"
                        :id="'image' + produk.id"
                      >
                        <img :src="$imgProduct + dataimg(produk)"
                        @error="imageerror(produk.id)" alt="">
                      </figure>

                      <figure
                        class="d-none img-profile img-error"
                        :id="'errorimage' + produk.id"
                      ></figure>
                      <div class="py-2 px-1 text-product">
                        <h6>{{ produk.name_product }}</h6>
                        <div v-if="parseInt(produk.discount) > 0">
                          <p class="m-0 text-danger">{{ produk?.qty_available }}
                          {{ produk?.unit }}
                          <small>X</small> Rp
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(produk?.price_after_discount).toFixed()
                            )
                          }}</p>
                          <div class="diskon">
                          <p>
                            {{ parseInt(produk?.discount) }}%
                          </p>
                          <small
                            ><s
                              >Rp
                              {{
                                new Intl.NumberFormat("id-ID").format(
                                  Number(parseInt(produk?.price)).toFixed()
                                )
                              }}</s
                            ></small
                          >
                        </div>
                        </div>
                        <p v-else>
                          {{ produk?.qty_available }}
                          {{ produk?.unit }}
                          <small>X</small> Rp
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(produk?.price).toFixed()
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="px-2 w-100">
                      <p class="mb-0 text-align-right"><b>Total Harga</b></p>
                      <h6 class="text-danger">
                        Rp
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(produk?.subtotal).toFixed()
                          )
                        }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="datatransaksi.length == 1" class="row">
                <div
                  v-for="(produk, index) in trasaksidata.transaction_detail"
                  :key="index"
                  class="col-md-6 p-md-3 mt-5"
                >
                  <div v-if="produk?.qty_available > 0"
                    class="card data-tabel w-100"
                    :class="{ console_log: produk.id == detail_id }"
                    @click="setId(produk.id)"
                  >
                    <div class="d-flex">
                      <figure
                        class="img-profile"
                        :id="'image' + produk.id"
                      >
                        <img :src="$imgProduct + dataimg(produk)" 
                        @error="imageerror(produk.id)" alt="">
                      </figure>

                      <figure
                        class="d-none img-profile img-error"
                        :id="'errorimage' + produk.id"
                      ></figure>
                      <div class="py-2 px-1 text-product">
                        <h6>{{ produk.name_product }}</h6>
                        <div v-if="parseInt(produk.discount) > 0">
                          <p class="m-0 text-danger">{{ produk?.qty_available }}
                          {{ produk?.unit }}
                          <small>X</small> Rp
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(produk?.price_after_discount).toFixed()
                            )
                          }}</p>
                          <div class="diskon">
                          <p>
                            {{ parseInt(produk?.discount) }}%
                          </p>
                          <small
                            ><s
                              >Rp
                              {{
                                new Intl.NumberFormat("id-ID").format(
                                  Number(parseInt(produk?.price)).toFixed()
                                )
                              }}</s
                            ></small
                          >
                        </div>
                        </div>
                        <p v-else>
                          {{ produk?.qty_available }}
                          {{ produk?.unit }}
                          <small>X</small> Rp
                          {{
                            new Intl.NumberFormat("id-ID").format(
                              Number(produk?.price).toFixed()
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="px-2 w-100">
                      <p class="mb-0 text-align-right"><b>Total Harga</b></p>
                      <h6 class="text-danger">
                        Rp
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(produk?.subtotal).toFixed()
                          )
                        }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="trasaksidata.length > 1" class="row">
                <div
                  v-for="(produk, index) in trasaksidata"
                  :key="index"
                  class="col-md-6 p-md-3 mt-5"
                >
                  <table
                    class="data-tabel w-100 mt-2"
                    :class="{ console_log: produk.id == detail_id }"
                    @click="setId(produk.id)"
                  >
                    <tr>
                      <td class="td-nama-produk">
                        <div class="nama-produk">
                          <figure
                            class="img-profile"
                          >
                            <img :src="$imgProductUrl + dataimg(produk)" alt="">
                          </figure>
                          <h6 class="px-2">{{ produk.name_product }}</h6>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="w-100" v-else>
                <p style="width: 100%; text-align: center">
                  Mohon Maaf tidak terdapat produk yang dapat di ulas pada ID pesanan
                  diatas
                </p>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <small class="error text-danger mt-5" v-if="alertprodukid"
              >Produk Wajib Dipilih</small
            >
          </div>
          <div class="form-group mt-5">
            <label for="keterangan">Keterangan Komplain</label>
            <textarea
              class="form-control mt-2"
              v-model="keterangan"
              :class="{ 'border-danger': v$.keterangan.$error }"
              name="keterangan"
              id="keterangan"
              rows="3"
            ></textarea>
            <small
              class="error text-danger"
              v-for="(error, index) of v$.keterangan.$errors"
              :key="index"
            >
              {{ error.$message }}
            </small>
          </div>

          <div class="w-100 px-3 pb-4 pt-3 card mt-3">
            <small class="text-danger w-100"
              ><b>Note: </b> Untuk melakukan komplain wajib melakukan upload video
              unboxing!</small
            >
            <div class="pt-2">
              <div @click="addInput()" class="btn btn-primary float-right">
                Tambah Bukti
              </div>
            </div>
          </div>
          <div class="w-100">
            <div v-for="(bukti, index) in databukti" :key="index">
              <label class="mt-3" for="bukti">Upload Bukti Komplain {{ index + 1 }}</label
              ><br />
              <div style="display: flex">
                <label :for="`file${index}`" class="input-group flex-nowrap">
                  <label
                    style="background-color: #e9ecef; color: #000"
                    :for="`file${index}`"
                    type="button"
                    class="btn btn-outline-secondary label-file"
                    >Choose File</label
                  >
                  <div
                    class="d-flex align-items-center border border-secondary p-2 w-100"
                    style="overflow-x: hidden"
                  >
                    <p class="mb-0">{{ bukti.fileName }}</p>
                  </div>
                </label>
                <input
                  :id="`file` + index"
                  type="file"
                  @change="inputfile($event, index)"
                  class="d-none form-control mt-2"
                  name="data"
                  accept=".jpg,.jpeg,.png,.webp,.mp4,.mpeg,.mov,.3gp,.mkv,.avi"
                  placeholder="Upload Bukti Komplain"
                />
                <div class="mt-2 view-web" style="margin-left: 10px">
                  <p
                    class="bg-danger btn-delete text-white bridge"
                    @click="deletefile(index)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style="transform: ; msfilter: "
                    >
                      <path
                        d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"
                      ></path>
                      <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                    </svg>
                  </p>
                </div>
              </div>
              <div class="mt-2 view-mobile">
                <p
                  class="bg-danger btn-delete text-white bridge"
                  @click="deletefile(index)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style="transform: ; msfilter: "
                  >
                    <path
                      d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"
                    ></path>
                    <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                  </svg>
                </p>
              </div>
            </div>
          </div>
          <small
            class="error text-danger"
            v-for="(error, index) of v$.databukti.$errors"
            :key="index"
          >
            {{ error.$message }}
          </small>
          <small v-if="cekdatabukti" class="error text-danger"> Bukti wajib diisi </small>
          <div class="w-100">
            <!-- <button type="submit" class="btn btn-success mt-3">Ajukan Komplain</button> -->
          </div>
        </div>

        <div class="modal-footer">
          <div
            type="button"
            class="btn btn-secondary"
            @click="closemodalform"
            data-bs-dismiss="modal"
          >
            Batal
          </div>
          <button type="submit" class="btn btn-primary">
            <i class="bx bxs-save"></i> Simpan
          </button>
        </div>
      </form>
    </b-modal>
    <b-modal id="modal-loading" centered hide-footer hide-header>
      <div class="text-center">
        <b-spinner variant="light"></b-spinner>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import '../../assets/css/sweetalertstyle.css'
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  props: ["datatransaksi"],
  name: "Add-Complain",
  data() {
    return {
      inputs: [],
      datakirim: {
        datacomplaint: {},
        transaksidata: {},
      },
      keterangan: "",
      transaksi_id: "",
      detail_id: "",
      modalTipe: "",
      massageadmin: "",
      alertprodukid: false,
      databukti: [],
      cekdatabukti: false,
      loadingSubmit: false,
      toastShowed: "hide-before",
      loading: true,
      LoadingSkeleton: true,
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      databukti: {
        required: helpers.withMessage("Bukti wajib diisi", required),
      },
      keterangan: {
        required: helpers.withMessage("Keterangan Komplain wajib diisi", required),
      },
    };
  },
  watch: {
    detail_id() {
      this.alertprodukid = false;
    },
  },

  created() {},
  computed: {
    trasaksidata(){
      return Object.values(this.datatransaksi).filter((el)=>{
        return el.qty_available > 0
      })
    }
  },
  methods: {
    dataimg(value){
      if(value.product_price.image != null){
        return value.product_price.image.image
      }else{
        return value.image
      }
    },
    imageerror(id) {
      document.getElementById("image" + id).classList.add("d-none");
      document.getElementById("errorimage" + id).classList.remove("d-none");
      document.getElementById("errorimage" + id).classList.add("d-block");
      // event.target.src = "../../assets/img/image_icon.png"
    },
    closemodalform() {
      this.$bvModal.hide("modal-complain");
    },
    setId(value) {
      this.detail_id = value;
    },
    deletefile(value) {
      this.databukti.splice(value, 1);
      this.inputs.splice(value, 1);
      this.cekdatabukti = false;
    },
    addInput() {
      let template = {
        fileName: "No file chosen",
        file: null,
      };
      if (
        this.databukti.length == 0 ||
        this.databukti[this.databukti.length - 1].file != null
      ) {
        this.databukti.push(template);
      }
    },
    inputfile(event, index) {
      let file = event.target.files[0];
      if (
        file["type"] === "image/jpeg" ||
        file["type"] === "image/jpg" ||
        file["type"] === "image/png" ||
        file["type"] === "image/webp"
      ) {
        if (file["size"] <= 2048000) {
          this.databukti[index].file = event.target.files[0];
          this.databukti[index].fileName = event.target.files[0].name;
        } else {
          document.getElementById(`file` + index).value = "";
          swal("Ooops", "Ukuran format Gambar anda lebih dari 2mb", "error", {
            button: "OK",
          });
          // this.$swal("Ooopss", "Ukuran format Gambar anda lebih dari 2mb", "error", {
          // button: "OK"})
        }
      } else if (
        file["type"] === "video/mp4" ||
        file["type"] === "video/*" ||
        file["type"] === "video/mpeg" ||
        file["type"] === "video/mov" ||
        file["type"] === "video/3gp" ||
        file["type"] === "video/mkv" ||
        file["type"] === "video/avi"
      ) {
        if (file["size"] <= 51200000) {
          this.databukti[index].file = event.target.files[0];
          this.databukti[index].fileName = event.target.files[0].name;
        } else {
          document.getElementById(`file` + index).value = "";
          swal("Ooops", "Ukuran format Video anda lebih dari 50mb", "error", {
            button: "OK",
          });
        }
      } else {
        document.getElementById(`file` + index).value = "";
        swal(
          "Ooops",
          "Format File Hanya berupa(.jpg, .png, .jpeg, .webp, .mp4, .mov, .avi, .mpeg, .3gp)",
          "error",
          {
            button: "OK",
          }
        );
      }
    },
    addComplain() {
      if (!this.detail_id) {
        this.alertprodukid = true;
      } else if (this.detail_id) {
        this.alertprodukid = false;
      }
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.cekdatabukti = true;
      }
      let k = 0;
      for (let i = 0; i <= this.databukti.length; i++) {
        if (this.databukti[k].file != null) {
          this.cekdatabukti = false;
          break;
        } else {
          this.cekdatabukti = true;
        }
        k++;
      }
      if (!this.v$.$invalid && this.detail_id && !this.cekdatabukti) {
        let data = new FormData();
        data.append("transaction_detail_id", this.detail_id);
        data.append("description_detail", this.keterangan);
        this.databukti.forEach((item) => {
          data.append("media[]", item?.file);
        });

        this.$bvModal.show("modal-loading");

        axios
          .post(`/transaction/complaint`, data)
          .then(() => {
            this.loadingSubmit = false;

            try {
              this.detail_id = ""
              this.keterangan = ""
              this.databukti = []
                this.v$.$reset()
              let idtransaksi = this.$route.query.id_transaksi;
              axios.get("/transaction/complaint/" + idtransaksi).then((res) => {
                this.datakirim.datacomplaint = res.data.data;
                axios.get("/transaction/" + idtransaksi).then((res) => {
                  this.datakirim.transaksidata = res.data.data;
                  this.$emit("datareload", this.datakirim);
                  this.$bvModal.hide("modal-complain");
                  this.$bvModal.hide("modal-loading");
                  swal("Berhasil!!!", "Komplain Anda berhasil Dikirim", "success", {
                    button: "OK",
                  });
                  // this.loading=false
                });
              });
              // this.loading=false
            } catch (error) {
              return error;
            }
          })
          .catch((e) => {
            // this.$swal("Ooops", e.response.data.message, "error", {
            //     button: "OK"})
            this.$bvModal.hide("modal-loading");
            this.loadingSubmit = false;
            if (
              e.response.data.message ==
              "You already make complaint with this transaction"
            ) {
              this.massageadmin =
                "Anda Sudah melakukan komplain pada produk didalam invoice ini";
            } else {
              this.massageadmin = e.response.data.message;
            }
            swal("Ooops", this.massageadmin, "error", {
              button: "OK",
            });
          });
      } else if (!this.detail_id) {
        this.$bvModal.hide("modal-loading");
        this.alertprodukid = true;
      }

      this.$bvModal.hide("modal-loading");
    },
  },
};
</script>

<style scoped>
::v-deep #modal-loading .modal-content {
  background-color: transparent;
  border: 0px !important;
}
::v-deep .modal-header button {
  border: 0px !important;
  background-color: #fff !important;
}
.diskon {
  display: flex;
}
.diskon p {
  color: #fff;
  font-size: 8px !important;
  padding: 3px;
  border-radius: 3px;
  background-color: red;
}
.diskon s {
  color: gray;
  font-size: 0.8rem !important;
  padding-left: 5px;
  text-decoration: line-through !important;
}
.img-error {
  background-image: url(../../assets/img/image_icon.png);
}
.data-tabel {
  border: 1px solid grey;
  border-radius: 15px;
}
.btn-delete {
  padding: 0.75rem 0.7rem;
  border-radius: 5px;
  display: initial;
  cursor: pointer;
}
.btn-delete svg {
  fill: #fff;
}
.img-profile {
  padding: 5px;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 0px;
}
.img-profile img {
  width: 100%;
}
.td-nama-produk {
  max-width: 200px !important;
}
.nama-produk {
  display: flex;
  margin: 0px;
  margin-right: 0px !important;
}
.nama-produk h6 {
  vertical-align: inherit;
  margin: auto 0;
}
.console_log {
  border: 2px solid red !important;
}
.label-file {
  width: 175px;
}
.view-web {
  display: none;
}
.view-mobile {
  display: block;
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .label-file {
    width: 140px;
  }
  .view-web {
    display: block;
  }
  .view-mobile {
    display: none;
  }
  ::v-deep .modal-lg {
    --bs-modal-width: 700px !important;
  }
}

@media (min-width: 1023px) {
  .label-file {
    width: 130px;
  }
}

@media (min-width: 1439px) {
  .label-file {
    width: 125px;
  }
}

@media (min-width: 2000px) {
}
</style>
