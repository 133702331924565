var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-dashboard p-2"},[_vm._m(0),_c('div',{staticClass:"mt-3 p-0"},[_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{
                'active': _vm.dataid=='detail_pesanan',
            },attrs:{"id":"detail_pesanan","data-toggle":"tab","role":"tab","aria-controls":"detail_pesanan","aria-selected":"true"},on:{"click":function($event){$event.preventDefault();return _vm.tabclick('detail_pesanan')}}},[_vm._v("Detail Pesanan")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{
                'active': _vm.dataid=='Timeline',
            },attrs:{"id":"Timeline","data-toggle":"tab","role":"tab","aria-controls":"Timeline","aria-selected":"false"},on:{"click":function($event){$event.preventDefault();return _vm.tabclick('Timeline')}}},[_vm._v("Log Transaksi")])]),(_vm.detailtransaksi?.data?.status == 'received' || _vm.detailtransaksi?.data?.status == 'done_refund' && !_vm.checksdata)?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{
                'active': _vm.dataid=='komplain',
            },attrs:{"id":"komplain","data-toggle":"tab","role":"tab","aria-controls":"komplain","aria-selected":"false"},on:{"click":function($event){$event.preventDefault();return _vm.tabclick('komplain')}}},[_vm._v("Komplain")])]):_vm._e(),(_vm.detailtransaksi?.data?.status == 'received' || _vm.detailtransaksi?.data?.status == 'done_refund' && !_vm.checksdata)?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{
                'active': _vm.dataid=='ulasan',
            },attrs:{"id":"ulasan","data-toggle":"tab","role":"tab","aria-controls":"ulasan","aria-selected":"false"},on:{"click":function($event){$event.preventDefault();return _vm.tabclick('ulasan')}}},[_vm._v("Ulasan")])]):_vm._e()]),_c('div',{staticClass:"tab-content container-fluid"})]),_c('div',[_c('div',{staticClass:"w-100 background-white pb-md-1"},[(_vm.dataid == 'detail_pesanan')?_c('detail-pesanan',{staticClass:"pb-5",attrs:{"checksdata":_vm.checksdata},on:{"transaksidata":_vm.transaksidata}}):(_vm.dataid == 'Timeline')?_c('timeline',{staticClass:"pb-5",attrs:{"datatimeline":_vm.datatimeline}}):(_vm.dataid == 'ulasan')?_c('data-ulasan',{staticClass:"pb-5",attrs:{"detailtransaksi":_vm.detailtransaksi},on:{"transaksidata":_vm.transaksidata}}):(_vm.dataid == 'komplain')?_c('data-complain',{staticClass:"pb-5",attrs:{"detailtransaksi":_vm.detailtransaksi},on:{"transaksidata":_vm.transaksidata}}):_vm._e()],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-3 pt-3 header-dashboard"},[_c('h4',{staticClass:"px-2 pt-2"},[_vm._v("Detail Transaksi")])])
}]

export { render, staticRenderFns }