<template>
    <div>
      <Loader v-if="loading" />
  <div class="container-fluid">
    <div class="header-data container border-bottom">
      <figure>
        <img src="../assets/logo_arys_mikro.jpeg" alt="" />
      </figure>
    </div>
    <div class="mt-3 content-konfirmasi">
      <figure>
        <img src="../assets/img/congrats.svg" alt="" />
      </figure>
      <h3 class="text-center w-100 mt-3">Registrasi Berhasil</h3>
      <p class="text-center px-2 px-md-5">
        Selamat anda telah berhasil registrasi sebagai customer Ary's Mikro <br />
        Silahkan klik tombol dibawah ini untuk Verifikasi akun anda
      </p>
    </div>
    <button class="btn btn-primary" @click="redirectdata">verifikasi</button>
  </div>
    </div>
</template>
<script>
import Loader from "@/components/Loader/Loader.vue";
import axios from "axios";
import swal from "sweetalert";
import '../assets/css/sweetalertstyle.css'
export default {
  data() {
    return {
      errorMessage: [],
      loading:false
    };
  },
  components: {
    Loader,
  },
  methods: {
    redirectdata() {
      // /customer/verify-email
      this.loading = true
      let data = new FormData();
      data.append("token", this.$route.query.token);
      data.append("url", window.location.origin + "/login");
      axios
        .post("/customer/verify-email", data)
        .then(() => {
          this.$router.push("/login");
        })
        .catch((error) => {     
          this.loading = false
          let val = Object.values(error.response.data);
          let key = Object.keys(error.response.data);
          for (let j = 0; j < key.length; j++) {
            if (key[j] == "message") {
              if (val[j] != "") {
                this.errorMessage = val[j];
              }
            }
          }
          if (key.length <= 0) {
            swal("Ooops", "Terdapat Kesalahan, silahkan hubungi Admin", "error", {
              button: "OK",
            });
          }
        });
    },
  },
};
</script>
<style scoped>
.border-bottom {
  border-bottom: 1px solid #cdcdcd;
}
.header-data figure {
  display: block;
  width: 180px;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  margin: 0 auto;
}
.header-data img {
  width: 100%;
}
.content-konfirmasi figure {
  width: 90%;
  margin: 0 auto;
  display: block;
}
.content-konfirmasi h3 {
  font-weight: 700;
}
.content-konfirmasi img {
  width: 100%;
}
button {
  display: block;
  margin: 0 auto;
}
@media (min-width: 750px) {
  .content-konfirmasi figure {
    max-width: 700px;
  }
}
</style>
