<template>
  <div>
    <Loader v-if="loading"></Loader>
    <PesananSkeleton v-if="loading"></PesananSkeleton>
    <div v-if="!loading" class="container">
      <div class="container-fluid px-0 px-md-2 mt-4">
        <span class="text-header-detail"
          ><b>Tanggal</b> : {{ dateTime(datatransaction.updated_at) }}</span
        ><br />
        <span class="text-header-detail"
          ><b>ID Pesanan</b> : {{ datatransaction.invoice }}</span
        ><br />
        <span class="text-header-detail"
          ><b>Status</b> :
          <span
            class="badge text-light bg-secondary"
            v-if="toLowerCase(datatransaction?.status) == 'ready_to_pickup'"
            >Siap Diambil</span
          >
          <span
            class="badge text-light bg-secondary"
            v-else-if="toLowerCase(datatransaction?.status) == 'waiting_pickup'"
            >Waiting Pickup</span
          >
          <span
            class="badge text-light bg-warning"
            v-else-if="toLowerCase(datatransaction?.status) == 'pending_payment'"
            >Pending Payment</span
          >
          <span
            class="badge text-light bg-success"
            v-else-if="toLowerCase(datatransaction?.status) == 'process'"
            >Sedang di Proses</span
          >
          <span
            class="badge text-light bg-secondary"
            v-else-if="toLowerCase(datatransaction?.status) == 'shipping'"
            >Dikirim</span
          >
          <span
            class="badge text-light bg-warning"
            v-else-if="toLowerCase(datatransaction?.status) == 'pending'"
            >Menunggu di Proses</span
          >
          <span
            class="badge text-light bg-warning"
            v-else-if="toLowerCase(datatransaction?.status) == 'waiting_payment'"
            >Menunggu Pembayaran</span
          >
          <span
            class="badge text-light bg-danger"
            v-else-if="toLowerCase(datatransaction?.status) == 'payment_expired'"
            >Dibatalkan</span
          >
          <span
            class="badge text-light bg-danger"
            v-else-if="toLowerCase(datatransaction?.status) == 'payment_failed'"
            >Pembayaran Gagal</span
          >
          <span
            class="badge text-light bg-danger"
            v-else-if="toLowerCase(datatransaction?.status) == 'cancel'"
            >Dibatalkan</span
          >
          <span
            class="badge text-light bg-success"
            v-else-if="toLowerCase(datatransaction?.status) == 'done_refund'"
            >Dana Dikembalikan
          </span>
          <span
            class="badge text-light bg-success"
            v-else-if="toLowerCase(datatransaction?.status) == 'received'"
            >Diterima
          </span>
          <span
            class="badge text-light bg-success"
            v-else-if="toLowerCase(datatransaction?.status) == 'paid'"
            >Dibayarkan
          </span>
          <span
            v-else
            class="badge text-light"
            :class="{
              'bg-warning':
                toLowerCase(datatransaction?.status) == 'pending' ||
                toLowerCase(datatransaction?.status) == 'pending_payment',
              'bg-success':
                toLowerCase(datatransaction?.status) == 'paid' ||
                toLowerCase(datatransaction?.status) == 'received' ||
                toLowerCase(datatransaction?.status) == 'confirmed',
              'bg-kuning': toLowerCase(datatransaction?.status) == 'document',
              'bg-primary':
                toLowerCase(datatransaction?.status) == 'process' ||
                toLowerCase(datatransaction?.status) == 'payment_process',
              'bg-secondary':
                toLowerCase(datatransaction?.status) == 'shipped' ||
                toLowerCase(datatransaction?.status) == 'shipping' ||
                toLowerCase(datatransaction?.status) == 'ready_to_pickup' ||
                toLowerCase(datatransaction?.status) == 'waiting_pickup',
              'bg-danger':
                toLowerCase(datatransaction?.status) == 'cancel' ||
                toLowerCase(datatransaction?.status) == 'payment_failed' ||
                toLowerCase(datatransaction?.status) == 'payment_expired',
            }"
            >{{ datatransaction.status }}</span
          ></span
        ><br />
        <span class="text-header-detail" v-if="datatransaction.payment_type === 'cod'"
          ><b>Tipe Pembayaran</b> : COD <br
        /></span>
        <span
          class="text-header-detail"
          v-else-if="datatransaction.payment_type === 'payment_gateway'"
          ><b>Tipe Pembayaran</b> : Pembayaran Digital <br />
        </span>
        <span
          class="text-header-detail"
          v-if="datatransaction.delivery_type === 'home_delivery'"
          ><b>Pengiriman Tipe</b> : Pengiriman oleh Kurir
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 36 36"
          >
            <path
              fill="currentColor"
              d="M30 12h-4V7a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h1V8h20v11.7a6.45 6.45 0 0 1 1.56-.2H26V14h4a2 2 0 0 1 2 2v1h-4v2h4v5h-2.4a4.54 4.54 0 0 0-8.34 0h-6.83a4.5 4.5 0 0 0-4.17-2.76A4.38 4.38 0 1 0 14.72 26H21a4.49 4.49 0 0 0 8.92 0H33a1 1 0 0 0 1-1v-9a4 4 0 0 0-4-4ZM10.26 28a2.38 2.38 0 1 1 0-4.75a2.38 2.38 0 1 1 0 4.75Zm15.17 0a2.38 2.38 0 1 1 2.5-2.37a2.44 2.44 0 0 1-2.5 2.37Z"
              class="clr-i-outline clr-i-outline-path-1"
            />
            <path fill="none" d="M0 0h36v36H0z" /></svg
        ></span>
        <span
          class="text-header-detail"
          v-else-if="datatransaction.delivery_type === 'self_pickup'"
          ><b>Pengiriman Tipe</b> : Mengambil sendiri
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 1024 1024"
          >
            <path
              fill="currentColor"
              d="M864 158.704H672.815V97.328c0-52.944-43.056-96-96-96H449.183c-52.944 0-96 43.056-96 96v61.376H159.999c-35.344 0-64 28.656-64 64v735.968c0 35.344 28.656 64 64 64h704c35.344 0 64-28.656 64-64V222.704c0-35.344-28.656-64-64-64H864zM417.184 97.328c0-17.664 14.336-32 32-32h127.632c17.664 0 32 14.336 32 32v61.376H417.184V97.328zM864 958.672H160V222.704h193.184v65.84s-.848 31.967 31.809 31.967c36 0 32.192-31.967 32.192-31.967v-65.84h191.632v65.84s-2.128 32.128 31.872 32.128c32 0 32.128-32.128 32.128-32.128v-65.84h191.184v735.968z"
            /></svg
        ></span>
        <div
          v-if="
            datatransaction?.delivery?.courier != null &&
            toLowerCase(datatransaction?.status) == 'shipping'
          "
        >
          <div
            class="card p-1 py-md-2 px-md-3"
            :class="visible ? null : 'collapsed'"
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="collapse-4"
            @click="visible = !visible"
          >
            <div class="d-flex justify-content-between">
              <div class="text-header-detail btn-detail">Detail Pengirim</div>
              <div class="arrow-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  :transform="visible ? 'rotate(90)' : ''"
                  viewBox="0 0 24 24"
                  style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
                >
                  <path
                    d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <b-collapse id="collapse-4" v-model="visible" class="mt-2">
            <b-card class="card-data">
              <div class="d-flex">
                <div>
                  <figure class="figure-driver">
                    <img
                      :src="
                        $imgProduct + datatransaction?.delivery?.courier?.profile_picture
                      "
                      alt=""
                    />
                  </figure>
                </div>
                <div class="px-3">
                  <h5 class="mb-0 line-height-thin text-header-detail">
                    <b>{{ datatransaction?.delivery?.courier?.name }}</b>
                  </h5>
                  <small class="text-secondary line-height-thin text-bottom-name"
                    >{{ datatransaction?.delivery?.courier?.vehicle_type }} |
                    {{ datatransaction?.delivery?.courier?.license_number }}</small
                  >
                  <div class="mb-0 mt-2 text-header-detail">
                    <div class="d-web-view">Telepon :</div>
                    {{ datatransaction?.delivery?.courier?.phone }}
                  </div>
                </div>
              </div>

              <!-- <div class="vehicle-image">
                <svg
                  v-if="datatransaction?.delivery?.courier?.vehicle_type == 'Motorbike'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="200"
                  height="200"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#7a7a7a"
                    d="M19 7c0-1.1-.9-2-2-2h-3v2h3v2.65L13.52 14H10V9H6c-2.21 0-4 1.79-4 4v3h2c0 1.66 1.34 3 3 3s3-1.34 3-3h4.48L19 10.35V7zM7 17c-.55 0-1-.45-1-1h2c0 .55-.45 1-1 1z"
                  />
                  <path
                    fill="#7a7a7a"
                    d="M5 6h5v2H5zm14 7c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3zm0 4c-.55 0-1-.45-1-1s.45-1 1-1s1 .45 1 1s-.45 1-1 1z"
                  />
                </svg>
                <svg
                  v-else-if="datatransaction?.delivery?.courier?.vehicle_type == 'Truck'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="200"
                  height="200"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#7a7a7a"
                    fill-rule="evenodd"
                    d="M1 3a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v5h4a5 5 0 0 1 5 5v4a3.001 3.001 0 0 1-2.129 2.872a3 3 0 0 1-5.7.128H8.83a3 3 0 0 1-5.7-.128A3.001 3.001 0 0 1 1 17v-4h6a1 1 0 1 0 0-2H1V9h4a1 1 0 0 0 0-2H1V3Zm13 15h1.171a3 3 0 0 1 5.536-.293A.997.997 0 0 0 21 17v-4a3 3 0 0 0-3-3h-4v8Zm-7 1a1 1 0 1 0-2 0a1 1 0 0 0 2 0Zm10.293-.707A.994.994 0 0 0 17 19a1 1 0 1 0 .293-.707Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div> -->
            </b-card>
          </b-collapse>
        </div>
        <div v-if="toLowerCase(datatransaction?.status) == 'cancel' || checksdata">
          <div
            class="card p-1 py-md-2 px-md-3"
            :class="visible ? null : 'collapsed'"
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="collapse-4"
            @click="visible = !visible"
          >
            <div class="d-flex justify-content-between">
              <div class="text-header-detail btn-detail">Catatan Pembatalan</div>
              <div class="arrow-right">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  :transform="visible ? 'rotate(90)' : ''"
                  viewBox="0 0 24 24"
                  style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
                >
                  <path
                    d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <b-collapse id="collapse-4" v-model="visible" class="mt-2">
            <b-card class="card-data">
              <p>{{ datatransaction.notes }}</p>
            </b-card>
          </b-collapse>
        </div>
        <div class="row confirmation-order-info-container web-view mt-3">
          <div class="col-lg-6">
            <div class="confirmation-order-user rounded-lg px-2 py-2 px-md-4 py-md-3">
              <div class="card_info">
                <h5>Informasi User</h5>
                <hr />
                <table class="table-no-border informationTable">
                  <tr>
                    <td>
                      <div class="user_label">
                        <b>Nama</b>
                      </div>
                    </td>
                    <td>: {{ user?.name }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="user_label">
                        <b>Email</b>
                      </div>
                    </td>
                    <td>: {{ user?.email }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="user_label">
                        <b>No Telepon</b>
                      </div>
                    </td>
                    <td>: {{ user?.phone }}</td>
                  </tr>
                  <tr v-if="datatransaction?.customer_address == null">
                    <td>
                      <div class="user_label">
                        <b>Alamat</b>
                      </div>
                    </td>
                    <td>:</td>
                  </tr>
                  <tr v-if="datatransaction?.customer_address != null">
                    <td>
                      <div class="user_label">
                        <b>Alamat</b>
                      </div>
                    </td>
                    <td>:</td>
                  </tr>
                </table>
                <!-- <h6>Alamat</h6> -->
                <p class="px-2" v-if="datatransaction?.delivery != null">
                  <b>{{ datatransaction?.delivery?.recipient_name }}</b
                  ><br />
                  {{ datatransaction?.delivery?.address }},
                  {{ datatransaction?.delivery?.sub_district }},
                  {{ datatransaction?.delivery?.district }},
                  {{ datatransaction?.delivery?.province }}
                  {{ datatransaction?.delivery?.postal_code }}
                </p>
                <p class="px-2" v-else-if="datatransaction?.customer_address != null">
                  {{ dataaddress?.address }},
                  {{ dataaddress?.sub_district?.subdistrict_name }},
                  {{ dataaddress?.district?.city_name }},
                  {{ dataaddress?.province?.province_name }}
                  {{ dataaddress?.district?.postal_code }}
                </p>
                <p class="px-2" v-else-if="address.length > 0">
                  {{ address[0]?.address }},
                  {{ address[0]?.sub_district?.subdistrict_name }},
                  {{ address[0]?.district?.city_name }},
                  {{ address[0]?.province?.province_name }}
                  {{ address[0]?.district?.postal_code }}
                </p>
                <p v-else>-</p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="confirmation-order-ekspedisi px-2 py-2 px-md-4 py-md-3">
              <div class="card_info">
                <h5>Informasi Cabang</h5>
                <hr />
                <table class="table-no-border informationTable">
                  <tr>
                    <td>
                      <div class="user_label_branch">
                        <b>Cabang</b>
                      </div>
                    </td>
                    <td>: {{ databranch?.name }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="user_label_branch">
                        <b>Jam Buka</b>
                      </div>
                    </td>
                    <td>
                      : {{ datastring(databranch?.open) }} -
                      {{ datastring(databranch?.close) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="user_label_branch">
                        <b>letak Cabang</b>
                      </div>
                    </td>
                    <td>
                      :
                      {{ databranch?.address }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="confirmation-order-info-container mobile-view">
          <div
            class="confirmation-order-user border border-4 border-light rounded-lg px-3 py-3 px-md-4 py-md-3"
          >
            <div class="">
              <h5>Informasi User</h5>
              <hr />
              <div class="table-no-border informationTable">
                <p>
                  <b>Nama :</b><br />
                  {{ user?.name }}
                </p>
                <p>
                  <b>Email :</b><br />
                  {{ user?.email }}
                </p>
                <p>
                  <b>No Telepon :</b> <br />
                  {{ user?.phone }}
                </p>

                <p v-if="datatransaction?.delivery != null">
                  <b>Alamat :</b><br />
                  <b>{{ datatransaction?.delivery?.recipient_name }}</b
                  ><br />
                  {{ datatransaction?.delivery?.address }},
                  {{ datatransaction?.delivery?.sub_district }},
                  {{ datatransaction?.delivery?.district }},
                  {{ datatransaction?.delivery?.province }}
                  {{ datatransaction?.delivery?.postal_code }}
                </p>
                <p v-else-if="datatransaction?.customer_address != null">
                  <b>Alamat :</b><br />
                  {{ dataaddress?.address }},
                  {{ dataaddress?.sub_district?.subdistrict_name }},
                  {{ dataaddress?.district?.city_name }},
                  {{ dataaddress?.province?.province_name }}
                  {{ dataaddress?.district?.postal_code }}
                </p>
                <p v-else-if="address?.length > 0">
                  <b>Alamat :</b><br />
                  {{ address[0]?.address }},
                  {{ address[0]?.sub_district?.subdistrict_name }},
                  {{ address[0]?.district?.city_name }},
                  {{ address[0]?.province?.province_name }}
                  {{ address[0]?.district?.postal_code }}
                </p>
                <p v-else>-</p>
              </div>
            </div>
          </div>
          <div
            class="confirmation-order-ekspedisi mt-3 border border-4 border-light rounded-lg px-3 py-3 px-md-4 py-md-3"
          >
            <div class="">
              <h5>Informasi Cabang</h5>
              <hr />
              <div class="table-no-border informationTable">
                <p>
                  <b>Cabang :</b><br />
                  {{ databranch?.name }}
                </p>
                <p>
                  <b>Jam Buka :</b><br />
                  {{ datastring(databranch?.open) }} - {{ datastring(databranch?.close) }}
                </p>
                <p class="mt-2" style="margin-bottom: 0px"><b>Alamat Cabang :</b></p>
                <p class="px-2 alamat_pengiriman">
                  <b> {{ databranch?.address }}</b>
                </p>
              </div>
              <div class="transaksi-footer-bottom">
                <!-- <router-link to="/oke" class="btn btn-danger float-right">Nego Ongkir</router-link> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container container-produk product-data mt-3 mt-md-5">
        <div
          class="bg-white product-data web-view p-md-3 mb-4"
          v-for="(dataproduct, index) in datatransaction?.transaction_detail"
          :key="index"
        >
          <table class="table-product">
            <tr class="header-table">
              <th class="p-md-3 px-3">Product</th>
              <th>Harga</th>
              <th>Qty</th>
              <th>Subtotal</th>
            </tr>
            <tr class="tr-product">
              <td colspan="2" class="p-3 dataproduk">
                <div class="d-flex">
                  <figure class="image mx-1">
                    <img :src="$imgProduct + dataimg(dataproduct)" alt="" />
                  </figure>
                  <div class="mx-1 detail_product">
                    <h6>{{ dataproduct.name_product }}</h6>
                    <p v-if="dataproduct.variant_2">
                      {{ dataproduct.variant_1 }} , {{ dataproduct.variant_2 }}
                    </p>
                    <p v-else-if="!dataproduct.variant_2">
                      {{ dataproduct.variant_1 }}
                    </p>
                  </div>
                </div>
              </td>
              <td class="td-nonproduk">
                <div v-if="Number(dataproduct.price_after_discount) > 1">
                  <h6 class="text-danger mb-0">
                    Rp
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(dataproduct.price_after_discount).toFixed()
                      )
                    }}
                  </h6>
                  <div class="diskon">
                    <p>{{ parseInt(dataproduct.discount) }}%</p>
                    <small
                      ><s
                        >Rp
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(dataproduct.price).toFixed()
                          )
                        }}</s
                      ></small
                    >
                  </div>
                </div>
                <div v-else>
                  <p class="text-danger mb-0">
                    Rp
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(dataproduct.price).toFixed()
                      )
                    }}
                  </p>
                </div>
              </td>
              <td>
                <div v-if="dataproduct.qty != dataproduct.qty_available" class="qty-w">
                  <p class="mb-0">
                    <b>{{ dataproduct.qty_available }} {{ dataproduct.unit }}</b>
                  </p>
                  <small
                    >Permintaan :
                    <s>{{ dataproduct.qty }} {{ dataproduct.unit }}</s></small
                  >
                </div>
                <div v-if="dataproduct.qty == dataproduct.qty_available" class="qty-w">
                  <p class="mb-0">{{ dataproduct.qty }} {{ dataproduct.unit }}</p>
                </div>
              </td>
              <td>
                <p class="text-danger mb-0">
                  Rp
                  {{
                    new Intl.NumberFormat("id-ID").format(
                      Number(dataproduct.subtotal).toFixed()
                    )
                  }}
                </p>
              </td>
            </tr>
            <tr :key="'footer' + index">
              <td colspan="5">
                <div v-if="dataproduct?.notes">
                  <span class="bottom-left d-flex" v-if="dataproduct?.notes.length > 50">
                    <p>
                      <b>Catatan :</b> {{ dataproduct?.notes.substring(0, 50) + "..." }}
                      <span
                        class="readlink"
                        @click.prevent="modalnote(dataproduct?.notes)"
                        >Selengkapnya</span
                      >
                    </p>
                  </span>
                  <span
                    class="bottom-left d-flex"
                    v-else-if="dataproduct?.notes.length < 50"
                  >
                    <p><b>Catatan :</b> {{ dataproduct?.notes }}</p>
                  </span>
                </div>
                <div v-if="!dataproduct?.notes">
                  <p><b>Catatan :</b></p>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div
          class="bg-white product-data mobile-view p-md-3 mb-4"
          v-for="(dataproduct, index) in datatransaction?.transaction_detail"
          :key="index"
        >
          <table class="table-product">
            <tr class="header-table">
              <th class="p-md-3 px-3">Product</th>
            </tr>
            <tr class="tr-product">
              <td colspan="2" class="p-3 dataproduk">
                <div class="d-flex">
                  <figure class="image mb-0 mx-1">
                    <img :src="$imgProduct + dataproduct.image" alt="" />
                  </figure>
                  <div class="mx-1 detail_product mobile_product_detail">
                    <h6>{{ dataproduct.name_product }}</h6>
                    <div v-if="Number(dataproduct.price_after_discount) > 1">
                      <p class="text-danger mb-0">
                        {{ dataproduct.qty_available }} {{ dataproduct.unit }}
                        <small>x</small> Rp
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(dataproduct.price_after_discount).toFixed()
                          )
                        }}
                      </p>

                      <div class="diskon">
                        <p class="mb-0">{{ Number(dataproduct.discount).toFixed() }}%</p>
                        <small
                          ><s
                            >Rp
                            {{
                              new Intl.NumberFormat("id-ID").format(
                                Number(dataproduct.price).toFixed()
                              )
                            }}</s
                          ></small
                        >
                      </div>
                    </div>
                    <div v-else>
                      <p class="mb-0">
                        {{ dataproduct.qty_available }} {{ dataproduct.unit }}
                        <small><b>x</b></small> Rp
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(dataproduct.price).toFixed()
                          )
                        }}
                      </p>
                    </div>
                    <small
                      class="text-secondary text-small-mobile"
                      v-if="dataproduct.qty != dataproduct.qty_available"
                      >Qty Permintaan :
                      <s>{{ dataproduct.qty }} {{ dataproduct.unit }}</s></small
                    >
                  </div>
                </div>
                <div class="total-harga-product">
                  <small
                    ><b>Variant :</b> {{ dataproduct?.variant_1 }} ,
                    {{ dataproduct?.variant_2 }}</small
                  >
                </div>
                <div class="total-harga-product">
                  <small>Total harga barang</small>
                  <h6 class="text-danger mb-0">
                    Rp
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(dataproduct.subtotal).toFixed()
                      )
                    }}
                  </h6>
                </div>
              </td>
            </tr>
            <tr :key="'footer' + index" class="border-subtotal">
              <td colspan="2">
                <div v-if="dataproduct?.notes">
                  <span class="bottom-left d-flex" v-if="dataproduct?.notes.length > 50">
                    <p>
                      <b>Catatan :</b> {{ dataproduct?.notes.substring(0, 50) + "..." }}
                      <span
                        class="readlink"
                        @click.prevent="modalnote(dataproduct?.notes)"
                        >Selengkapnya</span
                      >
                    </p>
                  </span>
                  <span
                    class="bottom-left d-flex"
                    v-else-if="dataproduct?.notes.length < 50"
                  >
                    <p><b>Catatan :</b> {{ dataproduct?.notes }}</p>
                  </span>
                </div>
                <div v-if="!dataproduct?.notes">
                  <p><b>Catatan :</b></p>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="ringkasan mt-3 mt-md-5">
          <h5>Ringkasan Belanja</h5>
          <div class="m-md-3">
            <div class="card-ringkasan">
              <table class="table-ringkasan-belanja border-0">
                <tr>
                  <th class="border-0 p-1">
                    Total Harga {{ datatransaction.total_qty }} Barang
                  </th>
                  <td class="border-0 p-1 text-danger">
                    Rp.
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(datatransaction.total_price).toFixed()
                      )
                    }}
                  </td>
                </tr>
                <tr v-if="datatransaction.delivery_type == 'home_delivery'">
                  <th class="border-0 p-1">Biaya Ongkir</th>
                  <td class="border-0 p-1 text-danger">
                    Rp.
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(datatransaction.shipping_cost).toFixed()
                      )
                    }}
                  </td>
                </tr>

                <!-- <tr>
                  <th class="border-0 p-1">Total PPN (11%)</th>
                  <td class="border-0 p-1">
                    Rp.
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(datatransaction.total_ppn).toFixed()
                      )
                    }}
                  </td>
                </tr> -->
                <!-- <tr>
                  <th class="border-0 p-1">Biaya Admin</th>
                  <td class="border-0 p-1 text-danger">
                    Rp. 1000
                  </td>
                </tr> -->
                <tr class="total-ringkasan-belanja border-top">
                  <th class="border-0 p-1">Total Transaksi</th>
                  <th class="border-0 p-1 text-danger">
                    Rp.
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(datatransaction.grand_total).toFixed()
                      )
                    }}
                  </th>
                </tr>
                <tr
                  v-if="
                    datatransaction.payment_type == 'cod' &&
                    datatransaction.status == 'cancel'
                  "
                >
                  <th class="border-0 p-1">Total Tagihan</th>
                  <td class="border-0 p-1 text-danger">
                    Rp.
                    {{ new Intl.NumberFormat("id-ID").format(Number(0).toFixed()) }}
                  </td>
                </tr>
                <tr
                  v-else-if="
                    datatransaction.status != 'cancel' &&
                    datatransaction.payment_type == 'cod'
                  "
                >
                  <th class="border-0 p-1">Total Tagihan</th>
                  <td class="border-0 p-1 text-danger">
                    Rp.
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(datatransaction.grand_total).toFixed()
                      )
                    }}
                  </td>
                </tr>
                <tr v-if="datatransaction.payment_type == 'payment_gateway'">
                  <th class="border-0 p-1">Total Pembayaran</th>
                  <td class="border-0 p-1 text-danger">
                    Rp.
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(datatransaction.original_price).toFixed()
                      )
                    }}
                  </td>
                </tr>
                <tr v-if="datatransaction.status == 'done_refund'">
                  <th class="border-0 p-1">Total Refund</th>
                  <td class="border-0 p-1 text-danger">
                    Rp.
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(datatransaction.total_refund).toFixed()
                      )
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div
            class="container-fluid mt-3"
            v-if="
              toLowerCase(datatransaction?.status) == 'pending' ||
              toLowerCase(datatransaction?.status) == 'pending_payment' ||
              toLowerCase(datatransaction?.status) == 'waiting_payment'
            "
          >
            <div class="d-flex float-right">
              <b-button
                v-if="
                  toLowerCase(datatransaction?.payment_type) == 'cod' ||
                  toLowerCase(datatransaction?.status) == 'waiting_payment'
                "
                class="catatan"
                variant="outline-secondary"
                v-b-modal.modal-cancel
              >
                Cancel Transaksi
              </b-button>
              <!-- <button
                v-if="toLowerCase(datatransaction?.payment_type) == 'cod' || toLowerCase(datatransaction?.status) == 'waiting_payment'"
                id="cancel_transaksi"
                class="btn btn-outline-secondary float-right"
                @click.prevent="CancelTransaksi(datatransaction?.id)"
              >
                Cancel Transaksi
              </button> -->
              <small
                class="text-danger"
                v-else-if="
                  toLowerCase(datatransaction?.payment_type) == 'payment_gateway'
                "
              >
                jika ingin membatalkan transaksi silahkan menghubungi admin*
                <router-link to="/profil/chat">klik disini.</router-link>
              </small>
              <button
                v-if="
                  toLowerCase(datatransaction?.payment_type) != 'cod' &&
                  toLowerCase(datatransaction?.status) == 'waiting_payment'
                "
                id="lanjutkan_transaksi"
                class="btn btn-primary float-right mx-2"
                @click.prevent="Checkoudata(datatransaction?.id)"
              >
                Bayar
              </button>
            </div>
          </div>
          <div
            class="container-fluid mt-3"
            v-if="
              toLowerCase(datatransaction?.status) == 'shipped' ||
              toLowerCase(datatransaction?.status) == 'shipping'
            "
          >
            <button
              id="lanjutkan_shipped"
              class="btn btn-success float-right"
              @click.prevent="Diterima(datatransaction?.id)"
            >
              Barang Diterima
            </button>
          </div>
        </div>
      </div>
      <b-modal id="modal-loading" centered hide-footer hide-header>
        <div class="text-center">
          <b-spinner variant="light"></b-spinner>
        </div>
      </b-modal>
    </div>
    <b-modal id="modal-catatan-transaksi" hide-footer scrollable title="Detail Catatan">
      {{ modal_note }}
    </b-modal>
    <b-modal id="modal-cancel" title="Cancel Transaksi" hide-footer>
      <form @submit.prevent="CancelTransaksi(datatransaction?.id)">
        <div class="form-group">
          <label for="catatan" class="mb-2"
            >Sebelum anda ingin melakukan pembatalan transaksi, silahkan untuk memberikan
            note mengenai alasan pembatalan pesanan</label
          >
          <textarea
            class="form-control"
            v-model="catatan"
            id="catatan"
            rows="3"
          ></textarea>
          <span
            v-if="v$?.catatan?.$error"
            class="text-danger font-small pesan-error"
            id="error-catatan"
          >
            {{ v$.catatan?.$errors[0]?.$message }}</span
          >
        </div>
        <div class="d-flex mt-3 float-end">
          <span class="btn btn-secondary" @click="cancelmodal()"> Batal </span>
          <button id="savecatatan" class="btn btn-primary mx-2">Simpan</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import PesananSkeleton from "../../components/Skeleton/Transaksi/DetailTransaksi.vue";
import Loader from "@/components/Loader/Loader.vue";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import "../../assets/css/sweetalertstyle.css";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
export default {
  props: ["checksdata"],

  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      loading: false,
      visible: false,
      datacart: [],
      databranch: [],
      datatransaction: [],
      dataaddress: [],
      modal_note: "",
      catatan: "",
    };
  },
  validations() {
    return {
      catatan: {
        required: helpers.withMessage("Catatan tidak boleh kosong", required),
      },
    };
  },
  components: {
    PesananSkeleton,
    Loader,
  },
  async mounted() {
    try {
      this.loading = true;
      if (this.user instanceof Promise == true) {
        await this.$store.dispatch("user");
        this.$store.dispatch("address").then(() => {
          this.dataaddress = this.$store.state.address[0];
        });
      }
      if (this.address == [] || this.$store.state.carts[0] === undefined) {
        await this.$store.dispatch("address").then(() => {
          this.dataaddress = this.$store.state.address[0];
        });
      }
      this.dataaddress = this.$store.state.address[0];
      let dataid = this.$route.query.id_transaksi;
      axios.get("/transaction/" + dataid).then((res) => {
        this.databranch = res.data.data.data.branch;
        this.datatransaction = res.data.data.data;
        this.$emit("transaksidata", res.data.data);
        this.loading = false;
      });
    } catch (error) {
      return error;
    }
  },
  computed: {
    user: function () {
      return this.$store.state.user;
    },
    carts: function () {
      return this.$store.state.carts;
    },
    address: function () {
      return this.$store.state.address;
    },
  },
  methods: {
    cancelmodal() {
      this.indexcatatan = "";
      this.notedata = "";
      this.$bvModal.hide("modal-cancel");
    },
    dataimg(value) {
      if (value.product_price.image != null) {
        return value.product_price.image.image;
      } else {
        return value.image;
      }
      // var valueimage = value.product.image
      // let dataimg = valueimage.filter((el)=>{
      //   if(el.product_price_id == value.id){
      //     return el
      //   }
      // })
      // if(dataimg.length > 0){
      //   return dataimg[0]?.image
      // }else{
      //    var img_default =  value?.product?.image.filter((item)=>{
      //       if(item.default_image){
      //           return item
      //       }
      //   })
      //   return img_default[0]?.image

      // }
      // console.log(seep);
    },
    modalnote(value) {
      this.modal_note = value;
      this.$bvModal.show("modal-catatan-transaksi");
      // this.idmodal = id
    },
    datastring(value) {
      var stringdata = value?.substring(0, 5);
      return stringdata;
    },
    dateTime(value) {
      return moment(value).format("DD MMMM YYYY");
    },
    toLowerCase(data) {
      return data.toLowerCase();
    },
    Diterima(value) {
      swal("Apakah benar pesanan telah diterima?", "", "info", {
        buttons: ["Batal", "Ya"],
      }).then((res) => {
        if (res) {
          document.getElementById("lanjutkan_shipped").disabled = true;
          let data = new FormData();
          data.append("transaction_id", value);
          this.$bvModal.show("modal-loading");
          axios
            .post("/transaction/receive-product", data)
            .then(() => {
              this.dataaddress = this.$store.state.address[0];
              let dataid = this.$route.query.id_transaksi;
              axios
                .get("/transaction/" + dataid)
                .then((res) => {
                  this.$bvModal.hide("modal-loading");
                  document.getElementById("lanjutkan_shipped").disabled = false;
                  swal(
                    "Terima kasih sudah belanja",
                    "silahkan mengisi ulasan setelah menerima pesanan anda.",
                    "success",
                    {
                      button: "OK",
                    }
                  );
                  this.databranch = res.data.data.data.branch;
                  this.datatransaction = res.data.data.data;
                  this.$emit("transaksidata", res.data.data);
                  this.loading = false;
                })
                .catch((err) => {
                  document.getElementById("lanjutkan_shipped").disabled = false;
                  this.$bvModal.hide("modal-loading");
                  swal("Ooops", err.response.data.message, "error", {
                    button: "OK",
                  });
                });

              // this.getDetailCheckout(this.cartiddata)
            })
            .catch((e) => {
              this.$bvModal.hide("modal-loading");
              document.getElementById("lanjutkan_shipped").disabled = false;
              swal("Ooops", e.response.data.message, "error", {
                button: "OK",
              });
            });
        }
      });
    },
    CancelTransaksi(value) {
      this.v$.$validate();
      document.getElementById("savecatatan").disabled = true;
      if (!this.v$.$error) {
        // console.log("okoko");
        let data = new FormData();
        data.append("transaction_id", value);
        data.append("notes", this.catatan);
        this.$bvModal.show("modal-loading");
        axios
          .post("/transaction/cancel", data)
          .then(() => {
            this.$bvModal.hide("modal-loading");
            this.$router.push("/profil/transaksi");
            this.$store.dispatch("carts");

            document.getElementById("savecatatan").disabled = false;
            // this.getDetailCheckout(this.cartiddata)
          })
          .catch((e) => {
            this.$bvModal.hide("modal-loading");
            document.getElementById("savecatatan").disabled = false;
            swal("Ooops", e.response.data.message, "error", {
              button: "OK",
            });
          });
      } else {
        document.getElementById("savecatatan").disabled = false;
      }
    },
    Checkoudata(value) {
      document.getElementById("lanjutkan_transaksi").disabled = true;
      let data = new FormData();
      data.append("transaction_id", value);
      data.append("payment_success_url", window.location.origin + "/profil/transaksi");
      data.append("payment_failed_url", window.location.origin + "/profil/cart");
      this.$bvModal.show("modal-loading");
      axios
        .post("/transaction/payment", data)
        .then((res) => {
          this.$bvModal.hide("modal-loading");
          document.getElementById("lanjutkan_transaksi").disabled = false;
          let datapayment = res.data.data;
          window.location.href = datapayment.url_payment;
          this.$store.dispatch("carts");

          // this.getDetailCheckout(this.cartiddata)
        })
        .catch(() => {
          this.$bvModal.hide("modal-loading");
        });
    },
  },
};
</script>
<style scoped>
::v-deep .modal-header button {
  background-color: #fff !important;
  border: 0px !important;
}
::v-deep #modal-loading .modal-content {
  background-color: transparent;
  border: 0px !important;
}
.card-data {
  position: relative;
  overflow: hidden;
}
.vehicle-image {
  position: absolute;
  bottom: -55px;
  right: -40px;
}
.btn-detail {
  cursor: pointer;
}
.line-height-thin {
  line-height: 1 !important;
}
.figure-driver {
  max-width: 80px;
  max-height: 120px;
  width: auto;
  height: auto;
  overflow: hidden;
  display: block;
  position: relative;
  margin: 0 auto;
}
.figure-driver img {
  width: 100%;
}
.total-harga-product small {
  font-size: 0.8rem !important;
}
.total-harga-product h6 {
  font-weight: 600;
}
.text-small-mobile {
  font-size: 0.8rem !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.border-subtotal {
  border-top: 1px solid grey;
}
.mobile-view {
  display: block;
}
.web-view {
  display: none;
}
.d-web-view {
  display: contents;
}
.mobile_product_detail p {
  margin-bottom: 0.5rem;
}
.mobile_product_detail h6 {
  margin-bottom: 0px !important;
}
.float-right {
  float: right;
}
.bg-kuning {
  background-color: #f7d716;
  color: #fff !important;
}
.informationTable td {
  padding: 5px 2px;
  vertical-align: baseline;
}
/* .data-tabel, tr, td, th{
    border:0px !important;
} */
.user_label {
  width: 90px;
}
.user_label_branch {
  width: 110px;
}
.card_info h5 {
  font-weight: 600;
}
.card_info h6 {
  font-weight: 600;
}
.data-ongkir {
  box-shadow: 10px 10px 19px #f5f5f5, -10px -10px 19px #efefef;
  border-radius: 15px;
}
.info_pengiriman p {
  margin-bottom: 0.5rem;
  line-height: 1.2;
}
.alamat_pengiriman {
  margin-bottom: 0px !important;
}
.diskon {
  display: flex;
}
.diskon p {
  color: #fff;
  font-size: 8px !important;
  padding: 3px;
  border-radius: 3px;
  background-color: red;
}
.diskon s {
  color: gray;
  font-size: 0.8rem !important;
  padding-left: 5px;
  text-decoration: line-through !important;
}
.product-data {
  overflow-x: auto;
}
.headcart {
  text-align: left;
  font-weight: 600;
  color: #000;
}

.product-cart {
  overflow-x: auto;
}

.table-product {
  position: relative;
  width: 100%;
}
.table-product tr {
  background-color: transparent !important;
}
.header-table::before {
  content: "";
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  height: 3.5rem;
  position: absolute;
}
.image {
  max-width: 50px;
  max-height: 50px;
  overflow: hidden;
  border-radius: 5px;
}
.image img {
  width: 100%;
}
.detail_product h6 {
  font-weight: 600;
}
.transaksi-footer-bottom {
  display: block;
  position: initial;
  bottom: 1.5rem;
  right: 2rem;
}
.confirmation-order-user {
  border-radius: 10px;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.05), -2px -2px 10px rgba(0, 0, 0, 0.02);
}
.confirmation-order-ekspedisi {
  border-radius: 10px;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.05), -2px -2px 10px rgba(0, 0, 0, 0.02);
}
.web-view {
  display: none;
}
.mobile-view {
  display: block;
}
.ringkasan h5 {
  font-weight: 600;
}
.card-ringkasan {
  border-radius: 10px;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
  padding: 1.75rem;
  text-align: right;
  display: flex;
  justify-content: end;
}

.table-ringkasan-belanja {
  width: 100%;
}

.table-ringkasan-belanja th {
  text-align: left;
}

.total-ringkasan-belanja th {
  border-top: 1px solid var(--primary-color);
  font-size: 1.25rem;
}

.total-ringkasan-belanja th:nth-child(2) {
  text-align: right;
}
@media (max-width: 500px) {
  .d-web-view {
    display: none !important;
  }
  .btn-detail {
    padding-top: 0.25rem;
    padding-left: 0.5rem;
  }
  .text-bottom-name {
    font-size: 0.7rem !important;
  }
  .text-header-detail {
    font-size: 0.8rem;
  }
  .confirmation-order-user p {
    font-size: 0.8rem !important;
  }
  .confirmation-order-user h5 {
    font-size: 0.9rem !important;
  }
  .confirmation-order-ekspedisi h5 {
    font-size: 0.9rem !important;
  }
  .confirmation-order-ekspedisi p {
    font-size: 0.8rem !important;
  }
  .container-produk {
    padding: 0px;
  }
  .header-table th {
    font-size: 0.8rem;
    padding-top: 1.7rem !important;
  }
  .detail_product h6 {
    font-size: 0.8rem;
  }
  .detail_product p {
    font-size: 0.8rem;
  }
  .tr-product td {
    min-width: 150px;
  }
  .dataproduk {
    min-width: 250px !important;
  }
  .tr-product td p {
    font-size: 0.8rem;
  }
  .ringkasan h5 {
    font-size: 1rem;
  }
  .card-ringkasan {
    padding: 0.7rem !important;
  }
  .card-ringkasan table {
    font-size: 0.8rem;
  }
  .total-ringkasan-belanja th {
    font-size: 0.9rem !important;
  }
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}
@media (min-width: 567) {
  .table-ringkasan-belanja {
    width: 70%;
  }
}

@media (min-width: 767px) {
  .detail_product {
    width: 150px;
  }
  .qty-w {
    width: 145px;
  }
  .mobile-view {
    display: none;
  }
  .web-view {
    display: block;
  }
  .text-header-detail {
    line-height: 2;
  }
  .transaksi-footer-bottom {
    position: absolute;
  }
  .tothar {
    max-width: 150px;
  }
  .view-table {
    display: table-footer-group;
    border-top: 1px solid #dee2e6 !important;
  }
  .view-div {
    display: none;
  }
  .header-product {
    display: block;
  }
  .table-ringkasan-belanja {
    width: 70%;
  }
}

@media (min-width: 1023px) {
  .web-view {
    display: flex;
    justify-content: space-evenly;
  }
  .mobile-view {
    display: none;
  }
}

@media (min-width: 1439px) {
  .table-ringkasan-belanja {
    width: 50%;
  }
}

@media (min-width: 2000px) {
}
</style>
