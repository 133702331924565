<template>
    <div class="card-dashboard">
        <Loader v-if="loading"/>
        <SkeletonDashboard v-if="loading"/>
        <div v-if="!loading">
        <div class="px-3 pt-3 header-dashboard d-flex justify-content-between mb-2">
            <h4 class="px-2 pt-2">Dashboard</h4>
            <b-dropdown id="dropdown-right" right text="" class="m-md-2">
                <b-dropdown-item to="/profil/sunting-profil">
                    <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-3"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  style="transform: ; msfilter: "
                >
                  <path
                    d="M12 2a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z"
                  ></path>
                </svg> Edit User
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item to="/profil/alamat">
                    
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-3"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  style="transform: ; msfilter: "
                >
                  <path
                    d="m12 17 1-2V9.858c1.721-.447 3-2 3-3.858 0-2.206-1.794-4-4-4S8 3.794 8 6c0 1.858 1.279 3.411 3 3.858V15l1 2zM10 6c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2z"
                  ></path>
                  <path
                    d="m16.267 10.563-.533 1.928C18.325 13.207 20 14.584 20 16c0 1.892-3.285 4-8 4s-8-2.108-8-4c0-1.416 1.675-2.793 4.267-3.51l-.533-1.928C4.197 11.54 2 13.623 2 16c0 3.364 4.393 6 10 6s10-2.636 10-6c0-2.377-2.197-4.46-5.733-5.437z"
                  ></path>
                </svg> Alamat
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="container-fluid row card-data-user">
            <div class="col-lg-4 px-4 align-middle">
                <!-- <h3 class="p-3">Beranda</h3> -->
                <figure class="img-profile">
                    <img :src="imageuser" alt="">
                </figure>
            </div>
            <div class="col-lg-8 card-user">
                <h6>Data Diri</h6>
                <table class="table border-0">
                    <tbody>
                        <tr class="border-0">
                            <td class="border-0"><div class="header-tabel-profile">Nama</div></td>
                            <td class="border-0" colspan="2"><div class="data-dash">: {{user.name}}</div></td>
                        </tr>
                        <tr class="border-0">
                            <td class="border-0"><div class="header-tabel-profile">Tanggal Lahir</div></td>
                            <td class="border-0" colspan="2"><div class="data-dash">: {{dateTime(user.birthdate)}}</div></td>
                        </tr>
                        <tr class="border-0">
                            <td class="border-0"><div class="header-tabel-profile">Jenis Kelamin</div></td>
                            <td class="border-0" colspan="2" v-if="user.gender==false"><div class="data-dash">: Perempuan</div></td>
                            <td class="border-0" colspan="2" v-if="user.gender==true"><div class="data-dash">: Laki-Laki</div></td>
                        </tr>
                        <tr class="border-0">
                            <td class="border-0"><div class="header-tabel-profile">Alamat</div></td>
                            <td class="border-0" colspan="2" v-if="address.length == 0"><div class="data-dash">: {{addressnull}}</div></td>
                            <td class="border-0" colspan="2" v-if="address.length >0"><div class="data-dash" >: {{address[0].address}}</div></td>
                        </tr>
                    </tbody>
                </table>
                <h6>Kontak</h6>
                <table class="table border-0">
                    <tbody>
                        <tr class="border-0">
                            <td class="border-0 header-tabel-profile">Email</td>
                            <td colspan="2" class="border-0"><div class="data-dash">: {{user.email}}</div></td>
                        </tr>
                        <tr class="border-0">
                            <td class="border-0 header-tabel-profile">No Telepon</td>
                            <td colspan="2" class="border-0"><div class="data-dash">: {{user.phone}}</div></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </div>
        <div class="p-1 p-lg-4">
            <TableTranskasi/>
        </div>
    </div>
</template>
<script>
import Img_Default from '../../assets/img/img-default.png'
import moment from 'moment'
import TableTranskasi from '@/components/Transaksi/TableTransaksi.vue'
import SkeletonDashboard from '@/components/Skeleton/Dashboard/SkeletonDashboard.vue'
import Loader from '@/components/Loader/Loader.vue'
export default {
    
    data(){
        return{
            loading:true,
            addressnull:'-',
            imageuser:'',
            dataaddress:'',
        }
    },
     async mounted(){
        try {
            this.loading =true
            if(this.user instanceof Promise == true){
                 await this.$store.dispatch('user')
                  this.$store.dispatch('address').then(()=>{
                  })
                 
            }
            if(this.address == [] || this.$store.state.address[0] === undefined){
                 await this.$store.dispatch('address')
                 
            }
            this.userdata = this.user
            if(this.userdata.image){
            this.imageuser = this.$imgUrl+this.userdata.image
            }else{
                this.imageuser = Img_Default
            }
            this.loading =false
        } catch (error) {
            this.loading =false
            return error
        }
        
        
    },
    components: {
        TableTranskasi,
        SkeletonDashboard,
        Loader
    },
    computed: {
        // loading:function(){
        //     return this.$store.state.user instanceof Promise
        // },
        user: function() {
            return this.$store.state.user;
        },
        address: function() {
            return this.$store.state.address;
        },
    },
    methods:{
        dateTime(value) {
            return moment(value).format("DD MMMM YYYY");
        },
    }
}
</script>
<style scoped>
.card-dashboard{
    border-radius: 10px;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}
.header-dashboard h4{
    font-weight: 600;
}
.card-data-user{
    margin:0 !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}
.nav-desk{
    display: none;
}
.navmobile{
    display: block;
}
.card-user{
    overflow-x: auto;
    margin-top: 3rem;
}
.data-user p{
    margin-bottom: 5px !important;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
}
.data-user h5{
    font-weight: 600;
    display: block;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 32px;
}
.labeldata{
    min-width: 150px;
}
.isidatauser{
    padding-left: 10px !important;
    font-weight: 400;
}
.img-profile{
    width: 100%;
    max-height: 240px;
    overflow: hidden;
    align-items: center;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.5);
}
.img-profile img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:  100%;
}
.card-user h6{
    font-weight: 600;
}
.table{
    width: 100%;
    color: rgba(0, 0, 0, 0.5);
}


@media (min-width: 319px) {
}
@media (max-width: 500px) {
    .header-dashboard h4{
        font-size: 1rem;
    }
    .card-user h6{
        font-size: 0.9rem;
    }
    .card-user .table tr td{
        font-size: 0.8rem;
    }
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .card-data-user{
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
    }
    .data-user h5{
        display: flex;
    }
    .img-profile{
        max-width: 240px;
    }

}

@media (min-width: 1023px) {
    .card-user{
    margin-top: 0rem;
    }
    .header-tabel-profile{
        width: 120px;
    }
    .data-dash{
        min-width: 200px !important;
    }
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>