import Vue from 'vue'
import Vuex from 'vuex'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import App from './App.vue'
import router from './router'
import { BootstrapVue } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
// import store from './vuex';
import VueCookies from 'vue-cookies';
import VueSweetalert2 from 'vue-sweetalert2';
import { Store } from './store/store'
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import 'boxicons'
import "./axios";
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import imgUrl from './Service/Apiimg'
import imgProduct from './Service/ApiProduct'
import VueYouTubeEmbed from 'vue-youtube-embed'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import './assets/css/style.css'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

// import VueTelInput from 'vue-tel-input';
// import 'vue-tel-input/dist/vue-tel-input.css';
import VueObserveVisibility from 'vue-observe-visibility'

// import './registerServiceWorker'

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/sw.js')
//     .then((registration) => {
//       alert("ini rhen navigator")
//       console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch((error) => {
//       console.error('Service Worker registration failed:', error);
//     });
// }

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(Cropper)
Vue.use(VueTelInput)
Vue.use(Vuex)
Vue.use(VueAwesomeSwiper)
Vue.use(Vuelidate)
Vue.use(VueObserveVisibility)
Vue.use(VueYouTubeEmbed, { global: true, componentId: "youtube-media" })
Vue.prototype.$imgUrl = imgUrl
Vue.prototype.$imgProduct = imgProduct
// Vue.use(store)

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.use(VueCookies)
Vue.use(VuePlyr, {
  plyr: {}
})
new Vue({
store: Store,
  router,
  render: h => h(App)
}).$mount('#app')
