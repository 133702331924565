<template>
  <div>
    <div class="form-profile-body">
      <b-button v-b-modal.modal-ulasan variant="primary">Tambah Ulasan</b-button>
    </div>
    <b-modal id="modal-ulasan" size="md" hide-footer title="Tambah Ulasan">
      <form @submit.prevent="addUlasan">
        <div>
          <!-- <produk-skeleton v-if="LoadingSkeleton"></produk-skeleton> -->
          <label for="" class="mt-3">Produk</label>
          <div class="container-fluid p-lg-3">
            <div v-if="datatransaksi.length <= 0" class="row">
              <p style="width: 100%; text-align: center">
                Mohon Maaf tidak terdapat produk yang dapat di ulas pada ID pesanan diatas
              </p>
            </div>
            <div v-else-if="datatransaksi" class="row">
              <div
                v-for="(produk, index) in trasaksidata"
                :key="index"
                class="col-md-6 p-md-3 mt-2"
              >
                <div
                  class="card data-tabel w-100"
                  :class="{ console_log: produk.id == detail_id }"
                  @click="setId(produk.id)"
                >
                  <div class="d-flex">
                    <figure
                      class="img-profile"
                      :id="'image' + produk.id"
                    >
                      <img :src="$imgProduct + dataimg(produk)" 
                      @error="imageerror(produk.id)" alt="">
                    </figure>

                    <figure
                      class="d-none img-profile img-error"
                      :id="'errorimage' + produk.id"
                    ></figure>
                    <div class="py-2 px-1">
                      <h6>{{ produk.name_product }}</h6>
                      <div v-if="parseInt(produk.discount) > 0">
                        <p  class="m-0 text-danger">
                          {{ produk?.qty_available }}
                        {{ produk?.unit }}
                        <small>X</small> Rp
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(produk?.price_after_discount).toFixed()
                          )
                        }}
                        </p>
                        <div class="diskon">
                          <p>
                            {{ parseInt(produk?.discount) }}%
                          </p>
                          <small
                            ><s
                              >Rp
                              {{
                                new Intl.NumberFormat("id-ID").format(
                                  Number(parseInt(produk?.price)).toFixed()
                                )
                              }}</s
                            ></small
                          >
                        </div>
                      </div>
                      <p v-else class=" text-product">
                        {{ produk?.qty_available }}
                        {{ produk?.unit }}
                        <small>X</small> Rp
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(produk?.price).toFixed()
                          )
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="px-2 w-100">
                    <p class="mb-0 text-align-right"><b>Total Harga</b></p>
                    <h6 class="text-danger">
                      Rp
                      {{
                        new Intl.NumberFormat("id-ID").format(
                          Number(produk?.subtotal).toFixed()
                        )
                      }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="datatransaksi.length > 0" class="row">
              <pre>
                {{trasaksidata}}
              </pre>
              <div
                v-for="(produk, index) in trasaksidata"
                :key="index"
                class="col-md-6 p-md-3 mt-2"
              >
                <div
                  class="card data-tabel w-100"
                  :class="{ console_log: produk.id == detail_id }"
                  @click="setId(produk.id)"
                >
                  <div class="d-flex">
                    <figure
                      class="img-profile"
                      :id="'image' + produk.id"
                    >
                      <img :src="$imgProduct + dataimg(produk)" 
                      @error="imageerror(produk.id)" alt="">
                    </figure>

                    <figure
                      class="d-none img-profile img-error"
                      :id="'errorimage' + produk.id"
                    ></figure>
                    <div class="py-2 px-1">
                      <h6>{{ produk.name_product }}</h6>
                      <div v-if="parseInt(produk.discount) > 0">
                        <p class="m-0 text-danger">{{ produk?.qty_available }}
                        {{ produk?.unit }}
                        <small>X</small> Rp
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(produk?.price_after_discount).toFixed()
                          )
                        }}</p>
                        <div class="diskon">
                          <p>
                            {{ parseInt(produk?.discount) }}%
                          </p>
                          <small
                            ><s
                              >Rp
                              {{
                                new Intl.NumberFormat("id-ID").format(
                                  Number(parseInt(produk?.price)).toFixed()
                                )
                              }}</s
                            ></small
                          >
                        </div>
                      </div>
                      <p v-else class=" text-product">
                        {{ produk?.qty_available }}
                        {{ produk?.unit }}
                        <small>X</small> Rp
                        {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(produk?.price).toFixed()
                          )
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="px-2 w-100">
                    <p class="mb-0 text-align-right"><b>Total Harga</b></p>
                    <h6 class="text-danger">
                      Rp
                      {{
                        new Intl.NumberFormat("id-ID").format(
                          Number(produk?.subtotal).toFixed()
                        )
                      }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100" v-else>
              <p style="width: 100%; text-align: center">
                Mohon Maaf tidak terdapat produk yang dapat di ulas pada ID pesanan diatas
              </p>
            </div>
          </div>
        </div>
        <div class="mt-3" v-if="!alertprodukid">
          <small class="error text-danger mt-5">Produk Wajib Dipilih</small>
        </div>
        <div class="container-fluid">
          <div class="form-group mt-3">
            <label for="satuan">Kasih Bintang</label>
            <div class="comment-star mt-2">
              <div class="star-widget">
                <input type="radio" v-model="rate" name="rate" id="rate-5" value="5" />
                <label for="rate-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="bintang"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style="transform: ; msfilter: "
                  >
                    <path
                      d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                    ></path>
                  </svg>
                </label>
                <input type="radio" v-model="rate" name="rate" id="rate-4" value="4" />
                <label for="rate-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="bintang"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style="transform: ; msfilter: "
                  >
                    <path
                      d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                    ></path>
                  </svg>
                </label>
                <input type="radio" v-model="rate" name="rate" id="rate-3" value="3" />
                <label for="rate-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="bintang"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style="transform: ; msfilter: "
                  >
                    <path
                      d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                    ></path>
                  </svg>
                </label>
                <input type="radio" v-model="rate" name="rate" id="rate-2" value="2" />
                <label for="rate-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="bintang"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style="transform: ; msfilter: "
                  >
                    <path
                      d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                    ></path>
                  </svg>
                </label>
                <input type="radio" v-model="rate" name="rate" id="rate-1" value="1" />
                <label for="rate-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="bintang"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style="transform: ; msfilter: "
                  >
                    <path
                      d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                    ></path>
                  </svg>
                </label>
              </div>
            </div>
            <small
              class="error text-danger"
              v-for="(error, index) of v$.rate.$errors"
              :key="index"
            >
              {{ error.$message }}
            </small>
          </div>
        </div>
        <div class="form-group mt-3" v-if="rate">
          <label for="catatan">Tulis Komentar</label>
          <textarea
            class="form-control mt-2"
            v-model="komentar"
            id="catatan"
            rows="3"
          ></textarea>
          <div v-if="v$.komentar.$error">
            <small
              class="error text-danger"
              v-for="(error, index) of v$.komentar.$errors"
              :key="index"
            >
              {{ error.$message }}
            </small>
          </div>
          <div v-else-if="rate <= 3 && komentar === '' && diinput">
            <small class="error text-danger"> Komentar Wajib diisi </small>
          </div>
          <div class="w-100 mt-5 mb-3 overkomen">
            <div class="d-flex">
              <div class="border-radius b-recommend px-3 pb-1" @click="recomendtext('Pengiriman sangat cepat.')">
              <p class="mb-0">Pengiriman cepat.</p>
            </div>
            <div class="border-radius b-recommend px-3 pb-1" @click="recomendtext('Barang tiba dalam kondisi baik.')">
              <p class="mb-0">Barang tiba dalam kondisi baik.</p>
            </div>
            <div class="border-radius b-recommend px-3 pb-1" @click="recomendtext('Layanan pelanggan yang ramah dan responsif')">
              <p class="mb-0">Layanan pelanggan yang ramah dan responsif</p>
            </div>
            <div class="border-radius b-recommend px-3 pb-1" @click="recomendtext('Puas dengan pembelian saya. Terima kasih!')">
              <p class="mb-0">Puas dengan pembelian saya. Terima kasih!</p>
            </div>
            </div>
          </div>
        </div>

        <div class="w-100 px-3 py-4 card mt-3" v-if="komentar && datafile.length < 3">
          <div class="">
            <div @click="addInput()" class="btn btn-primary float-right">
              Tambah Media
            </div>
          </div>
        </div>
        <div class="w-100">
          <div v-for="(bukti, index) in datafile" :key="index">
            <label class="mt-3" for="bukti">Upload Media Ulasan {{ index + 1 }}</label
            ><br />
            <div style="display: flex">
              <label :for="`file${index}`" class="input-group flex-nowrap">
                <label
                  style="background-color: #e9ecef; color: #000"
                  :for="`file${index}`"
                  type="button"
                  class="btn btn-outline-secondary label-file"
                  >Choose File</label
                >
                <div
                  class="d-flex align-items-center border border-secondary p-2 w-100"
                  style="overflow-x: hidden"
                >
                  <p class="mb-0">{{ bukti.fileName }}</p>
                </div>
              </label>
              <input
                :id="`file` + index"
                type="file"
                @change="inputfile($event, index)"
                class="d-none form-control mt-2"
                name="data"
                accept=".jpg,.jpeg,.png,.webp,.mp4,.mpeg,.mov,.3gp,.mkv,.avi"
                placeholder="Upload Bukti Komplain"
              />
              <div class="mt-2 view-web" style="margin-left: 10px">
                <p
                  class="bg-danger btn-delete text-white bridge mt-3"
                  @click="deletefile(index)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style="transform: ; msfilter: "
                  >
                    <path
                      d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"
                    ></path>
                    <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                  </svg>
                </p>
              </div>
            </div>
            <div class="mt-2 view-mobile">
              <p
                class="bg-danger btn-delete text-white bridge mt-3"
                @click="deletefile(index)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="transform: ; msfilter: "
                >
                  <path
                    d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z"
                  ></path>
                  <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
                </svg>
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div
            type="button"
            class="btn btn-secondary"
            @click="closemodalform"
            data-bs-dismiss="modal"
          >
            Batal
          </div>
          <button type="submit" class="btn btn-primary">
            <i class="bx bxs-save"></i> Kirim Ulasan
          </button>
        </div>
      </form>
    </b-modal>
    <b-modal id="modal-loading" centered hide-footer hide-header>
      <div class="text-center">
        <b-spinner variant="light"></b-spinner>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import '../../assets/css/sweetalertstyle.css'
import useVuelidate from "@vuelidate/core";
import { required, helpers,maxLength } from "@vuelidate/validators";

export default {
  props: ["datatransaksi"],
  name: "Add-Complain",
  data() {
    return {
      inputs: [],
      transaksi_id: "",
      rate: "",
      komentar: "",
      detail_id: "",
      datakirim: {
        datareview: {},
        datatransaksi: {},
      },
      alertprodukid: true,
      diinput: false,
      modalform: false,
      datafile: [],
      loading: true,
      LoadingSkeleton: true,
      loadingSubmit: false,
      massageadmin: "",
      modalTipe: "",
      modal: {
        showModal: false,
        header: false,
        headerBorder: false,
        title: false,
        titleText: "",
        footer: false,
        footerBtnPosition: "",
        footerBorder: false,
        btnCancel: false,
        btnSubmit: false,
        btnSubmitText: "",
        btnCancelText: "",
        btnSubmitWidth: "",
        btnCancelWidth: "",
      },
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      rate: {
        required: helpers.withMessage("Bintang wajib diisi", required),
      },
      komentar: {
        maxLength: helpers.withMessage(
          "Maksimal Komentar berisi sebanyak 255 huruf",maxLength(255)
        ),
      },
    };
  },
  watch: {
    detail_id() {
      this.alertprodukid = true;
    },
  },

  created() {
    this.LoadingSkeleton = true;
  },
  computed: {
    trasaksidata(){
      return Object.values(this.datatransaksi).filter((el)=>{
        return el.qty_available > 0
      })
    }
  },
  methods: {
    dataimg(value){
      if(value.product_price.image != null){
        return value.product_price.image.image
      }else{
        return value.image
      }
       
    },
    imageerror(id) {
      document.getElementById("image" + id).classList.add("d-none");
      document.getElementById("errorimage" + id).classList.remove("d-none");
      document.getElementById("errorimage" + id).classList.add("d-block");
      // event.target.src = "../../assets/img/image_icon.png"
    },
    recomendtext(data){
      if(this.komentar.length > 0){
        this.komentar = this.komentar + ' ' + data
      }else{
        this.komentar = data
      }
    },
    closemodalform() {
      this.$bvModal.hide("modal-ulasan");
    },
    deletefile(value) {
      this.datafile.splice(value, 1);
      this.inputs.splice(value, 1);
    },
    setId(value) {
      this.detail_id = value;
    },
    addInput() {
      if (this.datafile.length < 3) {
        let template = {
          fileName: "No file chosen",
          file: null,
        };
        if (
          this.datafile.length == 0 ||
          this.datafile[this.datafile.length - 1].file != null
        ) {
          this.datafile.push(template);
        }
      }
    },
    inputfile(event, index) {
      let file = event.target.files[0];
      if (
        file["type"] === "image/jpeg" ||
        file["type"] === "image/jpg" ||
        file["type"] === "image/png" ||
        file["type"] === "image/webp"
      ) {
        if (file["size"] <= 2048000) {
          this.datafile[index].file = event.target.files[0];
          this.datafile[index].fileName = event.target.files[0].name;
        } else {
          document.getElementById(`file` + index).value = "";
          swal("Ooops", "Ukuran format Gambar anda lebih dari 2mb", "error", {
            button: "OK",
          });
        }
      } else if (
        file["type"] === "video/mp4" ||
        file["type"] === "video/*" ||
        file["type"] === "video/mpeg" ||
        file["type"] === "video/mov" ||
        file["type"] === "video/3gp" ||
        file["type"] === "video/mkv" ||
        file["type"] === "video/avi"
      ) {
        if (file["size"] <= 51200000) {
          this.datafile[index].file = event.target.files[0];
          this.datafile[index].fileName = event.target.files[0].name;
        } else {
          document.getElementById(`file` + index).value = "";
          swal("Ooops", "Ukuran format Video anda lebih dari 50mb", "error", {
            button: "OK",
          });
        }
      } else {
        document.getElementById(`file` + index).value = "";
        swal(
          "Ooops",
          "Format File Hanya berupa(.jpg, .png, .jpeg, .webp, .mp4, .mov, .avi, .mpeg, .3gp)",
          "error",
          {
            button: "OK",
          }
        );
      }
    },
    addUlasan() {
      this.v$.$touch();
      this.diinput = true;
      if (this.rate <= 3 && this.komentar) {
        if (!this.v$.$invalid && this.detail_id) {
      this.loadingSubmit = true;
          let data = new FormData();
          data.append("transaction", this.detail_id);
          data.append("star", this.rate);
          data.append("notes", this.komentar);
          this.datafile.forEach((item) => {
            data.append("image[]", item.file);
          });
          axios
            .post(`/product/review`, data)
            .then(() => {
              this.loadingSubmit = false;
              try {
                this.detail_id = ""
                this.rate = ""
                this.komentar = ""
                this.datafile = []
                this.v$.$reset()
                let idtransaksi = this.$route.query.id_transaksi;
                axios.get("/review/" + idtransaksi).then((res) => {
                  this.datakirim.datareview = res.data.data;
                  axios.get("/transaction/" + idtransaksi).then((res) => {
                    this.datakirim.datatransaksi = res.data.data;
                    this.$emit("datareload", this.datakirim);
                    this.diinput = false;
                    this.$bvModal.hide("modal-ulasan");
                    swal("Berhasil!!!", "Ulasan Anda berhasil Ditambahkan", "success", {
                      button: "OK",
                    });
                  });
                });
              } catch (error) {
                return error;
              }
              this.modalform = false;
            })
            .catch((e) => {
              this.loadingSubmit = false;
              this.diinput = false;
              if (
                e.response.data.message ==
                "You already make complaint with this transaction"
              ) {
                this.massageadmin =
                  "Anda Sudah melakukan komplain pada produk didalam invoice yang anda pilih";
              } else {
                this.massageadmin = e.response.data.message;
              }
              swal("Ooops", this.massageadmin, "error", {
                button: "OK",
              });
            });
        } else if (!this.detail_id) {
              this.loadingSubmit = false;
          this.alertprodukid = false;
        }
      } else if (this.rate > 3) {
        if (!this.v$.$invalid && this.detail_id) {
          let data = new FormData();
          data.append("transaction", this.detail_id);
          data.append("star", this.rate);
          data.append("notes", this.komentar);
          this.datafile.forEach((item) => {
            data.append("image[]", item.file);
          });
          axios
            .post(`/product/review`, data)
            .then(() => {
                this.detail_id = ""
                this.rate = ""
                this.komentar = ""
                this.datafile = []
                this.v$.$reset()
              this.loadingSubmit = false;
              try {
                let idtransaksi = this.$route.query.id_transaksi;
                axios.get("/review/" + idtransaksi).then((res) => {
                  this.datakirim.datareview = res.data.data;
                  axios.get("/transaction/" + idtransaksi).then((res) => {
                    this.datakirim.datatransaksi = res.data.data;
                    this.$emit("datareload", this.datakirim);
                    this.diinput = false;
                    this.$bvModal.hide("modal-ulasan");
                    swal("Berhasil!!!", "Ulasan Anda berhasil Ditambahkan", "success", {
                      button: "OK",
                    });
                  });
                });
              } catch (error) {
                return error;
              }
              this.modalform = false;
            })
            .catch((e) => {
              this.loadingSubmit = false;
              this.diinput = false;
              if (e.response.data.message == "You have already reviewed this product") {
                this.massageadmin =
                  "Anda Sudah memberikan ulasan pada produk didalam invoice yang anda pilih";
              } else {
                this.massageadmin = e.response.data.message;
              }
              swal("Ooops", this.massageadmin, "error", {
                button: "OK",
              });
            });
        } else if (!this.detail_id) {
              this.loadingSubmit = false;
          this.alertprodukid = false;
        }
      }else{
        this.$bvModal.hide("modal-loading");
      }
    },
  },
};
</script>

<style scoped>
.diskon {
  display: flex;
}
.diskon p {
  color: #fff;
  font-size: 8px !important;
  padding: 3px;
  border-radius: 3px;
  background-color: red;
}
.diskon s {
  color: gray;
  font-size: 0.8rem !important;
  padding-left: 5px;
  text-decoration: line-through !important;
}
::v-deep #modal-loading .modal-content {
  background-color: transparent;
  border: 0px !important;
}
::v-deep .modal-header button {
  border: 0px !important;
  background-color: #fff !important;
}
.overkomen{
  overflow-x: auto;
}
.b-recommend{
  border: 1px solid #2F3192 !important;
  color: #2F3192 !important;
  border-radius: 15px;
  cursor: pointer;
	height: auto;
	width: auto;
  margin: 0 5px;
}
.b-recommend p{
  display: inline-block;
    width: max-content;
    margin-bottom: 0px !important;
}
.b-recommend:hover{
  background-color: #2F3192 !important;
  color: #fff !important;
  border: 1px solid #2F3192 !important;
}
.image {
  max-width: 50px;
  max-height: 50px;
  overflow: hidden;
  border-radius: 5px;
}
.image img {
  width: 100%;
}
.img-error {
  background-image: url(../../assets/img/image_icon.png);
}
.data-tabel {
  border: 1px solid grey;
  border-radius: 10px;
}
.text-product {
  color: grey;
  font-size: 0.9rem;
}
.img-profile {
  padding: 5px;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 0px;
}
.btn-delete {
  padding: 0.75rem 0.7rem;
  border-radius: 5px;
  display: initial;
  cursor: pointer;
}
.btn-delete svg {
  fill: #fff;
}
.img-profile img {
  width: 100%;
}
.td-nama-produk {
  max-width: 200px !important;
}
.nama-produk {
  display: flex;
  margin: 0px;
  margin-right: 0px !important;
}
.nama-produk h6 {
  vertical-align: inherit;
  margin: auto 0;
}
.console_log {
  border: 2px solid red !important;
}

.comment-star {
  padding: 0px;
  border-radius: 5px;
  display: flex;
  text-align: left !important;
  /* align-items: right; */
}

.star-widget input {
  display: none;
}

.comment-star label {
  font-size: 1.5rem;
  color: #444;
  padding: 5px;
  float: right;
  transition: all 0.2s ease;
}
.btn-delete {
  padding: 0.75rem 0.7rem;
  border-radius: 5px;
  display: initial;
  cursor: pointer;
}
.btn-delete svg {
  fill: #fff;
}

input:not(:checked) ~ label:hover,
input:not(:checked) ~ label:hover ~ label {
  fill: #fd4;
}

input:checked ~ label {
  fill: #fd4;
}

input#rate-5:checked ~ label {
  fill: #fe7;
}

.label-file {
  width: 175px;
}
.view-web {
  display: none;
}
.view-mobile {
  display: block;
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .label-file {
    width: 140px;
  }
  .view-web {
    display: block;
  }
  .view-mobile {
    display: none;
  }
  ::v-deep .modal-md {
    --bs-modal-width: 700px !important;
  }
}

@media (min-width: 1023px) {
  .label-file {
    width: 130px;
  }
}

@media (min-width: 1439px) {
  .label-file {
    width: 125px;
  }
}

@media (min-width: 2000px) {
}
</style>
