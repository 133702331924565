import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies';
import axios from 'axios';
import moment from 'moment'

Vue.use(Vuex)

export const Store = new Vuex.Store({
    state: {
        user: Promise.resolve(),
        berita: Promise.resolve(),
        setting: Promise.resolve(),
        id:null,
        cart_id:Promise.resolve(),
        idcart:[],
        gettoken:null,
        address:[],
        category:[],
        carts:[],
        semuapesan:null,
        jmlhpesan:0,
        getpesan:null,
        banners:null,
        suntingdata:false,
        dataupdate:null,
        loading: false,
        statuspesan: true,
        chatcheck: false,
        delaychat: false

    },
    getters:{
        loading: (state) => {
            return state.loading
        },
        chatcheck: (state) => {
            return state.chatcheck
        },
        delaychat: (state) => {
            return state.delaychat
        },
        user: (state) =>{
            return state.user
        },
        berita: (state) =>{
            return state.berita
        },
        setting: (state) =>{
            return state.berita
        },
        gettoken:(state)=>{
            return state.gettoken
        },
        address: (state) =>{
            return state.address
        },
        category: (state) =>{
            return state.category
        },
        suntingdata: (state) =>{
            return state.suntingdata
        },
        carts: (state) =>{
            return state.carts
        },
        banners: (state) =>{
            return state.banners
        },
        cart_id: (state) =>{
            return state.cart_id
        },
        idcart: (state) =>{
            return state.idcart
        },
        datapesan:(state)=>{
            return state.datapesan
        },
        semuapesan:(state)=>{
            return state.semuapesan
        },
        jmlhpesan:(state)=>{
            return state.jmlhpesan
        },
        statuspesan:(state)=>{
            return state.statuspesan
        },
        getpesan:(state)=>{
            return state.semuapesan
        },
    },
    actions:{
        async user(context){
            context.commit("loading", true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + VueCookies.get('token')
            
            if(VueCookies.get('token')){
                await axios.get('user')
                .then(response => {
                    context.commit('user', response.data) 
                    context.commit("loading", false)
                })
                .catch(() => {
                    context.commit("loading", false)
                })
            }
        },
        async carts(context){
            if(VueCookies.get('token')){
               await axios.get('/transaction/cart/list-cart').then(response => {
                    context.commit('carts',response.data.data)
                    context.commit("loading", false)
                })
                .catch(() => {
                    context.commit('carts',[])
                    context.commit("loading", false)
                })
            }
            
        },
        async banners(context){
            context.commit("loading", true)
            await axios.get('banner')
            .then(res =>{
                context.commit('banners',res.data.data)
                context.commit("loading", false)
            }).catch(() => {
                context.commit("loading", false)
            })
        },
        async berita(context){
            context.commit("loading", true)
            await axios.get('/blog')
            .then(response => {
                context.commit('berita',response.data.data.blogs)
                context.commit("loading", false)
            })
            .catch(() => {
                context.commit('berita',[])
                context.commit("loading", false)
            })
        },
        async setting(context){
            context.commit("loading", true)
            await axios.get('/setting')
            .then(response => {
                context.commit('setting',response.data.data)
                context.commit("loading", false)
            })
            .catch(() => {
                context.commit('setting',[])
                context.commit("loading", false)
            })
        },
        suntingdata(context){
            // console.log(databaru);
            context.commit('suntingdata',true)
        },
        resetchat: ({ commit }, {datachat}) => commit('menuchat', datachat),
        delaychat({ commit },chatstatus){
            commit('delaychat',chatstatus)
        },
        set_Cart({ commit },datacart){
            localStorage.setItem("cart_id", JSON.stringify(datacart.cart_id));
            commit('idcart',datacart)
        },
        async datapesan(context){
            context.commit("loading", true)
            await axios.get('/usermanagement/chat/detail')
            .then(response =>{
                let pesandata = response.data.data
                let k = 0
                let cekdata = localStorage.getItem('datac')
                if(pesandata.length != cekdata){
                    pesandata.slice(cekdata, pesandata.length).forEach(element => {
                        if(element.is_user){
                            k = k + 1
                            context.commit('menuchat', k)
                            localStorage.setItem('datac', response.data.data.length)
                        }else{
                            k = 0
                            context.commit('menuchat', k)
                        }
                    });
                }
                context.commit("loading", false)
                
            }).catch(() => {
                context.commit("loading", false)
            })
        },
        async jmlhpesan(context){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + VueCookies.get('token')
            if(VueCookies.get('token')&& context.state.statuspesan){
                context.commit('statuspesan',false) 
                await axios.get('/usermanagement/chat/unread-count')
                .then(response =>{
                    context.commit('jmlhpesan',response.data.data.count) 
                    setTimeout(()=> {
                        context.commit('statuspesan',true) 
                        this.dispatch('jmlhpesan')
                     }
                     ,5000)
                   
                }).catch(()=>{
                    setTimeout(()=> {
                        context.commit('statuspesan',true) 
                        this.dispatch('jmlhpesan')
                     }
                     ,5000)

                })
            }
        },
        async semuapesan(context){
            context.commit("loading", true)
            await axios.get('/usermanagement/chat/detail')
            .then(response =>{
                context.commit('semuapesan',response.data.data) 
                context.commit("loading", false)
            }).catch(() => {
                context.commit("loading", false)
            })
        },
        async getpesan(context){
            context.commit("loading", true)
            context.commit("loading", false)
            let jmlh_data = context.state.semuapesan.length
            let statuschat = context.state.delaychat
            if(jmlh_data>0 && !statuschat){
                let datapesan = context.state.semuapesan[(jmlh_data-1)]
                if(context.state.dataupdate==null){
                
                    let datatgl = moment(datapesan.created_at).format("YYYY-MM-DD HH:mm:ss ")
                    let data = new FormData()
                    data.append('id', context.state.user.id)
                    data.append('date', datatgl)
                    await axios.post('/usermanagement/chat/mark-as-read')
                    
                    await axios.post('/usermanagement/chat/ajax-request',data)
                    .then((responce)=>{
                        let datapesan = context.state.semuapesan
                        if(responce.data.data.data.length > 0){
                            let k = 0
                            var dataid = false
                            for(let i = 0;i<responce.data.data.data.length;i++){
                                dataid = datapesan.some(item => item.id === responce.data.data.data[k].id)
                                if(!dataid){
                                    datapesan.push(responce.data.data.data[k])
                                }
                                k++
                            }
                            context.commit('dataupdate',responce.data.data.dateUpdate)
                            context.commit('semuapesan',datapesan)
                            context.commit('chatcheck',true)
                        }
                        context.commit("loading", false)
                    }).catch(()=>{
                        context.commit("loading", false)
                    })
                }else{
                    let datatgl = context.state.dataupdate
                    let data = new FormData()
                    data.append('id', context.state.user.id)
                    data.append('date', datatgl)
                    // await axios.post('/usermanagement/chat/mark-as-read')
                    await axios.post('/usermanagement/chat/ajax-request',data)
                    .then((responce)=>{
                        context.commit('dataupdate',responce.data.data.dateUpdate)
                        let datapesan = context.state.semuapesan
                        if(responce.data.data.data.length > 0){
                            let k = 0
                            for(let i = 0;i<responce.data.data.data.length;i++){
                                datapesan.push(responce.data.data.data[k])
                                k++
                            }
                            context.commit('semuapesan',datapesan)
                            context.commit('chatcheck',true)
                        }
                        context.commit("loading", false)
                    }).catch(()=>{
                        context.commit("loading", false)
                    })
                }
            }
            
            
        },
        async id(context){
            context.commit("loading", true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + VueCookies.get('token')
            
            await axios.get('user')
            .then(response => {
                context.commit('id', response.data.id)
                context.commit("loading", false)
            })
            .catch(() => {
                context.commit("loading", false)
            })
        },
        async gettoken(context){
            context.commit("loading", true)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + VueCookies.get('token')
            
                context.commit('gettoken', VueCookies.get('token'))
                context.commit("loading", false)
        },
        settings(context){
            context.commit("loading", true)
            axios.get('setting')
            .then(res =>{
                context.commit('setting', res.data.data)
                context.commit("loading", false)
            }).catch(() => {
                context.commit("loading", false)
            })
        },
        async address(context){
            context.commit("loading", true)
            if(VueCookies.get('token')){
                await axios.get('/customer/address/list/'+context.state.user.id)
                .then(response => {
                    context.commit('address',response.data.data)
                    context.commit("loading", false)
                })
                .catch(() => {
                    context.commit('address',[])
                    context.commit("loading", false)
                })
            }
            context.commit("loading", false)
            
        },
        async category(context){
            context.commit("loading", true)
            await axios.get('/category')
            .then(response => {
                context.commit('category',Object.values(response.data.data))
                context.commit("loading", false)
            })
            .catch(() => {
                context.commit('category',[])
                context.commit("loading", false)
            })
        },
    },
    mutations: {
        increment (state) {
            state.count++
        },
        loading(state, newLoading) {
            state.loading = newLoading
        },
        chatcheck(state, newChat) {
            state.loading = newChat
        },
        delaychat(state, delaychat){
            state.delaychat = delaychat
        },
        address(state, address){
            state.address = address
        },
        category(state, category){
            state.category = category
        },
        carts(state, carts){
            state.carts = carts
        },
        berita(state, berita){
            state.berita = berita
        },
        setting(state, setting){
            state.setting = setting
        },
        suntingdata(state, suntingdata){
            state.suntingdata = suntingdata
        },
        idcart(state, idcart){
            state.idcart = idcart
        },
        cart_id(state){
            state.cart_id = JSON.parse(localStorage.getItem("cart_id"))
        },
        banners(state, banners){
            state.banners = banners
        },
        resetchat(state,menuchat){
            state.menuchat =menuchat
        },
        menuchat(state, menuchat){
            state.menuchat = menuchat
        },
        semuapesan(state, semuapesan){
            state.semuapesan = semuapesan
        },
        jmlhpesan(state, jmlhpesan){
            state.jmlhpesan = jmlhpesan
        },
        statuspesan(state, statuspesan){
            state.statuspesan = statuspesan
        },
        dataupdate(state, dataupdate){
            state.dataupdate = dataupdate
        },
        getpesan(state, semuapesan,dataupdate){
            state.semuapesan = semuapesan
            state.dataupdate = dataupdate
        },
      user(state, user){
        state.user = user
        },
        id(state, id){
            state.id = id
        },
        settings(state, settings){
            state.settings = settings
        },
        gettoken(state, gettoken){
            state.gettoken = gettoken
        },
    }
  })