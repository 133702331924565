import axios from "axios";
import router from "./router";
import VueCookies from 'vue-cookies'
axios.defaults.baseURL = process.env.VUE_APP_NAME_AXIOS;

if(VueCookies.get('token')){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + VueCookies.get('token')
}
axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.message == 'Unauthenticated.') {
          // Redirect to login page
          VueCookies.remove("token");
              localStorage.removeItem("cart_id");
              VueCookies.remove("id_checkout");
              VueCookies.remove("id_transaction");
          this.$router.push('/')
        } 
        else if(error.response.status === 404 && error.response.data == undefined){
          router.push('/error')
        }
      }
      return Promise.reject(error)
    },
  );