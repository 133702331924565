<template>
  <div class="home">
    <Navbar/>
    <Cart/>
    <Footer/>
  </div>
</template>

<script>
import Navbar from '@/components/NavbarUno.vue'
import Footer from '@/components/FooterUno.vue'
import Cart from '@/components/Cart/cartView.vue'

export default {
  name: 'HomeView',
  components: {
    Navbar,
    Footer,
    Cart,
  }
}
</script>
