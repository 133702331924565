<template>
  <div>
    <Loader v-if="submit" />
    <SkeletonCart v-if="loading" />
    <div class="container" v-if="!loading">
      <div class="header-cart w-100">
      </div>
      <h4 class="headcart">Keranjang</h4>
      <div v-if="sortedArray.length > 0">
        <div class="d-flex">
          <input type="checkbox" name="allproduct" id="allproduct" @input="handleSelectAll" v-model="selectAll">
          <p class="mx-2 mb-0 allproduk"><b>Semua Produk</b></p>
        </div>
        <table class="table-product">
          <template v-for="(dataproduct, index) in sortedArray">
            <listproduk @addtotal=addtotal @modalcatatan=modalcatatan @modalnote="modalnote" @deletedata="deletedata"
              :listcart="listtotal" :dataproduct="dataproduct" :datanew="checkdata(dataproduct)" :selectdata="selectAll"
              :dataindex="index" :key="index"></listproduk>
          </template>
        </table>
        <b-modal id="modal-catatan" title="Catatan Produk" hide-footer>
          <form @submit.prevent="updatenote()">
            <div class="form-group">
              <label for="catatan">Catatan</label>
              <textarea class="form-control" v-model="notedata" id="catatan" rows="3"></textarea>
            </div>
            <div class="d-flex mt-3 float-end">
              <span class="btn btn-secondary" @click="cancelmodal()">
                Batal
              </span>
              <button class="btn btn-primary mx-2">Simpan</button>
            </div>
          </form>
        </b-modal>
        <b-modal id="modalcatatan" hide-footer scrollable title="Detail Catatan">
          {{ modal_note }}
        </b-modal>
        <div class="ringkasan_belanja mt-3">
          <h5>Ringkasan Belanja</h5>
          <div class="total p-3 p-md-4">
            <h6 class="text-danger">Total Harga ({{ qty }} Barang) : Rp.{{ new
              Intl.NumberFormat('id-ID').format(Number(total).toFixed()) }}</h6>
          </div>
        </div>
        <button @click="cartToCheckout" class="btn btn-primary mt-3 float-right">Beli Sekarang</button>
      </div>
      <div class="p-3 p-lg-5" v-else-if="sortedArray?.length <= 0">
        <img class="img-not-found" src="../../assets/img/empty_cart.svg" alt="">
        <p class="text-center mt-3">Anda belum memiliki produk didalam keranjang</p>
        <p class="text-center">Silahkan memilih produk <router-link class="link_home" to="/">disini</router-link></p>
      </div>
      <b-modal id="modal-loading" centered hide-footer hide-header>
        <div class="text-center">
          <b-spinner variant="light"></b-spinner>
        </div>
      </b-modal>
    </div>
    <b-modal id="modalcatatan" hide-footer scrollable title="Detail Catatan">
      {{ modal_note }}
    </b-modal>
  </div>

</template>
<script>
import swal from "sweetalert";
import '../../assets/css/sweetalertstyle.css'
import axios from "axios";
import listproduk from './produkList.vue'
import SkeletonCart from '../Skeleton/Cart/SkeletonCart.vue'
import Loader from '@/components/Loader/Loader.vue'
export default {
  data() {
    return {
      qty: 0,
      total: 0,
      iddel: null,
      allcheck: false,
      selectAll: false,
      loading: false,
      submit: false,
      indexcatatan: '',
      notedata: '',
      modal_note: '',
      listtotal: [],
      cart_id: [],
      itemCheckedStatus: false,
    }
  },
  components: {
    listproduk,
    SkeletonCart,
    Loader
  },
  async mounted() {
    // this.selectAll = false
    // try {
    await this.$store.dispatch("carts")

    // } catch{
    // }
  },
  watch: {
    listtotal() {
      // console.log(this.listtotal)
      // pengecekan checklist yang di cheklist atau yang di uncheklist dari list cart 
      this.listtotal.forEach((el) => {
        if (document.getElementById('check' + el.id) != null) {
          document.getElementById('check' + el.id).checked = true

        }
      })
      let diferrent = this.sortedArray
        .filter(item1 => !this.listtotal.some(item2 => item1.id === item2.id))
        .concat(
          this.listtotal.filter(item2 => !this.sortedArray.some(item1 => item2.id === item1.id))
        );
      diferrent.forEach((element) => {
        if (document.getElementById('check' + element.id) != null) {
          document.getElementById('check' + element.id).checked = false
        }

      })
      // console.log(diferrent);
    }
  },
  methods: {
    checkdata(data) {
      return this.listtotal.some(item => item.id === data.id)
    },
    cartToCheckout() {

      this.$bvModal.show('modal-loading')
      if (this.listtotal.length > 0) {
        this.loadingSubmit = true
        for (let i = 0; i < this.listtotal.length; i++) {
          this.cart_id.push(this.listtotal[i].id)
        }
        this.$store.dispatch('set_Cart', {
          cart_id: this.cart_id
        }).then(() => {
          this.$bvModal.hide('modal-loading')
        })
        this.$cookies.set('cart_id', this.cart_id);
        this.$router.push('/checkout')
      } else {
        this.$bvModal.hide('modal-loading')
        swal("Ooops", 'Mohon memilih produk terlebih dahulu', "error", {
          button: "OK"
        }).then(() => {

          this.$bvModal.hide('modal-loading')
        })
      }

    },
    deletedata(data) {
      if (this.selectAll) {
        this.selectAll = true
      }
      this.iddel = data
    },
    modalcatatan(value) {
      this.indexcatatan = value.index
      if (value.note != null && value.note != 'null') {
        this.notedata = value.note
      }
      this.$bvModal.show("modal-catatan");
    },
    cancelmodal() {
      this.indexcatatan = ''
      this.notedata = ''
      this.$bvModal.hide("modal-catatan");
    },
    async updatenote() {
      // this.$bvModal.show('modal-loading')
      let cart = this.sortedArray[this.indexcatatan];
      let data = new FormData();
      data.append("note", this.notedata);


      data.append("_method", "PUT");
      try {
        let response = await axios.post(
          "/transaction/cart/update-note/" + cart.id,
          data
        );
        this.sortedArray[this.indexcatatan].note = response.data.data.note;
        this.$store.dispatch("carts")

        swal("Berhasil!!!", "Catatan telah diperbaharui", "success", {
          button: "OK",
        });
        this.$bvModal.hide("modal-catatan");
      } catch {
        return
      }
    },
    modalnote(value) {
      this.modal_note = value
      this.$bvModal.show('modalcatatan')

    },
    handleSelectAll() {
      let checkAll = document.getElementById('allproduct').checked
      // console.log("Checked!!!", checkAll);
      if (!checkAll) {
        this.selectAll = true
      } else {
        this.selectAll = false
        setTimeout(() => {
          this.selectAll = true
        }, 100);
      }
    },
    addtotal(value) {
      // untuk proses pengecekan total belanja yang dilakukan terlebih dahulu adalah pengcekan apakah semua terceklist, sebagian atau beberapa, dan yang hanya yang terceklist yang akan di masukkan ke total 
      const checkid = this.listtotal.some(x => x.id == value.id)
      if (checkid) {
        // console.log("Masuk Sini : ", checkid);
        if (value.status) {
          this.listtotal = this.listtotal.map(item => {
            if (item.id === value.id) {
              return {
                id: item.id, qty: value.qty,
                harga: value.harga,
                status: value.status
              };
            }
            return item;
          });
          this.getTotal()
        } else if (!value.status && this.selectAll) {
          // this.selectAll = false
          document.getElementById('allproduct').checked = false
          this.selectAll = true
          this.listtotal = this.listtotal.filter(obj => obj.id !== value.id)
          this.getTotal()
          if (this.listtotal.length <= 0) {
            this.selectAll = false
          }
          // console.log("Di Uncheck 2", JSON.stringify(this.listtotal));
        } else if (!value.status) {
          // console.log("Di Uncheck");
          let indexToDelete = this.listtotal.findIndex(item => item.id === value.id);
          // this.selectAll = false
          if (indexToDelete !== -1) {
            this.listtotal.splice(indexToDelete, 1);
          }
          this.getTotal()
          if (this.listtotal.length == this.carts.length) {
            document.getElementById('allproduct').checked = true
          } else {
            document.getElementById('allproduct').checked = false
          }
        }
      } else {
        if (value.status) {
          this.listtotal.push(value)
          this.getTotal()
        }
        if (this.listtotal.length == this.carts.length) {
          this.selectAll = true
          document.getElementById('allproduct').checked = true
        } else {
          document.getElementById('allproduct').checked = false
        }
        // console.log("Tercentang : ", this.selectAll);
      }
      this.itemCheckedStatus = value.status;
      // console.log("Value Status : ", this.itemCheckedStatus);
    },
    getTotal() {
      let dataqty = 0
      let datatotal = 0
      this.listtotal.forEach((element) => {
        dataqty = Number(dataqty) + Number(element.qty)
        datatotal = Number(datatotal) + Number(element.harga)

      });
      this.qty = dataqty
      this.total = datatotal
    }
  },
  computed: {
    datacart: function () {
      return this.$store?.state?.carts;
    },
    carts: {
      get() {
        return this.datacart.map((item) => {
          return item
        })
      },
      set() {
        this.$store.commit('')
      }
    },
    sortedArray() {
      // ini merupakan pengecekan data terbaru dan terlama, jika yang terbaru maka otomatis akan dipindah ke paling atas saat terjadi perubahan didalam data tersebut 
      const dataarray = this.$store?.state?.carts
      const indexdata = dataarray.findIndex(obj => obj.id === this.iddel)
      if (indexdata !== -1) {
        dataarray.splice(indexdata, 1);

      }
      return [...dataarray].sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at)
      });
    },
  }
}
</script>
<style scoped>
::v-deep #modal-loading .modal-content {
  background-color: transparent;
  border: 0px !important;
}

::v-deep .modal-header button {
  background-color: #fff !important;
  border: 0px !important;
}

.btnmobile {
  height: 25px !important;
  padding: 2px 10px;
  line-height: 1 !important;
  font-size: 0.5rem !important;
  margin-top: 6px;
  border-radius: 5px !important;
}

.view-mobile {
  display: table;
}

.view-web {
  display: none;
}

.view-mobile .catatan {
  font-size: 0.65rem !important;
  padding: 0px 3px;
  line-height: 4;
}

.view-mobile .ubahcatatan {
  font-size: 0.65rem !important;
  padding: 0px 3px;
  line-height: 1;
}

.readmore {
  cursor: pointer;
}

.harga_data h6 {
  font-weight: 600;
}

.diskon {
  display: flex;
}

.diskon p {
  color: #fff;
  font-size: 8px;
  padding: 3px;
  border-radius: 3px;
  background-color: red;
}

.diskon s {
  color: gray;
  font-size: 0.8rem;
  padding-left: 5px;
  text-decoration: line-through !important;
}

.img-not-found {
  display: block;
  width: 35%;
  margin: 0 auto;
}

.float-right {
  float: right !important;
}

.header-cart {
  height: 5rem;
}

.headcart {
  text-align: left;
  font-weight: 600;
  color: #000;
}

.product-cart {
  overflow-x: auto;
}

.table-product {
  position: relative;
  width: 100%;
}

.tr-table {
  position: relative;
}

.header-table::before {
  content: "";
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  height: 3.5rem;
  position: absolute;
}

.stokdataproduk td::after,
.stokdataproduk td div::after,
.stokdataproduk th::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(255, 255, 255, 0.1);
}

.image {
  max-width: 50px;
  max-height: 50px;
  overflow: hidden;
  border-radius: 5px;
}

.image img {
  width: 100%;
}

.detail_product h6 {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

.link_product {
  text-decoration: none;
  color: #000;
}

.detail_product small {
  text-decoration: none;
  color: #000;
}

.input_cart {
  max-width: 120px;
  text-align: center;
  height: 35px !important;
  margin: 5px 5px 0px 5px !important;
  border-radius: 3px !important;
}

.header-product {
  display: inline-block;
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
}

.detail_product h6 {
  font-weight: 600;
}

.product-bottom {
  justify-content: space-between;
}

.catatan-bottom {
  max-width: 200px;
  text-align: left !important;
}

.bottom-product {
  text-align: right;
}

.bottom-product svg {
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
}

.bottom-product .catatan {
  background-color: #fff;
  border: 0px !important;
  fill: #1f7acf;
  color: #1f7acf;
}

.bottom-product .ubahcatatan {
  background-color: #fff;
  border: 0px !important;
  fill: #1f7acf;
  color: #1f7acf;
}

.bottom-product .catatan:hover {
  background-color: #fff;
  border: 0px !important;
  fill: #1f7acf;
  color: #1f7acf;
}

.bottom-product .ubahcatatan:hover {
  background-color: #fff;
  border: 0px !important;
  fill: #1f7acf;
  color: #1f7acf;
}

.bottom-product .trash {
  z-index: 30;
  position: relative;
  color: rgba(0, 0, 0, 0.4);
  fill: rgba(0, 0, 0, 0.4);
}

.bottom-product .trash svg {
  cursor: pointer;
  position: relative;
  top: 7px;
}

.bottom-product input {
  max-height: 1.8rem;
}

.ringkasan_belanja h5 {
  font-weight: 600;
}

.total {
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

.total h6 {
  font-weight: 600;
  text-align: right;
}

@media (max-width: 500px) {
  .headcart {
    font-size: 1rem;
  }

  p,
  h6 {
    font-size: 0.9rem;
  }

  .header-table th {
    font-size: 15px !important;
  }

  .header-table::before {
    height: 3.25rem !important;
  }

  .w-product {
    width: 300px;
  }

  .tb-product {
    min-width: 250px;
    max-width: 300px;
  }

  .w-product {
    width: 250px !important;
  }

  .tb-ukuran {
    min-width: 100px;
    max-width: 150px;
  }

  .tb-price {
    min-width: 100px;
    max-width: 150px;
  }

  .tb-quantity {
    min-width: 100px;
    max-width: 150px;
  }

  .tb-total {
    min-width: 100px;
    max-width: 150px;
  }

  .detail_product h5 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .detail_product h6 {
    font-size: 0.9rem;
    margin-bottom: 0px;
  }

  .bottom-product svg {
    width: 1.3rem;
    height: 1.3rem;
    vertical-align: text-bottom;
  }

  .trash span {
    line-height: 1.4;
    font-size: 1.5rem;
  }

  .detail_product small {
    font-size: 0.8rem;
  }

  .ringkasan_belanja h5 {
    font-size: 1rem;
  }

  .total h6 {
    font-size: 0.85rem;
  }
}

@media (min-width: 319px) {
  .catatan-bottom {
    max-width: 215px;
  }
}

@media (min-width: 374px) {
  .catatan-bottom {
    max-width: 300px;
  }

  .view-mobile .catatan {
    font-size: 0.8rem !important;
    padding: 5px 10px;
    line-height: 2;
  }

  .view-mobile .ubahcatatan {
    font-size: 0.8rem !important;
    padding: 5px 10px;
  }
}

@media (min-width: 424px) {}

@media (min-width: 767px) {
  .view-mobile {
    display: none;
  }

  .view-web {
    display: table;
  }

  .header-product {
    display: block;
  }

  .tb-product {
    min-width: 150px;
    max-width: 200px;
  }

  .w-product {
    width: 250px !important;
  }

  .tb-ukuran {
    min-width: 70px;
    max-width: 100px;
  }

  .tb-price {
    min-width: 70px;
    max-width: 100px;
  }

  .tb-quantity {
    min-width: 70px;
    max-width: 100px;
  }

  .tb-total {
    min-width: 70px;
    max-width: 100px;
  }

  .header-cart {
    height: 9rem;
  }

  .bottom-product .trash svg {
    top: 0px;
  }
}

@media (min-width: 1023px) {}

@media (min-width: 1439px) {}

@media (min-width: 2000px) {}
</style>
