<template>
    <div>
        <div class="d-flex container-custom">
            <div class="data-left">
                <div class="data-left-content">
                    <router-link to="/" class="d-block text-center font-bold custom-link logo-container auth-logo-container" style="font-size:24px;">
                        <!-- <logo></logo> -->
                        <!-- <img :src="logo" alt="" class="logo"> -->
                        <img src="../../assets/logo_arys_mikro.jpeg" alt="" class="logo">
                    </router-link>
                    <div class="text-center">
                        <h3 class="mt-3"><b>Selamat datang <br> di Ary's Mikro.</b></h3>
                        <p>Silahkan masukkan email/username dan kata sandi Anda</p>
                    </div>
                    <form @submit.prevent="login" class="w-100">
                        <div class="form-group mt-4">
                            <label for="email">Email atau Username</label>
                            <input type="text" class="form-control radius" id="email" placeholder="Email/Username" v-model="email" 
                            :class="[v$.email.$error ? 'is-invalid': '']"
                            >
                            <span  v-if="v$.email.$error" class="text-danger font-small pesan-error" id="error-email"> {{ v$.email.$errors[0].$message }}</span>

                        </div>
                        <div class="form-group mt-4">
                            <label for="password">Kata Sandi</label>
                            <div class="position-relative password-input">
                                <input type="password" class="form-control radius field-password" id="password" placeholder="Kata sandi" autocomplete="false" v-model="password" 
                                :class="[v$.password.$error ? 'is-invalid': '']"
                                >
                                <div v-if="!v$.password.$error" class="icon-container">
                                     <svg @click="togglePass" xmlns="http://www.w3.org/2000/svg" class="eyeshow d-show" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path fill="currentColor" d="M33.62 17.53c-3.37-6.23-9.28-10-15.82-10S5.34 11.3 2 17.53l-.28.47l.26.48c3.37 6.23 9.28 10 15.82 10s12.46-3.72 15.82-10l.26-.48Zm-15.82 8.9C12.17 26.43 7 23.29 4 18c3-5.29 8.17-8.43 13.8-8.43S28.54 12.72 31.59 18c-3.05 5.29-8.17 8.43-13.79 8.43Z" class="clr-i-outline clr-i-outline-path-1"/><path fill="currentColor" d="M18.09 11.17A6.86 6.86 0 1 0 25 18a6.86 6.86 0 0 0-6.91-6.83Zm0 11.72A4.86 4.86 0 1 1 23 18a4.87 4.87 0 0 1-4.91 4.89Z" class="clr-i-outline clr-i-outline-path-2"/><path fill="none" d="M0 0h36v36H0z"/></svg>
                                    <svg @click="togglePass" xmlns="http://www.w3.org/2000/svg" class="eyeslas d-hide" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path fill="currentColor" d="M25.19 20.4a6.78 6.78 0 0 0 .43-2.4a6.86 6.86 0 0 0-6.86-6.86a6.79 6.79 0 0 0-2.37.43L18 13.23a4.78 4.78 0 0 1 .74-.06A4.87 4.87 0 0 1 23.62 18a4.79 4.79 0 0 1-.06.74Z" class="clr-i-outline clr-i-outline-path-1"/><path fill="currentColor" d="M34.29 17.53c-3.37-6.23-9.28-10-15.82-10a16.82 16.82 0 0 0-5.24.85L14.84 10a14.78 14.78 0 0 1 3.63-.47c5.63 0 10.75 3.14 13.8 8.43a17.75 17.75 0 0 1-4.37 5.1l1.42 1.42a19.93 19.93 0 0 0 5-6l.26-.48Z" class="clr-i-outline clr-i-outline-path-2"/><path fill="currentColor" d="m4.87 5.78l4.46 4.46a19.52 19.52 0 0 0-6.69 7.29l-.26.47l.26.48c3.37 6.23 9.28 10 15.82 10a16.93 16.93 0 0 0 7.37-1.69l5 5l1.75-1.5l-26-26Zm9.75 9.75l6.65 6.65a4.81 4.81 0 0 1-2.5.72A4.87 4.87 0 0 1 13.9 18a4.81 4.81 0 0 1 .72-2.47Zm-1.45-1.45a6.85 6.85 0 0 0 9.55 9.55l1.6 1.6a14.91 14.91 0 0 1-5.86 1.2c-5.63 0-10.75-3.14-13.8-8.43a17.29 17.29 0 0 1 6.12-6.3Z" class="clr-i-outline clr-i-outline-path-3"/><path fill="none" d="M0 0h36v36H0z"/></svg>
                                </div>
                            </div>

                            <span v-if="v$.password.$error" class="text-danger font-small pesan-error" id="error-password">{{ v$.password.$errors[0].$message }}</span>
                        </div>

                        <div v-if="errorMessage != ''" class="alert alert-warning alert-dismissible fade show mt-4"  role="alert">
                            <strong>Peringatan ! </strong> 
                            <span> {{errorMessage}} </span>
                        </div>
                        <div class="form-group mt-2">
                            <router-link to="/lupa-password" class="linkforgot" style="float:right;">Lupa Kata Sandi?</router-link>
                        </div>
                        <div class="form-group mt-5">
                            <button type="submit" class="btn btn-blue w-100 btn-radius">Masuk</button>
                            <div class="mt-2 hr-login">
                                <span>
                                    Atau 
                                </span>
                            </div>
                            <router-link to="/register" class="btn btn-outline-blue w-100 mt-3 btn-radius" >Daftar</router-link>
                        </div>
                    </form>
                </div>
            </div>
            <div class="data-right">
                <img src="../../assets/img/minimarket3.webp" alt="">
            </div>
        </div>


        <div v-if="loadingSubmit" class="loading-submit-animation-container" >
            <div class="loading-submit-animation-content">
                <div>
                    <div class="lds-facebook"><div></div><div></div><div></div></div>
                    <p>Mohon tunggu, data sedang diproses</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from "axios";
import useVuelidate from '@vuelidate/core'
import { required, minLength, helpers} from '@vuelidate/validators'

export default {
    name: 'login-component',
    props:['banners'],
    setup () {
        return { v$: useVuelidate() }
    },
    components: {
    },
    data(){
        return{
            email: "",
            password: "",
            errorMessage: '',
            loadingSubmit: false,
            loginImage: {},
            baseUrl: process.env.VUE_APP_NAME_AXIOS
        }
    },
    validations() {
        return {
            email: {
                required: helpers.withMessage("Email / Username tidak boleh kosong", required),
                // email: helpers.withMessage("Format email salah", email)
            },
            password: {
                required: helpers.withMessage("Kata sandi tidak boleh kosong", required), 
                min: helpers.withMessage(
                    ({
                        $params
                    }) => `Kata sandi minimal memiliki ${$params.min} karakter `,
                    minLength(8)
                )
            },
        }
    },
    mounted(){
        this.setImage()
    },
    methods: {
        togglePass(){
            const input = document.querySelector(".field-password");
            const eyeshow = document.querySelector(".eyeshow");
            const eyehide = document.querySelector(".eyeslas");
            if (input.type === "password") {
                eyeshow.classList.remove("d-show");
                eyehide.classList.remove("d-hide");
                eyehide.classList.add("d-show");
                eyeshow.classList.add("d-hide");
                input.type = "text";
            } else {
                eyehide.classList.remove("d-show");
                eyeshow.classList.remove("d-hide");
                eyeshow.classList.add("d-show");
                eyehide.classList.add("d-hide");
                input.type = "password";
            }
        },
        setImage(){
            if(this.banners != null){
                for(let i = 0; i < Object.keys(this.banners).length; i++){
                    if(this.banners[i].position === 'Login Page'){
                        this.loginImage = this.banners[i].image
                    }
                }
                this.loadingMainBanner = false
            }
        },
        login() {

            this.v$.$validate()
            let data = new FormData();
            data.append('email', this.email)
            data.append('password', this.password)
            this.errorMessage = ''

            if ( !this.v$.$error) { 
                this.loadingSubmit =  true

                axios.post('customer/login', data)
                .then(response => {
                    this.$cookies.set('token', response.data.data.token)
                    this.$router.push('/');
                    this.loadingSubmit =  false

                })
                .catch(error => {
                    
                    let val = Object.values(error.response.data)
                    let key = Object.keys(error.response.data)
                    for(let j = 0; j < key.length; j++){
                        if(key[j] == 'message'){
                            if(val[j] != ''){
                                this.errorMessage = val[j] 
                            }
                        }
                    }
                    this.loadingSubmit =  false
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });

                }); 
            }
        },
    },
     watch:{
        banners:{
            deep: true,
            handler: function(){
                this.setImage()
                //           :autoplay='{
                // "delay": 2500,
                // "disableOnInteraction": false
                // }'
            }
        },
    }
}
</script>


<style scoped>
.d-show{
    display: block;
    cursor: pointer;
}
.d-hide{
    display: none;
}
.btn-blue{
    background-color: #1F7ACF;
    color:#fff;
    padding: 5px 30px;
    border-radius: 25px;
}
.btn-blue:hover{
    background-color: #125da3;
    color:#fff;
}
.btn-outline-blue{
    border:1px solid #1F7ACF;
    color:#1F7ACF;
    padding: 5px 30px;
    border-radius: 25px;
}
.btn-outline-blue:hover{
    background-color: #1F7ACF;
    color:#fff;
}

.logo{
    width: 200px;
}
.container-custom{
    display: flex;
    flex-direction: column;
}
.password-input .icon-container{
    position:  absolute;
    top:0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items:center;
    padding: 1rem;
}

.password-input .icon-container:hover{
    cursor:pointer;
}

.password-input input[type="password"]{
    padding-right: 2.5rem;
}

.data-left{
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:70px 20px;
}
.data-left,
.data-right{
    width: 100%;
}
.data-left-content{
    width: 85%;
}
.data-left small{
    color: rgba(0, 0, 0, 0.5);
}
.data-right{
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: auto;
    z-index: 100;
    height: auto;
    /* background: url('../../assets/national-cancer-institute-GcrSgHDrniY-unsplash.webp'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    display: none;
}
.data-right img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.hr-login{
    width: 100%; 
    height: 13px; 
    border-bottom: 1px solid rgba(0, 0, 0, 0.5); 
    text-align: center
}
.hr-login span{
    padding: 0 10px;
    color: rgba(0, 0, 0, 0.5);
    background-color: #fff;
}
.radius{
    border-radius: 25px;
    padding: 0.7rem 1.5rem
}
.btn-radius{
    border-radius: 25px;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}
.linkforgot{
    float: right;
    color: rgba(0, 0, 0, 0.5) !important;
    font-size: 0.8rem;
    text-decoration: none;
    padding-right: 15px;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .container-custom{
        flex-direction: row;
    }
    .data-left,
    .data-right{
        width: 50%;
    }

    .data-right{
        display: block;
    }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>