<template>
  <div class="profile section">
    <Navbar />
    <div class="header-nav"></div>
    <Loader v-if="loading" />
    <SkeletonKategori v-if="loading" />
    <div v-if="!loading" class="profile-container container">

      <div class="profile-data row">
        <div class="col-lg-3 web-view">
          <ul class="list-group-custom" id="profilList">
            <li class="li-group-costum">
              <h5>Rating</h5>
              <div class="data-rating">
                <input
                  type="radio"
                  @click="uncheck(5)"
                  id="5"
                  name="fav_language"
                  v-model="rating"
                  value="5"
                />
                <label for="5" :class="rating == 5 ? 'active' : ''">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    style="transform: ; msfilter: "
                  >
                    <path
                      d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                    ></path>
                  </svg>
                  <span class="pt-2">5</span> </label
                ><br />
                <input
                  type="radio"
                  @click="uncheck(4)"
                  id="4"
                  name="fav_language"
                  v-model="rating"
                  value="4"
                />
                <label for="4" :class="rating == 4 ? 'active' : ''">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    style="transform: ; msfilter: "
                  >
                    <path
                      d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                    ></path>
                  </svg>
                  <span class="pt-2">4</span>
                  </label
                ><br />
                <input
                  type="radio"
                  @click="uncheck(3)"
                  id="3"
                  name="fav_language"
                  v-model="rating"
                  value="3"
                />
                <label for="3" :class="rating == 3 ? 'active' : ''">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    style="transform: ; msfilter: "
                  >
                    <path
                      d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                    ></path>
                  </svg>
                  <span>3</span></label
                ><br />
                <input
                  type="radio"
                  @click="uncheck(2)"
                  id="2"
                  name="fav_language"
                  v-model="rating"
                  value="2"
                />
                <label for="2" :class="rating == 2 ? 'active' : ''">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    style="transform: ; msfilter: "
                  >
                    <path
                      d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                    ></path>
                  </svg>
                  <span>2</span></label
                ><br />
                <input
                  type="radio"
                  @click="uncheck(1)"
                  id="1"
                  name="fav_language"
                  v-model="rating"
                  value="1"
                />
                <label for="1" :class="rating == 1 ? 'active' : ''">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    style="transform: ; msfilter: "
                  >
                    <path
                      d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                    ></path>
                  </svg>
                  <span>1</span></label
                ><br />
              </div>
            </li>
            <li class="li-group-costum">
              <h5>Harga</h5>
              <div class="inputminmax">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Rp</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    min="0"
                    v-model="minPrice"
                    placeholder="Harga Minimum"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Rp</span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    :min="minPrice"
                    v-model="maxPrice"
                    placeholder="Harga Maksimum"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
            </li>
            <li>
              <button class="btn btn-secondary w-100 my-2" @click="filterProduct">
                Filter
              </button>
            </li>
          </ul>
        </div>
        <b-modal id="modal-loading" centered hide-footer hide-header>
          <div class="text-center">
            <b-spinner variant="light"></b-spinner>
          </div>
        </b-modal>

        <div class="col-lg-9 produk_kategori-mobile">
          <div class="header-kategori px-2">
            <div class="d-flex">
              <h3 class="mt-1">{{ namekategori }}</h3>
              <b-button
                class="mx-2 btn-modal-filter mobile-view"
                v-b-modal.modal-filter
                variant="outline-dark"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  style="transform: ; msfilter: "
                >
                  <path
                    d="M21 3H5a1 1 0 0 0-1 1v2.59c0 .523.213 1.037.583 1.407L10 13.414V21a1.001 1.001 0 0 0 1.447.895l4-2c.339-.17.553-.516.553-.895v-5.586l5.417-5.417c.37-.37.583-.884.583-1.407V4a1 1 0 0 0-1-1zm-6.707 9.293A.996.996 0 0 0 14 13v5.382l-2 1V13a.996.996 0 0 0-.293-.707L6 6.59V5h14.001l.002 1.583-5.71 5.71z"
                  ></path>
                </svg>
                filter</b-button
              >
              <b-modal id="modal-filter" hide-footer title="Filter" class="filter-modal">
                <ul class="list-group-custom" id="profilList">
                  <li class="li-group-costum d-mobile">
                    <h5>Urutkan</h5>
                    <select
                      class="m-2 m-md-2 dropdown-filter form-select form-select-sm"
                      v-model="sort"
                    >
                      <option v-bind:value="'terlaris'">Terlaris</option>
                      <option v-bind:value="'terbaru'">Terbaru</option>
                    </select>
                  </li>
                  <li class="li-group-costum">
                    <h5>Rating</h5>
                    <select
                      class="m-2 m-md-2 dropdown-filter form-select form-select-sm d-mobile"
                      v-model="rating"
                    >
                      <option v-bind:value="'5'" class="rating-option">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style="transform: ; msfilter: "
                        >
                          <path
                            d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                          ></path>
                        </svg>
                        <span>5</span>
                      </option>
                      <option v-bind:value="'4'" class="rating-option">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style="transform: ; msfilter: "
                        >
                          <path
                            d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                          ></path>
                        </svg>
                        <span>4</span>
                      </option>
                      <option v-bind:value="'3'" class="rating-option">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style="transform: ; msfilter: "
                        >
                          <path
                            d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                          ></path>
                        </svg>
                        <span>3</span>
                      </option>
                      <option v-bind:value="'2'" class="rating-option">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style="transform: ; msfilter: "
                        >
                          <path
                            d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                          ></path>
                        </svg>
                        <span>2</span>
                      </option>
                      <option v-bind:value="'1'" class="rating-option">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style="transform: ; msfilter: "
                        >
                          <path
                            d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                          ></path>
                        </svg>
                        <span>1</span>
                      </option>
                    </select>
                    <div class="data-rating d-web">
                      <input
                        type="radio"
                        @click="uncheck(5)"
                        id="5"
                        name="fav_language"
                        v-model="rating"
                        value="5"
                      />
                      <label for="5" :class="rating == 5 ? 'active' : ''">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style="transform: ; msfilter: "
                        >
                          <path
                            d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                          ></path>
                        </svg>
                        <span>5</span>
                        </label
                      ><br />
                      <input
                        type="radio"
                        @click="uncheck(4)"
                        id="4"
                        name="fav_language"
                        v-model="rating"
                        value="4"
                      />
                      <label for="4" :class="rating == 4 ? 'active' : ''">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style="transform: ; msfilter: "
                        >
                          <path
                            d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                          ></path>
                        </svg>
                        <span>4</span>
                        </label
                      ><br />
                      <input
                        type="radio"
                        @click="uncheck(3)"
                        id="3"
                        name="fav_language"
                        v-model="rating"
                        value="3"
                      />
                      <label for="3" :class="rating == 3 ? 'active' : ''">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style="transform: ; msfilter: "
                        >
                          <path
                            d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                          ></path>
                        </svg>
                        <span>3</span>
                        </label
                      ><br />
                      <input
                        type="radio"
                        @click="uncheck(2)"
                        id="2"
                        name="fav_language"
                        v-model="rating"
                        value="2"
                      />
                      <label for="2" :class="rating == 2 ? 'active' : ''">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style="transform: ; msfilter: "
                        >
                          <path
                            d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                          ></path>
                        </svg>
                        <span>2</span>
                        </label
                      ><br />
                      <input
                        type="radio"
                        @click="uncheck(1)"
                        id="1"
                        name="fav_language"
                        v-model="rating"
                        value="1"
                      />
                      <label for="1" :class="rating == 1 ? 'active' : ''">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style="transform: ; msfilter: "
                        >
                          <path
                            d="M21.947 9.179a1.001 1.001 0 0 0-.868-.676l-5.701-.453-2.467-5.461a.998.998 0 0 0-1.822-.001L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.213 4.107-1.49 6.452a1 1 0 0 0 1.53 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082c.297-.268.406-.686.278-1.065z"
                          ></path>
                        </svg>
                        <span>1</span>
                        </label
                      ><br />
                    </div>
                  </li>
                  <li class="li-group-costum">
                    <h5>Harga</h5>
                    <div class="inputminmax">
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">Rp</span>
                        </div>
                        <input
                          type="number"
                          class="form-control"
                          min="0"
                          v-model="minPrice"
                          placeholder="Harga Minimum"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1">Rp</span>
                        </div>
                        <input
                          type="number"
                          class="form-control"
                          :min="minPrice"
                          v-model="maxPrice"
                          placeholder="Harga Maksimum"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                  </li>
                  
                  <li>
                    <button class="btn btn-secondary w-100 my-2" @click="filterProduct">
                      Filter
                    </button>
                  </li>
                </ul>
              </b-modal>
            </div>
            <div class="d-flex d-web">
              <h6 class="">Urutkan</h6>
              <select
                class="m-2 m-md-2 dropdown-filter form-select form-select-sm"
                @change="filterProduct"
                v-model="sort"
              >
                <option v-bind:value="'terlaris'">Terlaris</option>
                <option v-bind:value="'terbaru'">Terbaru</option>
              </select>
            </div>
          </div>
          <div class="kategori-produk container mt-3 mt-md-5">
            <div
              class="item_produk_rekomendasi justify-content-center"
              v-if="recommendData.length > 0"
            >
              <div
                v-for="(productrecommend, index) in recommendData"
                :key="'produk' + index + productrecommend?.id"
              >
                <product-list
                  :index="index"
                  :id="productrecommend?.product.id"
                  :img="productrecommend?.product.image"
                  :title="productrecommend?.product.name"
                  :desc="productrecommend?.product.descriptions"
                  :price="productrecommend?.product.price"
                  :rating="productrecommend?.product.star_review"
                  :total_sold="productrecommend?.product.total_sold"
                  :status="productrecommend?.product.status"
                  :class="!productrecommend?.product.price[0].price ? 'd-none' : ''"
                  class="data_produk"
                >
                </product-list>
              </div>
            </div>
            <div
              class="item_produk_rekomendasi justify-content-center"
              v-else-if="ProdukShamppo.length > 0"
            >
              <product-list
                v-for="(product, index) in ProdukShamppo"
                :key="'produk' + index + product.id"
                :index="index"
                :id="product.id"
                :img="product.image"
                :title="product.name"
                :desc="product.descriptions"
                :price="product.price"
                :rating="product.star_review"
                :total_sold="product.total_sold"
                :status="product.status"
                :class="!product.price[0].price ? 'd-none' : ''"
                class="data_produk"
              >
              </product-list>
            </div>
            <div v-else-if="ProdukShamppo.length <= 0 && !loadingPage" class="">
              <div>
                <p class="text-center"><b>Produk tidak ditemukan.</b></p>
              </div>
            </div>
            <div
              class="pt-5"
              v-if="ProdukShamppo.length > 9 || recommendData.length > 9"
              ref="divScroll"
            ></div>
            <div v-if="loadingPage" class="loadingdata">
              <div class="pulse-container">
                <div class="pulse-bubble pulse-bubble-1"></div>
                <div class="pulse-bubble pulse-bubble-2"></div>
                <div class="pulse-bubble pulse-bubble-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import SkeletonKategori from "../Skeleton/SkeletonKategori.vue";
import Loader from "@/components/Loader/Loader.vue";
import ProductList from "./ProductKategory.vue";
import Navbar from "@/components/NavbarUno.vue";
import Footer from "@/components/FooterUno.vue";
import axios from "axios";
import LogTransaksiVue from "../Transaksi/LogTransaksi.vue";
export default {
  data() {
    return {
      namekategori: "Produk",
      allDataProducts: [],
      recProduct: [],
      dataProducts: [],
      dataRecommend: [],
      kategoriId: [],
      kategoriIdCadangan: [],
      subKategoriIdCadangan: [],
      categories_title: [],
      subcategories_title: [],
      kategoriCadangan: [],
      subKategori: "",
      linkurl: "",
      categoryproduk: [],
      arrays: [],
      loading: true,
      loadingPage: false,
      recomenddata: true,
      editdata: false,
      promo: false,
      activeclass: false,
      rating: "",
      pagedata: 1,
      sort: "",
      search: "",
      minPrice: "",
      maxPrice: "",
      page: "",
      lastPage: "",
      pageTotal: "",
      show: 50,
      pagination: {
        currentPage: 1,
        nextPage: false,
        prevPage: false,
        from: 0,
        to: 0,
        totalData: 0,
        maxLink: 5,
        linkPagination: [],
      },
    };
  },
  async mounted() {
    try {
      if (this.$route.query?.page) {
        this.page = this.$route.query.page;
      } else {
        this.page = 1;
      }
      window.addEventListener("scroll", this.handleScroll);
      if (this.$route.query?.rekomendasi === "true" && this.recomenddata) {
        this.namekategori = "Produk Rekomendasi";
        this.getProduct();
      } else {
        this.setSearch();
        this.getProduct();
      }
    } catch (error) {
      this.loading = false;
      return error;
    }
  },
  beforeDestroy() {
    // Remove the scroll event listener when the component is destroyed to prevent memory leaks
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    kategori: function () {
      return this.$store.state.category;
    },
    ProdukShamppo: function () {
      // pengecekan apakah didalam data price yang didapat dari api, didalam tersebut terdapat semua stock yang kosong, jika tidak maka akan ditampilkan dan jika ditemukan data price dengan semua price stoknya kosong maka akan dipindah ke bawah
      return [...this.dataProducts].sort((a, b) => {
        const sumStockA = a.price.reduce((total, p) => total + p.stock, 0);
        const sumStockB = b.price.reduce((total, p) => total + p.stock, 0);

        if (sumStockA <= 0 && sumStockB > 0) {
          return 1; // Move product A to the end
        } else if (sumStockA > 0 && sumStockB <= 0) {
          return -1; // Move product B to the end
        }

        return 0; // Maintain the order for other cases
      });
    },
    recommendData() {
      // pengecekan apakah didalam data price yang didapat dari api, didalam tersebut terdapat semua stock yang kosong, jika tidak maka akan ditampilkan dan jika ditemukan data price dengan semua price stoknya kosong maka akan dipindah ke bawah 
      return [...this.dataRecommend].sort((a, b) => {
        const sumStockA = a.product?.price.reduce((total, p) => total + p.stock, 0);
        const sumStockB = b.product?.price.reduce((total, p) => total + p.stock, 0);

        if (sumStockA <= 0 && sumStockB > 0) {
          return 1; // Move product A to the end
        } else if (sumStockA > 0 && sumStockB <= 0) {
          return -1; // Move product B to the end
        }

        return 0; // Maintain the order for other cases
      });
    },
  },
  components: {
    Navbar,
    Footer,
    ProductList,
    SkeletonKategori,
    Loader,
  },
  watch: {
    dataProducts() {
      this.editdata = false;
    },
    "$route.query": {
      handler: function () {
        this.dataProducts = [];
        this.dataRecommend = [];
        this.setSearch();
        this.getProduct();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    uncheck(data) {
      if (data == this.rating) {
        this.rating = "";
      }
    },
    handleScroll() {
      // Get the scroll position of the window
      const scrollY = window.scrollY;

      // Get the position of the target div
      const divPosition = this.$refs?.divScroll?.getBoundingClientRect();

      // Calculate the bottom position of the div
      const divBottom = divPosition?.top + divPosition?.height;
      // this.loadingPage = true
      // Check if the scroll position is at the bottom of the div
      if (scrollY >= divBottom && this.pagedata < this.pageTotal && !this.editdata) {
        this.pagedata = this.pagedata + 1;
        this.loadingPage = true;
        this.editdata = true;
        // The scroll position is at the bottom of the div
        let formdata = new FormData();
        if (this.subKategoriIdCadangan.length > 0) {
          this.subKategoriIdCadangan.forEach((element) => {
            formdata.append("subcategory[]", element);
          });
        }

        if (this.kategoriIdCadangan.length > 0) {
          this.kategoriIdCadangan.forEach((element) => {
            formdata.append("category[]", element);
          });
        }
        if (this.$route.query.search != undefined) {
          formdata.append("search", this.$route.query.search);
        }
        if (this.$route.query.price_min != undefined) {
          formdata.append("price_min", parseInt(this.$route.query.price_min));
        }
        if (this.$route.query.price_max != undefined) {
          formdata.append("price_max", parseInt(this.$route.query.price_max));
        }
        if (this.$route.query.rating != undefined) {
          formdata.append("rating", parseInt(this.$route.query.rating));
        }
        if (this.$route.query.sort != undefined) {
          formdata.append("sort", this.$route.query.sort);
        }
        if (this.promo == true) {
          formdata.append("promo", this.promo);
        }
        formdata.append("stocked", true);
        formdata.append("limit", 12);
        formdata.append("page", this.pagedata);
        axios
          .post(`/product/filter`, formdata)
          .then((res) => {
            var newdata = res.data.data.products;
            newdata.forEach((data) => {
              this.dataProducts.push(data);
            });
            this.pageTotal = res.data.data.pageTotal;
            if (this.pageTotal == this.pagedata) {
              this.loadingPage = false;
            }
            // this.loadingPage = false
          })
          .catch(() => {
            // this.loadingPage = false
          });
      } else {
        // this.loadingPage = false
        // The scroll position is not at the bottom of the div
        // console.log('Scroll position is not at the bottom of the div.');
      }
    },
    setSearch() {
      
      this.loading = true;
      this.namekategori = "Produk";
      //if search is '' redirec to /produk
      // ini digunakan untuk mendapatkan url sebelum dilakukan search sehingga nanti bisa ditambahkan param search
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
      let search = params.search;
      if (search != null) {
        this.search = search;
        if (this.search == "" && search != null) {
          this.$router.push("/kategori");
        }
      } else {
        this.search = "";
      }
      
      this.loading = false;
    },
    setPagination() {
      let totalDataWillShow = 0;
      let from = 0;
      let currentPage = 0;

      let page = this.page;
      if (page != "") {
        totalDataWillShow = parseInt(this.show) * parseInt(page);
        if (page > 1) {
          from = parseInt((page - 1) * this.show) + 1;
        } else {
          from = 1;
        }
        currentPage = parseInt(page);
      } else {
        totalDataWillShow = this.pagination.totalData >= this.show;
        currentPage = 1;
        from = 1;
      }

      this.pagination.currentPage = currentPage;
      this.pagination.from = from;
      this.pagination.to = this.setDataPaginationTo(totalDataWillShow);
      if (this.pagination.from <= 1) {
        this.pagination.prevPage = false;
      } else {
        this.pagination.prevPage = true;
      }
      if (this.pagination.totalData > this.pagination.to) {
        this.pagination.nextPage = true;
      } else {
        this.pagination.nextPage = false;
      }

      this.pagination.linkPagination = this.setLinkPagination();
    },
    setLinkPagination() {
      // set number link
      let arrayLink = [];
      let totalLink = this.pageTotal;
      for (let i = 1; i <= totalLink; i++) {
        arrayLink.push(i);
      }

      let currentPage = 0;
      if (this.pagination.currentPage > 1) {
        currentPage = parseInt(this.pagination.currentPage - 2);
      }
      arrayLink = arrayLink.slice(
        currentPage,
        parseInt(currentPage + this.pagination.maxLink)
      );
      this.lastPage = arrayLink[arrayLink.length - 1];
      return arrayLink;
    },
    setDataPaginationTo(totalDataWillShow) {
      let paginationTo = 0;
      /**
       * Menentukan nilai this.pagination.to
       *
       * Jika total seluruh data kurang dari jumlah data yang akan ditampilkan
       * contoh :
       * total seluruh data  = x, x= 8
       * jumlah data yang akan di tampilkan = y, y=10
       *
       * z = y + ( x - y )
       * z = 10 + (8 - 10)
       * z = 10 - 2
       * z = 8  -> this.pagination.to
       */
      if (this.pagination.totalData < totalDataWillShow) {
        paginationTo =
          totalDataWillShow + (this.pagination.totalData - totalDataWillShow);
      } else {
        /**
         *
         * Jika total seluruh data lebih dari jumlah data yang akan ditampilkan
         * contoh :
         * total seluruh data  = x, x= 20,
         * jumlah data yang akan di tampilkan = y, y= 10
         *
         * z = x - ( x - y )
         * z = 20 + (20 - 10)
         * z = 20 - 10
         * z = 10  -> this.pagination.to
         */
        paginationTo =
          this.pagination.totalData - (this.pagination.totalData - totalDataWillShow);
      }

      return paginationTo;
    },
    setSubKategoriId() {
      // ketika centang kategori set data subkategori sesuai dengan kategori yang dipilih
      // centang semua subkategori, sesuai dengan kategori yang dipilih
      this.subKategoriIdCadangan = [];
      this.kategoriIdCadangan.forEach((kategori_id) => {
        let check_kategori = document.querySelectorAll(".kategori-" + kategori_id);
        check_kategori.forEach((check) => {
          if (!this.subKategoriIdCadangan.includes(parseInt(check.value))) {
            this.subKategoriIdCadangan.push(parseInt(check.value));
          }
        });
      });
    },
    setKategoriId() {
      // centang semua kategori, sesuai dengan subkategori yang dipilih
      this.kategoriIdCadangan = [];
      this.kategori?.forEach((kategori) => {
        if (kategori?.subcategory.length > 0) {
          for (let i = 0; i < kategori.subcategory.length; i++) {
            if (this.subKategoriIdCadangan.includes(kategori.subcategory[i].id)) {
              if (
                !this.kategoriIdCadangan.includes(kategori.subcategory[i].category_id)
              ) {
                this.kategoriIdCadangan.push(kategori.subcategory[i].category_id);
              }
            }
          }
        }
      });
    },
    showData() {
      let page = this.page;
      if (page != "") {
        this.pagination.totalData = Object.values(this.allDataProducts).length;
        this.setPagination();
        this.dataProducts = Object.values(this.allDataProducts);
      } else {
        this.page = 1;
        this.dataProducts = Object.values(this.allDataProducts);
        this.pagination.totalData = Object.values(this.allDataProducts).length;
        this.setPagination();
      }
    },
    setDataFilter() {
      /** Fungsi ini untuk mengatur parameter filter data yang didapat dari url, parameter filter akan digunakan untuk mangambil data api*/

      /** Set Paramater Kategori ID & Sub Kategori ID*/
      let kategori_id = [];
      let subkategori_id = [];

      if (this.$route.query.kategori) {
        let routeKategori = this.$route.query.kategori;
        let id_kategori = routeKategori.split(",");
        id_kategori.forEach((element) => {
          kategori_id.push(parseInt(element));
        });
      }
      if (this.$route.query.sub_kategori && this.$route.query.kategori) {
        let routeSubKategori = this.$route.query.sub_kategori;
        let id_subkategori = routeSubKategori.split(",");
        id_subkategori.forEach((element) => {
          subkategori_id.push(parseInt(element));
        });
      }
      this.kategoriIdCadangan = kategori_id;
      this.kategoriId = kategori_id;

      this.subKategoriIdCadangan = subkategori_id;
      this.subKategoriId = subkategori_id;

      /** Set Price */
      this.minPrice = this.$route.query.price_min ? this.$route.query.price_min : "";
      this.maxPrice = this.$route.query.price_max ? this.$route.query.price_max : "";

      /** Set Rating */
      this.rating = this.$route.query.rating ? this.$route.query.rating : "";
      /** Set recommend */

      this.recomenddata = this.$route.query.rekomendasi == "true" ? true : false;

      /** Set Sort */
      this.sort = this.$route.query.sort ? this.$route.query.sort : "";

      /** Set Page */
      this.page = this.$route.query.page ? this.$route.query.page : 1;
      this.datalink();
    },
    openAccordion(event, id) {
      /** Fungsi ini untuk mengatur accordion di modal filter, kategori dan subkategori */
      const accordion = document.querySelectorAll(".accordion-custom-link");
      if (event.currentTarget.classList.contains("active")) {
        event.currentTarget.classList.remove("active");
        this.kategoriIdCadangan.forEach((data, index) => {
          if (data == id) {
            this.kategoriIdCadangan.splice(index, 1);
          }
        });
      } else {
        accordion.forEach((n) => {
          n.classList.remove("active");
        });
        event.currentTarget.classList.add("active");
        this.kategoriIdCadangan.push(id);
        this.setSubKategoriId();
      }
    },
    datalink() {
      this.kategoriId = this.kategoriIdCadangan;
      this.subKategoriId = this.subKategoriIdCadangan;
      let p_subkategori =
        this.subKategoriId.length > 0 ? "sub_kategori=" + this.subKategoriId : "";
      let p_kategori = this.kategoriId.length > 0 ? "kategori=" + this.kategoriId : "";
      let p_search = "search=" + this.search;
      let p_minprice = "price_min=" + this.minPrice;
      let p_maxprice = "price_max=" + this.maxPrice;
      let p_rating = "rating=" + this.rating;
      let p_sort = "sort=" + this.sort;
      let p_recomenddata = "rekomendasi=" + this.recomenddata;

      let array_filter = [];
      array_filter.push(p_subkategori);
      array_filter.push(p_kategori);
      array_filter.push(p_search);
      array_filter.push(p_minprice);
      array_filter.push(p_maxprice);
      array_filter.push(p_rating);
      array_filter.push(p_sort);
      array_filter.push(p_recomenddata);
      let url = "";
      // let currentUrl = this.$route.query.search

      for (let i = 0; i < array_filter.length; i++) {
        if (array_filter[i].substring(array_filter[i].indexOf("=") + 1) != "") {
          url += "&" + array_filter[i];
        }
      }
      this.linkurl = url + "&stocked=1";
    },
    filterProduct() {
      this.kategoriId = this.kategoriIdCadangan;
      this.subKategoriId = this.subKategoriIdCadangan;
      let p_subkategori =
        this.subKategoriId.length > 0 ? "sub_kategori=" + this.subKategoriId : "";
      let p_kategori = this.kategoriId.length > 0 ? "kategori=" + this.kategoriId : "";
      let p_search = "search=" + this.search;
      let p_minprice = "price_min=" + this.minPrice;
      let p_maxprice = "price_max=" + this.maxPrice;
      let p_rating = "rating=" + this.rating;
      let p_sort = "sort=" + this.sort;
      let p_recomenddata = "rekomendasi=" + this.recomenddata;
      let p_page = "page=1";

      let array_filter = [];
      array_filter.push(p_subkategori);
      array_filter.push(p_kategori);
      array_filter.push(p_search);
      array_filter.push(p_minprice);
      array_filter.push(p_maxprice);
      array_filter.push(p_rating);
      array_filter.push(p_sort);
      array_filter.push(p_page);
      array_filter.push(p_recomenddata);
      let url = "";

      for (let i = 0; i < array_filter.length; i++) {
        if (array_filter[i].substring(array_filter[i].indexOf("=") + 1) != "") {
          if (url == "") {

            url += "?" + array_filter[i];
          } else {
            url += "&" + array_filter[i];
          }
        }
      }

      this.$router.push("/kategori" + url + "&stocked=1");
      if (this.$route.fullPath == "/kategori" + url + "&stocked=1") {
        this.$bvModal.hide("modal-filter");
      }
    },
    getrecommend() {
      this.loading = true;
      axios.post(`/product/recommended`).then((response) => {
        this.dataRecommend = response.data?.data?.recommendations;
        this.loading = false;
      });
    },
    async getProduct() {
      this.namekategori = "Produk";
      this.loading = true;
      /** Fungsi ini untuk mengambil data */

      this.setDataFilter();
      let formdata = new FormData();
      let url = "";
      let method = "";
      if (this.recomenddata) {
        url = `product/recommended?${this.linkurl}`;
        method = "get";
      } else {
        url = "product/filter";
        method = "post";
      }
      if (this.subKategoriIdCadangan.length > 0) {
        this.subKategoriIdCadangan.forEach((element) => {
          formdata.append("subcategory[]", element);
        });
      }

      if (this.kategoriIdCadangan.length > 0) {
        this.kategoriIdCadangan.forEach((element) => {
          formdata.append("category[]", element);
        });
      }
      if (this.$route.query.promo === "true") {
        this.promo = true;
      }
      if (this.minPrice != "") {
        formdata.append("price_min", parseInt(this.minPrice));
      }
      if (this.maxPrice != "") {
        formdata.append("price_max", parseInt(this.maxPrice));
      }
      if (this.rating != "") {
        formdata.append("rating", parseInt(this.rating));
      }
      if (this.sort != "") {
        formdata.append("sort", this.sort);
      }
      if (this.search != "" && !this.recomenddata) {
        formdata.append("search", this.search);
      }
      if (this.page != "") {
        formdata.append("page", this.page);
      }
      if (this.promo == true && !this.recomenddata) {
        formdata.append("promo", this.promo);
      }
      formdata.append("stocked", true);
      formdata.append("limit", 12);
      this.loading = true;
      if (this.$router.path == "/kategori") {
        this.$router.push(this.$router.path + "&stocked=1");
      }
      try {
        this.loading = true;
        if (this.recomenddata) {
          const { data } = await axios({
            method: method,
            url: url,
          });
          this.dataRecommend = data?.data?.recommendations;
          this.loading = LogTransaksiVue;
          this.pageTotal = data.data.pageTotal;
          this.loading = false;
          this.showData();
        } else {
          const { data } = await axios({
            method: method,
            url: url,
            data: formdata,
          });
          this.allDataProducts = data.data.products;
          this.loading = LogTransaksiVue;
          this.pageTotal = data.data.pageTotal;
          this.loading = false;
          this.showData();
        }
      } catch (err) {
        return err;
      }
    },
  },
};
</script>
<style scoped>
/* ::v-deep .modal.fade .modal-dialog {
  transform: translate3d(0, 60vh, 0) !important;
} */
::v-deep .btn-modal-filter:hover > svg {
  fill: #fff;
}
::v-deep .modal-header button {
  background-color: transparent;
  border: 0px !important;
}
::v-deep #modal-loading .modal-content {
  background-color: transparent;
  border: 0px !important;
}
.pulse-container {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.pulse-bubble {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ff0f0f;
}

.pulse-bubble-1 {
  animation: pulse 0.4s ease 0s infinite alternate;
}
.pulse-bubble-2 {
  animation: pulse 0.4s ease 0.2s infinite alternate;
}
.pulse-bubble-3 {
  animation: pulse 0.4s ease 0.4s infinite alternate;
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0.25;
    transform: scale(0.75);
  }
}
.web-view {
  display: none;
}
.mobile-view {
  display: block;
}
.accordion-custom {
  /* border: 1px solid rgba(0, 0, 0, 0.5); */
  padding: 0rem;
  list-style-type: none;
}

.accordion-custom-btn {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-size: medium;
  border: 1px solid #fff;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.5); */
  z-index: 1;
}
.accordion-custom-btn b {
  padding-left: 5px;
}
.accordion-custom-btn:hover {
  cursor: pointer;
}
.accordion-custom-btn.active,
.accordion-custom-btn:hover {
  transition: 0.4s;
  margin-bottom: 0px;
  background-color: var(--secondary-color);
  color: var(--text-color-dark);
  border: 1px solid var(--secondary-color-dark);
}

.accordion-custom-btn .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: 0.4s;
  transform: rotate(-90deg);
}

.accordion-custom-btn .active .icon {
  transition: 0.4s;
  transform: rotate(0deg);
}

.accordion-custom-content {
  width: 100%;
  padding: 0 0.5rem;
  margin: 0;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  background-color: var(--secondary-color);
  height: 0;
}

.sidebar .accordion-custom-content {
  padding: 0 0.5rem;
}

.accordion-custom-btn.active ~ .accordion-custom-content {
  padding: 0.25rem 1.2rem;
  z-index: 0;
  opacity: 1;
  height: auto;
}

.sidebar .accordion-custom-btn .active ~ .accordion-custom-content {
  padding: 0.5rem;
}
.accordion-custom-content {
  list-style-type: none;
}
.accordion-custom-content span {
  padding-left: 5px;
}
.label-checkbox {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 0.725rem;
}

.item_produk_rekomendasi {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.data_produk {
  padding: 5px;
}
.card figure {
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.card-title {
  text-align: left;
}
.card .star {
  text-align: left;
}
.card .star svg {
  fill: gold;
  font-size: 10px;
  width: 20px;
  height: 20px;
}
.harga {
  font-size: 1.1rem !important;
  text-align: left;
  font-weight: 550;
  margin-bottom: 5px !important;
  color: rgba(0, 0, 0, 0.6);
}
.diskon {
  display: flex;
}
.diskon p {
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  background-color: red;
}
.diskon s {
  color: gray;
  font-size: 0.8rem;
  padding-left: 10px;
}
.d-mobile {
  display: none;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 420px) and (orientation: portrait) {
  ::v-deep .data_produk {
    width: 11.5rem !important;
    width: 11.5rem !important;
  }
}
@media (max-width: 500px) {
  ::v-deep #modal-filter > .modal-dialog {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  ::v-deep .data_produk {
    width: 8.3rem;
  }
  .col-lg-9 {
    padding: 0px !important;
  }
  .item_produk_rekomendasi {
    justify-content: center;
  }
  ::v-deep .produk_kategori-mobile {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  ::v-deep .w-card {
    width: 100% !important;
  }
  ::v-deep .d-web {
    display: none !important;
  }
  .d-mobile {
    display: block;
  }
  ::v-deep .kategori-produk {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
::v-deep .w-card {
  width: 200px;
}
.data-rating input {
  position: relative;
  top: 3px;
}
.data-rating label {
  margin-left: 5px;
  padding: 5px 10px;
}
.data-rating label svg {
  width: 18px;
  height: 18px;
  fill: rgb(255, 167, 39);
  margin: 0px 2.5px;
}
.rating-option svg {
  width: 18px;
  height: 18px;
  fill: rgb(255, 167, 39);
  margin: 0px 2.5px;
}
.data-rating .active {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}
.data-rating span {
  position: relative;
  top: 2px;
  margin-left: 0.2rem !important;
}
.list-group-kategori {
  list-style-type: none;
  padding-left: 1rem !important;
}
.list-group-kategori li {
  position: relative;
}
.list-group-kategori li .left-button {
  position: absolute;
  top: 10px;
}
.inputminmax .input-group-text {
  background-color: #fff;
  border-right: 0px !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.inputminmax .form-control {
  border-left: 0px !important;
}
.btndropdown {
  display: block;
}
.btn-dropdown {
  margin: 0px !important;
  padding: 0px !important;
}
::v-deep .btn-dropdown button {
  background-color: transparent !important;
  width: 100%;
  padding-left: 20px;
  text-align: left;
  border: 0px !important;
  font-weight: 400;
  color: black;
  font-size: 11pt;
  text-transform: capitalize;
}
::v-deep .btn-dropdown ul {
  position: relative !important;
  padding: 0px !important;
  transform: translate3d(0px, 0px, 0px) !important;
}
.btn-dropdown li {
  padding-top: 5px;
  padding-bottom: 5px;
}
.btn-dropdown a .nav-link {
  font-weight: 400;
  color: blue;
  font-size: 13pt;
  text-transform: capitalize;
}
.p-kategori {
  padding-left: 20px;
  line-height: 2;
}
.li-group-costum h5 {
  font-size: 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}
.li-group-costum {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.header-kategori {
  display: block;
}
.header-kategori h6 {
  line-height: 2.5;
  margin-bottom: 0px;
}
.mr-3 {
  margin-right: 0.8rem;
}
.header-nav {
  height: 5rem;
}
.profile-data {
  padding-right: 0px;
  padding-left: 0px;
  margin: 0px !important;
}
.profile-content {
  padding-right: 0px;
  padding-left: 0px;
}

.profile-content-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.list-group-custom {
  padding: 1rem;
  border-radius: 0.5rem;
  list-style-type: none;
  border-radius: 10px;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}

.list-group-custom-item {
  color: #000;
  fill: #000;
  padding: 0.825rem 0.5rem;
  width: 100%;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-decoration: none;
  cursor: pointer;
}

.list-group-custom-item.active,
.list-group-custom-item:hover {
  color: #1f7acf;
  fill: #1f7acf;
}

@media (max-width: 400px) {
  .profile-container,
  .profile-container .col-lg-3,
  .profile-container .col-lg-9 {
    padding: 0px;
  }
}
@media (min-width: 319px) {
  ::v-deep .data_produk {
    max-width: 9rem;
  }
}

@media (min-width: 374px) {
  ::v-deep .data_produk {
    max-width: 11.5rem;
  }
}

@media (min-width: 424px) {
  ::v-deep .data_produk {
    max-width: 8rem;
  }
}
@media (min-width: 576px) {
  .profile-content {
    width: 80%;
  }

  .profile-content-container {
    flex-direction: row;
    column-gap: 1rem;
  }
}
@media (min-width: 767px) {
  ::v-deep .data_produk {
    max-width: fit-content;
  }
  .header-kategori {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 1023px) {
  .header-nav {
    height: 9rem;
  }
  .web-view {
    display: block;
  }
  .mobile-view {
    display: none;
  }
  .profile-data {
    padding-right: (1, 5rem, 0.75rem);
    padding-left: (1, 5rem, 0.75rem);
    margin: 0px !important;
  }
  .profile-content {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
@media (min-width: 1100px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1439px) {
}
@media (min-width: 1500px) {
}
@media (min-width: 2000px) {
}
</style>
