<template>
  <div class="d-table w-100">
    <tr class="header-table tr-table view-web">
      <td class="tb-product p-3">Produk</td>
      <td class="tb-ukuran">Varian</td>
      <td class="tb-price">Harga</td>
      <td class="tb-quantity">Qty</td>
      <td class="tb-total">Total</td>
    </tr>
    <tr class="tr-table view-web" :class="dataproduct?.product?.stock <= 0 ? 'stokdataproduk' : ''">
      <td colspan="2" class="p-3 w-product">
        <div class="d-flex w-100">
          <input v-if="dataproduct?.product?.stock > 0" checked
            :disabled="valueinput > dataproduct.product.stock ? true : false" type="checkbox"
            :id="'check' + dataproduct.id" class="mx-1" v-model="checkdata" :value="dataproduct.id"
            @change="chcklistvalue(dataproduct, false)" />

          <figure class="image mx-1">
            <img :src="$imgProduct + dataimg(dataproduct?.product)
              " alt="" />
          </figure>
          <div class="mx-1 detail_product pt-2">
            <h6>{{ dataproduct.product.product.name }}</h6>
            <small>Stok:
              <span v-if="dataproduct.product.stock > 0">Sisa {{ dataproduct.product.stock }}</span>
              <span v-else-if="dataproduct.product.stock <= 0"><b class="text-danger">Habis</b></span>
            </small>
          </div>
        </div>
      </td>
      <td>
        <p v-if="dataproduct.product?.second_variant_option?.option">
          {{ dataproduct.product?.first_variant_option?.option }} ,
          {{ dataproduct.product?.second_variant_option?.option }}
        </p>
        <p v-else-if="!dataproduct.product?.second_variant_option?.option">
          {{ dataproduct.product?.first_variant_option?.option }}
        </p>
      </td>
      <td class="harga_data">
        <div>
          <h6 class="text-danger">Rp{{ checkharga(dataproduct?.product).harga }}</h6>
          <div class="diskon" v-if="checkharga(dataproduct?.product).diskon != null">
            <p>{{ parseInt(checkharga(dataproduct?.product).diskon) }}%</p>
            <small><s>Rp {{ checkharga(dataproduct?.product).harga_asli }}</s></small>
          </div>
        </div>
      </td>
      <td>
        <span>
          <b-button :id="'btnmin' + dataproduct.id" @click="updatenote(dataproduct, -1)" class="btn-primary-outline"
            :disabled="valueinput <= 1">-</b-button>
          <input class="w-50 input_cart input-primary-outline" type="text" min="0" :id="'inputFile' + dataproduct.id"
            v-model="valueinput" @change="updatenote(dataproduct, 0)"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
          <b-button @click="updatenote(dataproduct, +1)" :disabled="valueinput >= dataproduct?.product?.stock"
            :id="'btnplus' + dataproduct.id" class="btn-primary-outline">+</b-button>
        </span><br>
        <span v-if="valueinput > dataproduct.product.stock"><small>Stock tersedia {{ dataproduct.product.stock }} {{
          dataproduct?.product?.product?.unit?.name }}</small></span>
      </td>
      <td class="harga_data">

        <h6 class="text-danger">Rp {{ setrupiah(totalharga(dataproduct?.product)) }}</h6>
      </td>
    </tr>
    <tr class="tr-table view-web" :class="dataproduct?.product?.stock <= 0 ? 'stokdataproduk' : ''">
      <td colspan="6">
        <div class="w-100 d-flex product-bottom">
          <div v-if="dataproduct?.note == 'null'">
            <p><b>Catatan :</b></p>
          </div>
          <div v-else-if="dataproduct?.note">
            <span class="bottom-left d-flex" v-if="dataproduct?.note.length > 70">
              <p>
                <b>Catatan :</b> {{ dataproduct?.note.substring(0, 50) + '...' }}
                <span class="readmore" @click.prevent="modalnote(dataproduct.note)">Selengkapnya</span>
              </p>
            </span>
            <span class="bottom-left d-flex" v-else-if="dataproduct?.note.length < 70">
              <p><b>Catatan :</b> {{ dataproduct?.note }}</p>
            </span>
          </div>
          <div v-if="!dataproduct?.note">
            <p><b>Catatan :</b></p>
          </div>
          <p class="bottom-product">
            <b-button class="catatan" v-if="dataproduct?.note == 'null'" @click="modaldata(dataproduct, index)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ; msfilter: ">
                <path
                  d="M4 21a1 1 0 0 0 .24 0l4-1a1 1 0 0 0 .47-.26L21 7.41a2 2 0 0 0 0-2.82L19.42 3a2 2 0 0 0-2.83 0L4.3 15.29a1.06 1.06 0 0 0-.27.47l-1 4A1 1 0 0 0 3.76 21 1 1 0 0 0 4 21zM18 4.41 19.59 6 18 7.59 16.42 6zM5.91 16.51 15 7.41 16.59 9l-9.1 9.1-2.11.52z">
                </path>
              </svg>
              Tulis Catatan
            </b-button>
            <b-button class="catatan" v-else-if="dataproduct?.note" @click="modaldata(dataproduct, index)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ; msfilter: ">
                <path
                  d="M4 21a1 1 0 0 0 .24 0l4-1a1 1 0 0 0 .47-.26L21 7.41a2 2 0 0 0 0-2.82L19.42 3a2 2 0 0 0-2.83 0L4.3 15.29a1.06 1.06 0 0 0-.27.47l-1 4A1 1 0 0 0 3.76 21 1 1 0 0 0 4 21zM18 4.41 19.59 6 18 7.59 16.42 6zM5.91 16.51 15 7.41 16.59 9l-9.1 9.1-2.11.52z">
                </path>
              </svg>
              Ubah Catatan
            </b-button>
            <b-button class="catatan" v-else-if="!dataproduct?.note" @click="modaldata(dataproduct, index)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ; msfilter: ">
                <path
                  d="M4 21a1 1 0 0 0 .24 0l4-1a1 1 0 0 0 .47-.26L21 7.41a2 2 0 0 0 0-2.82L19.42 3a2 2 0 0 0-2.83 0L4.3 15.29a1.06 1.06 0 0 0-.27.47l-1 4A1 1 0 0 0 3.76 21 1 1 0 0 0 4 21zM18 4.41 19.59 6 18 7.59 16.42 6zM5.91 16.51 15 7.41 16.59 9l-9.1 9.1-2.11.52z">
                </path>
              </svg>
              Tulis Catatan
            </b-button>
            <span class="trash">
              |
              <svg @click="deletelist(dataproduct)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                style="fill:#000; transform: ; msfilter: ">
                <path
                  d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z">
                </path>
                <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
              </svg>
            </span>
          </p>
        </div>
      </td>
    </tr>
    <tr :class="dataproduct?.product?.stock <= 0 ? 'stokdataproduk' : ''" class="header-table tr-table view-mobile">
      <th class="tb-product p-3">Produk</th>
    </tr>
    <tr class="tr-table view-mobile" :class="dataproduct?.product?.stock <= 0 ? 'stokdataproduk' : ''">
      <td colspan="2" class="p-3 w-product">
        <div class="d-flex w-100">
          <input v-if="dataproduct?.product?.stock > 0" type="checkbox"
            :disabled="valueinput > dataproduct.product.stock ? true : false" :id="'check' + dataproduct.id" checked
            class="mx-1" v-model="checkdata" :value="dataproduct.id" @change="chcklistvalue(dataproduct, false)" />

          <figure class="image mx-1">
            <img :src="$imgProduct + dataimg(dataproduct?.product)
              " alt="" />
          </figure>
          <div class="mx-1 detail_product pt-2">
            <h5>{{ dataproduct.product.product.name }}</h5>
            <h6 class="text-danger">Rp{{ checkharga(dataproduct?.product).harga }}</h6>
            <div class="diskon" v-if="checkharga(dataproduct?.product).diskon != null">
              <p>{{ parseInt(checkharga(dataproduct?.product).diskon) }}%</p>
              <small><s>Rp {{ checkharga(dataproduct?.product).harga_asli }}</s></small>
            </div>
          </div>
        </div>
      </td>
    </tr>
    <tr class="tr-table">
      <td colspan="2">
        <div class="px-3"><small><b>Variant: </b><span v-if="dataproduct.product?.second_variant_option?.option">{{
          dataproduct.product?.first_variant_option?.option }} ,
              {{ dataproduct.product?.second_variant_option?.option }}</span><span
              v-else-if="!dataproduct.product?.second_variant_option?.option">{{
                dataproduct.product?.first_variant_option?.option }}</span></small></div>
      </td>
    </tr>
    <tr class="tr-table view-mobile" :class="dataproduct?.product?.stock <= 0 ? 'stokdataproduk' : ''">
      <td colspan="2" class="pb-3">
        <div class="w-100 d-flex product-bottom">
          <div v-if="dataproduct?.note == 'null'" class="bottom-product catatan-bottom">
            <b-button class="catatan" @click="modaldata(dataproduct, index)">
              Tulis Catatan
            </b-button>
          </div>
          <div v-else-if="dataproduct?.note">
            <span class="bottom-left d-flex" v-if="dataproduct?.note.length > 20">
              <p class="bottom-product catatan-bottom">
                {{ dataproduct?.note.substring(0, 20) + '...' }}
                <b><span class="readmore" @click.prevent="modalnote(dataproduct.note)">Selengkapnya</span></b>
                <b-button class="ubahcatatan" @click="modaldata(dataproduct, index)">
                  ubah
                </b-button>
              </p>
            </span>
            <span class="bottom-left d-flex bottom-product catatan-bottom" v-else-if="dataproduct?.note.length < 20">
              <p>
                {{ dataproduct?.note }}
                <b-button class="ubahcatatan px-1 py-1" @click="modaldata(dataproduct)">
                  ubah
                </b-button>
              </p>
            </span>
          </div>
          <div v-if="!dataproduct?.note" class="bottom-product catatan-bottom">
            <b-button class="catatan" @click="modaldata(dataproduct)">
              Tulis Catatan
            </b-button>
          </div>
          <div class="bottom-product d-flex">
            <div>
              <span class="d-flex justify-content-end">
                <b-button :id="'btnmin' + dataproduct.id" @click="updatenote(dataproduct, -1)"
                  class="btnmobile btn-primary-outline" :disabled="valueinput <= 1">-</b-button>
                <input class="w-50 input_cart input-primary-outline" type="number" min="0"
                  :max="dataproduct?.product?.stock" :id="'inputFile' + dataproduct.id" v-model="valueinput"
                  @change="updatenote(dataproduct, 0)"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                <b-button @click="updatenote(dataproduct, +1)" :disabled="valueinput >= dataproduct?.product?.stock"
                  :id="'btnplus' + dataproduct.id" class="btnmobile btn-primary-outline">+</b-button>
              </span>
              <span><small class="text-verysmall" v-if="valueinput > dataproduct.product.stock">Stock tersedia {{
                dataproduct.product.stock }} {{ dataproduct?.product?.product?.unit?.name }}</small></span>
            </div>
            <span class="trash d-flex">
              <span class="px-1">|</span>
              <svg @click="deletelist(dataproduct)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                style="fill:#000; transform: ; msfilter: ">
                <path
                  d="M5 20a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8h2V6h-4V4a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H3v2h2zM9 4h6v2H9zM8 8h9v12H7V8z">
                </path>
                <path d="M9 10h2v8H9zm4 0h2v8h-2z"></path>
              </svg>
            </span>
          </div>
        </div>
      </td>
    </tr>

  </div>
</template>
<script>
import swal from "sweetalert";
import '../../assets/css/sweetalertstyle.css'
import axios from "axios";
export default {
  props: ["dataproduct", "dataindex", "selectdata", "listcart", "datanew"],
  data() {
    return {
      loading: true,
      submit: false,
      errorsubmit: false,
      checkdata: false,
      dataIndex: "",
      catatan: '',
      modal_note: '',
      idmodal: '',
      datamodal: [],
      check: true,
      selected: [],
      productChecked: false,
      dataCartsSelected: [],
      cart_id: [],
      totalBarang: 0,
      totalPrice: 0,
      checkflashsale: 0,
      urlvideo: "",
      catatanproduk: [],
      mute: false,
      statusvideo: true,
      dataid: '',
      valueinput: 0,
      flashsaledata: []
    };
  },
  mounted() {
    this.valueinput = this.dataproduct?.qty
    if (this.dataproduct.id == this.$route.query.cart_select) {
      this.checkdata = true
    }
  },
  watch: {
    datanew() {
      if (this.datanew) {
        this.checkdata = this.datanew
      } else {
        this.checkdata = false
      }
    },
    dataproduct() {
      this.valueinput = this.dataproduct?.qty
    },
    selectdata() {
      if (this.valueinput <= this.dataproduct.product.stock) {
        this.checkdata = this.selectdata
        this.chcklistvalue(this.dataproduct, false)
      }
      if (!this.selectdata) {
        this.checkdata = false
        this.chcklistvalue(this.dataproduct, false)
      }

    },
    valueinput() {
      if (this.checkdata) {
        this.chcklistvalue(this.dataproduct, false)
      }
    }
  },
  methods: {
    deletelist(data) {
      swal("Apakah yakin untuk menghapus produk ini?", '', "info", {
        buttons: ["Batal", "Hapus"]
      }).then((res) => {
        if (res) {
          this.deteledata(data)

        }
      });
    },
    deteledata(data) {
      // console.log(this.checkdata);
      try {
        this.submit = true
        if (this.checkdata) {
          this.chcklistvalue(data, true)
        }
        this.$emit('deletedata', data.id)
        axios.delete('/transaction/cart/delete-cart/' + data.id)
          .then(() => {
            this.submit = false
            swal("Berhasil!!!", "Produk telah dihapus", "success", {
              button: "OK",
            });
            this.$store.dispatch("carts");
          }).catch((err) => {
            this.submit = false
            if (err.response.data.message == 'error') {
              swal("Ooops", "Produk yang anda pilih saat ini sudah tak tersedia", "error", {
                button: "OK",
              });
            } else {
              swal("Ooops", err.response.data.message, "error", {
                button: "OK",
              });
            }
          })
      } catch (err) {
        return
      }

    },
    updatenote(value, sum) {
      let datavalue = this.valueinput + sum
      if (datavalue <= value?.product?.stock && this.selectdata) {

        this.checkdata = true
        this.chcklistvalue(this.dataproduct, false)
      }
      if (this.valueinput <= 0 || this.valueinput == null || this.valueinput == undefined) {
        swal("Apakah yakin untuk menghapus produk ini?", '', "info", {
          buttons: ["Batal", "Hapus"]
        }).then((res) => {
          if (res) {
            this.deteledata(value)

          } else {
            this.valueinput = 1
            this.updateqty(value)
          }
        });
      }
      else if (this.valueinput > value.product.stock) {

        this.valueinput = parseInt(this.valueinput) + parseInt(sum)
        this.valueinput = value.product.stock
        if (this.selectdata) {

          this.checkdata = true
          this.chcklistvalue(this.dataproduct, false)
        }
        this.updateqty(value)
      } else if (this.valueinput <= value.product.stock) {

        this.valueinput = parseInt(this.valueinput) + parseInt(sum)
        this.updateqty(value)
      }


    },
    async updateqty(value) {
      let data = new FormData();
      data.append("note", '');
      data.append("qty", this.valueinput);

      data.append("_method", "PUT");
      try {
        await axios.post(
          "/transaction/cart/update-note/" + value.id,
          data
        ).catch((err) => {
          if (!this.errorsubmit) {
            this.errorsubmit = true
            swal("Ooops", err.response.data.message, "error", {
              button: "OK",
            }).then(() => {
              this.errorsubmit = false
            })
          }


        })
        this.$bvModal.hide("modal-catatan");
      } catch {
        return
      }
    },
    checkharga(data) {
      if (parseInt(this.dataflashsale(data)?.flashsale_price) > 0 && this.valueinput <= this.dataflashsale(data).ammount_left) {
        let harga_flash_sale = {
          harga: this.setrupiah(this.dataflashsale(data).flashsale_price),
          diskon: this.dataflashsale(data).discount,
          harga_asli: this.setrupiah(data.price)
        }
        return harga_flash_sale
      } else if (this.datapromo(data).length > 0) {

        let harga_promo = {
          harga: this.setrupiah(this.datapromo(data)[0].price_promo),
          diskon: this.datapromo(data)[0].discount,
          harga_asli: this.setrupiah(data.price)
        }
        return harga_promo
      } else {
        let price = {
          harga: this.setrupiah(data.price)
        }
        return price

      }
    },
    setrupiah(data) {
      return new Intl.NumberFormat('id-ID').format(Number(data).toFixed())
    },
    totalharga(data) {
      if (parseInt(this.dataflashsale(data).flashsale_price) > 0 && this.valueinput <= this.dataflashsale(data).ammount_left) {
        return parseInt(this.dataflashsale(data).flashsale_price) * this.valueinput
      } else if (this.datapromo(data).length > 0) {
        return this.datapromo(data)[0].price_promo * this.valueinput
      } else {
        return data.price * this.valueinput

      }
    },
    chcklistvalue(data, deletedata) {
      let datacount = {}
      if (deletedata) {
        datacount = {
          id: data.id,
          qty: this.valueinput,
          harga: this.totalharga(data.product),
          status: false
        }
      } else {
        datacount = {
          id: data.id,
          qty: this.valueinput,
          harga: this.totalharga(data.product),
          status: this.checkdata
        }
      }
      this.$emit('addtotal', datacount)

    },
    dataimg(value) {
      var valueimage = value.product.image
      let dataimg = valueimage.filter((el) => {
        if (el.product_price_id == value.id) {
          return el
        }
      })
      if (dataimg.length > 0) {
        return dataimg[0]?.image
      } else {
        var img_default = value?.product?.image.filter((item) => {
          if (item.default_image) {
            return item
          }
        })
        return img_default[0]?.image

      }
    },
    modaldata: function (data,) {
      let datavalue = {
        note: data.note,
        index: this.dataindex
      }
      this.$emit('modalcatatan', datavalue)
      console.log("Index : ", this.dataindex);

    },
    dataflashsale(data) {
      var dataflashsale = data?.flashsale?.filter((element) => {
        let checkflashsale = element?.flashsale != null
        if (checkflashsale) {
          return element?.flashsale_price
        }
      })
      if (data?.flashsale.length > 0) {

        if (dataflashsale.length > 0) {
          if (!this.flashsaledata.includes(dataflashsale[0]?.id)) {
            this.flashsaledata.push(dataflashsale[0].id)
            if (data?.second_variant_option != null) {
              axios
                .get(
                  `transaction/check-flashsale?product_name=` +
                  data.product.name +
                  `&flashsale=` +
                  dataflashsale[0].flashsale.name +
                  `&flashsale_date=` +
                  dataflashsale[0].flashsale.date +
                  `&variant_1=` +
                  data?.first_variant_option.option +
                  `&variant_2=` +
                  data?.second_variant_option.option
                )
                .then((res) => {
                  this.checkflashsale = res?.data?.data?.ammount_left;
                })
                .catch(() => {
                });
            } else {
              axios
                .get(
                  `transaction/check-flashsale?product_name=` +
                  data.product.name +
                  `&flashsale=` +
                  dataflashsale[0].flashsale.name +
                  `&flashsale_date=` +
                  dataflashsale[0].flashsale.date +
                  `&variant_1=` +
                  data?.first_variant_option.option
                )
                .then((res) => {
                  this.checkflashsale = res?.data?.data?.ammount_left;
                })
                .catch(() => {
                });
            }
          }
          var flashsale = {
            flashsale_price: dataflashsale[0]?.flashsale_price,
            discount: dataflashsale[0]?.discount,
            ammount_left: this.checkflashsale
          }
          return flashsale
        }
        else {
          var flashda = {
            flashsale_price: 0,
          }
          return flashda
        }
      } else {
        var flashsaledata = {
          flashsale_price: 0,
        }
        return flashsaledata
      }


    },
    datapromo(data) {
      var datapromo = data?.promo?.filter((element) => {
        let checkpromo = element?.promo != null
        if (checkpromo) {
          return element?.price_promo
        }
      })
      return datapromo

    },
    modalnote(value) {
      this.$emit('modalnote', value)
    },
  }
}
</script>

<style scoped>
::v-deep #modal-loading .modal-content {
  background-color: transparent;
  border: 0px !important;
}

::v-deep .modal-header button {
  background-color: #fff !important;
  border: 0px !important;
}

.btnmobile {
  height: 25px !important;
  padding: 2px 10px;
  line-height: 1 !important;
  font-size: 0.5rem !important;
  margin-top: 6px;
  border-radius: 5px !important;
}

.view-mobile {
  display: revert-layer;
}

.view-web {
  display: none;
}

.view-mobile .catatan {
  font-size: 0.65rem !important;
  padding: 0px 3px;
  line-height: 4;
}

.view-mobile .ubahcatatan {
  font-size: 0.65rem !important;
  padding: 0px 3px;
  line-height: 1;
}

.readmore {
  cursor: pointer;
}

.harga_data h6 {
  font-weight: 600;
}

.diskon {
  display: flex;
}

.diskon p {
  color: #fff;
  font-size: 8px;
  padding: 3px;
  border-radius: 3px;
  background-color: red;
}

.diskon s {
  color: gray;
  font-size: 0.8rem;
  padding-left: 5px;
  text-decoration: line-through !important;
}

.img-not-found {
  display: block;
  width: 35%;
  margin: 0 auto;
}

.float-right {
  float: right !important;
}

.header-cart {
  height: 5rem;
}

.headcart {
  text-align: left;
  font-weight: 600;
  color: #000;
}

.product-cart {
  overflow-x: auto;
}

.table-product {
  position: relative;
  width: 100%;
}

.tr-table {
  position: relative;
}

.header-table::before {
  content: "";
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  height: 3.5rem;
  position: absolute;
}

.stokdataproduk td::after,
.stokdataproduk td div::after,
.stokdataproduk th::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(255, 255, 255, 0.1);
}

.image {
  max-width: 50px;
  max-height: 50px;
  overflow: hidden;
  border-radius: 5px;
}

.image img {
  width: 100%;
}

.detail_product h6 {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

.link_product {
  text-decoration: none;
  color: #000;
}

.detail_product small {
  text-decoration: none;
  color: #000;
}

.input_cart {
  max-width: 120px;
  text-align: center;
  height: 35px !important;
  margin: 5px 5px 0px 5px !important;
  border-radius: 3px !important;
}

.header-product {
  display: inline-block;
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
}

.detail_product h6 {
  font-weight: 600;
}

.product-bottom {
  justify-content: space-between;
}

.catatan-bottom {
  max-width: 200px;
  text-align: left !important;
}

.bottom-product {
  text-align: right;
}

.bottom-product svg {
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
}

.bottom-product .catatan {
  background-color: #fff;
  border: 0px !important;
  fill: #1f7acf;
  color: #1f7acf;
}

.bottom-product .ubahcatatan {
  background-color: #fff;
  border: 0px !important;
  fill: #1f7acf;
  color: #1f7acf;
}

.bottom-product .catatan:hover {
  background-color: #fff;
  border: 0px !important;
  fill: #1f7acf;
  color: #1f7acf;
}

.bottom-product .ubahcatatan:hover {
  background-color: #fff;
  border: 0px !important;
  fill: #1f7acf;
  color: #1f7acf;
}

.bottom-product .trash {
  z-index: 30;
  position: relative;
  color: rgba(0, 0, 0, 0.4);
  fill: rgba(0, 0, 0, 0.4);
}

.bottom-product .trash svg {
  cursor: pointer;
  position: relative;
  top: 7px;
}

.bottom-product input {
  max-height: 1.8rem;
}

.ringkasan_belanja h5 {
  font-weight: 600;
}

.total {
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

.total h6 {
  font-weight: 600;
  text-align: right;
}

@media (max-width: 500px) {
  .text-verysmall {
    font-size: 0.65rem !important;
  }

  .headcart {
    font-size: 1rem;
  }

  p,
  h6 {
    font-size: 0.9rem;
  }

  .header-table th {
    font-size: 15px !important;
  }

  .header-table::before {
    height: 3.25rem !important;
  }

  .w-product {
    width: 300px;
  }

  .tb-product {
    min-width: 250px;
    max-width: 300px;
  }

  .w-product {
    width: 250px !important;
  }

  .tb-ukuran {
    min-width: 100px;
    max-width: 150px;
  }

  .tb-price {
    min-width: 100px;
    max-width: 150px;
  }

  .tb-quantity {
    min-width: 100px;
    max-width: 150px;
  }

  .tb-total {
    min-width: 100px;
    max-width: 150px;
  }

  .detail_product h5 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .detail_product h6 {
    font-size: 0.9rem;
    margin-bottom: 0px;
  }

  .bottom-product svg {
    width: 1.3rem;
    height: 1.3rem;
    vertical-align: text-bottom;
  }

  .trash span {
    line-height: 1.4;
    font-size: 1.5rem;
  }

  .detail_product small {
    font-size: 0.8rem;
  }

  .ringkasan_belanja h5 {
    font-size: 1rem;
  }

  .total h6 {
    font-size: 0.85rem;
  }
}

@media (min-width: 319px) {
  .catatan-bottom {
    max-width: 215px;
  }
}

@media (min-width: 374px) {
  .catatan-bottom {
    max-width: 300px;
  }

  .view-mobile .catatan {
    font-size: 0.8rem !important;
    padding: 5px 10px;
    line-height: 2;
  }

  .view-mobile .ubahcatatan {
    font-size: 0.8rem !important;
    padding: 5px 10px;
  }
}

@media (min-width: 424px) {}

@media (min-width: 767px) {
  .view-mobile {
    display: none;
  }

  .view-web {
    display: revert-layer;
  }

  .header-product {
    display: block;
  }

  .tb-product {
    min-width: 150px;
    max-width: 200px;
  }

  .w-product {
    width: 250px !important;
  }

  .tb-ukuran {
    min-width: 70px;
    max-width: 100px;
  }

  .tb-price {
    min-width: 70px;
    max-width: 100px;
  }

  .tb-quantity {
    min-width: 70px;
    max-width: 100px;
  }

  .tb-total {
    min-width: 70px;
    max-width: 100px;
  }

  .header-cart {
    height: 9rem;
  }

  .bottom-product .trash svg {
    top: 0px;
  }
}

@media (min-width: 1023px) {}

@media (min-width: 1439px) {}

@media (min-width: 2000px) {}
</style>
