<template>
  <div>
    <Loader v-if="loading"></Loader>
    <SkeletonLog v-if="loading"></SkeletonLog>
    <div v-if="!loading" class="container-fluid py-3">
      <div class="row">
        <div class="col-md-12">
          <ul class="timeline">
            <li
              class="timeline-item"
              v-for="(timeline, index) in datatimeline"
              :key="index"
            >
              <div v-if="toLower(timeline?.section_change) == 'make order'">
                <p
                  class="fw-bold"
                  v-if="toLower(timeline.name_section_change) == 'make order'"
                >
                  <b>Pembuatan Order</b>
                </p>
                <p class="text-muted mb-2 fw-bold">
                  {{ dateTime(timeline.created_at) }} WITA
                </p>
                <h6>Total Pembayaran</h6>
                <p>{{ timeline.new_value }}</p>
              </div>
              <div v-else-if="toLower(timeline?.section_change) == 'price'">
                <p
                  class="fw-bold"
                  v-if="toLower(timeline.name_section_change) == 'change price'"
                >
                  <b>Permintaan Nego tahap {{ timeline.section_nego }}</b>
                </p>
                <p class="text-muted mb-2 fw-bold">
                  {{ dateTime(timeline.created_at) }} WITA
                </p>
                <div class="text-muted w-100" style="overflow-x: scroll">
                  <table class="table table-striped table-hover table-timeline">
                    <thead>
                      <tr>
                        <td>Nama Produk</td>
                        <td>Harga</td>
                        <td>Nego Harga</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ timeline.transaction_detail.name_product }}</td>
                        <td>{{ timeline.old_value }}</td>
                        <td>{{ timeline.new_value }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                v-else-if="toLower(timeline?.section_change) == 'shipping cost'"
              >
                <p
                  class="fw-bold"
                  v-if="
                    toLower(timeline.name_section_change) ==
                    'change shipping cost'
                  "
                >
                  <b
                    >Permintaan Nego Ongkir tahap {{ timeline.section_nego }}</b
                  >
                </p>
                <p class="text-muted mb-2 fw-bold">
                  {{ dateTime(timeline.created_at) }} WITA
                </p>
                <h6>Nego Ongkir</h6>
                <div class="text-muted w-100" style="overflow-x: scroll">
                  <table class="table table-striped table-hover table-timeline">
                    <thead>
                      <tr>
                        <td>Harga</td>
                        <td>Nego Harga</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ timeline.old_value }}</td>
                        <td>{{ timeline.new_value }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else-if="toLower(timeline?.section_change) == 'status'">
                <p class="fw-bold" v-if="toLower(timeline.name_section_change) == 'status' || toLower(timeline.name_section_change) == 'update status payment'"><b>Perubahan Status</b></p>
                <p class="text-muted mb-2 fw-bold"> {{dateTime(timeline.created_at)}} WITA</p>
                <h6><b>Status</b></h6>
                <p> <span
            class="badge text-light bg-secondary"
            v-if="toLowerCase(timeline.old_value) == 'ready_to_pickup'"
            >Siap Diambil</span
          >
          <span
            class="badge text-light bg-secondary"
            v-else-if="toLowerCase(timeline.old_value) == 'waiting_pickup'"
            >Waiting Pickup</span
          >
          <span
            class="badge text-light bg-warning"
            v-else-if="toLowerCase(timeline.old_value) == 'pending_payment'"
            >Pending Payment</span
          >
          <span
            class="badge text-light bg-success"
            v-else-if="toLowerCase(timeline.old_value) == 'process'"
            >Sedang di Proses</span
          >
          <span
            class="badge text-light bg-secondary"
            v-else-if="toLowerCase(timeline.old_value) == 'shipping'"
            >Dikirim</span
          >
          <span
            class="badge text-light bg-warning"
            v-else-if="toLowerCase(timeline.old_value) == 'pending'"
            >Menunggu di Proses</span
          >
          <span
            class="badge text-light bg-warning"
            v-else-if="toLowerCase(timeline.old_value) == 'waiting_payment'"
            >Menunggu Pembayaran</span
          >
          <span
            class="badge text-light bg-danger"
            v-else-if="toLowerCase(timeline.old_value) == 'payment_expired'"
            >Dibatalkan</span
          >
          <span
            class="badge text-light bg-danger"
            v-else-if="toLowerCase(timeline.old_value) == 'payment_failed'"
            >Pembayaran Gagal</span
          >
          <span
            class="badge text-light bg-danger"
            v-else-if="toLowerCase(timeline.old_value) == 'cancel'"
            >Dibatalkan</span
          >
          <span
            class="badge text-light bg-success"
            v-else-if="toLowerCase(timeline.old_value) == 'done_refund'"
            >Dana Dikembalikan
          </span>
          <span
            class="badge text-light bg-success"
            v-else-if="toLowerCase(timeline.old_value) == 'received'"
            >Diterima
          </span>
          <span
            class="badge text-light bg-success"
            v-else-if="toLowerCase(timeline.old_value) == 'paid'"
            >Dibayarkan
          </span>
          <span
            v-else
            class="badge text-light"
            :class="{
              'bg-warning':
                toLowerCase(timeline.old_value) == 'pending' ||
                toLowerCase(timeline.old_value) == 'pending_payment',
              'bg-success':
                toLowerCase(timeline.old_value) == 'paid' ||
                toLowerCase(timeline.old_value) == 'received' ||
                toLowerCase(timeline.old_value) == 'confirmed',
              'bg-kuning': toLowerCase(timeline.old_value) == 'document',
              'bg-primary':
                toLowerCase(timeline.old_value) == 'process' ||
                toLowerCase(timeline.old_value) == 'payment_process',
              'bg-secondary':
                toLowerCase(timeline.old_value) == 'shipped' ||
                toLowerCase(timeline.old_value) == 'shipping' ||
                toLowerCase(timeline.old_value) == 'ready_to_pickup' ||
                toLowerCase(timeline.old_value) == 'waiting_pickup',
              'bg-danger':
                toLowerCase(timeline.old_value) == 'cancel' ||
                toLowerCase(timeline.old_value) == 'payment_failed' ||
                toLowerCase(timeline.old_value) == 'payment_expired',
            }"
            >{{ timeline.old_value }}</span
          > <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path></svg> <span
            class="badge text-light bg-secondary"
            v-if="toLowerCase(timeline.new_value) == 'ready_to_pickup'"
            >Siap Diambil</span
          >
          <span
            class="badge text-light bg-secondary"
            v-else-if="toLowerCase(timeline.new_value) == 'waiting_pickup'"
            >Waiting Pickup</span
          >
          <span
            class="badge text-light bg-warning"
            v-else-if="toLowerCase(timeline.new_value) == 'pending_payment'"
            >Pending Payment</span
          >
          <span
            class="badge text-light bg-success"
            v-else-if="toLowerCase(timeline.new_value) == 'process'"
            >Sedang di Proses</span
          >
          <span
            class="badge text-light bg-secondary"
            v-else-if="toLowerCase(timeline.new_value) == 'shipping'"
            >Dikirim</span
          >
          <span
            class="badge text-light bg-warning"
            v-else-if="toLowerCase(timeline.new_value) == 'pending'"
            >Menunggu di Proses</span
          >
          <span
            class="badge text-light bg-warning"
            v-else-if="toLowerCase(timeline.new_value) == 'waiting_payment'"
            >Menunggu Pembayaran</span
          >
          <span
            class="badge text-light bg-danger"
            v-else-if="toLowerCase(timeline.new_value) == 'payment_expired'"
            >Dibatalkan</span
          >
          <span
            class="badge text-light bg-danger"
            v-else-if="toLowerCase(timeline.new_value) == 'payment_failed'"
            >Pembayaran Gagal</span
          >
          <span
            class="badge text-light bg-danger"
            v-else-if="toLowerCase(timeline.new_value) == 'cancel'"
            >Dibatalkan</span
          >
          <span
            class="badge text-light bg-success"
            v-else-if="toLowerCase(timeline.new_value) == 'done_refund'"
            >Dana Dikembalikan
          </span>
          <span
            class="badge text-light bg-success"
            v-else-if="toLowerCase(timeline.new_value) == 'received'"
            >Diterima
          </span>
          <span
            class="badge text-light bg-success"
            v-else-if="toLowerCase(timeline.new_value) == 'paid'"
            >Dibayarkan
          </span>
          <span
            v-else
            class="badge text-light"
            :class="{
              'bg-warning':
                toLowerCase(timeline.new_value) == 'pending' ||
                toLowerCase(timeline.new_value) == 'pending_payment',
              'bg-success':
                toLowerCase(timeline.new_value) == 'paid' ||
                toLowerCase(timeline.new_value) == 'received' ||
                toLowerCase(timeline.new_value) == 'confirmed',
              'bg-kuning': toLowerCase(timeline.new_value) == 'document',
              'bg-primary':
                toLowerCase(timeline.new_value) == 'process' ||
                toLowerCase(timeline.new_value) == 'payment_process',
              'bg-secondary':
                toLowerCase(timeline.new_value) == 'shipped' ||
                toLowerCase(timeline.new_value) == 'shipping' ||
                toLowerCase(timeline.new_value) == 'ready_to_pickup' ||
                toLowerCase(timeline.new_value) == 'waiting_pickup',
              'bg-danger':
                toLowerCase(timeline.new_value) == 'cancel' ||
                toLowerCase(timeline.new_value) == 'payment_failed' ||
                toLowerCase(timeline.new_value) == 'payment_expired',
            }"
            >{{ timeline.new_value }}</span
          > </p>

              </div>
              <div
                v-else-if="
                  toLower(timeline?.section_change) == 'request payment'
                "
              >
                <p
                  class="fw-bold"
                  v-if="
                    toLower(timeline.name_section_change) == 'request payment'
                  "
                >
                  <b>Permintaan Pembayaran</b>
                </p>
                <p class="text-muted mb-2 fw-bold">
                  {{ dateTime(timeline.created_at) }} WITA
                </p>
                <h6>Total Pembayaran</h6>
                <p>{{ timeline.new_value }}</p>
              </div>
              <div v-else-if="toLower(timeline?.section_change) == 'payment'">
                <p
                  class="fw-bold"
                  v-if="
                    toLower(timeline.name_section_change) ==
                    'update status payment'
                  "
                >
                  <b>Pembayaran</b>
                </p>
                <p class="text-muted mb-2 fw-bold">
                  {{ dateTime(timeline.created_at) }} WITA
                </p>
                <h6>Total Pembayaran</h6>
                <p>{{ timeline.new_value }}</p>
              </div>
              <div
                v-else-if="
                  toLower(timeline?.section_change) == 'upload payment'
                "
              >
                <p
                  class="fw-bold"
                  v-if="
                    toLower(timeline.name_section_change) ==
                    'upload proof of payment'
                  "
                >
                  <b>Mengirim Bukti Pembayaran</b>
                </p>
                <p class="text-muted mb-2 fw-bold">
                  {{ dateTime(timeline.created_at) }} WITA
                </p>
                <h6>Total Pembayaran</h6>
                <p>{{ timeline.new_value }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SkeletonLog from '../../components/Skeleton/Transaksi/LogTransaksi.vue'
import Loader from '@/components/Loader/Loader.vue'
// import axios from "axios";
import moment from "moment";
export default {
  props:["datatimeline"],
  setup() {},
  data() {
    return {
      // loading: true,
    };
  },
  components: {
    SkeletonLog,
    Loader
  },
  methods: {
    dateTime(value) {
      return moment(value).format("DD MMMM YYYY hh:mm:ss");
    },
    toLower(value) {
      return value.toLowerCase();
    },
    toLowerCase(data) {
      return data.toLowerCase();
    },
  },
};
</script>
<style scoped>
.timeline {
  position: relative;
  list-style: none;
  max-height: 400px;
  overflow-y: scroll;
}
.timeline .timeline-item {
  position: relative;
  padding: 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.4);
}
.timeline .timeline-item:after {
  position: absolute;
  display: block;
  top: 22px;
}
.timeline .timeline-item:after {
  background-color: hsl(0, 0%, 90%);
  left: -6px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
}
.timeline-item h5 {
  font-size: 0.9rem;
}
.timeline-item h6,
.timeline-item p {
  font-size: 0.8rem;
}
.table-timeline th,
.table-timeline td {
  font-size: 0.8rem;
  min-width: 140px;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .timeline-item h5 {
    font-size: 1.25rem;
  }
  .timeline-item h6,
  .timeline-item p {
    font-size: 1rem;
  }
  .table-timeline th,
  .table-timeline td {
    font-size: 1rem;
    min-width: 150px;
  }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>
