<template>
  <div>
    <!-- <pre>{{chatData}}</pre> -->
    <div class="card-dashboard p-3 p-md-4">
      <div class="chat-header d-flex justify-content-between p-3">
        <div class="d-flex align-items-center">
          <div
            class="small-user-picture bg-primary text-white d-flex justify-content-center align-items-center"
          >
            A
          </div>
          <p class="mx-3 my-0">Admin</p>
        </div>
      </div>
      <div class="message-container position-relative" id="messageContainer">
        <!-- <button class="btn-primary position-absolute btn-scroll" @click="scrollBottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="fill: #fff;transform: ;msFilter:;"><path d="m12 15.586-4.293-4.293-1.414 1.414L12 18.414l5.707-5.707-1.414-1.414z"></path><path d="m17.707 7.707-1.414-1.414L12 10.586 7.707 6.293 6.293 7.707 12 13.414z"></path></svg>
                </button> -->
        <div v-for="(data, index) in dataPesan" :key="data.id">
          <div
            class="d-flex my-2 w-100 justify-content-start position-relative"
            v-if="data.is_user"
          >
            <div class="message-item message-item-left left-top">
              <p class="m-0 data-massage">{{ data.message }}</p>
              <p class="font-smaller m-0 text-right text-secondary">
                {{ dateTime(data.created_at) }}
              </p>
            </div>
          </div>
          <div
            class="d-flex my-2 w-100 justify-content-end position-relative"
            v-if="data.is_pending"
          >
            <div class="message-item message-item-right right-in">
              <p class="m-0 data-massage">{{ data.message }}</p>
              <p class="font-smaller m-0 text-right text-secondary">
                <span class="loader"></span>
              </p>
            </div>
          </div>
          <div
            class="d-flex my-2 w-100 justify-content-end position-relative errormessage"
            v-if="data.is_pending && errormessage"
          >
            <div
              class="message-item message-item-right position-relative errormessage right-in"
            >
              <div class="hover-button">
                <b-dropdown
                  id="dropdown-1"
                  variant="link"
                  no-caret
                  class="buton-dropdown"
                >
                  <template #button-content>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
                    >
                      <path
                        d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"
                      ></path>
                    </svg>
                  </template>
                  <b-dropdown-item @click="sendback(data.message, index)"
                    >Kirim Ulang</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <p class="m-0 data-massage">{{ data.message }}</p>
              <p class="font-smaller m-0 text-right text-secondary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#e60a0a"
                    d="M12 6.25a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V7a.75.75 0 0 1 .75-.75ZM12 17a1 1 0 1 0 0-2a1 1 0 0 0 0 2Z"
                  />
                  <path
                    fill="#e60a0a"
                    fill-rule="evenodd"
                    d="M1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12S17.937 22.75 12 22.75S1.25 17.937 1.25 12ZM12 2.75a9.25 9.25 0 1 0 0 18.5a9.25 9.25 0 0 0 0-18.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </p>
            </div>
          </div>
          <div
            class="d-flex my-2 w-100 justify-content-end position-relative"
            v-else-if="!data.is_user && data.customer_id == user.id"
          >
            <div class="message-item message-item-right right-in">
              <p class="m-0 data-massage">{{ data.message }}</p>
              <p class="font-smaller m-0 text-right text-secondary">
                {{ dateTime(data.created_at) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <form @submit.prevent="kirimPesan">
        <textarea
          placeholder="Tulis pesan di sini .."
          :class="{ 'border-danger': v$.message.$error }"
          class="input w-100 mt-3 p-2 border-radius"
          v-model="message"
        ></textarea>
        <div class="text-danger" v-if="v$.message.$error">
          <small v-if="v$.message.required">Pesan Wajib Diisi</small>
        </div>
        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-primary" id="btn-kirim">
            <i class="bx bxs-message-dots"></i> Kirim pesan
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
// import {mapState} from 'vuex'

export default {
  name: "form-profil",
  data() {
    return {
      message: "",
      errormessage: false,
      jumlahPesan: 0,
      jumlahData: 0,
      dataPesan: [],
      chatdelay: [],
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      message: {
        required,
      },
    };
  },
  async created() {
    try {
      if (this.user instanceof Promise == true) {
        await this.$store.dispatch("user");
        this.$store.dispatch("address").then(() => {
          this.address = this.$store.state.address[0].customer_address?.address;
        });
      }
      this.dataset();
    } catch (error) {
      return error;
    }
  },
  components: {},
  async mounted() {
    // mengirim bahwa chat sudah terbaca ke BE, supaya jumlah pesan yang dikirim dari BE berubah
    await axios.post('/usermanagement/chat/mark-as-read').then(()=>{
          // merequest pengecekan jumlah pesan di store, supaya jumlah pesan terupdate, ini karena jumlah pesan juga tampil pada navbar 
          this.$store.dispatch("jmlhpesan")
        })
    this.$store
      .dispatch("semuapesan")
      .then(() => {
        this.dataPesan = this.$store.state.semuapesan;
        // this.updateChat();
        this.$store.dispatch("resetchat", {
          datachat: 0,
        });
      })
      .catch(() => {});
    // }
  },
  computed: {
    user: function () {
      return this.$store.state.user;
    },
  },
  methods: {
    updateChat: async function () {
      // mengambil data pesan terbaru
      await this.$store.dispatch("getpesan");
      // pengecekan apakah ada pesan baru atau tidak, jika ya maka data dari state.semuapesan akan dikirim ke data pesan supaya dapat ditampilkan
      if (this.$store.state.chatcheck) {
        this.dataPesan = [...this.$store.state.semuapesan];
        // ini untuk mengecek jika terdapat data id yang sama pada data pesan, maka yang ditamppilkan hanya satu data
        this.dataPesan = this.dataPesan.filter((item, index, self) =>
      index === self.findIndex((i) => i.id === item.id)
    );
      }
      return;
    },
    scrollBottom() {
      const element = document.getElementById("messageContainer");
      element.scrollTop = element.scrollHeight;
    },
    dateTime(value) {
      return moment(value).format("MMM DD, YYYY [|] HH:mm a ");
    },
    tanggal(value) {
      return moment(value).format("DD MMM YYYY : HH:mm:ss ");
    },
    dataset() {
      let me = this;
      // setInterval(this.updateChat, 5000)

      // let urlParams = new URLSearchParams(window.location.search);

      // let i = 0
      TimerHandler();
      // ini dilakukan untuk emlakukan pengecekan pesan ika terdapat pesan baru, supaya dapat direquest dan di ganti dari hasil get api 
      function TimerHandler() {
        setTimeout(function () {
          if (window.location.pathname === "/profil/chat") {
            me.updateChat().then(() => {
              TimerHandler();
            });
          }
        }, 3000);
      }
    },
    kirimPesan() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.$store.dispatch("delaychat",true);
        document.getElementById("btn-kirim").disabled = true;
        let datamessage = new FormData();
        datamessage.append("message", this.message);
      
        let indexchat = this.dataPesan.length;
        this.dataPesan.push({
          message: this.message,
          is_pending: true,
        });
        
            this.message = "";
            this.v$.$reset();
            document.getElementById("btn-kirim").disabled = false;

        axios
          .post("/usermanagement/chat/reply", datamessage)
          .then((res) => {
            // var dataid = this.dataPesan.some(item => item.id === res.data.data.id)
            // if(!dataid){
        this.$store.dispatch("delaychat",false);
              this.dataPesan[indexchat] = res.data.data;
              this.dataPesan = this.dataPesan.filter((item, index, self) =>
      index === self.findIndex((i) => i.id === item.id)
    );
            // }
          })
          .catch(() => {
            this.v$.$reset();
            this.errormessage = true;
          });
      }
    },
    sendback(data, index) {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        // document.getElementById("btn-kirim").disabled = true;
        let datamessage = new FormData();
        datamessage.append("message", data);
        this.dataPesan.slice(0, index);
        let indexchat = this.dataPesan.length;
        this.dataPesan.push({
          message: data,
          is_pending: true,
        });

        axios
          .post("/usermanagement/chat/reply", datamessage)
          .then((res) => {
            this.dataPesan[indexchat] = res.data.data;
            this.v$.$reset();
            this.message = "";
          })
          .catch(() => {
            this.v$.$reset();
            this.errormessage = true;
          });
      }
    },
  },

  watch: {
    dataPesan: function () {
      this.$nextTick(() => {
        this.scrollBottom();
      });
    },
  },
};
</script>
<style scoped>
.loader {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.hover-button {
  display: none;
  position: absolute;
  top: -5px;
  right: 0px;
  cursor: pointer;
}

.errormessage:hover .hover-button {
  display: block;
}
.border-radius {
  border-radius: 10px;
}
.btn-scroll {
  border-radius: 50%;
  bottom: 10px;
  left: auto;
  right: 10px;
  border: 0px !important;
}
.btn-scroll svg {
  width: 16px;
  height: 25px;
}
.font-smaller {
  font-size: 0.6rem;
  float: right;
}
.left-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: -8px;
  bottom: auto;
}
.left-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 22px solid;
  border-color: #e3e3f7 transparent transparent transparent;
}
.right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: 30px;
  bottom: auto;
}
.right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -15px;
  top: 1px;
  bottom: auto;
  border: 12px solid;
  border-color: #e3e3f7 transparent transparent #e3e3f7;
}
.card-dashboard {
  border-radius: 10px;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}
.small-user-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: blue;
}
.data-massage {
  word-wrap: break-word;
}
.message-container {
  padding: 0px;
  border-radius: 10px !important;
}
.message-item {
  max-width: 100%;
}

.message-container {
  border: 1px solid #000;
  padding: 1rem;
  height: 350px;
  overflow-y: scroll;
}

.message-item {
  display: inline-block;
  padding: 0.5rem 1rem;
  width: auto;
  max-width: 340px;
}

.message-item-left {
  background-color: #e3e3f7;
  border: 1px solid var(--secondary-color-dark);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  min-width: 210px;
}

.message-item-right {
  min-width: 210px;
  background-color: #e3e3f7;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
}

.detail-info-stock {
  display: block;
}

#btnToTop {
  position: fixed;
  bottom: 8rem;
  right: 2rem;
  left: auto;
  top: auto;
  z-index: 8888;
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .btn-scroll svg {
    width: 24px;
    height: 30px;
  }
  .message-container {
    padding: 1rem;
  }
  .message-item {
    max-width: 340px;
  }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>
