<template>
    <div>
        <div class="p-2 p-md-3 maps">
          <l-map
            style="height: 300px"
            @click="handleMapClick"
            :zoom="zoom"
            :center="center"
          >
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker
              v-for="marker in markers"
              :key="marker.id"
              :icon="icon"
              :lat-lng="marker.latLng"
            >
            </l-marker>
          </l-map>
        </div>
    </div>
</template>
<script>
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import { icon } from "leaflet";
import pinimage from "../../assets/img/mappoint.png";
export default {
    props: ["mapdata"],
    data() {
        return{
            
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 13,
      position: { lat: -8.627301, lng: 115.223389 },
      center: [-8.627301, 115.223389],
      markerLatLng: [-8.627301, 115.223389],
      icon: icon({
        iconUrl: pinimage,
        iconSize: [22, 37],
        iconAnchor: [11, 37],
      }),
      markers:[]
        }
    },
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  mounted(){
  },
  methods:{
    
    handleMapClick(event) {
      const latLng = event.latlng;
      this.markers.push({
        latLng,
      });
      // Remove the previous markers
      if (this.markers.length > 1) {
        this.markers.shift();
      }
        this.$emit("setmap", this.markers);
    },
  }
}
</script>
<style scoped>
.maps{
  z-index: 20 !important;
  position: relative;
}
</style>
