<template>
  <div class="form-profile-body p-md-3">
    <div class="data-user">
      <div class="product-data mt-3">
        <table class="table-product web-view">
          <tr class="header-table">
            <th class="p-3">Produk</th>
            <th>Harga</th>
            <th>Qty</th>
            <th>Subtotal</th>
          </tr>
          <tr>
            <td colspan="2" class="produk_data p-3">
              <div class="d-flex">
                <figure class="image mx-1">
                  <img :src="$imgProduct + dataimg(datatransaksi)" alt="" />
                </figure>
                <div class="mx-1 detail_product">
                  <h6>{{ datatransaksi?.name_product }}</h6>
                  <p v-if="datatransaksi?.variant_2">
                    {{ datatransaksi?.variant_1 }} , {{ datatransaksi?.variant_2 }}
                  </p>
                  <p v-else-if="!datatransaksi?.variant_2">
                    {{ datatransaksi?.variant_1 }}
                  </p>
                </div>
              </div>
            </td>
            <td>
              <div v-if="parseInt(datatransaksi.discount) > 0">
                        <p  class="m-0 text-danger">
                        Rp {{
                          new Intl.NumberFormat("id-ID").format(
                            Number(datatransaksi?.price_after_discount).toFixed()
                          )
                        }}
                        </p>
                        <div class="diskon">
                          <p>
                            {{ parseInt(datatransaksi?.discount) }}%
                          </p>
                          <small
                            ><s
                              >Rp
                              {{
                                new Intl.NumberFormat("id-ID").format(
                                  Number(parseInt(datatransaksi?.price)).toFixed()
                                )
                              }}</s
                            ></small
                          >
                        </div>
                      </div>
                      <p v-else class=" text-product text-danger">
                        
              Rp
              {{
                new Intl.NumberFormat("id-ID").format(
                  Number(datatransaksi?.price).toFixed()
                )
              }}
            </p>
            </td>
            <td>
              <p>{{ datatransaksi?.qty_available }}</p>
            </td>
            <td>
              <p class="text-danger">
                Rp
                {{
                  new Intl.NumberFormat("id-ID").format(
                    Number(datatransaksi?.subtotal).toFixed()
                  )
                }}
              </p>
            </td>
          </tr>
        </table>
        <table class="table-product mobile-view">
          <tr class="header-table">
            <th class="p-3">Product</th>
          </tr>
          <tr>
            <td colspan="2" class="produk_data p-3">
              <div class="d-flex">
                <figure class="image mx-1">
                  <img :src="$imgProduct + dataimg(datatransaksi)" alt="" />
                </figure>
                <div class="mx-1 detail_product mobile_product_detail">
                  <h6>{{ datatransaksi?.name_product }}</h6>
                  <p>
                    {{ datatransaksi?.qty_available }} <small>x</small> Rp
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(datatransaksi?.price).toFixed()
                      )
                    }}
                  </p>
                  <h6>
                    Rp
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(datatransaksi?.subtotal).toFixed()
                      )
                    }}
                  </h6>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <h5 class="mt-3 header-content">Komplain</h5>
      <p class="px-3 mt-3">{{ complaindata?.complaint?.description_complaint }}</p>
      <div class="w-100 list-data-bukti mt-2 px-3">
        <div
          class="card position-relative p-1 p-lg-2 ml-2"
          v-for="(dataimg, index) in complaindata?.complaint?.image"
          :key="index"
        >
          <div
            class="card-index"
            @click="imagemodal(complaindata?.complaint?.image, index)"
          ></div>
          <div
            v-if="
              cekextensi(dataimg.image) == 'jpg' ||
              cekextensi(dataimg.image) == 'png' ||
              cekextensi(dataimg.image) == 'jpeg' ||
              cekextensi(dataimg.image) == 'webp'
            "
            class="figure-img"
          >
            <img :src="$imgProduct + dataimg.image" alt="" style="width: 100%" />
          </div>
          <div
            v-else-if="
              cekextensi(dataimg.image) == 'mp4' ||
              cekextensi(dataimg.image) == 'mpeg' ||
              cekextensi(dataimg.image) == 'mov' ||
              cekextensi(dataimg.image) == '3gp' ||
              cekextensi(dataimg.image) == 'mkv' ||
              cekextensi(dataimg.image) == 'avi'
            "
          >
            <video width="100" height="75" controls>
              <source
                :src="$imgProduct + dataimg.image"
                :type="'video/' + cekextensi(dataimg.image)"
              />
            </video>
          </div>
        </div>
      </div>
      <div class="tanggapan_admin p-3 mt-3 mx-3">
        <p><b>Tanggapan Admin :</b></p>
        <p class="px-3" v-if="complaindata?.complaint.respon_admin">
          {{ complaindata?.complaint.respon_admin }}
        </p>
        <p class="px-3" v-else-if="!complaindata?.complaint.respon_admin">-</p>
      </div>
    </div>
    <!-- <div class="w-100 mt-4 pt-4 pb-4">
                <router-link to="/profil/komplain" class="btn btn-secondary float-right mb-5">Kembali</router-link>
            </div> -->

    <b-modal id="modal-image" hide-footer size="md">
      <div class="p-md-3" v-if="Object.keys(firstimagemodal).length > 0">
        <div
          class="modalfirstimage"
          v-if="
            cekextensi(firstimagemodal[0]?.image) == 'jpg' ||
            cekextensi(firstimagemodal[0]?.image) == 'png' ||
            cekextensi(firstimagemodal[0]?.image) == 'jpeg' ||
            cekextensi(firstimagemodal[0]?.image) == 'webp'
          "
        >
          <figure class="h-100">
            <img
              class="h-100"
              id="gallerymodal"
              :src="$imgProduct + firstimagemodal[0]?.image"
              alt=""
            />
          </figure>
        </div>
        <div
          class="modalfirstimage"
          v-else-if="
            cekextensi(firstimagemodal[0]?.image) == 'mp4' ||
            cekextensi(firstimagemodal[0]?.image) == 'mpeg' ||
            cekextensi(firstimagemodal[0]?.image) == 'mov' ||
            cekextensi(firstimagemodal[0]?.image) == '3gp' ||
            cekextensi(firstimagemodal[0]?.image) == 'mkv' ||
            cekextensi(firstimagemodal[0]?.image) == 'avi'
          "
        >
          <video class="video-modal" controls>
            <source
              :src="$imgProduct + firstimagemodal[0]?.image"
              :type="'video/' + cekextensi(firstimagemodal[0]?.image)"
            />
          </video>
        </div>
        <div class="w-100 d-flex bottom-image-modal">
          <div class="w-25" v-for="(dataimage, index) in modalimage" :key="index">
            <figure
              class="cursor-pointer"
              v-if="
                cekextensi(dataimage?.image) == 'jpg' ||
                cekextensi(dataimage?.image) == 'png' ||
                cekextensi(dataimage?.image) == 'jpeg' ||
                cekextensi(dataimage?.image) == 'webp'
              "
            >
              <img
                v-if="dataimage != undefined"
                :src="$imgProduct + dataimage?.image"
                alt="Nature"
                style="width: 100%; height: auto"
                @click="changeImagemodal(index)"
              />
            </figure>
            <figure
              class="position-relative"
              v-else-if="
                cekextensi(dataimage?.image) == 'mp4' ||
                cekextensi(dataimage?.image) == 'mpeg' ||
                cekextensi(dataimage?.image) == 'mov' ||
                cekextensi(dataimage?.image) == '3gp' ||
                cekextensi(dataimage?.image) == 'mkv' ||
                cekextensi(dataimage?.image) == 'avi'
              "
            >
              <div class="card-index" @click="changeImagemodal(index)"></div>
              <video style="width: 100%; max-height: 150px; height: auto" controls>
                <source
                  :src="$imgProduct + dataimage?.image"
                  :type="'video/' + cekextensi(dataimage?.image)"
                />
              </video>
            </figure>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: ["complaindata", "datatransaksi"],
  data() {
    return {
      image: "",
      name_product: "",
      complaint: {},
      firstimagemodal: [],
      modalimage: [],
    };
  },
  mounted() {},
  methods: {
    
    dataimg(value){
      if(value.product_price.image != null){
        return value.product_price.image.image
      }else{
        return value.image
      }
       
    },
    imagemodal(value, index) {
      this.firstimagemodal = [];
      this.modalimage = [];
      this.$bvModal.show("modal-image");
      for (var i = 0; i < value.length; i++) {
        if (i == index) {
          this.firstimagemodal.push(value[i]);
        } else {
          this.modalimage.push(value[i]);
        }
      }
    },
    changeImagemodal(data) {
      let dataimg1 = this.firstimagemodal[0];
      let dataimg2 = this.modalimage[data];
      this.firstimagemodal.splice(0, 1, dataimg2);
      this.modalimage.splice(data, 1, dataimg1);
    },
    cekextensi(value) {
      return value.split(".").pop();
    },
    addInput() {
      this.inputs.push(this.inputs.length + 1);
    },
  },
};
</script>

<style scoped>
::v-deep .modal-header button {
  background-color: #fff;
  border: 0px !important;
}
.diskon {
  display: flex;
}
.diskon p {
  color: #fff;
  font-size: 8px !important;
  padding: 3px;
  border-radius: 3px;
  background-color: red;
}
.diskon s {
  color: gray;
  font-size: 0.8rem !important;
  padding-left: 5px;
  text-decoration: line-through !important;
}
.card-index {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0px;
  left: 0px;
  cursor: pointer;
}
.video-modal {
  height: auto;
  width: auto;
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 45vh;
}
.modalfirstimage {
  height: auto;
  max-height: 45vh;
  max-width: 100%;
  width: auto;
  display: block;
  margin: 0 auto;
}
.modalfirstimage figure img,
.modalfirstimage figure {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 45vh;
  margin: 0 auto;
}
.card-relative {
  position: relative;
}
.card-index {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0px;
  left: 0px;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.bottom-image-modal {
  overflow-x: auto;
}
.bottom-image-modal figure {
  max-height: 100px;
  overflow: hidden;
}
.product-data {
  width: 100%;
  overflow-x: auto;
}
.border-subtotal {
  border-top: 1px solid grey;
}
.mobile-view {
  display: table;
}
.web-view {
  display: none;
}
.mobile_product_detail p {
  margin-bottom: 0.5rem;
}
.mobile_product_detail h6 {
  margin-bottom: 0px !important;
}
.header-content {
  font-weight: bold;
}
.list-data-bukti {
  display: flex;
  overflow-x: auto;
}
.list-data-bukti i {
  text-align: center;
  font-size: 5rem;
}
.list-data-bukti p {
  margin-bottom: 0px !important;
}
.list-data-bukti .card {
  margin-left: 0.5rem !important;
}
.data-user h5 {
  margin-bottom: 5px !important;
  color: #000;
  font-weight: 600;
}
.img-profile {
  width: 100px;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.img-profile img {
  width: 100%;
}
.td-nama-produk {
  max-width: 250px !important;
}


.product-cart {
  overflow-x: auto;
}

.table-product {
  position: relative;
  width: 100%;
}
.table-product tr {
  background-color: transparent !important;
}
.table-product tr th {
  min-width: 150px;
}
.produk_data {
  min-width: 200px !important;
}
.header-table::before {
  content: "";
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  z-index: -10;
  height: 3.5rem;
  position: absolute;
}
.image {
  max-width: 50px;
  max-height: 50px;
  overflow: hidden;
  border-radius: 5px;
}
.image img {
  width: 100%;
}
.detail_product h6 {
  font-weight: 600;
}
.tanggapan_admin {
  background-color: #f1f1f1;
}
.figure-img {
  width: 100px;
}
@media (max-width: 500px) {
  .figure-img {
    width: 70px;
  }
}
@media (min-width: 740px) {
  .mobile-view {
    display: none;
  }
  .web-view {
    display: table;
  }
}
</style>
