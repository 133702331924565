<template>
    <div class="container mt-5">
        <!-- <div v-if="bannerdata?.length > 1"> -->
          <router-link to="" @click="bannerOne" class="col-12 bannerdata">
            <figure class="w-100">
              <img :src="$imgUrl+banner1.image" class="w-100" alt="">
            </figure>
          </router-link>
        <!-- </div> -->
    </div>
</template>
<script>
  // import axios from "axios";
  // import VueSlickCarousel from 'vue-slick-carousel'
//   import SkeletonBanner from '../Skeleton/Homepage/SkeletonBanner.vue'
export default {
  data(){
    return{
      banner1:{},
      loading:true
    }
  },
  async mounted(){
      this.loading =true
    try {
      this.loading =true
      // if (this.banners== null) {
      //   await this.$store.dispatch("banners")

      // }
      this.setbanner()
    } catch (error) {
      this.loading = false
      return error
    }
  },
  components:{
  },
  computed:{
    banners: function () {
      return this.$store.state.banners;
    },
  },
  watch:{
    banners(){
      this.loading =true
      this.setbanner()
    }
  },
  methods:{
    setbanner(){
      this.loading =true
       var bannerdata =Object.entries(this.banners).map((data)=>{
        return data[1]
       })
      this.loading =false
       this.bannerdata = bannerdata
       this.bannerdata.forEach((databanner)=>{
        if(databanner.position == 'Banner 1'){
          this.banner1 = databanner
        }
       })
    },
    bannerOne(){
      window.open((this.banners[0].url.substring(0,4) !== 'http' ? 'https://' : '')+this.banners[0].url, '_blank');
    },
  }
}
</script>
<style scoped>
.bannerdata figure{
    height: 60px;
    overflow: hidden;
    border-radius: 10px;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .bannerdata figure{
    height: 150px;
    overflow: hidden;
    border-radius: 15px;
}
  
}
@media (min-width: 1023px) {

}
@media (min-width: 1439px) {

}
</style>