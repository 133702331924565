<template>
  <div>
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <NavbarUno/>
    <SlideHome/>
    <KategoriProduk/>
    <FlashHome/>
  <BannerSatu/>
    <!-- <TerlarisHome/> -->
    <!-- <BannerHome class="banner-high" /> -->
    <!-- <PromoHome/> -->
    <RecomendHome/>
  <BannerDua/>
    <ProductHome/>
    <BeritaHome/>
    <FooterUno/>
    <!-- <Ohoke/> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import NavbarUno from '@/components/NavbarUno.vue'
import FooterUno from '@/components/FooterUno.vue'
import SlideHome from '@/components/HomePage/SliderHome.vue'
import KategoriProduk from '@/components/HomePage/KategoriProduk.vue'
import FlashHome from '@/components/HomePage/FlashSale.vue'
import BannerSatu from '@/components/HomePage/BannerSatu.vue'
import BannerDua from '@/components/HomePage/BannerDua.vue'
// import Terlaris from '@/components/HomePage/ProdukTerlaris.vue'

// import TerlarisHome from '@/components/HomePage/TerlarisProduk.vue'
// import BannerHome from '@/components/HomePage/BannerHome.vue'
// import PromoHome from '@/components/HomePage/ProdukPromo.vue'

import RecomendHome from '@/components/HomePage/ProdukRekomendasiary.vue'
import ProductHome from '@/components/HomePage/listProduct.vue'
import BeritaHome from '@/components/HomePage/BeritaShamppo.vue'

// import Ohoke from '@/components/CobaNah.vue'

export default {
  components: {
    // HelloWorld,
    NavbarUno,
    SlideHome,
    KategoriProduk,
    FlashHome,
    BannerSatu,
    RecomendHome,
    BannerDua,
    // TerlarisHome,
    // BannerHome,
    // PromoHome,
    ProductHome,
    BeritaHome,
    FooterUno,
    // Ohoke
  },
  mounted(){
    // untuk meminta request data user dari store
    this.$store.dispatch('user')
  },
  computed: {
        // loading:function(){
        //     return this.$store.state.user instanceof Promise
        // },
        // memanggil data user yang disimpan didalam store dan supaya otomatis dapat diambil maka dipanggil dari computed 
        user: function() {
            return this.$store.state.user;
        },
    },
}
</script>
<style scoped>
.banner-high{
    height: 75vh;
  }
@media (min-width: 319px) {
}

@media (min-width: 374px) {
  .banner-high{
    height: 80vh;
  }
}

@media (min-width: 424px) {
  .banner-high{
    height: 90vh;
  }
}

@media (min-width: 767px) {
  .banner-high{
    height: 40vh;
  }
}

@media (min-width: 1023px) {
  .banner-high{
    height: 38vh;
  }
}

@media (min-width: 1439px) {
  .banner-high{
    height: 60vh;
  }
}

@media (min-width: 2000px) {
}
</style>
