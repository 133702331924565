<template>
  <div>
    <SkeletonFooter v-if="loading" />
    <div class="container footer mt-5 pt-5" v-if="!loading">
      <div class="header-footer"></div>
      <div class="row pt-3 mb-3">
        <div class="col-md-3 mt-m">
          <h4 class="mb-3 mt-md-3 mt-lg-5">Info Pelanggan</h4>
          <router-link to="/">Beranda</router-link><br />
          <router-link :to="'/berita-detail?slug_berita=' + news[0]?.slug">Berita</router-link><br />
          <router-link to="/tentang-kami">Tentang Kami</router-link>
        </div>
        <!-- <div class="col-md-3">
                <h4 class="mb-3">Help</h4>
                <p>Exchanges & Returns</p>
                <p>Payment Information</p>
                <p>Track Your Order</p>
                <p>FAQs</p>
            </div>
            <div class="col-md-3">
                <h4 class="mb-3">Business</h4>
                <p>About Us</p>
                <p>Store</p>
                <p>Career</p>
                <p>News</p>
            </div> -->
        <div class="col-md-3">
          <h4 class="mb-3 mt-md-3 mt-lg-5">Sosial Media</h4>
          <div class="sosmed mb-2">
            <a :href="(setting?.instagram?.substring(0, 4) === 'http' ? '' : 'https://') +
              setting?.instagram
              " v-if="setting?.instagram !== '' || setting?.instagram !== null" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ; msfilter: ">
                <path
                  d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z">
                </path>
                <circle cx="16.806" cy="7.207" r="1.078"></circle>
                <path
                  d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z">
                </path>
              </svg>
            </a>
            <a :href="(setting?.tiktok?.substring(0, 4) === 'http' ? '' : 'https://') +
              setting?.tiktok
              " v-if="setting?.tiktok !== '' || setting?.tiktok !== null" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ; msfilter: ">
                <path
                  d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z">
                </path>
              </svg>
            </a>
            <a :href="(setting?.facebook?.substring(0, 4) === 'http' ? '' : 'https://') +
              setting?.facebook
              " v-if="setting?.facebook !== '' || setting?.facebook !== null" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ; msfilter: ">
                <path
                  d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z">
                </path>
              </svg>
            </a>
            <!-- <a :href="(setting?.youtube?.substring(0,4) === 'http' ? '' : 'https://')+setting?.youtube" v-if="setting?.youtube !== '' || setting?.youtube !== null" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765 1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6 5.207 3.005-5.212 2.995z"></path></svg>
                    </a> -->
          </div>
          <!-- <h4 class="mb-3">Sign up for our Newsletter</h4>
                <div class="email">
                    <div class="px-1">
                        <b-form-input
                        id="input-1"
                        type="email"
                        placeholder="Enter email"
                        ></b-form-input>
                    </div>
                        <b-button type="submit" variant="dark">Submit</b-button>
                </div> -->
        </div>
        <div class="col-md-2">
          <a href="https://play.google.com/store/apps/details?id=com.yandit301.ArysMikro&pcampaignid=web_share"
            target="_blank">
            <figure class="image-download pt-md-4 pt-xl-5">
              <img src="../assets/img/installandroid.svg" alt="" />
            </figure>
          </a>
        </div>
        <div class="col-md-4">
          <figure class="image-footer">
            <img src="../assets/img/bankdata.webp" alt="" />
          </figure>
        </div>
      </div>
      <div class="footer-bottom">
        <p>@Copyright Bithouse 2023</p>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonFooter from "../components/Skeleton/SkeletonFooter.vue";
export default {
  data() {
    return {
      loading: true,
      news: [],
    };
  },
  async mounted() {
    try {
      this.loading = true;
      if (this.berita instanceof Promise == true || this.berita == []) {
        await this.$store.dispatch("berita");
        this.news = this.berita;
      }
      if (this.setting instanceof Promise == true || this.setting == []) {
        await this.$store.dispatch("setting");
      }
      this.news = this.berita;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      return error;
    }
  },
  components: {
    SkeletonFooter,
  },
  computed: {
    berita: function () {
      return this.$store.state.berita;
    },
    setting: function () {
      return this.$store.state.setting;
    },
  },
};
</script>
<style scoped>
.footer {
  margin-bottom: 2rem;
}

.footer h4 {
  font-weight: 600;
  color: #000;
  text-align: left;
  font-size: 1.25rem;
}

.footer p {
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
}

.footer a {
  text-decoration: none;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
  line-height: 1;
}

.footer .header-footer {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0px -5px 50px 1px rgba(0, 0, 0, 0.3);
}

.sosmed {
  display: flex;
}

.sosmed a {
  background-color: #000;
  border-radius: 50%;
  padding: 7px;
  margin-left: 5px;
  margin-right: 5px;
}

.sosmed svg {
  fill: #fff;
  width: 24px;
  height: 24px;
}

.email {
  display: flex;
}

.footer-bottom {
  border-top: 1px solid #000;
  padding-top: 10px;
}

.footer-bottom p {
  text-align: center;
  width: 100%;
  display: block;
}

.image-download {
  width: 200px;
  max-width: 100%;
}

.image-download img {
  width: 100%;
}

.image-footer {
  max-height: 320px;
  width: 300px;
}

.image-footer img {
  width: 100%;
  height: 100%;
}

@media (min-width: 319px) {}

@media (min-width: 374px) {}

@media (min-width: 424px) {}

@media (min-width: 767px) {
  .image-download {
    margin: 0 auto;
  }

  .image-footer {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .footer {
    margin-bottom: 0rem;
  }

  .footer h4 {
    font-size: 1rem;
  }

  .footer p {
    font-size: 0.6rem;
  }

  .footer a {
    font-size: 0.8rem;
    line-height: 1.5;
  }
}

@media (min-width: 1023px) {
  .footer h4 {
    font-size: 1.5rem;
  }

  .footer p {
    font-size: 1rem;
  }

  .footer a {
    font-size: 1rem;
    line-height: 2;
  }

  .sosmed a {
    padding: 9px 10px;
  }

  .sosmed svg {
    width: 28px;
    height: 28px;
  }
}

@media (min-width: 1439px) {}

@media (min-width: 2000px) {}
</style>
