<template>
  <div class="home">
    <Navbar/>
    <BeritaShamppo/>
    <RecomendProduk/>
    <Footer/>
  </div>
</template>

<script>
import Navbar from '@/components/NavbarUno.vue'
import Footer from '@/components/FooterUno.vue'
import BeritaShamppo from '../components/Berita/DetailBerita.vue'
import RecomendProduk from '@/components/Produk/RekomendasiProduk.vue'

export default {
  components: {
    Navbar,
    Footer,
    BeritaShamppo,
    RecomendProduk,
  }
}
</script>
