<template>
  <div>
    <Loader v-if="loading" />
    <SkeletonTable v-if="loading" />
    <b-container fluid v-if="!loading">
      <!-- User Interface controls -->
      <div class="d-flex px-2 justify-content-between">
        <div></div>
        <div class="search_tabel">
          <b-input-group class="mt-3">
            <template #prepend>
              <b-input-group-text
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
                >
                  <path
                    d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"
                  ></path></svg
              ></b-input-group-text>
            </template>
            <b-form-input
              id="filter-input"
              v-model="filterTitle"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
      <div v-if="filteredData.length > 0">
        <div
          class="w-100 card p-2 mt-3"
          v-for="(datatransaksi, index) in filteredData"
          :key="index"
        >
          <div class="d-flex justify-content-between">
            <div class="d-flex px-2 justify-mobile">
              <small class="text-secondary mb-0 pt-1">{{ datatransaksi.invoice }}</small>
              <span
                class="badge bg-warning mx-1 pt-badge"
                v-if="toLowerCase(datatransaksi.status) == 'pending_payment'"
              >
                Pending Payment
              </span>
              <span
                class="badge bg-warning mx-1 pt-badge"
                v-else-if="toLowerCase(datatransaksi.status) == 'pending'"
              >
                Menunggu di Proses
              </span>
              <span
                class="badge bg-success mx-1 pt-badge"
                v-else-if="toLowerCase(datatransaksi.status) == 'received'"
              >
                Diterima
              </span>
              <span
                class="badge bg-success mx-1 pt-badge"
                v-else-if="toLowerCase(datatransaksi.status) == 'paid'"
              >
                Dibayarkan
              </span>
              <span
                class="badge bg-success mx-1 pt-badge"
                v-else-if="toLowerCase(datatransaksi.status) == 'process'"
              >
                Sedang di Proses
              </span>
              <span
                class="badge bg-danger mx-1 pt-badge"
                v-else-if="toLowerCase(datatransaksi.status) == 'payment_expired'"
              >
                Dibatalkan
              </span>
              <span
                class="badge bg-warning mx-1 pt-badge"
                v-else-if="toLowerCase(datatransaksi.status) == 'waiting_payment'"
              >
                Menunggu Pembayaran
              </span>
              <span
                class="badge bg-secondary text-white mx-1 pt-badge"
                v-else-if="toLowerCase(datatransaksi.status) == 'ready_to_pickup'"
              >
                Siap Diambil
              </span>
              <span
                class="badge bg-secondary text-white mx-1 pt-badge"
                v-else-if="toLowerCase(datatransaksi.status) == 'shipping'"
              >
                Dikirim
              </span>
              <span
                class="badge bg-secondary text-white mx-1 pt-badge"
                v-else-if="toLowerCase(datatransaksi.status) == 'waiting_pickup'"
              >
                Menunggu untuk diambil
              </span>
              <span
                class="badge bg-primary text-white mx-1 pt-badge"
                v-else-if="toLowerCase(datatransaksi.status) == 'payment_process'"
              >
                Proses Pembayaran
              </span>
              <span
                class="badge bg-danger text-white mx-1 pt-badge"
                v-else-if="toLowerCase(datatransaksi.status) == 'payment_failed'"
              >
                Pembayaran Gagal
              </span>
              <span
                class="badge bg-danger text-white mx-1 pt-badge"
                v-else-if="toLowerCase(datatransaksi.status) == 'cancel'"
              >
                Dibatalkan
              </span>
              <span
                class="badge bg-success text-white mx-1 pt-badge"
                v-else-if="toLowerCase(datatransaksi.status) == 'done_refund'"
              >
                Dana Dikembalikan
              </span>
              <span
                class="badge mx-1 pt-badge"
                v-else
                :class="{
                  'bg-warning': toLowerCase(datatransaksi.status) == 'pending',
                  'bg-success text-white':
                    toLowerCase(datatransaksi.status) == 'paid' ||
                    toLowerCase(datatransaksi.status) == 'confirmed' ||
                    toLowerCase(datatransaksi.status) == 'received',
                  'bg-primary text-white': toLowerCase(datatransaksi.status) == 'process',
                  'bg-kuning text-white': toLowerCase(datatransaksi.status) == 'document',
                  'bg-secondary text-white':
                    toLowerCase(datatransaksi.status) == 'ready_to_pickup',
                  'bg-secondary text-white':
                    toLowerCase(datatransaksi.status) == 'waiting_pickup',
                  'bg-secondary text-white':
                    toLowerCase(datatransaksi.status) == 'shipping',
                  'bg-danger text-white': toLowerCase(datatransaksi.status) == 'cancel',
                }"
                >{{ datatransaksi.status }}</span
              >
            </div>
            <div class="px-2 d-web">
              <small class="text-secondary mb-0">{{
                dateTime(datatransaksi.updated_at)
              }}</small>
            </div>
          </div>
          <div class="px-2 w-100">
            <hr class="w-100 my-2" />
          </div>
          <div :id="'produk'+datatransaksi?.id" class="content-produk-card" :class="datatransaksi?.transaction_detail.length > 2 ? 'hide-product pb-3' :''">
            <div :id="'gradient'+datatransaksi?.id" class="bg-gradient-card" v-if="datatransaksi?.transaction_detail.length > 2"></div>
            <div :id="'selengkapnya'+datatransaksi?.id" class="button-selengkapnya w-100" v-if="datatransaksi?.transaction_detail.length > 2">
              <p class="text-center w-100 color-primary" @click="selengkapnya(datatransaksi?.id)">Selengkapnya</p>
            </div>
            <div :id="'sembunyikan'+datatransaksi?.id" @click="sembunyikan(datatransaksi?.id)" class="button-selengkapnya d-none w-100" v-if="datatransaksi?.transaction_detail.length > 2">
              <p class="text-center w-100 color-primary">Sembunyikan</p>
            </div>
            <div
            class="d-flex h-card-product justify-content-between pt-md-1"
            v-for="(detailtrans, index) in datatransaksi?.transaction_detail"
            :key="datatransaksi.id + index"
          >
            <div class="d-flex">
              <figure class="image-produk">
                <img :src="$imgProduct + dataimg(detailtrans)" alt="" />
              </figure>
              <div class="px-1 px-md-2 pt-1 pt-md-2 grid-card">
                <div>
                  <h6 class="mb-0">
                    <b>{{ detailtrans.name_product }}</b>
                  </h6>
                  <p class="text-danger">
                    {{ detailtrans?.qty }} {{ detailtrans?.unit }} x Rp
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(detailtrans?.price).toFixed()
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="py-1 card-web">
              <div class="bl-1 px-3 grid-card">
                <small class="mb-0 text-secondary">Total harga</small>
                <h6 class="px-1 text-danger">
                  <b
                    >Rp
                    {{
                      new Intl.NumberFormat("id-ID").format(
                        Number(detailtrans?.subtotal).toFixed()
                      )
                    }}</b
                  >
                </h6>
              </div>
            </div>
          </div>
          </div>
          <div class="d-flex justify-content-between px-2">
            <div class="total-belanja pl-1">
              <small class="text-secondary">Total Belanja</small>
              <h6 class="text-danger">
                Rp
                {{
                  new Intl.NumberFormat("id-ID").format(
                    Number(datatransaksi?.grand_total).toFixed()
                  )
                }}
              </h6>
            </div>
            <div class="d-flex">
              <p
                class="text-secondary pt-3 d-web"
                v-if="datatransaksi?.delivery_type == 'home_delivery'"
              >
                <span>Dikirim oleh kurir </span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 36 36"
                >
                  <path
                    fill="currentColor"
                    d="M30 12h-4V7a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h1V8h20v11.7a6.45 6.45 0 0 1 1.56-.2H26V14h4a2 2 0 0 1 2 2v1h-4v2h4v5h-2.4a4.54 4.54 0 0 0-8.34 0h-6.83a4.5 4.5 0 0 0-4.17-2.76A4.38 4.38 0 1 0 14.72 26H21a4.49 4.49 0 0 0 8.92 0H33a1 1 0 0 0 1-1v-9a4 4 0 0 0-4-4ZM10.26 28a2.38 2.38 0 1 1 0-4.75a2.38 2.38 0 1 1 0 4.75Zm15.17 0a2.38 2.38 0 1 1 2.5-2.37a2.44 2.44 0 0 1-2.5 2.37Z"
                    class="clr-i-outline clr-i-outline-path-1"
                  />
                  <path fill="none" d="M0 0h36v36H0z" />
                </svg>
              </p>
              <p
                class="text-secondary pt-3 d-web"
                v-else-if="datatransaksi?.delivery_type == 'self_pickup'"
              >
                <span class="pt-2">Mengambil sendiri </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M864 158.704H672.815V97.328c0-52.944-43.056-96-96-96H449.183c-52.944 0-96 43.056-96 96v61.376H159.999c-35.344 0-64 28.656-64 64v735.968c0 35.344 28.656 64 64 64h704c35.344 0 64-28.656 64-64V222.704c0-35.344-28.656-64-64-64H864zM417.184 97.328c0-17.664 14.336-32 32-32h127.632c17.664 0 32 14.336 32 32v61.376H417.184V97.328zM864 958.672H160V222.704h193.184v65.84s-.848 31.967 31.809 31.967c36 0 32.192-31.967 32.192-31.967v-65.84h191.632v65.84s-2.128 32.128 31.872 32.128c32 0 32.128-32.128 32.128-32.128v-65.84h191.184v735.968z"
                  />
                </svg>
              </p>
              <router-link
                :to="{
                  path: '/profil/detailtransaksi',
                  query: { id_transaksi: datatransaksi.id },
                }"
                class="btn btn-primary mx-2 mt-2"
                style="height: max-content"
                >Lihat Detail</router-link
              >
            </div>
          </div>
          <hr class="w-100 border-dash d-mobile" />
          <div class="pt-1">
            <small
              class="text-secondary d-mobile"
              v-if="datatransaksi?.delivery_type == 'home_delivery'"
            >
              <span>Dikirim oleh kurir </span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 36 36"
              >
                <path
                  fill="currentColor"
                  d="M30 12h-4V7a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h1V8h20v11.7a6.45 6.45 0 0 1 1.56-.2H26V14h4a2 2 0 0 1 2 2v1h-4v2h4v5h-2.4a4.54 4.54 0 0 0-8.34 0h-6.83a4.5 4.5 0 0 0-4.17-2.76A4.38 4.38 0 1 0 14.72 26H21a4.49 4.49 0 0 0 8.92 0H33a1 1 0 0 0 1-1v-9a4 4 0 0 0-4-4ZM10.26 28a2.38 2.38 0 1 1 0-4.75a2.38 2.38 0 1 1 0 4.75Zm15.17 0a2.38 2.38 0 1 1 2.5-2.37a2.44 2.44 0 0 1-2.5 2.37Z"
                  class="clr-i-outline clr-i-outline-path-1"
                />
                <path fill="none" d="M0 0h36v36H0z" />
              </svg>
            </small>
            <small
              class="text-secondary d-mobile"
              v-else-if="datatransaksi?.delivery_type == 'self_pickup'"
            >
              <span class="pt-2">Mengambil sendiri </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 1024 1024"
              >
                <path
                  fill="currentColor"
                  d="M864 158.704H672.815V97.328c0-52.944-43.056-96-96-96H449.183c-52.944 0-96 43.056-96 96v61.376H159.999c-35.344 0-64 28.656-64 64v735.968c0 35.344 28.656 64 64 64h704c35.344 0 64-28.656 64-64V222.704c0-35.344-28.656-64-64-64H864zM417.184 97.328c0-17.664 14.336-32 32-32h127.632c17.664 0 32 14.336 32 32v61.376H417.184V97.328zM864 958.672H160V222.704h193.184v65.84s-.848 31.967 31.809 31.967c36 0 32.192-31.967 32.192-31.967v-65.84h191.632v65.84s-2.128 32.128 31.872 32.128c32 0 32.128-32.128 32.128-32.128v-65.84h191.184v735.968z"
                />
              </svg>
            </small>
          </div>
        </div>
        <div>
          <div class="display-flex w-100 mt-3 mb-3 mb-lg-5 button-pagination">
            <button
              class="btn btn-primary-outline p-1 px-2 rounded-0 btn-arrow"
              @click="btnpage(-1)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                style="transform: ; msfilter: "
              >
                <path
                  d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"
                ></path>
              </svg>
            </button>
            <button
              v-if="curentpage + 1 == initPagination && curentpage - 1 > 0"
              class="btn btn-primary-outline btn-current p-1 px-2 rounded-0"
              @click="btnpage(-2)"
            >
              {{ curentpage - 1 }}
            </button>
            <button
              v-if="curentpage > 0"
              class="btn btn-primary-outline btn-current p-1 px-2 rounded-0"
              @click="btnpage(-1)"
            >
              {{ curentpage }}
            </button>
            <button class="btn btn-primary p-1 px-3 btn-current rounded-0">
              {{ curentpage + 1 }}
            </button>
            <button
              v-if="initPagination > 1 && curentpage + 1 < initPagination"
              class="btn btn-primary-outline btn-current p-1 px-2 rounded-0"
              @click="btnpage(+1)"
            >
              {{ curentpage + 2 }}
            </button>
            <button
              class="btn btn-primary-outline p-1 px-2 rounded-0 btn-arrow"
              @click="btnpage(+1)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                style="transform: ; msfilter: "
              >
                <path
                  d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="mt-4 pt-4 pb-5 mb-4 text-center w-100">
          Maaf belum terdapat data transaksi
        </p>
      </div>
    </b-container>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import SkeletonTable from "../Skeleton/Dashboard/SkeletonTransaksi.vue";
import Loader from "@/components/Loader/Loader.vue";
export default {
  data() {
    return {
      loading: true,
      items: [],
      fields: [
        { key: "index", label: "No" },
        { key: "invoice", label: "ID Pembelian" },
        { key: "updated_at", label: "Tanggal" },
        { key: "status", label: "Status" },
        { key: "Aksi", label: "Aksi" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 100],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterTitle: "",
      jmlh_value: 4,
      curentpage: 0,
      filterOn: [],
    };
  },
  components: {
    SkeletonTable,
    Loader,
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    sortedItems() {
      return this.items
        .slice()
        .sort(
          (a, b) =>
            new Date(b.updated_at.toLocaleString()) -
            new Date(a.updated_at.toLocaleString())
        );
    },
    filteredProducts() {
      // Filter the "name_product" from your data structure
      return this.sortedItems.filter((transaction) => {
        var transactiondata = transaction.transaction_detail.filter(
          (detail) =>
            detail.name_product.toLowerCase().includes(this.filterTitle.toLowerCase()) ||
            transaction.invoice.toLowerCase().includes(this.filterTitle.toLowerCase()) ||
            transaction.status.toLowerCase().includes(this.filterTitle.toLowerCase())
        );
        if (transactiondata.length > 0) {
          return transactiondata;
        }
      });
    },
    initPagination() {
      return Math.ceil(this.filteredProducts?.length / this.jmlh_value);
    },
    filteredData: function () {
      let pageStart = this.curentpage * this.jmlh_value;
      let pageEnd = this.jmlh_value * (this.curentpage * 1 + 1);

      return this.filteredProducts.filter((item, index) => {
        return index >= pageStart && index < pageEnd;
      });
    },
  },
  mounted() {
    this.loading = true;
    axios.get("/transaction/list-transaction").then((response) => {
      let datatransaksi = response.data.data;
      this.items = datatransaksi.reverse();
      this.loading = false;
    });
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  watch: {
    filterTitle() {
      this.curentpage = 0;
    },
  },
  methods: {
    dataimg(value){
      if(value.product_price.image != null){
        return value.product_price.image.image
      }else{
        return value.image
      }
       
    },
  selengkapnya(data){
    document.getElementById('produk'+data).classList.remove('hide-product')
    document.getElementById('gradient'+data).classList.remove('bg-gradient-card')
    document.getElementById('selengkapnya'+data).classList.add('d-none')
    document.getElementById('sembunyikan'+data).classList.remove('d-none')


  },
  sembunyikan(data){
    document.getElementById('produk'+data).classList.add('hide-product')
    document.getElementById('gradient'+data).classList.add('bg-gradient-card')
    document.getElementById('selengkapnya'+data).classList.remove('d-none')
    document.getElementById('sembunyikan'+data).classList.add('d-none')

  },
    btnpage(value) {
      let datapage = this.initPagination;

      if (this.curentpage <= 0 && value == -1) {
        this.curentpage = 0;
      } else if (this.curentpage <= 0 && value == -2) {
        this.curentpage = 0;
      } else if (this.curentpage >= 0 && this.curentpage < datapage - 1) {
        this.curentpage += value;
      } else if (this.curentpage >= 0 && this.curentpage <= datapage - 1 && value == -1) {
        this.curentpage += value;
      } else if (this.curentpage >= 0 && this.curentpage <= datapage - 1 && value == -2) {
        this.curentpage += value;
      }
    },
    toLowerCase(value) {
      return value.toLowerCase();
    },
    dateTime(value) {
      return moment(value).format("DD MMM YYYY");
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style scoped>
.content-produk-card{
  position: relative;
}
.button-selengkapnya{
  width: 100%;
  position: absolute;
  bottom: 0px;
  z-index: 30;
}
.button-selengkapnya p{
  cursor: pointer;
  margin-bottom: 0px !important;
}
.hide-product{
  overflow-y: hidden;
  height: 200px;
}
.bg-gradient-card{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 25;
  background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.6));
}
.bl-1 {
  border-left: 1px solid grey;
}
.settext-badge::first-letter {
  text-transform: capitalize;
}
.border-dash {
  border-style: dashed solid;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.card-web {
  display: none;
  min-width: 125px;
}
.d-web {
  display: none;
}
.grid-card {
  display: grid;
  align-items: center;
}
.w-datatable {
  overflow-x: auto;
}
.select-tabel {
  height: 1.8rem;
}
.h-card-product {
  min-height: 100px;
}
::v-deep .search_tabel .input-group-prepend {
  background-color: transparent;
}
::v-deep .search_tabel .input-group-prepend .input-group-text {
  background-color: transparent;
  border-right: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
::v-deep .search_tabel input {
  border-left: 0px !important;
}
.w-invoice {
  width: 225px !important;
}
.w-updated_at {
  width: 95px !important;
}
.w-datatable {
  overflow-x: auto;
}
.select-tabel {
  height: 1.8rem;
}
.image-produk {
  width: auto;
  height: auto;
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
  margin: 0px !important;
}
.image-produk img {
  width: 100%;
}
.pt-badge {
  padding-top: 0.4rem;
}
.w-invoice {
  width: 225px !important;
}
.w-updated_at {
  width: 95px !important;
}
.total-belanja small {
  font-size: 0.8rem;
}

.total-belanja h6 {
  font-weight: 600;
}
@media (max-width: 500px) {
  .justify-mobile {
    width: 100%;
    justify-content: space-between;
  }
  .d-mobile {
    display: block;
  }
}

@media (min-width: 510px) {
  .d-mobile {
    display: none;
  }
}

@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .header-table {
    display: flex;
    justify-content: space-between;
  }
  .card-web {
    display: block !important;
  }
  .d-web {
    display: block !important;
  }
}

@media (min-width: 1023px) {
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>
