<template>
  <div>
    <div>
      
      <b-button v-b-toggle.sidebarbackdrop class="btn-trasnparant">
        Semua Kategori
      </b-button>
      <b-sidebar class="datasidebar" id="sidebarbackdrop" ref="sidebarbackdrop" title="" backdrop shadow>
        <router-link class="navbar-brand p-3" to="/">
          <figure class="img-logo-mobile pt-2">
            <img src="../assets/logo_arys_mikro.jpeg" alt="" />
          </figure>
        </router-link>
        <hr class="mt-2 mb-2" />
        <div class="px-3 py-2">
          <h3><b>Kategori</b></h3>
          <!-- <div class="accordion" role="tablist">
            <b-card no-body class="mb-1 no-border">
              <b-card-header header-tag="header" class="w-100 p-0 no-border" role="tab">
                <b-button block v-b-toggle.accordion-1 class="w-100 nav-link text-left bg-transparant">Accordion 1</b-button>
              </b-card-header>
              <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>adadad</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1 no-border">
              <b-card-header header-tag="header" class="w-100 p-0 no-border" role="tab">
                <b-button block v-b-toggle.accordion-2 class="w-100 nav-link text-left bg-transparant">Accordion 2</b-button>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>adadadgdgdg</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1 no-border">
              <b-card-header header-tag="header" class="w-100 p-0 no-border" role="tab">
                <b-button block v-b-toggle.accordion-3 class="w-100 nav-link text-left bg-transparant">Accordion 3</b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>hhfhfhfh</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div> -->
          <ul class="ul-sidebar">
            <template v-for="(datakategori, index) in kategori">
              <router-link
                v-if="datakategori?.subcategory?.length <= 0"
                :to="'/kategori?kategori=' + datakategori.id + '&stocked=1'"
                class="nav-link border-list py-1"
                @click.prevent=""
                :key="index"
              >
                <li class="nav-item pb-2 pt-3 px-2 position-relative" style="z-index: 2000;" :class="{'text-primary':route.query.kategori == datakategori?.id}" >
                  {{ datakategori.name }}
                </li>
              </router-link>
              <li
                class="p-1 border-list"
                :key="index"
                v-if="datakategori?.subcategory?.length > 0"
                :id="'tool-' + datakategori?.id"
                @click="dataklick(datakategori?.id)"
              > 
              
      <div :id="'dropdownid'+datakategori?.id" class="dropdown-data d-none bg-white p-2">
        <router-link
                    class="link_kategori w-100 d-block"
                    :to="
                      '/kategori?kategori=' +
                      datakategori.id +
                      '&sub_kategori=' +
                      subkategory.id +
                      '&stocked=1'
                    "
                    v-for="(subkategory, dataindex) in datakategori?.subcategory"
                    :key="dataindex"
                    ><span class="px-1" :class="{'text-primary':route.query.sub_kategori == subkategory.id}">{{ subkategory?.name }}</span>
                    <hr
                      style="margin: 5px 0px !important"
                      class="w-100"
                      v-if="datakategori?.subcategory?.length > 1"
                  /></router-link>
      </div>
                <b-card no-body class="mb-1 no-border bg-transparant z-index-height">
                  <b-card-header
                    header-tag="header"
                    class="w-100 p-header-card no-border bg-transparant"
                    role="tab"

                  >
                    <b-button
                      block
                      v-b-toggle="'accordion-' + datakategori?.id"
                      class="w-100 nav-link text-left bg-transparant px-2 position-relative" style="z-index: 2000;" :class="{'text-primary':route.query.kategori == datakategori?.id}"
                      >{{ datakategori.name }}
                    </b-button>
                  </b-card-header>
                </b-card>
                <b-tooltip :target="'tool-' + datakategori?.id" tabindex="0" role="button" triggers="focus" placement="left" boundary="scrollParent">
                  <!-- <b-collapse :id="'accordion-'+datakategori?.id" accordion="my-accordion" class="tab_link" role="tabpanel">
                                  <b-card-body class="data-grid"> -->
                  <!-- <router-link
                    class="link_kategori w-100 d-block"
                    :to="
                      '/kategori?kategori=' +
                      datakategori.id +
                      '&sub_kategori=' +
                      subkategory.id +
                      '&stocked=1'
                    "
                    v-for="(subkategory, dataindex) in datakategori?.subcategory"
                    :key="dataindex"
                    ><span class="px-1" :class="{'text-primary':route.query.sub_kategori == subkategory.id}">{{ subkategory?.name }}</span>
                    <hr
                      style="margin: 5px 0px !important"
                      class="w-100"
                      v-if="datakategori?.subcategory?.length > 1"
                  /></router-link> -->
                  <!-- <b-card-text v></b-card-text> -->
                  <!-- </b-card-body>
                                </b-collapse> -->
                </b-tooltip>
                <!-- <b-tooltip :target="'tool-'+datakategori?.id" placement="topleft" class="web-tool"> -->
                <!-- <b-collapse :id="'accordion-'+datakategori?.id" accordion="my-accordion" class="tab_link" role="tabpanel">
                                  <b-card-body class="data-grid"> -->
                <!-- <router-link class="link_kategori" :to="'/kategori?kategori='+datakategori.id+'&sub_kategori='+subkategory.id+'&stocked=1'" v-for="(subkategory, dataindex) in datakategori?.subcategory" :key="dataindex"><span class="px-1">{{subkategory?.name}}</span> <hr style="margin:5px 0px !important" class="w-100" v-if="datakategori?.subcategory?.length>1"></router-link> -->
                <!-- <b-card-text v></b-card-text> -->
                <!-- </b-card-body>
                                </b-collapse> -->
                <!-- </b-tooltip> -->
              </li>
            </template>
          </ul>
        </div>
    <div class="bg-dropdown" @click="deletedropdown()">
    </div>
    <div class="bg-right" @click="closesidebar()">
    </div>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import Img_Default from "../assets/img/img-default.png";
export default {
  data() {
    return {
      check: false,
      isMobile: false,
      homebtn: false,
      searchdata: "",
      news: [],
      showMenu: false,
      loading: true,
      isCatActive: false,
      route: null,
      iddata:null
    };
  },
  async mounted() {
    try {
      this.route = this.$route
      this.loading = true;
      if (this.user instanceof Promise == true) {
        await this.$store.dispatch("user");
        this.$store.dispatch("address");
        this.$store.dispatch("carts");
      }
      if (this.user instanceof Promise == true) {
        this.check = false;
      } else if (this.user == undefined) {
        this.check = false;
      } else {
        this.check = true;
      }
      if (this.kategori.length == 0) {
        await this.$store.dispatch("category");
      }
      if (this.carts == [] || this.$store.state.carts[0] === undefined) {
        await this.$store.dispatch("carts");
      }
      this.chatrealtime();
      // if (this.berita instanceof Promise == true || this.berita == []) {
      //       await this.$store.dispatch("berita");
      //       this.news = this.berita
      //   }
      window.addEventListener("resize", this.checkScreenWidth);
      this.checkScreenWidth();
      this.userdata = this.user;
      if (this.userdata.image) {
        this.imageuser = this.$imgUrl + this.userdata.image;
      } else {
        this.imageuser = Img_Default;
      }
      this.news = this.berita;
      this.loading = false;
      if (window.location.pathname === "/") {
        this.homebtn = true;
      } else {
        this.homebtn = false;
      }
    } catch (error) {
      this.loading = false;
      return error;
    }
    this.$watch(
      () => this.$refs.sidebarbackdrop.style.display,
      (newValue) => {
        // console.log(oldValue);
        if (newValue === 'none') {
          // console.log('Style changed to "display: none;"');
          // Perform actions here when style changes to "display: none;"
        }
      }
    );
    window.addEventListener("scroll", this.onScroll);
  },
  methods:{
    closesidebar(){
      document.querySelector('.close').click();
      this.deletedropdown()
    },
    deletedropdown(){
      if(this.iddata != null){
        document.getElementById('dropdownid'+this.iddata).classList.remove('d-block')
      document.getElementById('dropdownid'+this.iddata).classList.add('d-none')
      this.iddata = null
      }
    },
    dataklick(data){
      if(this.iddata != null){
        document.getElementById('dropdownid'+this.iddata).classList.remove('d-block')
      document.getElementById('dropdownid'+this.iddata).classList.add('d-none')
      }
      this.iddata = data
      let id = document.getElementById('tool-'+this.iddata)
      if (id) {
            // Position
            var rect = id.getBoundingClientRect();
            var checkbottom = window.innerHeight - rect.bottom
            //var checktop = window.innerHeight - rect.top
            //const topPositionInVh = rect.top / window.innerHeight * 100
            // const bottomPositionInVh = rect.bottom / window.innerHeight * 100
            document.getElementById('dropdownid'+this.iddata).classList.remove('d-none')
            document.getElementById('dropdownid'+this.iddata).classList.add('d-block')
            
            let iddata = document.getElementById('dropdownid'+this.iddata)
            var rectcontent = iddata.getBoundingClientRect();
            
      // console.log(check);
      // console.log(bottomPositionInVh);
      // console.log(rectcontent.height);
      // console.log(checkbottom);
      // console.log(checktop);
      // console.log(topPositionInVh);
      // console.log(rect.top);
      // console.log(rect.bottom);
      // console.log(rectcontent.height / window.innerHeight * 100);
      
      // console.log(rect.top);
      // console.log(rect.bottom);
      // console.log(rectcontent);
      // console.log(rect);
            if(rectcontent.height < checkbottom ){
              // console.log("ke bawah");
            document.getElementById('dropdownid'+this.iddata).style.top = rect.top+'px'
            }else if(rectcontent.height <  rect.top){
              // console.log("ke atas");
              document.getElementById('dropdownid'+this.iddata).style.bottom = checkbottom+'px'
            }else{
              // console.log("ke tengah-tengah");
              document.getElementById('dropdownid'+this.iddata).style.top = rect.top+'px'
              document.getElementById('dropdownid'+this.iddata).style.height = rect.bottom+'px'
              document.getElementById('dropdownid'+this.iddata).style.overflowY = 'scroll'
            }


            // Height
            //var height = id.clientHeight;
            // console.log("Height:", height);

            // Content
            //var content = id.innerHTML;
            // console.log("Content:", content);
        } else {
            console.error("Element not found!");
        }
    }
  },
  computed: {
    berita: function () {
      return this.$store.state.berita;
    },
    carts: function () {
      return this.$store.state.carts;
    },
    statussunting() {
      return this.$store.state.suntingdata;
    },
    jmlhpesan: function () {
      return this.$store.state.jmlhpesan;
    },
    kategori: function () {
      return this.$store.state.category;
    },
    categorydata() {
      return this.kategori.map((datakategori) => ({
        datasub: datakategori.subcategory,
      }));
    },
    user: function () {
      return this.$store.state.user;
    },
    getMainNavClasses() {
      return {
        "scroll-up": this.scrollingUp,
        "scroll-down": this.scrollingDown,
      };
    },
  },

  watch: {
    async $route() {
      this.route = this.$route
      if (window.location.pathname === "/") {
        this.homebtn = true;
      }else {
        this.homebtn = false;
      }
    },
    '$refs.sidebarbackdrop.style.display'(newValue) {
      if (newValue === 'none') {
        // console.log('Style changed to display: none;');
      } else {
        // console.log('Style changed to display: ' + newValue + ';');
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.checkScreenWidth);
  },
};
</script>
<style scoped>
.p-header-card{
  padding: 0.85rem 0px 0px 0px;
}
.btn-trans {
  background-color: transparent !important;
  border: 0px !important;
  border-radius: 0px !important;
}
::v-deep .mobile-tool {
  display: block;
}
::v-deep .close{
  z-index: 30 !important;
}
::v-deep .web-tool {
  display: none;
}
::v-deep .ul-sidebar {
  list-style-type: none !important;
}
::v-deep .tooltip .tooltip-inner {
  background-color: #fff !important;
  color: #000 !important;
  position: relative;
  width: 250px;
  left: -30px;
  /* max-width: 400px; */
}
.z-index-height{
  z-index: 30 !important;
}
.bg-dropdown{
  height: 100vh;
  width: 100vh;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 20;
}
.bg-right{
  height: 100vh;
  width: 100vh;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 200px;
  right: 0px;
  z-index: 20;
}
.dropdown-data{
  position: absolute;
  border-radius: 5px;
  background-color: #fff;
  left: 200px;
  min-width: 150px;
  max-width: 200px;
  z-index: 25;
}
.floating-wa{
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 70px;
  right: 10px;
  z-index: 195;
}
.floating-wa figure{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.floating-wa figure img{
  width: 100%;
}
::v-deep .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}
::v-deep .collapsed > .when-open,
::v-deep .not-collapsed > .when-closed {
  display: none;
}
::v-deep .b-sidebar > .b-sidebar-header {
  height: 100px !important;
}
::v-deep .data-grid {
  display: grid !important;
}
.bg-dropdownuser {
  position: absolute;
  width: 100%;
  height: 100vh;
  bottom: 0px;
  top: 0px;
  left: 0px;
  display: none;
  z-index: 205;
}
.dropdown-user {
  width: 125px;
  height: 0px;
  border-radius: 5px !important;
  position: absolute;
  top: 50px;
  right: 15px;
  z-index: 210;
  overflow: hidden;
  transition: height 0.5s;
  border: 0px !important;
}

.list-group-custom {
  padding: 0px !important;
  border-radius: 0.5rem;
  list-style-type: none;
  border-radius: 10px;
}

.list-group-custom-item {
  color: #000;
  fill: #000;
  padding: 7px 0px !important;
  font-weight: 400 !important;
  width: 100%;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-decoration: none;
  cursor: pointer;
}
.kategori-header {
  font-size: 1rem;
}
.border-list {
  border-radius: 5px;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
}
.border-list:hover{
  background-color: rgba(47, 49, 146, 0.1);
}
.text-left {
  text-align: left !important;
  display: flex;
  justify-content: space-between;
}
.p-0 {
  padding: 0px !important;
}
.tab_link {
  background-color: transparent;
}
::v-deep .tab_link .card-body {
  background-color: transparent !important;
}
.link_kategori {
  line-height: 2;
  text-decoration: none !important;
  color: #000;
}
.link_kategori:hover{
  background-color: rgba(47, 49, 146, 0.1);
}
.no-border {
  border: 0px !important;
}
.bg-transparant {
  background-color: transparent !important;
  color: #000;
  border: 0px !important;
}
::v-deep .b-sidebar-header button {
  background-color: transparent !important;
  border: 0px !important;
}
::v-deep .datalink {
  color: #000 !important;
  text-decoration: none !important;
}
::v-deep .collapse a:active {
  background-color: transparent !important;
}
::v-deep .navbar-header {
  z-index: 200;
  background-color: #fff;
  position: fixed;
}
::v-deep .navbar {
  padding: 10px 0px;
}
::v-deep .navbar-nav {
  display: none;
}

::v-deep .navbar .nav-link {
  margin-bottom: 0px !important;
}
.img-nav {
  width: 25px;
  height: 25px;
  overflow: hidden;
  display: block;
  border: 2px solid rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  margin: 0px !important;
}
.img-nav img {
  width: 100%;
}
.small-nav {
  line-height: 2.5;
  font-weight: 600;
  padding-left: 5px;
  color: rgba(0, 0, 0, 0.6);
}
.span-cart-mobile {
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 0px 4px;
  font-size: 10px;
  border-radius: 15px;
  text-align: center;
}
.span-chat-mobile {
  position: absolute;
  background-color: red;
  color: #fff;
  padding: 0px 4px;
  font-size: 10px;
  border-radius: 15px;
  text-align: center;
}
.span-cart-web {
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 0px 4px;
  font-size: 10px;
  border-radius: 15px;
  text-align: center;
}
.navbar-nav input {
  width: 300px;
}
.d-show {
  display: block !important;
}
.d-none {
  display: none;
}

.scroll-nav {
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}
.color-light {
  color: #fff !important;
}
.color-light:hover {
  color: #fff !important;
}
.btn-daftar {
  margin-left: 0.5rem;
}
.btn-daftar:hover {
  color: #fff !important;
}
.btn-daftar:active {
  color: #fff !important;
}
.navbar {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
}
.nav-bottom {
  z-index: 200;
  position: fixed;
  bottom: 0;
  padding: 5px 0px;
  box-shadow: -5px -5px 20px rgba(1, 1, 1, 0.05);
}
::v-deep .nav-bottom .row {
  padding: 0px !important;
  margin: 0px !important;
}
::v-deep .col-3 {
  text-align: center;
}
::v-deep .nav-bottom .col-3,
.nav-bottom .col-3 small {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
}
.collapse-kategori {
  border: 0px !important;
  background-color: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}
::v-deep .collapse-kategori .card-body {
  display: flex;
  overflow-x: auto;
}
.navbar .nav-mobile {
  display: block;
}
.navbar .nav-mobile input {
  max-width: 140px;
  border: 0px !important;
}
.navbar .nav-mobile input:hover,
.navbar .nav-mobile input:focus,
.navbar .nav-mobile input:focus-visible {
  border: 0px !important;
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0.25) !important;
  outline: -webkit-focus-ring-color auto 0px !important;
}
.link-cart-mobile {
  padding-top: 5px;
}
.btn-mobile {
  border: 0px !important;
}
.navbar .btn-nav {
  background-color: transparent !important;
}
.navbar .navbar-brand {
  display: flex;
  align-items: center;
}
.navbar .navbar-brand .mobile-nav {
  display: none;
}

.navbar .navbar-collapse {
  justify-content: space-between;
}
.navbar .nav-link {
  font-weight: 400;
  color: #000;
  font-size: 0.8rem;
  text-transform: capitalize;
}
/* .navbar .nav-link:hover {
  color: #000 !important;
} */
/* .navbar .nav-link:active {
  color: #000 !important;
} */
.navbar .justify-content-right {
  justify-content: right !important;
}
.img-logo {
  max-width: 150px;
  margin: 0 auto;
}
.img-logo-mobile img {
  height: 60px !important;
}
.img-logo img {
  width: 100%;
}
.border-cart {
  border-right: solid 2px #fff !important;
}
.datasidebar .navbar-brand {
  position: absolute;
  top: -5px;
}
.datasidebar .navbar-brand img {
  height: 60px;
}
.datalink {
  color: #000 !important;
  text-decoration: none !important;
}

.datalink:hover {
  background-color: transparent !important;
}
.datalink:active {
  background-color: transparent !important;
}

.middle {
  position: absolute;
  border-radius: 50px;
  padding-right: 15px;
  border: 1px solid #000;
}
.middcliked {
  padding-right: 30px !important;
}
.input_search {
  width: 0.6rem;
  height: 0.1rem;
  background: none;
  outline: none;
  border: 0px;
  transition-property: width, height;
  transition-duration: 0.8s;
  transition-timing-function: ease-in-out;
  color: #000;
  font-size: 1rem;
}
.border-right {
  border-right: 3px solid #2f3192a5;
}
.input-group input,
.input-group input:focus,
.input-group input:focus-visible {
  border-right: 0px !important;
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0.25) !important;
  outline: -webkit-focus-ring-color auto 0px !important;
}
.input-group button,
.input-group .input-group-append span {
  border-left: 0px !important;
  cursor: pointer;
}
.incliked {
  height: 32px;
  border-radius: 40px;
  padding: 0 1rem;
  transition-property: width, height;
  transition-duration: 0.8s;
  transition-timing-function: ease-in-out;
}
.btndata svg {
  width: 25px;
  height: 25px;
}

@media (max-width: 500px) {
  ::v-deep .b-sidebar {
    width: 200px !important
    ;
  }
  .border-list{
    font-size: 0.85rem !important;
  }
  ::v-deep .ul-sidebar {
    list-style-type: none !important;
    padding-left: 0px !important;
  }
  ::v-deep .tooltip {
    left: 200px !important;
  }
  ::v-deep .tooltip .tooltip-inner {
    width: 150px !important;
  }
}
@media (min-width: 374px) {
  .navbar .nav-mobile input {
    max-width: 205px;
  }
}

@media (min-width: 424px) {
  .navbar .nav-mobile input {
    width: 240px;
    max-width: 250px;
  }
}
@media (max-width: 767.98px) {
  .navbar.navbar-brand {
    margin: 0;
  }
  /* ::v-deep .tooltip{ */
  /* transform: translate3d(30px, 190px, 0px) !important; */
  /* } */
}
@media (min-width: 767px) {
  .nav-link {
    padding: 0.38rem 1rem;
  }
  .floating-wa{
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 15px;
  right: 20px;
}
.floating-wa figure{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.floating-wa figure img{
  width: 100%;
}
  ::v-deep .ul-sidebar {
    list-style-type: none !important;
    padding-left: 10px !important;
  }
  .small-nav {
    max-width: 40px;
    overflow-x: hidden;
  }
  ::v-deep .mobile-tool {
    display: none;
  }
  ::v-deep .web-tool {
    display: block;
  }
  ::v-deep .tooltip .tooltip-inner {
    background-color: #fff !important;
    position: relative;
    width: 300px;
    /* max-width: 400px; */
    left: 14.5rem;
  }
  .navbar .nav-mobile {
    display: none;
  }
  ::v-deep .navbar-nav {
    display: flex;
  }
  .nav-bottom {
    display: none;
  }
  .component-nav-left {
    width: 100px;
    display: flex;
  }
  ::v-deep .btn-kategori-nav {
    background-color: transparent !important;
    border: 0px !important;
    color: rgba(0, 0, 0, 0.5) !important;
    padding-top: 10px;
  }
  ::v-deep .btn-kategori-nav:hover {
    background-color: transparent !important;
    border: 0px !important;
    color: rgba(0, 0, 0, 0.5) !important;
    padding-top: 10px;
  }
  .btn-kategori-nav small {
    color: rgba(0, 0, 0, 0.5);
  }
  .navbar {
    padding: 10px;
  }
  .incliked {
    width: 10rem;
  }
  .navbar-nav input {
    width: 175px;
  }
  .img-logo {
    max-width: 100px;
  }
}
@media (max-width: 1023px) {
  .nav-right {
    margin-bottom: 10px;
  }
}
@media (min-width: 1023px) {
  .small-nav {
    max-width: 50px;
  }
  
  .link-data-name {
    padding: 0.5rem 0.5rem 0.1rem 0.5rem !important;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .nav-cart {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .nav-cart:hover {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .nav-user {
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .nav-user:hover {
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .img-logo {
    max-width: 150px;
  }
  .btn-kategori-nav {
    padding-left: 20px;
  }
  .navbar-nav input {
    width: 325px;
  }
}
@media (min-width: 1023px) {
  .navbar {
    padding: 10px;
  }
  .component-nav-left {
    width: 150px;
  }
}
@media (min-width: 1200px) {
  .small-nav {
    max-width: 85px;
  }
  .container-nav {
    width: 1200px !important ;
    margin-left: auto;
    margin-right: auto;
  }
  .nav-link {
    padding: 0.38rem 1rem;
  }
  .link-data-name {
    padding: 0.5rem 0.5rem 0.1rem 0.5rem !important;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .nav-cart {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .nav-cart:hover {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .nav-user {
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .nav-user:hover {
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .incliked {
    width: 360px;
  }
  .navbar-collapse {
    max-width: 1140px;
    margin: auto;
  }
  .btn-kategori-nav {
    padding-left: 30px;
  }
  .navbar-nav input {
    width: 425px;
  }
}
@media (min-width: 1439px) {
  .navbar-collapse {
    max-width: 1320px;
    margin: auto;
  }
  .navbar-nav input {
    width: 525px;
  }
}
</style>

