<template>
  <div class="home">
    <Navbar/>
    <Checkout/>
    <Footer/>
  </div>
</template>

<script>
import Navbar from '@/components/NavbarUno.vue'
import Footer from '@/components/FooterUno.vue'
import Checkout from '@/components/Checkout/DataCheckout.vue'

export default {
  name: 'HomeView',
  components: {
    Navbar,
    Footer,
    Checkout,
  }
}
</script>
