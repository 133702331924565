import { render, staticRenderFns } from "./404_not_found.vue?vue&type=template&id=c2121d82&scoped=true"
var script = {}
import style0 from "./404_not_found.vue?vue&type=style&index=0&id=c2121d82&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2121d82",
  null
  
)

export default component.exports