<template>
    <div class="card-dashboard p-3 p-lg-4">

        <div class="form-profile-body">
            <Transaksi/>
        </div>
    </div>
</template>

<script>
import Transaksi from '@/components/Transaksi/TableTransaksi.vue'

export default {
    name: 'form-profil',
    data(){
        return {
        }
    },

    components : {
        Transaksi
    },

    methods: {
    }
}
</script>

<style>

.card-dashboard{
    border-radius: 10px;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}
.header-transaksi{
    display: flex;
    justify-content: space-between;
}
.header-transaksi h4{
    font-weight: 600;
}
</style>
